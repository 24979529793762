const defaultKeyboard = {
    lower: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
        ['shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'back'],
        ['ipa', ',', 'space', '.', 'done'],
    ],
    upper: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'back'],
        ['ipa', ',', 'space', '.', 'done'],
    ],
    vowel: [
        ['i', '-y', 'ɪ', '-ɨ', 'ʊ', 'u'],
        ['e', '-ø', 'ə', 'o'],
        ['consonant', 'ɛ', '-œ', 'ɜ', 'ɔ', 'back'],
        ['abc', 'æ', 'a', 'ɑ', 'done'],
    ],
    consonant: [
        /*
        ['p+0', 'b0', 't+0', 'd0', 'k+0', 'ɡ0', 's+1', 'z1', 'ʃ+1', 'ʒ1'],
        ['-β1', 'f+1', 'v1', 'θ+1', 'ð1', '-ʝ1', '-x+1', '-ɣ1', '-ʁ1', 'h+1'],
        ['vowel', 'm2', 'n2', '-ɲ2', 'ŋ2', '-ɾ3', '-r3', 'ɹ3', 'back'],
        ['abc', 'j', 'l', 'ɫ', '-ʎ', 'ɥ', 'w', 'tʃ', 'dʒ', 'done'],
        */
        ['p+0', 'b0', 't+0', 'd0', 'k+0', 'ɡ0', 's+1', 'z1', 'ʃ+1', 'ʒ1'],
        ['-β1', 'f+1', 'v1', 'θ+1', 'ð1', '-ʝ1', '-x+1', '-ɣ1', 'tʃ', 'dʒ'],
        ['m2', 'n2', '-ɲ2', 'ŋ2', '-ɾ3', '-r3', 'ɹ3', '-ʁ1'],
        ['vowel', 'j', 'l', 'ɫ', '-ʎ', 'ɥ', 'h+1', 'w', 'done'],
    ],
    ipa_vowel: 'vaʊəl',
    ipa_cononant: 'kɑnsənənt',
};

export const keyboards = {
    en_us: defaultKeyboard,
    es_es: {
        lower: [
            ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
            ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ñ'],
            ['shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        upper: [
            ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
            ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ñ'],
            ['shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        vowel: [
            ['i', '-y', '-ɪ', '-ɨ', '-ʊ', 'u'],
            ['e', '-ø', '-ə', 'o'],
            ['consonant', '-ɛ', '-œ', 'ɜ', '-ɔ', 'back'],
            ['abc', '-æ', 'a', '-ɑ', 'done'],
        ],
        consonant: [
            ['p+0', 'b0', 't+0', 'd0', 'k+0', 'ɡ0', 's+1', 'z1', 'ʃ+1', '-ʒ1'],
            ['β1', 'f+1', '-v1', 'θ+1', 'ð1', 'ʝ1', 'x+1', 'ɣ1', '-ʁ1', '-h+1'],
            ['vowel', 'm2', 'n2', 'ɲ2', 'ŋ2', 'ɾ3', 'r3', '-ɹ3', 'back'],
            ['abc', '-j', 'l', '-ɫ', 'ʎ', '-ɥ', 'w', 'tʃ', 'dʒ', 'done'],
        ],
        ipa_vowel: 'bokal',
        ipa_cononant: 'konsonante',
    },
    es_mx: {
        lower: [
            ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
            ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ñ'],
            ['shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        upper: [
            ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
            ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ñ'],
            ['shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        vowel: [
            ['i', '-y', '-ɪ', '-ɨ', '-ʊ', 'u'],
            ['e', '-ø', '-ə', 'o'],
            ['consonant', '-ɛ', '-œ', 'ɜ', '-ɔ', 'back'],
            ['abc', '-æ', 'a', '-ɑ', 'done'],
        ],
        consonant: [
            ['p+0', 'b0', 't+0', 'd0', 'k+0', 'ɡ0', 's+1', 'z1', 'ʃ+1', '-ʒ1'],
            ['β1', 'f+1', '-v1', '-θ+1', 'ð1', 'ʝ1', 'x+1', 'ɣ1', '-ʁ1', '-h+1'],
            ['vowel', 'm2', 'n2', 'ɲ2', 'ŋ2', 'ɾ3', 'r3', '-ɹ3', 'back'],
            ['abc', '-j', 'l', '-ɫ', 'ʎ', '-ɥ', 'w', 'tʃ', 'dʒ', 'done'],
        ],
        ipa_vowel: 'bokal',
        ipa_cononant: 'konsonante',
    },
    fr_fr: {
        lower: [
            ['a', 'z', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
            ['q', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ù'],
            ['shift', 'w', 'x', 'c', 'v', 'b', 'n', 'm', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        upper: [
            ['A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
            ['Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'µ'],
            ['shift', 'W', 'X', 'C', 'V', 'B', 'N', 'M', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        vowel: [
            ['i', 'y', 'ɪ', '-ɨ', 'ʊ', 'u'],
            ['e', 'ø', 'ə', 'o'],
            ['consonant', 'ɛ̃+', 'œ̃+', 'ɜ', 'ɔ̃+', 'back'],
            ['abc', '-æ', 'a', 'ɑ̃+', 'done'],
        ],
        consonant: [
            ['p+0', 'b0', 't+0', 'd0', 'k+0', 'ɡ0', 's+1', 'z1', 'ʃ+1', 'ʒ1'],
            ['-β1', 'f+1', 'v1', '-θ+1', '-ð1', '-ʝ1', 'x+1', '-ɣ1', 'ʁ1', '-h+1'],
            ['vowel', 'm2', 'n2', 'ɲ2', 'ŋ2', '-ɾ3', '-r3', '-ɹ3', 'back'],
            ['abc', '-j', 'l', '-ɫ', '-ʎ', 'ɥ', 'w', 'tʃ', 'dʒ', 'done'],
        ],
        ipa_vowel: 'vwajɛl',
        ipa_cononant: 'kɔ̃sɔn',
    },
    it_it: {
        lower: [
            ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
            ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ù'],
            ['shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'back'],
            ['ipa', ',', 'spc', '.', 'è', 'ù', 'ò', 'à', 'done'],
        ],
        upper: [
            ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
            ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'ç'],
            ['shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'back'],
            ['ipa', ',', 'spc', '.', 'é', '§', 'ç', '°', 'done'],
        ],
        vowel: [
            ['i', 'y', 'ɪ', 'ɨ', 'ʊ', 'u'],
            ['e', 'ø', 'ə', 'o'],
            ['consonant', 'ɛ', 'œ', 'ɜ', 'ɔ', 'back'],
            ['abc', 'æ', 'a', 'ɑ', 'done'],
        ],
        consonant: [
            ['p+0', 'b0', 't+0', 'd0', 'k+0', 'ɡ0', 's+1', 'z1', 'ʃ+1', 'ʒ1'],
            ['β1', 'f+1', 'v1', 'θ+1', 'ð1', 'ʝ1', 'x+1', 'ɣ1', 'ʁ1', 'h+1'],
            ['vowel', 'm2', 'n2', 'ɲ2', 'ŋ2', 'ɾ3', 'r3', 'ɹ3', 'back'],
            ['abc', 'j', 'l', 'ɫ', 'ʎ', 'ɥ', 'w', 'tʃ', 'dʒ', 'done'],
        ],
        ipa_vowel: 'vokale',
        ipa_cononant: 'konsonante',
    },
    pt_pt: {
        lower: [
            ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
            ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ç'],
            ['shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        upper: [
            ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
            ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ç'],
            ['shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        vowel: [
            ['i', 'y', 'ɪ', 'ɨ', 'ʊ', 'u'],
            ['e', 'ø', 'ə', 'o'],
            ['consonant', 'ɛ', 'œ', 'ɜ', 'ɔ', 'back'],
            ['abc', 'æ', 'a', 'ɑ', 'done'],
        ],
        consonant: [
            ['p+0', 'b0', 't+0', 'd0', 'k+0', 'ɡ0', 's+1', 'z1', 'ʃ+1', 'ʒ1'],
            ['β1', 'f+1', 'v1', 'θ+1', 'ð1', 'ʝ1', 'x+1', 'ɣ1', 'ʁ1', 'h+1'],
            ['vowel', 'm2', 'n2', 'ɲ2', 'ŋ2', 'ɾ3', 'r3', 'ɹ3', 'back'],
            ['abc', 'j', 'l', 'ɫ', 'ʎ', 'ɥ', 'w', 'tʃ', 'dʒ', 'done'],
        ],
        ipa_vowel: 'vuɡaɫ',
        ipa_cononant: 'kõswɐ̃tɨ',
    },
    pt_br: {
        lower: [
            ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
            ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ç'],
            ['shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        upper: [
            ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
            ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ç'],
            ['shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'back'],
            ['ipa', ',', 'space', '.', 'done'],
        ],
        vowel: [
            ['i', 'y', 'ɪ', 'ɨ', 'ʊ', 'u'],
            ['e', 'ø', 'ə', 'o'],
            ['consonant', 'ɛ', 'œ', 'ɜ', 'ɔ', 'back'],
            ['abc', 'æ', 'a', 'ɑ', 'done'],
        ],
        consonant: [
            ['p+0', 'b0', 't+0', 'd0', 'k+0', 'ɡ0', 's+1', 'z1', 'ʃ+1', 'ʒ1'],
            ['β1', 'f+1', 'v1', 'θ+1', 'ð1', 'ʝ1', 'x+1', 'ɣ1', 'ʁ1', 'h+1'],
            ['vowel', 'm2', 'n2', 'ɲ2', 'ŋ2', 'ɾ3', 'r3', 'ɹ3', 'back'],
            ['abc', 'j', 'l', 'ɫ', 'ʎ', 'ɥ', 'w', 'tʃ', 'dʒ', 'done'],
        ],
        ipa_vowel: 'voɡaw',
        ipa_cononant: 'kõswɐ̃tɨ',
    },
    ro_ro: {
        lower: [
            ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
            ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ș'],
            ['shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'back'],
            ['ipa', ',', 'spc', '.', 'ă', 'î', 'â', 'ț', 'done'],
        ],
        upper: [
            ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
            ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ș'],
            ['shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'back'],
            ['ipa', ',', 'spc', '.', 'Ă', 'Î', 'Â', 'Ț', 'done'],
        ],
        vowel: [
            ['i', '-y', '-ɪ', 'ɨ', '-ʊ', 'u'],
            ['e', '-ø', 'ə', 'o'],
            ['consonant', '-ɛ', '-œ', 'ɜ', '-ɔ', 'back'],
            ['abc', '-æ', 'a', '-ɑ', 'done'],
        ],
        consonant: [
            ['p+0', 'b0', 't+0', 'd0', 'k+0', 'ɡ0', 's+1', 'z1', 'ʃ+1', 'ʒ1'],
            ['-β1', 'f+1', 'v1', '-θ+1', '-ð1', '-ʝ1', '-x+1', 'ɣ1', '-ʁ1', 'h+1'],
            ['vowel', 'm2', 'n2', '-ɲ2', '-ŋ2', '-ɾ3', 'r3', '-ɹ3', 'back'],
            ['abc', 'j', 'l', '-ɫ', '-ʎ', '-ɥ', 'w', 'tʃ', 'dʒ', 'done'],
        ],
        ipa_vowel: 'vokalə',
        ipa_cononant: 'konswanə',
    },

    en_gb: defaultKeyboard,
    zh_cn: defaultKeyboard,
    zh_tw: defaultKeyboard,
    ja_jp: defaultKeyboard,
    ko_kr: defaultKeyboard,
    hi_in: defaultKeyboard,
    ru_ru: defaultKeyboard,
    de_de: defaultKeyboard,
    haw_hi: defaultKeyboard,
    ar_sa: defaultKeyboard,
};
