import { S3_SERVER } from '../constants';

const ImageLookup = new Map([
    ['d_01', { uri: `${S3_SERVER}/weather/01d.png` }],
    ['d_02', { uri: `${S3_SERVER}/weather/02d.png` }],
    ['d_03', { uri: `${S3_SERVER}/weather/03d.png` }],
    ['d_04', { uri: `${S3_SERVER}/weather/04d.png` }],
    ['d_09', { uri: `${S3_SERVER}/weather/09d.png` }],
    ['d_10', { uri: `${S3_SERVER}/weather/10d.png` }],
    ['d_11', { uri: `${S3_SERVER}/weather/11d.png` }],
    ['d_13', { uri: `${S3_SERVER}/weather/13d.png` }],
    ['d_50', { uri: `${S3_SERVER}/weather/50d.png` }],
    ['n_01', { uri: `${S3_SERVER}/weather/01n.png` }],
    ['n_02', { uri: `${S3_SERVER}/weather/02n.png` }],
    ['n_03', { uri: `${S3_SERVER}/weather/03n.png` }],
    ['n_04', { uri: `${S3_SERVER}/weather/04n.png` }],
    ['n_09', { uri: `${S3_SERVER}/weather/09n.png` }],
    ['n_10', { uri: `${S3_SERVER}/weather/10n.png` }],
    ['n_11', { uri: `${S3_SERVER}/weather/11n.png` }],
    ['n_13', { uri: `${S3_SERVER}/weather/13n.png` }],
    ['n_50', { uri: `${S3_SERVER}/weather/50n.png` }],

    ['waikiki_cn', { uri: `${S3_SERVER}/da_app_hawaii/waikiki_cn_overlay.png` }],
    ['waikiki_en', { uri: `${S3_SERVER}/da_app_hawaii/waikiki_en_overlay.png` }],
    ['waikiki_jp', { uri: `${S3_SERVER}/da_app_hawaii/waikiki_jp_overlay.png` }],
    ['waikiki_kr', { uri: `${S3_SERVER}/da_app_hawaii/waikiki_kr_overlay.png` }],
]);

/*

areas
brief
chance
definite
few
frequent
intermittent
isolated
likely
numerous
occasional
patchy
periods
scattered
slight_chance
widespread

---

blowing_dust
blowing_sand
blowing_snow
drizzle
fog
freezing_fog
freezing_drizzle
freezing_rain
freezing_spray
frost
hail
haze
ice_crystals
ice_fog
rain
rain_showers
sleet
smoke
snow
snow_showers
thunderstorms
volcanic_ash
water_spouts

---

very_light
light
moderate
heavy

*/

const ImageMap = new Map([
    ['waikiki_cn', ImageLookup.get('waikiki_cn')],
    ['waikiki_en', ImageLookup.get('waikiki_en')],
    ['waikiki_jp', ImageLookup.get('waikiki_jp')],
    ['waikiki_kr', ImageLookup.get('waikiki_kr')],

    // https://w1.weather.gov/xml/current_obs/weather.php
    // https://www.weather.gov/documentation/services-web-api
    ['d_skc', ImageLookup.get('d_01')],
    ['d_few', ImageLookup.get('d_02')],
    ['d_sct', ImageLookup.get('d_03')],
    ['d_bkn', ImageLookup.get('d_04')],
    ['d_ovc', ImageLookup.get('d_04')],
    ['d_wind_skc', ImageLookup.get('d_01')],
    ['d_wind_few', ImageLookup.get('d_02')],
    ['d_wind_sct', ImageLookup.get('d_03')],
    ['d_wind_bkn', ImageLookup.get('d_04')],
    ['d_wind_ovc', ImageLookup.get('d_04')],
    ['d_snow_showers', ImageLookup.get('d_13')],
    ['d_blowing_snow', ImageLookup.get('d_13')],
    ['d_snow', ImageLookup.get('d_13')],
    ['d_rain_snow', ImageLookup.get('d_13')],
    ['d_rain_sleet', ImageLookup.get('d_13')],
    ['d_snow_sleet', ImageLookup.get('d_13')],
    ['d_freezing_drizzle', ImageLookup.get('d_13')],
    ['d_freezing_rain', ImageLookup.get('d_13')],
    ['d_fzra', ImageLookup.get('d_13')],
    ['d_rain_fzra', ImageLookup.get('d_13')],
    ['d_snow_fzra', ImageLookup.get('d_13')],
    ['d_sleet', ImageLookup.get('d_13')],
    ['d_freezing_spray', ImageLookup.get('d_13')],
    ['d_frost', ImageLookup.get('d_13')],
    ['d_hail', ImageLookup.get('d_13')],
    ['d_ice_crystals', ImageLookup.get('d_13')],
    ['d_drizzle', ImageLookup.get('d_10')],
    ['d_rain', ImageLookup.get('d_10')],
    ['d_rain_showers', ImageLookup.get('d_10')],
    ['d_rain_showers_hi', ImageLookup.get('d_09')],
    ['d_thunderstorms', ImageLookup.get('d_11')],
    ['d_tsra', ImageLookup.get('d_11')],
    ['d_tsra_sct', ImageLookup.get('d_11')],
    ['d_tsra_hi', ImageLookup.get('d_11')],
    ['d_water_spouts', ImageLookup.get('d_04')],
    ['d_tornado', ImageLookup.get('d_04')],
    ['d_hurricane', ImageLookup.get('d_09')],
    ['d_tropical_storm', ImageLookup.get('d_09')],
    ['d_blowing_dust', ImageLookup.get('d_50')],
    ['d_blowing_sand', ImageLookup.get('d_50')],
    ['d_dust', ImageLookup.get('d_50')],
    ['d_volcanic_ash', ImageLookup.get('d_50')],
    ['d_smoke', ImageLookup.get('d_50')],
    ['d_haze', ImageLookup.get('d_50')],
    ['d_hot', ImageLookup.get('d_01')],
    ['d_cold', ImageLookup.get('d_01')],
    ['d_blizzard', ImageLookup.get('d_09')],
    ['d_freezing_fog', ImageLookup.get('d_50')],
    ['d_ice_fog', ImageLookup.get('d_50')],
    ['d_fog', ImageLookup.get('d_50')],

    ['n_skc', ImageLookup.get('n_01')],
    ['n_few', ImageLookup.get('n_02')],
    ['n_sct', ImageLookup.get('n_03')],
    ['n_bkn', ImageLookup.get('n_04')],
    ['n_ovc', ImageLookup.get('n_04')],
    ['n_wind_skc', ImageLookup.get('n_01')],
    ['n_wind_few', ImageLookup.get('n_02')],
    ['n_wind_sct', ImageLookup.get('n_03')],
    ['n_wind_bkn', ImageLookup.get('n_04')],
    ['n_wind_ovc', ImageLookup.get('n_04')],
    ['n_snow_showers', ImageLookup.get('n_13')],
    ['n_blowing_snow', ImageLookup.get('n_13')],
    ['n_snow', ImageLookup.get('n_13')],
    ['n_rain_snow', ImageLookup.get('n_13')],
    ['n_rain_sleet', ImageLookup.get('n_13')],
    ['n_snow_sleet', ImageLookup.get('n_13')],
    ['n_freezing_drizzle', ImageLookup.get('n_13')],
    ['n_freezing_rain', ImageLookup.get('n_13')],
    ['n_fzra', ImageLookup.get('n_13')],
    ['n_rain_fzra', ImageLookup.get('n_13')],
    ['n_snow_fzra', ImageLookup.get('n_13')],
    ['n_sleet', ImageLookup.get('n_13')],
    ['n_freezing_spray', ImageLookup.get('n_13')],
    ['n_frost', ImageLookup.get('n_13')],
    ['n_hail', ImageLookup.get('n_13')],
    ['n_ice_crystals', ImageLookup.get('n_13')],
    ['n_drizzle', ImageLookup.get('n_10')],
    ['n_rain', ImageLookup.get('n_10')],
    ['n_rain_showers', ImageLookup.get('n_10')],
    ['n_rain_showers_hi', ImageLookup.get('n_09')],
    ['n_thunderstorms', ImageLookup.get('n_11')],
    ['n_tsra', ImageLookup.get('n_11')],
    ['n_tsra_sct', ImageLookup.get('n_11')],
    ['n_tsra_hi', ImageLookup.get('n_11')],
    ['n_water_spouts', ImageLookup.get('n_04')],
    ['n_tornado', ImageLookup.get('n_04')],
    ['n_hurricane', ImageLookup.get('n_09')],
    ['n_tropical_storm', ImageLookup.get('n_09')],
    ['n_blowing_dust', ImageLookup.get('n_50')],
    ['n_blowing_sand', ImageLookup.get('n_50')],
    ['n_dust', ImageLookup.get('n_50')],
    ['n_volcanic_ash', ImageLookup.get('n_50')],
    ['n_smoke', ImageLookup.get('n_50')],
    ['n_haze', ImageLookup.get('n_50')],
    ['n_hot', ImageLookup.get('n_01')],
    ['n_cold', ImageLookup.get('n_01')],
    ['n_blizzard', ImageLookup.get('n_09')],
    ['n_freezing_fog', ImageLookup.get('n_50')],
    ['n_ice_fog', ImageLookup.get('n_50')],
    ['n_fog', ImageLookup.get('n_50')],
]);

export const GetMarkerImage = (key, scale = 1) => {
    let resource = key;
    if (resource.substring(0, 3) === 'bus') {
        if (scale > 10) {
            resource += 'Small';
        }
    }
    return ImageMap.get(resource);
};
