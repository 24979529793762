import React from 'react';
import { StyleSheet } from 'react-native';
import { Grid, Path, Chart as RNWChart, useLayout, useChart, useLine } from 'react-native-web-svg-charts';
import { Console } from '../utils';
import { Colors } from '../styles';

const NAME = 'Chart';


export const Chart = props => {

    const {
        data,
        style,
        color,
        dynamic,
    } = props;

    const { width, height, onLayout } = useLayout();
    const { x, y, ticks, mappedData } = useChart({ width, height, data, yMin: -1, yMax: 1 });
    const { line } = useLine({ mappedData, x, y });

    Console.stack(NAME, props, { width, height, x, y, ticks, mappedData, line });

    return (
        <RNWChart
            style={[styles.chart, style]}
            {...{ width, height, onLayout }}
        >
            <Grid
                y={y}
                ticks={ticks}
            />
            <Path
                d={line}
                fill={'none'}
                stroke={color ? color : Colors.colors.black}
                animate={dynamic}
                animationDuration={dynamic}
            />
        </RNWChart>
    );
};

const styles = StyleSheet.create({
    chart: {
    },
});
