import {useCallback, useMemo, useRef, useState} from 'react';
import {Console} from '../utils';
import {GENDER, PlatformTTS} from './platform';
import {TTS as OnlineTTS} from './utils';

const NAME = 'useTTS';

export const TTS_VARIANT = {
  PLATFORM: 'platform',
  DEEPGRAM: 'deepgram',
  SPEECHIFY: 'speechify',
  UNREAL: 'unreal',
};

export const useTTS = play => {
  const [tts] = useState(new OnlineTTS(play));

  const [transcript, setTranscript] = useState('');
  const setTranscriptRef = useRef(setTranscript);

  const getVariant = useCallback((variant, language) => {
    let result = variant;
    if (variant === TTS_VARIANT.SPEECHIFY) {
      if (
        !language.startsWith('en') &&
        !language.startsWith('es') &&
        !language.startsWith('fr')
      ) {
        Console.LOG(`${NAME}.speak unsupported speechify language`, {
          language,
        });
        result = TTS_VARIANT.PLATFORM;
      }
    } else if (variant !== TTS_VARIANT.PLATFORM) {
      if (!language.startsWith('en')) {
        Console.LOG(`${NAME}.speak unsupported language`, {
          variant,
          language,
        });
        result = TTS_VARIANT.PLATFORM;
      }
    }
    return result;
  }, []);

  const speak = useCallback(
    async (variant, text = null, language = 'en_us', gender = GENDER.MALE) => {
      const isFemale = gender === GENDER.FEMALE ? true : false;
      const ttsVariant = getVariant(variant, language);

      Console.log(`${NAME}.speak`, {
        variant,
        gender,
        language,
        text,
        transcript,
        isFemale,
        ttsVariant,
      });

      switch (ttsVariant) {
        case TTS_VARIANT.DEEPGRAM:
          await tts.deepgram(text, isFemale);
          break;
        case TTS_VARIANT.SPEECHIFY:
          await tts.speechify(text, isFemale, language);
          break;
        case TTS_VARIANT.UNREAL:
          await tts.unreal(text, isFemale);
          break;
        case TTS_VARIANT.PLATFORM:
        default:
          await PlatformTTS.Speak(
            text?.length ? text : transcript,
            language,
            gender,
          );
          break;
      }

      if (text?.length) {
        setTranscriptRef.current(text);
      }
    },
    [getVariant, setTranscriptRef, transcript, tts],
  );

  return useMemo(() => {
    Console.log(`${NAME} render`);
    return {speak};
  }, [speak]);
};
