// https://ui.docs.amplify.aws/react-native/getting-started/installation
import 'react-native-url-polyfill/auto';
import 'react-native-get-random-values';

import 'react-native-gesture-handler';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ActivityIndicator, LogBox, StyleSheet, Text, View } from 'react-native';
import { AmplifyLoader } from './aws';
import { MaterialCommunityIconsLoader, IconFontLoader, ThreeLoader } from './media';
import { Providers } from './context';
import { Router } from './navigation';
import { Console, Optional, Splash, Statistics } from './utils';
import { APP_NAME } from './constants';
import { Colors } from './styles';

// MARKMARK: Disable these occasionally to make sure things are cool
LogBox.ignoreAllLogs();
LogBox.ignoreLogs([
  'Setting a timer',
  'Require cycle',
  '[Intervention] Slow network',
  'Animated: `useNativeDriver`',
  'Cannot connect to Metro',
  '[react-native-gesture-handler] Seems like you\'re using an old API with gesture components, check out new Gestures system!',
  'EventEmitter.removeListener',
  'matter-js: Bodies.fromVertices: Install the \'poly-decomp\' library',
]);

const NAME = 'App';


const App = props => {

  const [amplify, setAmplify] = useState(false);
  const setAmplifyRef = useRef(setAmplify);

  const [font, setFont] = useState(false);
  const setFontRef = useRef(setFont);

  const [icons, setIcons] = useState(false);
  const setIconsRef = useRef(setIcons);

  const [three, setThree] = useState(false);
  const setThreeRef = useRef(setThree);

  const [loaded, setLoaded] = useState(false);
  const setLoadedRef = useRef(setLoaded);

  useEffect(
    () => {
      Console.log(`${NAME} useEffect`, { loaded, amplify, font, icons, three });
      if (!loaded && amplify && icons && font && three) {
        setLoadedRef.current(true);
      }
    },
    [
      amplify,
      font,
      icons,
      three,
      loaded,
      setLoadedRef,
    ],
  );

  useEffect(
    () => {
      if (loaded) {
        Console.log(`${NAME} useEffect loaded`);
        Splash.hide();
      }
    },
    [
      loaded,
    ],
  );

  Console.stack(NAME, props, { loaded, amplify, font, icons, three });

  return useMemo(
    () => {

      Statistics.Report({ id: 'App render' });

      Console.log(`${NAME} render`, { loaded, amplify, font, icons, three });
      return Optional(loaded, (
        <Providers>
          <Router />
        </Providers>
      ), (
        <View
          style={styles.view}
        >
          <Text style={styles.title} >
            {APP_NAME}
          </Text>
          <ActivityIndicator size={'large'} />
          <View
            style={styles.loading}
          >
            <Text style={styles.loadingText} >loading...</Text>
            <Text style={styles.loadingText} >cargando...</Text>
            <Text style={styles.loadingText} >chargement...</Text>
            <Text style={styles.loadingText} >caricamento...</Text>
            <Text style={styles.loadingText} >carregando...</Text>
            <Text style={styles.loadingText} >se încarcă...</Text>
            <Text style={styles.loadingText} >wird geladen...</Text>
            <Text style={styles.loadingText} >загрузка...</Text>
            <Text style={styles.loadingText} > 加載中...</Text>
            <Text style={styles.loadingText} >読み込んでいます...</Text>
            <Text style={styles.loadingText} >로드 중...</Text>
            <Text style={styles.loadingText} >लोड हो रहा है...</Text>
            <Text style={styles.loadingText} >جار التحميل...</Text>
            <Text style={styles.loadingText} >ke hoʻouka nei...</Text>

          </View>
          {Optional(!amplify, (
            <AmplifyLoader
              onLoaded={() => {
                setAmplifyRef.current(true);
                Console.log(`${NAME} amplify loaded`);
              }}
            />
          ))}
          {Optional(!font, (
            <IconFontLoader
              onLoaded={() => {
                setFontRef.current(true);
                Console.log(`${NAME} font loaded`);
              }}
            />
          ))}
          {Optional(!icons, (
            <MaterialCommunityIconsLoader
              onLoaded={() => {
                setIconsRef.current(true);
                Console.log(`${NAME} icons loaded`);
              }}
            />
          ))}
          {Optional(!three, (
            <ThreeLoader
              onLoaded={() => {
                setThreeRef.current(true);
                Console.log(`${NAME} three loaded`);
              }}
            />
          ))}
        </View>
      ));
    },
    [
      loaded,
      amplify,
      font,
      icons,
      three,
      setAmplifyRef,
      setFontRef,
      setIconsRef,
      setThreeRef,
    ],
  );
};

const styles = StyleSheet.create({
  view: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: '33%',
    backgroundColor: Colors.colors.white,
  },
  title: {
    fontWeight: 'bold',
  },
  loadingText: {
    textAlign: 'center',
  },
  loading: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default App;

/*** Begin: Implementation Notes ***\

0. Initial setup
  https://mmazzarolo.com/blog/2020-10-24-adding-react-native-web/

1. Configure AWS
  https://docs.amplify.aws/start/getting-started/installation/q/integration/react-native

2. Navigation
  https://enlear.academy/complete-guide-to-aws-amplify-and-react-native-59e455881d4f

3. signing
  https://developer.android.com/studio/publish/app-signing#generate-key
  (playstore-keystore.jks)

\*** End: Implementation Notes ***/
