// https://oblador.github.io/react-native-vector-icons/

const platformIcons = [
  ['Android'],
  ['Apple'],
  ['Web'],
  ['Unknown', 'help'],
];

const socialMediaIcons = [
  ['Android'],
  ['Apple'],
  ['AWS'],
  ['Facebook'],
  ['GitHub'],
  ['Google'],
  ['Instagram'],
  ['LinkedIn'],
  ['Twitter'],
  ['YouTube'],
  ['QRCode'],
  ['Email'],
];

const generalIcons = [
  ['Enter', 'exit-to-app'],
  ['Login'],
  ['Logout'],
  ['Microphone'],
  ['VolumeMute', 'volume-mute'],
  ['VolumeOff', 'volume-off'],
  ['VolumeOn', 'volume-high'],
  ['VolumeLow', 'volume-low'],
  ['Send', 'send'],
  ['Delete', 'delete-forever', 'delete-forever-outline'],
  ['ScrollToBottom', 'chevron-double-down'],
  ['Male', 'human-male'],
  ['Female', 'human-female'],
  ['Dimensions2D', 'video-3d-off'],
  ['Dimensions3D', 'video-3d'],
  ['Detail', 'card-bulleted', 'card-bulleted-outline'],
  ['Graph', 'graph', 'graph-outlined'],
  ['HandDown', 'hand-pointing-down'],
  ['HandUp', 'hand-pointing-up'],
  ['HandLeft', 'hand-pointing-left'],
  ['HandRight', 'hand-pointing-right'],
  ['Text', 'format-font'],
  ['Colors', 'palette', 'palette-outline'],
  ['Menu', 'menu'],
];

const headerIcons = [
  ['Back', 'arrow-left'],
  ['BackIOS', 'chevron-left'],
  ['FrontCamera', 'camera-front-variant'],
  ['BackCamera', 'camera-rear-variant'],
  ['Phone', 'phone', 'phone-outline'],
  ['AutoSendOn', 'text-to-speech'],
  ['AutoSendOff', 'text-to-speech-off'],
  ['Image', 'image-edit', 'image-edit-outline'],
  ['Theme', 'white-balance-sunny', 'moon-waning-crescent'],
  ['Translate', 'translate'],
  ['Help', 'help-circle', 'help-circle-outline'],
];

const controlIcons = [
  ['Play', 'play-circle', 'play-circle-outline'],
  ['Pause', 'pause-circle', 'pause-circle-outline'],
  ['SkipPrevious', 'skip-previous-circle', 'skip-previous-circle-outline'],
  ['SkipNext', 'skip-next-circle', 'skip-next-circle-outline'],
];

const applicationIcons = [
  ['Awards', 'trophy', 'trophy-outline'],
  ['Auth', 'logout'],
  ['Bots', 'account-multiple', 'account-multiple-outline'],
  ['Camera', 'camera-enhance', 'camera-enhance-outline'],
  ['Charts', 'equalizer', 'equalizer-outline'],
  ['Education', 'school', 'school-outline'],
  ['Games', 'puzzle', 'puzzle-outline'],
  ['Graph', 'graph', 'graph-outline'],
  ['Hand', 'hand-front-right', 'hand-front-right-outline'],
  ['Home', 'home', 'home-outline'],
  ['Info', 'information', 'information-outline'],
  ['Keyboard', 'keyboard', 'keyboard-outline'],
  ['KeyboardOff', 'keyboard-off', 'keyboard-off-outline'],
  ['Schedule', 'calendar-month', 'calendar-month-outline'],
  ['Search', 'card-search', 'card-search-outline'],
  ['Settings', 'cog', 'cog-outline'],
  ['Speech', 'account-tie-voice', 'account-tie-voice-outline'],
  ['School', 'school', 'school-outline'],
  ['Tools', 'wrench', 'wrench-outline'],
  ['User', 'account', 'account-outline'],
  ['Users', 'chat', 'chat-outline'],
  ['Video', 'video', 'video-outline'],
  ['Grid', 'grid'],
  ['Sound', 'sine-wave'],
];

const oahuIcons = [
  ['Oahu', 'island'],
  ['MapView', 'island'],
  ['Coupons', 'cash'],
  ['Calendar', 'calendar', 'calendar-outline'],
];

const iconName = icon => {
  const title = icon[0];
  const key = title.replace(/-/g, '_');
  const fallback = title.toLowerCase();
  const solid = icon.length > 1 ? icon[1] : fallback;
  const outline = icon.length === 3 ? icon[2] : icon.length === 2 ? icon[1] : fallback;
  return { key, title, solid, outline };
};

var icons = {};
[
  ...platformIcons,
  ...socialMediaIcons,
  ...generalIcons,
  ...headerIcons,
  ...controlIcons,
  ...applicationIcons,
  ...oahuIcons,
].forEach(icon => {
  const { key, title, solid, outline } = iconName(icon);
  const name = (dark = false) => dark ? solid : outline;
  icons[key] = { key, title, name };
});

export const Icons = icons;
