import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { SearchBar as RNESearchBar } from 'react-native-elements';
import { APP_TYPES, useAppDispatch, useAppState, useSystemState } from '../context';
import { Console, Numbers } from '../utils';
import { TextStyles } from '../styles';

const NAME = 'SearchBar';
const PLACEHOLDER = 'Enter search word';
const MATERIAL_COMMUNITY = 'material-community';


export const SearchBar = React.forwardRef((props, ref) => {

    const {
        style,
    } = props;

    const { deviceScale } = useSystemState();
    const { DEFAULT, search, t, theme } = useAppState();
    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);
    const [value, setValue] = useState(search.split(/(_|-|')/)[0]);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect value ${value}`);
            appDispatchRef.current({ type: APP_TYPES.SET_SEARCH, payload: value });
        },
        [
            value,
            appDispatchRef,
        ],
    );

    const { colors } = theme;
    const { onSurface, surface } = colors;
    const searchBarStyle = Numbers.ReScaleStyle(
        {
            ...TextStyles.searchBar,
            color: onSurface,
            backgroundColor: surface,
            ...style,
        },
        deviceScale,
    );

    Console.stack(NAME, props, { DEFAULT, deviceScale, theme, value });

    return (
        <RNESearchBar
            ref={ref}
            style={[styles.searchBarStyle, searchBarStyle]}
            containerStyle={styles.containerStyle}
            inputStyle={styles.inputStyle}
            inputContainerStyle={styles.inputContainerStyle}
            value={value}
            placeholder={t(PLACEHOLDER)}
            onChangeText={setValue}
            searchIcon={{ type: MATERIAL_COMMUNITY, name: 'magnify' }}
            clearIcon={{ type: MATERIAL_COMMUNITY, name: 'close' }}
            leftIconContainerStyle={styles.leftIconContainerStyle}
            rightIconContainerStyle={styles.rightIconContainerStyle}
            round={true}
        />
    );
});

const styles = StyleSheet.create({
    searchBarStyle: {
        //      fontSize: 8,
        //      fontColor: Colors.colors.lightred,
        //      backgroundColor: Colors.colors.red,
        //      color: Colors.colors.lightred,
    },
    containerStyle: {
        width: '100%',
        //      backgroundColor: Colors.colors.gray,
    },
    inputStyle: {
        //      fontSize: 8,
        //      fontColor: Colors.colors.lightgreen,
        //      backgroundColor: Colors.colors.green,
        //      color: Colors.colors.lightgreen,
    },
    inputContainerStyle: {
        //      fontSize: 8,
        //      fontColor: Colors.colors.lightblue,
        //      backgroundColor: Colors.colors.darkgray,
        //      color: Colors.colors.lightblue,
    },
    leftIconContainerStyle: {
        //      color: Colors.colors.black,
    },
    rightIconContainerStyle: {
        //      color: Colors.colors.white,
    },
});
