import {useCallback, /*useEffect,*/ useMemo, useRef, useState} from 'react';
import {Buffer} from 'buffer';
import {Console} from '../../utils';
import {STT} from '../utils';
import { ExtendableMediaRecorder/*, register*/ } from 'extendable-media-recorder';
//import {connect} from 'extendable-media-recorder-wav-encoder';

const NAME = 'useSTT.web';

const BITS_PER_SAMPLE = 16;
const CHANNELS = 1;
const SAMPLE_RATE = 16000;
const ENGLISH = 'en-US';

export const useSTT = (keepAlive = true, channels = CHANNELS, sampleRate = SAMPLE_RATE) => {

  const [stt, setSTT] = useState(null);
  const setSTTRef = useRef(setSTT);

  const [transcribing, setTranscribing] = useState(false);
  const setTranscribingRef = useRef(setTranscribing);

  const [transcript, setTranscript] = useState('');
  const setTranscriptRef = useRef(setTranscript);

  const [utterance, setUtterance] = useState('');
  const setUtteranceRef = useRef(setUtterance);

  const mediaRecorderRef = useRef(null);
/*
  useEffect(() => {
    Console.devLog(`${NAME} useEffect start`);
    (async () => {
      try {
        await register(await connect());
      } catch (error) {
        console.error(`${NAME} useEffect error`, {error});
      }
    })();
    Console.devLog(`${NAME} useEffect end`);
  }, []);
*/
  // initialize and start audio stream
  const startAudioStream = useCallback(
    socket => {
      Console.devLog(`${NAME}.startAudioStream`, {channels, sampleRate, socket});
      navigator.mediaDevices.getUserMedia({audio: true})
      .then(stream => {
        const options = {mimeType: 'audio/wav', audioBitsPerSecond: sampleRate * BITS_PER_SAMPLE * channels};
        const mediaRecorder = new ExtendableMediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;
        Console.devLog(`${NAME}.getUserMedia`, {mediaRecorder});

        mediaRecorder.ondataavailable = event => {
          Console.devLog(`${NAME} ondataavailable`, {event});
          if (event.data.size > 0) {
            const reader = new FileReader();
            reader.onload = () => {
              const binaryAudio = Buffer.from(reader.result);
              Console.devLog(`${NAME} onload`, {socket});
              socket.send(binaryAudio);
            };
            reader.readAsArrayBuffer(event.data);
          }
        };

        mediaRecorder.start(1000); // Collect 1 second chunks of audio
      })
      .catch(error => {
        Console.error(`${NAME}.startAudioStream error:`, error);
      });
    },
    [channels, sampleRate],
  );

  // stop the audio stream
  const stopAudioStream = useCallback(() => {
    Console.devLog(`${NAME}.stopAudioStream`);
    const mediaRecorder = mediaRecorderRef.current;
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
    }
  }, []);

  // set the transcribing flag
  const startTranscribing = useCallback(() => {
    Console.devLog(`${NAME}.startTranscribing`);
    setTranscribingRef.current(true);
  }, [setTranscribingRef]);

  // unset the transcription flag and call stopAudioStream
  const stopTranscribing = useCallback(() => {
    Console.devLog(`${NAME}.stopTranscribing`);
    stopAudioStream();
    setTranscribingRef.current(false);
  }, [setTranscribingRef, stopAudioStream]);

  // detect end-of-speech and call stopTranscription with final text
  const onSpeechEnded = useCallback(
    text => {
      Console.devLog(`${NAME}.onSpeechEnded`, {text});
      stopTranscription(text);
    },
    [stopTranscription],
  );

  // detect and store updates to the transcript
  const onTranscript = useCallback(
    text => {
      const message = text.trim();
      Console.devLog(`${NAME}.onTranscript`, {message});
      setTranscriptRef.current(message);
    },
    [setTranscriptRef],
  );

  const startTranscription = useCallback((language = ENGLISH) => {
      Console.devLog(`${NAME}.startTranscription`, {language, channels, sampleRate});

      // initialize and start stt socket
      const sttSocket = new STT(
        language,
        {channels, sampleRate},
        startTranscribing,
        stopTranscribing,
        onSpeechEnded,
        onTranscript,
      );
      sttSocket.start(keepAlive);
      setSTTRef.current(sttSocket);

      startAudioStream(sttSocket);
  }, [channels, keepAlive, onSpeechEnded, onTranscript, sampleRate, startAudioStream, startTranscribing, stopTranscribing]);

  const stopTranscription = useCallback(
    (text = null) => {
      // the final utterance will be the text if it is set,
      // otherwise transcript
      let message = transcript ? transcript.trim() : null;
      if (text?.length) {
        const textTrim = text.trim();
        if (textTrim.length) {
          message = textTrim;
        }
      }
      Console.devLog(`${NAME}.stopTranscription`, {message, text, transcript});
      if (stt) {
        Console.devLog(`${NAME}.stopTranscription: stopping stt`);
        stt.stop();
      }
      stopTranscribing();
      message?.length && setUtteranceRef.current(message);
    },
    [setUtteranceRef, stopTranscribing, stt, transcript],
  );

  Console.devLog(`${NAME}`, {channels, sampleRate});

  return useMemo(() => {
    return {
      startTranscription,
      stopTranscription,
      transcribing,
      transcript,
      utterance,
    };
  }, [startTranscription, stopTranscription, transcribing, transcript, utterance]);
};
