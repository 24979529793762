import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Auth } from '../aws';
import { Icons } from '../media';
import { Button, Screen, Switch, Text, TEXT_VARIANTS, View } from '../components';
import { useAppDispatch, useAppState, useUserDispatch, useUserState, APP_TYPES, USER_TYPES } from '../context';
import { Console, Optional } from '../utils';
import { Colors } from '../styles';
import { DEV_USER, VERBOSE } from '../constants';
//import { Navigate } from './utils';

const LEGACY_UI = false;

const NAME = 'Profile';

const LOGOUT = 'Logout';
const TOGGLE_ADS = 'Ads';

const TOGGLE_UI_ACQUILINGUA = 'AcquiLingua';
const TOGGLE_UI_DA_APP = 'DaʻApp Hawaiʻi';
const TOGGLE_UI_SPEAK = 'Speak';
const TOGGLE_UI_ASL = 'ASL';
const TOGGLE_UI_GAMES = 'Games';
const TOGGLE_UI_IPA = 'IPA';
const TOGGLE_UI_TOOLS = 'Tools';
const TOGGLE_UI_WORD5 = 'Word5';
const TOGGLE_UI_DICTIONARY = 'Dictionary';

//const CHANGE_PASSWORD = 'Change Password';


export const Profile = props => {

    /*
    const {
        navigation,
    } = props;
    */

    const { username } = useUserState();
    const { t, theme, themeUpdate, language, dev, ui_acquilingua, ui_daapp, ui_speak, ui_asl, ui_games, ui_ipa, ui_tools, ui_word5, ui_dictionary, showAds } = useAppState();
    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);
    const userDispatch = useUserDispatch();
    const userDispatchRef = useRef(userDispatch);

    const [logging, setLogging] = useState(VERBOSE.ENABLED);
    const [stack, setStack] = useState(VERBOSE.STACK);
    const [trace, setTrace] = useState(VERBOSE.TRACE);

    useEffect(
        () => {
            Console.Verbose(logging);
            Console.SetStack(stack);
            Console.SetTrace(trace);
        },
        [
            logging,
            stack,
            trace,
        ],
    );

    const onLogout = useCallback(
        async () => {
            await Auth.logout();
            Console.log(`${NAME}.onLogout`);
            userDispatchRef.current({ type: USER_TYPES.UNSET_USER });
        },
        [
            userDispatchRef,
        ],
    );

    const setDev = useCallback(
        payload => {
            appDispatchRef.current({ type: APP_TYPES.SET_DEV, payload });
        },
        [
            appDispatchRef,
        ],
    );

    const toggleAds = useCallback(
        () => {
            appDispatchRef.current({ type: APP_TYPES.SET_SHOW_ADS, payload: !showAds });
        },
        [
            appDispatchRef,
            showAds,
        ],
    );

    const toggleUI = useCallback(
        (type) => {
            appDispatchRef.current({ type });
        },
        [
            appDispatchRef,
        ],
    );

    const devSwitchEnabled = username === DEV_USER;

    Console.stack(NAME, props, { devSwitchEnabled, ui_acquilingua, ui_daapp, ui_speak, ui_asl, ui_games, ui_ipa, ui_tools, ui_word5, ui_dictionary, showAds, username, dev, logging, stack, trace });

    return useMemo(
        () => {
            /*
            navigation,
            t
            <Button
                value={CHANGE_PASSWORD}
                onPress={() => Navigate(navigation, t(CHANGE_PASSWORD), { title: t(CHANGE_PASSWORD) })}
            />
            */
            const { colors } = theme;
            const { primary, text } = colors;
            const leftWidth = { width: '70%' };
            const appColor = { color: primary };
            const textColor = { color: text };
            Console.log(`${NAME} render`, { language, themeUpdate });
            return (
                <Screen
                    {...props}
                    value={NAME}
                    mainHeight={'100%'}
                    mainStyle={styles.main}
                    mainView={(
                        <>
                            <View
                                style={styles.row}
                            >
                                <Text
                                    value={`${t('Name')}: ${username}`}
                                    variant={TEXT_VARIANTS.HEADLINE}
                                />
                                <Button
                                    value={LOGOUT}
                                    onPress={onLogout}
                                />
                            </View>
                            <View
                                style={styles.row}
                            >
                                <Switch
                                    switchColor={Colors.colors.lightgreen}
                                    leftLabel={TOGGLE_ADS}
                                    textVariant={TEXT_VARIANTS.TITLE}
                                    value={showAds}
                                    onValueChange={toggleAds}
                                />
                                {LEGACY_UI &&
                                <Switch
                                    switchColor={Colors.colors.lightgreen}
                                    leftLabel={'Dev'}
                                    textVariant={TEXT_VARIANTS.TITLE}
                                    value={dev}
                                    enabled={devSwitchEnabled}
                                    onValueChange={setDev}
                                />
                                }
                            </View>
                            {LEGACY_UI &&
                            <View
                                style={styles.switches}
                            >
                                {[
                                    { icon: 'Home', label: TOGGLE_UI_ACQUILINGUA, type: APP_TYPES.TOGGLE_UI_ACQUILINGUA, value: ui_acquilingua },
                                    { icon: 'Oahu', label: TOGGLE_UI_DA_APP, type: APP_TYPES.TOGGLE_UI_DA_APP, value: ui_daapp },
                                    { icon: 'Phone', label: TOGGLE_UI_SPEAK, type: APP_TYPES.TOGGLE_UI_SPEAK, value: ui_speak },
                                    { icon: 'Hand', label: TOGGLE_UI_ASL, type: APP_TYPES.TOGGLE_UI_ASL, value: ui_asl },
                                    { icon: 'Games', label: TOGGLE_UI_GAMES, type: APP_TYPES.TOGGLE_UI_GAMES, value: ui_games },
                                    { icon: 'Speech', label: TOGGLE_UI_IPA, type: APP_TYPES.TOGGLE_UI_IPA, value: ui_ipa },
                                    { icon: 'Tools', label: TOGGLE_UI_TOOLS, type: APP_TYPES.TOGGLE_UI_TOOLS, value: ui_tools },
                                    { icon: 'Grid', label: TOGGLE_UI_WORD5, type: APP_TYPES.TOGGLE_UI_WORD5, value: ui_word5 },
                                    { icon: 'Search', label: TOGGLE_UI_DICTIONARY, type: APP_TYPES.TOGGLE_UI_DICTIONARY, value: ui_dictionary },
                                ].map(({ icon, label, type, value }) => (
                                    <Switch
                                        key={`switch_ui_${label}`}
                                        switchColor={Colors.colors.lightgreen}
                                        leftLabel={label}
                                        leftStyle={[leftWidth, value ? appColor : textColor]}
                                        rightIcon={Icons[icon]}
                                        rightStyle={value ? appColor : textColor}
                                        textVariant={TEXT_VARIANTS.TITLE}
                                        value={value}
                                        noTranslate={true}
                                        onValueChange={() => toggleUI(type)}
                                    />
                                ))}
                            </View>
                            }
                            {Optional(dev, (
                                <View
                                    style={styles.switches}
                                >
                                    <Switch
                                        leftLabel={'Logging'}
                                        textVariant={TEXT_VARIANTS.SUBHEADING}
                                        value={logging}
                                        enabled={dev}
                                        onValueChange={setLogging}
                                    />
                                    <Switch
                                        leftLabel={'Stack'}
                                        textVariant={TEXT_VARIANTS.SUBHEADING}
                                        value={stack}
                                        enabled={dev && logging}
                                        onValueChange={setStack}
                                    />
                                    <Switch
                                        leftLabel={'Trace'}
                                        textVariant={TEXT_VARIANTS.SUBHEADING}
                                        value={trace}
                                        enabled={dev && logging}
                                        onValueChange={setTrace}
                                    />
                                    <Text
                                        value={'050904660188'}
                                    />
                                </View>
                            ))}
                        </>
                    )}
                />
            );
        },
        [
            theme,
            themeUpdate,
            t,
            language,
            props,
            ui_acquilingua,
            ui_daapp,
            ui_speak,
            ui_asl,
            ui_games,
            ui_ipa,
            ui_tools,
            ui_word5,
            ui_dictionary,
            showAds,
            username,
            devSwitchEnabled,
            dev,
            logging,
            stack,
            trace,
            onLogout,
            toggleAds,
            toggleUI,
            setDev,
            setLogging,
            setStack,
            setTrace,
        ],
    );
};

const styles = StyleSheet.create({
    main: {
        width: '80%',
        justifyContent: 'space-evenly',
    },
    row: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    switches: {
        //alignItems: 'space-between',
        justifyContent: 'space-evenly',
    },
});
