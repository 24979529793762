import {Console} from '../../utils';
import {sleep} from '../../utils';
import {GENDER, toLang, toLanguage, Voices} from './PlatformTTSVoices';

const NAME = 'PlatformTTS.web';

const synth = window.speechSynthesis;

let VOICES = null;

export class PlatformTTS {
  static async Voices() {
    if (!VOICES) {
      await sleep();
      const installedVoices = synth.getVoices();
      VOICES = Voices(
        installedVoices.map(v => ({
          id: v.voiceURI,
          language: v.lang,
          name: v.name,
        })),
      );
      Console.log(`${NAME}.Voices`, {VOICES});
    }
    return VOICES;
  }

  static async #GetVoices(lang, gender) {
    const voices = await PlatformTTS.Voices();
    if (!voices) {
      return null;
    }
    const langVoices = await voices[toLang(lang)];
    if (!langVoices) {
      return null;
    }
    const genderVoices = langVoices[gender];
    if (!genderVoices) {
      return null;
    }
    return Object.keys(genderVoices);
  }

  static async Speak(
    text,
    lang,
    rate = 1, // 0.01 - 10.0 MARKMARK: Normalize
    pitch = 1, // 0.0 - 2.0 MARKMARK: Normalize
    volume = 1, // 0.0 - 1.0
    voice = null,
    gender = GENDER.FEMALE,
  ) {
    const voices = await PlatformTTS.#GetVoices(lang, gender);
    const voiceId = voice ? voice : voices?.length ? voices[0] : null;

    Console.log(`${NAME}.Speak`, {
      text,
      lang,
      rate,
      pitch,
      volume,
      voice,
      voices,
      voiceId,
      language: toLanguage(lang),
      gender,
    });

    // https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesisUtterance
    let msg = new SpeechSynthesisUtterance(text);
    msg.voice = voiceId;
    msg.lang = toLanguage(lang);
    //msg.rate = rate; MARKMARK
    //msg.pitch = pitch; MARKMARK
    //msg.volume = volume; MARKMARK

    synth.speak(msg);

    return voiceId;
  }
}
