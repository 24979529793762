export const hawaii = {
    state: {
        island: {
            name: 'Island of Hawaiʻi',
            center: {
                latitude: 20.79,
                longitude: -157.53,
            },
            heading: 0,
            pitch: 0,
            zoom: 6.45,
        },
    },
    /**/
    hawaii: {
        island: {
            name: 'Island of Hawaiʻi',
            center: {
                latitude: 19.60,
                longitude: -155.51,
            },
            heading: 0,
            pitch: 0,
            zoom: 8.5,
        },
    },
    kauai: {
        island: {
            name: 'Kauaʻi',
            center: {
                latitude: 22.07,
                longitude: -159.54,
            },
            heading: 0,
            pitch: 0,
            zoom: 9.9,
        },
    },
    lanai: {
        island: {
            name: 'Lānaʻi',
            center: {
                latitude: 20.83,
                longitude: -156.93,
            },
            heading: 0,
            pitch: 0,
            zoom: 10.9,
        },
    },
    maui: {
        island: {
            name: 'Maui',
            center: {
                latitude: 20.85,
                longitude: -156.34,
            },
            heading: 0,
            pitch: 0,
            zoom: 9.3,
        },
    },
    molokai: {
        island: {
            name: 'Molokaʻi',
            center: {
                latitude: 21.16,
                longitude: -157.01,
            },
            heading: 0,
            pitch: 0,
            zoom: 9.7,
        },
    },
    /**/
    oahu: {
        island: {
            name: 'Oʻahu',
            center: {
                latitude: 21.48,
                longitude: -157.96,
            },
            heading: 0,
            pitch: 0,
            zoom: 9.65,
        },
        waikiki: {
            name: 'Waikiki',
            center: {
                latitude: 21.278,// 21.2748,
                longitude: -157.834,// -157.8355,
            },
            heading: -48,
            pitch: 0,
            zoom: 14.45,
        },
        /**/
        airport: {
            name: 'HNL',
            center: {
                latitude: 21.3388,
                longitude: -157.9086,
            },
            heading: 0,
            pitch: 0,
            zoom: 12.75,
        },
        kokohead: {
            name: 'Koko Head',
            center: {
                latitude: 21.2875,
                longitude: -157.67976,
            },
            heading: 0,
            pitch: 0,
            zoom: 12.95,
        },
        diamondhead: {
            name: 'Diamond Head',
            center: {
                latitude: 21.29155,
                longitude: -157.81342,
            },
            heading: 0,
            pitch: 0,
            zoom: 12.75,
        },
        southshore: {
            name: 'South Shore',
            center: {
                latitude: 21.29145,
                longitude: -157.83832,
            },
            heading: -45,
            pitch: 0,
            zoom: 12.75,
        },
        northshore: {
            name: 'North Shore',
            center: {
                latitude: 21.6423,
                longitude: -158.02275,
            },
            heading: 15,
            pitch: 0,
            zoom: 11.75,
        },
        haleiwa: {
            name: 'Haleʻiwa',
            center: {
                latitude: 21.599,
                longitude: -158.125,
            },
            heading: 22.5,
            pitch: 0,
            zoom: 12.75,
        },
        maili: {
            name: 'Māʻili',
            center: {
                latitude: 21.4243,
                longitude: -158.1823,
            },
            heading: 0,
            pitch: 0,
            zoom: 12.6,
        },
        kapolei: {
            name: 'Kapolei',
            center: {
                latitude: 21.3285,
                longitude: -158.0852,
            },
            heading: 0,
            pitch: 0,
            zoom: 12.7,
        },
        bellows: {
            name: 'Bellows',
            center: {
                latitude: 21.333,
                longitude: -157.6983,
            },
            heading: 0,
            pitch: 0,
            zoom: 12.0,
        },
        kaneohe: {
            name: 'Kaneohe',
            center: {
                latitude: 21.415,
                longitude: -157.7835,
            },
            heading: 0,
            pitch: 0,
            zoom: 13.1,
        },
        //uhmanoa: {},
        //dole: {},
        //pearlharbor: {}
        /**/
    },
};
