import { Colors } from '../../../styles';

const width = 3;

export const TRANSIT_MODES = {
    NONE: 'NONE',
    WALKING: 'WALKING',
    BICYCLING: 'BICYCLING',
    DRIVING: 'DRIVING',
    TRANSIT: 'TRANSIT',
};

export const TransitMode = [
    {
        mode: TRANSIT_MODES.NONE,
        width,
        color: Colors.colors.lightgray,
        icon: 'sign-direction-remove',
    },
    {
        mode: TRANSIT_MODES.WALKING,
        width,
        color: Colors.colors.red,
        icon: 'walk',
    },
    {
        mode: TRANSIT_MODES.BICYCLING,
        width,
        color: Colors.colors.orange,
        icon: 'bicycle',
    },
    {
        mode: TRANSIT_MODES.DRIVING,
        width,
        color: Colors.colors.lightgreen,
        icon: 'car-side',
    },
    {
        mode: TRANSIT_MODES.TRANSIT,
        width,
        color: Colors.colors.yellow,
        icon: 'bus',
    },
];

var transitModeMap = new Map();
TransitMode.forEach(v => transitModeMap.set(v.mode, v));

export const TransitModeMap = transitModeMap;
