import { Routes as AuthRoutes } from './auth.js';
import { Routes as InfoRoutes } from './info.js';
import { Routes as UserRoutes } from './user.js';

import { Routes as AcquiLinguaRoutes } from './acquilingua.js';
import { Routes as DaAppHawaiiRoutes } from './da_app_hawaii.js';
import { Routes as SpeakRoutes } from './speak.js';
import { Routes as ASLRoutes } from './asl.js';
import { Routes as GamesRoutes } from './games.js';
import { Routes as IPARoutes } from './ipa.js';
import { Routes as ToolsRoutes } from './tools.js';
import { Routes as Word5Routes } from './word5.js';
import { Routes as DictionaryRoutes } from './dictionary.js';

export const Routes = {
    Auth: AuthRoutes,
    Info: InfoRoutes,
    User: UserRoutes,
    Home: AcquiLinguaRoutes, // MARKMARK: dummy value for HOME to prevent crash in Navigation.js

    AcquiLingua: AcquiLinguaRoutes,
    DaAppHawaii: DaAppHawaiiRoutes,
    Speak: SpeakRoutes,
    ASL: ASLRoutes,
    Games: GamesRoutes,
    IPA: IPARoutes,
    Tools: ToolsRoutes,
    Word5: Word5Routes,
    Dictionary: DictionaryRoutes,
};
