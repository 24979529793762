export const GENDER = {UNKNOWN: 'unknown', MALE: 'male', FEMALE: 'female'};

export const ToggleGender = v =>
  v === GENDER.UNKNOWN
    ? GENDER.UNKNOWN
    : v === GENDER.MALE
    ? GENDER.FEMALE
    : GENDER.MALE;

export const toLang = language => {
  if (language.indexOf('-') < 0) {
    return language;
  }
  const tokens = language.split('-');
  if (!tokens?.length === 2) {
    return language;
  }
  return `${tokens[0].toLowerCase()}_${tokens[1].toLowerCase()}`;
};

export const toLanguage = lang => {
  if (lang.indexOf('_') < 0) {
    return lang;
  }
  const tokens = lang.split('_');
  if (!tokens?.length === 2) {
    return lang;
  }
  return `${tokens[0].toLowerCase()}-${tokens[1].toUpperCase()}`;
};

let BlacklistedVoices = new Set([
  'en-US|Albert',
  'en-US|Bad News',
  'en-US|Bahh',
  'en-US|Bells',
  'en-US|Boing',
  'en-US|Bubbles',
  'en-US|Cellos',
  'en-US|Fred',
  'en-US|Good News',
  'en-US|Jester',
  'en-US|Junior',
  'en-US|Kathy',
  'en-US|Organ',
  'en-US|Superstar',
  'en-US|Ralph',
  'en-US|Trinoids',
  'en-US|Whisper',
  'en-US|Wobble',
  'en-US|Zarvox',
]);

[
  'Eddy',
  'Flo',
  'Grandma',
  'Grandpa',
  'Reed',
  'Rocko',
  'Sandy',
  'Shelley',
].forEach(name => {
  [
    ['en-US', 'English', 'US'],
    ['en-GB', 'English', 'UK'],
    ['es-ES', 'Spanish', 'Spain'],
    ['es-MX', 'Spanish', 'Mexico'],
    ['fr-FR', 'French', 'France'],
    ['pt-BR', 'Portuguese', 'Brazil'],
    ['de-DE', 'German', 'Germany'],
    ['it-IT', 'Italian', 'Italy'],
  ].forEach(data => {
    BlacklistedVoices.add(`${data[0]}|${name} (${data[1]} (${data[2]}))`);
  });
});

export const Voices = installedVoices => {
  let result = {};
  installedVoices.forEach(voice => {
    const {id, lang, language, name, voiceURI} = voice;
    const _lang = lang ? toLang(lang) : language ? toLang(language) : null;
    const _language = lang
      ? toLanguage(lang)
      : language
      ? toLanguage(language)
      : null;
    const _id = id ? id : voiceURI;
    if (BlacklistedVoices.has(`${_language}|${name}`)) {
      return;
    }
    if (!result[_lang]) {
      result[_lang] = {};
    }
    const ANDROID_PREFIX = `${_language}-SMT`;
    let gender = GENDER.UNKNOWN;
    if (_id.indexOf(ANDROID_PREFIX) >= 0) {
      const g = _id.charAt(ANDROID_PREFIX.length);
      if (g === 'm') {
        gender = GENDER.MALE;
      } else if (g === 'f') {
        gender = GENDER.FEMALE;
      }
    }
    if (!result[_lang][gender]) {
      result[_lang][gender] = {};
    }
    if (!result[_lang][gender][id]) {
      result[_lang][gender][id] = voice;
    }
  });
  return result;
};
