import React, { createContext, useContext, useReducer } from 'react';
import { Array2Object, Console, Numbers } from '../utils';
import { GetMarkerImage } from '../media';

const NAME = 'OahuProvider';

export const KEYS = {
    AIRPORTS: 'airports',
  ATMS: 'atms',
  ATTRACTIONS: 'attractions',
    BEACHES: 'beaches',
  BIKI: 'biki',
  BUSES: 'buses',
  BUS_STOPS: 'busStops',
    CHURCHES: 'churches',
    FARMERS_MARKETS: 'farmersMarkets',
    GOLF: 'golf',
  HIKES: 'hikes',
    LOCATIONS: 'locations',
    MARINAS: 'marinas',
    MUSIC: 'music',
  SATELLITES: 'satellites',
  SURF: 'surf',
  TOILETS: 'toilets',
    TOP20: 'top20',
  WEATHER: 'weather',

    YELP_BARS: 'yelp_bars',
    YELP_COFFEE: 'yelp_coffee',
    YELP_DEPTSTORES: 'yelp_deptstores',
    YELP_GROCERY: 'yelp_grocery',
    YELP_HAWAIIAN: 'yelp_hawaiian',
    YELP_HOTELS: 'yelp_hotels',
    YELP_RESTAURANTS: 'yelp_restaurants',
};

class OahuProviderUtils {

  static CreateBusMap(buses) {
    var result = new Map();
    buses.forEach(bus => {
      const { id } = bus;
      if (!result.has(id)) {
        result.set(id, []);
      }
      result.get(id).push(bus);
    });
    return result;
  }

  static Transform(payload, type) {
    var transform = payload.map(item => {
      return {
        ...item,
        title: item?.id,
        coordinate: {
          latitude: item?.lat,
          longitude: item?.lng,
        },
      };
    });
    if (type === KEYS.BUSES) {
      return transform.map(item => {
        var result = { ...item, type };
        result.title = `${item.route}: ${item.sign}`;
        return result;
      });
    }
    if (type === KEYS.WEATHER) {
      return transform.map(item => {
        const { tokens, descriptions, indices } = item.weather;
        const { temperature } = item.properties;
        const indexMap = descriptions.map(v => {
          const t = v.split('|');
          //return `${tokens[+t[0]]}|${tokens[+t[1]]}|${tokens[+t[2]]}`;
          return tokens[+t[0]];
        });
        const values = indices.map(index => {
          return indexMap[+index];
        });
        var title = item.title;
        if (temperature?.length) {
          const degrees = [temperature[0] * 1.8 + 32.0, temperature[0]];
          title = `${Numbers.fix(degrees[0])}°F|${Numbers.fix(degrees[1])}°C`;
        }
        const MARKMARK_daynightPrefix = 'd';
        const image = item?.image ? item.image : GetMarkerImage(`${MARKMARK_daynightPrefix}_${values[0]}`);
        return {
          ...item,
          key: item.id,
          title,
          conditions: {
            values,
          },
          weather: values[0],
          report: {
            wind: '',
            humidity: '',
            rain: '',
          },
          image,
        };
      });
    }
    return transform.map(item => {
      var result = { ...item };
      [
        { key: 't', value: 'title' },
        { key: 'name', value: 'title' },

        { key: 'i', value: 'icon' },
        //{ key: 'r', value: 'iconColor' },
        { key: 'dif', value: 'difference' },
        { key: 'a', value: 'address' },
        { key: 'f', value: 'phone' },
        { key: 'p', value: 'timestamp' },
        { key: 'prc', value: 'price' },

        { key: 'descr', value: 'description' },
        { key: 'url', value: 'image_url' },

      ].forEach(entry => {
        if (result[entry.key]) {
          result[entry.value] = result[entry.key];
          delete result[entry.key];
        }
      });
      if (result?.c) {
        result.coordinate = {
            latitude: result.c.l,
            longitude: result.c.g,
        };
        delete result.c;
      }
      if (result?.s) {
        result.coordinates = result.s.map(_v => ({ latitude: _v?.l, longitude: _v?.g/*, elevation: _v?.z*/ }));
        result.coordinate = result.coordinates.length ? result.coordinates[0] : null;
        delete result.s;
      }
      if (result?.img) {
        const tokens = result.img.split('|');
        if (tokens.length === 2) {
            result.image_url = `https://s3-media${tokens[0]}.fl.yelpcdn.com/bphoto/${tokens[1]}/l.jpg`;
        } else {
            result.image_url = result.img;
        }
        delete result.img;
      }
      return result;
    });
  }
}

const types = [
  'UPDATE_BUSES',
  'UPDATE_SATELLITES',
  'UPDATE_LOCATIONS',
  'UPDATE_SURF',
  'UPDATE_WEATHER',
  'UPDATE_YELP',
];
export const TYPES = Array2Object(types);

const OahuStateContext = createContext();
const OahuDispatchContext = createContext();

var oahuState = {

  buses: [],
  busesUpdate: 1,

  satellites: [],
  satellitesUpdate: 1,

  locations: {},
  locationsUpdate: 1,

  surf: [],
  surfUpdate: 1,

  weather: [],
  weatherUpdate: 1,

  yelp: [],
  yelpUpdate: 1,

  busMap: new Map(),
};

const oahuReducer = (state, action) => {

  const { type, payload } = action;
  var result;

  switch (type) {

    case TYPES.UPDATE_BUSES:
      result = {
        ...state,
        buses: OahuProviderUtils.Transform(payload, KEYS.BUSES),
        busesUpdate: state.busesUpdate + 1,
      };
      result.busMap = OahuProviderUtils.CreateBusMap(result.buses);
      break;

    case TYPES.UPDATE_SATELLITES:
      result = {
        ...state,
        satellites: payload,
        satellitesUpdate: state.satellitesUpdate + 1,
      };
      break;

    case TYPES.UPDATE_LOCATIONS:
      result = {
        ...state,
        locationsUpdate: state.locationsUpdate + 1,
      };
      if (!result.locations) {
        result.locations = {};
      }
      Object.keys(payload).forEach(key => {
        result.locations[key] = OahuProviderUtils.Transform(payload[key], key);
      });
      break;

    case TYPES.UPDATE_SURF:
      result = {
        ...state,
        surf: payload,
        surfUpdate: state.surfUpdate + 1,
      };
      break;

    case TYPES.UPDATE_WEATHER:
      result = {
        ...state,
        weather: OahuProviderUtils.Transform(payload, KEYS.WEATHER),
        weatherUpdate: state.weatherUpdate + 1,
      };
      break;

    case TYPES.UPDATE_YELP:
      result = {
        ...state,
        yelp: [...state.yelp, ...payload],
        yelpUpdate: state.yelpUpdate + 1,
      };
      break;

    default:
      throw new Error(`oahuReducer: Unhandled action '${type}'.`);
  }

  Console.LOG(`oahuReducer[${type}] payload.length (${payload?.length}) (${payload?.length ? JSON.stringify(payload[0]) : Object.keys(payload)})`);
  //Console.LOG(`oahuReducer[${type}] (${JSON.stringify(payload)})`);
  Console.trace('oahuReducer', { state, result });

  return result;
};


export const OahuProvider = props => {

  const {
    children,
  } = props;

  const [state, dispatch] = useReducer(oahuReducer, oahuState);

  Console.stack(NAME, props, { state });

  return (
    <OahuStateContext.Provider value={state}>
      <OahuDispatchContext.Provider value={dispatch}>
        {children}
      </OahuDispatchContext.Provider>
    </OahuStateContext.Provider>
  );
};

export const useOahuState = () => useContext(OahuStateContext);
export const useOahuDispatch = () => useContext(OahuDispatchContext);
