import { Api } from './amplify';

const NAME = 'API';

const CHAT = 'chat';
const CHAT_PATH = '/turn';

const IMPLEMENT_WHITELIST_MARKMARK = false;
const WHITELIST = 'whitelist';
const WHITELIST_PATH = '/username';

const HIDATA = 'hidata';
const HIDATA_PATH = '/hidata';


export class API {

    static async #api(api, path, body) {
        var result = null;
        try {
            result = await Api.Get().post(api, path, { body });
        } catch (err) {
            console.warn(`${NAME}.${api} error`, { body: { ...body, base64: body?.base64?.length }, err });
        }
        return result;
    }

    static async chat(body) {
        return await API.#api(CHAT, CHAT_PATH, body);
    }

    static async whitelist(body) {
        return IMPLEMENT_WHITELIST_MARKMARK
            ? await API.#api(WHITELIST, WHITELIST_PATH, body)
            : console.error('INVALID CALL TO WHITELIST');
    }

    static async hidata(body) {
        return await API.#api(HIDATA, HIDATA_PATH, body);
    }
}
