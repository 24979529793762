import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, Easing, StyleSheet } from 'react-native';
import { Auth } from '../aws';
import { Button, BUTTON_VARIANTS, Flag, Icon, Image, Switch, Text, TEXT_VARIANTS, Screen, Video, VIDEO_WIDTH, View } from '../components';
import { useAppState, useSystemState } from '../context';
import { Audios, Icons, Images } from '../media';
//import features from '../localization/en/features.json';
import { Audio, Console/*, Numbers*/, Optional, Speech } from '../utils';
import { Colors } from '../styles';
import { DEV_COLOR, ANIMATED_USE_NATIVE_DRIVER } from '../constants';
import { Navigate } from './utils';
//import { getS3Buckets } from 'aws';

const LEGACY_UI = false;

const NAME = 'Splash';

const RENDER_OAHU = false;
//const SHOW_FEATURES = false;

// first chance to initialize voices
Speech.GetVoices();

//import Login from '../Login';
//import Register from '../Register';
//import ConfirmRegister from '../ConfirmRegister';
//import ForgotPassword from '../ForgotPassword';


const ANIMATE = true;//!DEV_MODE;

const SHORT = '⏱️';
const LONG = '⏳';

//const TEST_VIDEO = 'https://s3-us-west-1.amazonaws.com/assets.startasl.com/videos/dog.mp4';
//const ACQUILINGUA = 'AcquiLingua';

const ACQUILINGUA_IPA = '/ak.kwiˈlin.ɡwa/';
const ACQUILINGUA_IPA_AUDIO = 'acquilingua';
const WELCOME = 'Welcome';
const WELCOME_IPA = 'Welcome-IPA';
const ENTER_APP = 'Enter App';
const INFORMATION = 'Information';

const LOGIN = 'Login';
/*
const REGISTER = 'Register';
const CONFIRM_REGISTER = 'ConfirmRegister';
const FORGOT_PASSWORD = 'ForgotPassword';
*/
// MARKMARK: AUTH_SCREENS and 'auth' are part of effort
// to reroute to 'current' authentication screen
// when the translation language changes...to be continued
/*
const AUTH_SCREENS = new Map([
    [LOGIN, Login],
    [REGISTER, Register],
    [CONFIRM_REGISTER, ConfirmRegister],
    [FORGOT_PASSWORD, ForgotPassword],
    ['', Text], // MARKMARK: Necessary dummy? Why?
]);
*/

const ASL_VIDEO = 'eT0rrji4yU0';
//const PITCH_VIDEO = 'HJGvL2-Wttg';//'b9y4SUv65vQ';//'7jELdpBxSx8';
const KRASHEN_SHORT_VIDEO = 'lxKvMqPl6j4';
const KRASHEN_LONG_VIDEO = 'nT7FugEmko0';
const KRASHEN = 'Krashen';

const DA_APP_HAWAII_VIDEO = 'G6InHYb6Tsg';

//const COLORS = Colors.randomColors;

const LOGO_WIDTH = 85;
const LOGO_HEIGHT = 150;
/*
const FEATURE_SECONDS = 19;
const FEATURES = Object.keys(features);

const FEATURES_BLACKLIST = new Set([
    'Comprehensible Input',
    'Conversational Chatbot',
]);
*/

const HEADER_SECONDS = 7;
const HEADERS = [
    ['_Info', Icons.Info],
    ['_Help', Icons.Help],
    ['_Theme', Icons.Theme],
    ['_Translate', Icons.Translate],
];

const START_HERE = 'Start here';


export const Splash = props => {

    const {
        navigation,
    } = props;

    const { awsConfig, env, isWeb, isIOS, deviceScale, headerHeight, width, adjHeight } = useSystemState();
    const { auth, t, language, theme, themeUpdate } = useAppState();

    const [asl, setAsl] = useState(false);
    const setAslRef = useRef(setAsl);

    const [video, setVideo] = useState(false);
    const setVideoRef = useRef(setVideo);

    const [welcomeIpas, setWelcomeIpas] = useState([]);
    const setWelcomeIpasRef = useRef(setWelcomeIpas);
/*
    const [animatedFeature] = useState(new Animated.Value(0));
    const [featureTicker, setFeatureTicker] = useState(0);
    const setFeatureTickerRef = useRef(setFeatureTicker);
    const [featureIndex, setFeatureIndex] = useState(0);
    const setFeatureIndexRef = useRef(setFeatureIndex);
    const [featureIndexOffset, setFeatureIndexOffset] = useState(0);
    const setFeatureIndexOffsetRef = useRef(setFeatureIndexOffset);
    const [featureColor, setFeatureColor] = useState(0);
    const setFeatureColorRef = useRef(setFeatureColor);
*/
    const [animatedHeader] = useState(new Animated.Value(0));
    const [headerTicker, setHeaderTicker] = useState(0);
    const setHeaderTickerRef = useRef(setHeaderTicker);
    const [headerIndex, setHeaderIndex] = useState(0);
    const setHeaderIndexRef = useRef(setHeaderIndex);

    const onEnter = useCallback(
        async () => {
            const currentUser = await Auth.currentAuthenticatedUser();
            Navigate(navigation, t(LOGIN), { title: t(LOGIN), currentUser: currentUser ? JSON.stringify(currentUser) : null });
        },
        [
            t,
            navigation,
        ],
    );

    useEffect(
        () => {
            if (isWeb) {
                return;
            }
            Console.devLog(`${NAME} MARKMARK useEffect environment start`, { env, awsConfig });
            /*(async () => {
                Console.devLog(`${NAME}.getS3Buckets start`);
                await getS3Buckets(awsConfig);
                Console.devLog(`${NAME}.getS3Buckets end`);
            })();*/
            Console.devLog(`${NAME} MARKMARK useEffect environment end`);
        },
        [
            isWeb,
            env,
            awsConfig,
        ],
    );
/*
    useEffect(
        () => {
            if (!ANIMATE) {
                return;
            }
            Console.log(`${NAME} useEffect animate feature`);
            Animated.timing(animatedFeature, {
                useNativeDriver: ANIMATED_USE_NATIVE_DRIVER,
                toValue: Number.MAX_SAFE_INTEGER / 1000,
                duration: Number.MAX_SAFE_INTEGER,
                easing: Easing.linear, // inOut, bounce, linear, elastic(0.7), bezier(0.75, -0.25, 0.25, 1.25)
                delay: 0,
            }).start();
            animatedFeature.addListener(({ value }) => {
                setFeatureTickerRef.current(Math.floor(value / FEATURE_SECONDS));
            });
            return () => {
                Console.log(`${NAME} useEffect remove animate feature`);
                animatedFeature.removeAllListeners();
            };
        },
        [
            animatedFeature,
            setFeatureTickerRef,
        ],
    );

    useEffect(
        () => {
            const value = (featureIndexOffset + featureTicker) % FEATURES.length;
            Console.log(`${NAME} useEffect feature`, { featureTicker, featureIndexOffset, value });
            setFeatureIndexRef.current(value);
            setFeatureColorRef.current(Numbers.randomItem(COLORS[dark ? 1 : 0]));
        },
        [
            dark,
            featureTicker,
            featureIndexOffset,
            setFeatureIndexRef,
            setFeatureColorRef,
        ],
    );
*/
    useEffect(
        () => {
            if (!ANIMATE) {
                return;
            }
            Console.log(`${NAME} useEffect animate header`);
            Animated.timing(animatedHeader, {
                useNativeDriver: ANIMATED_USE_NATIVE_DRIVER,
                toValue: Number.MAX_SAFE_INTEGER / 1000,
                duration: Number.MAX_SAFE_INTEGER,
                easing: Easing.linear, // inOut, bounce, linear, elastic(0.7), bezier(0.75, -0.25, 0.25, 1.25)
                delay: 0,
            }).start();
            animatedHeader.addListener(({ value }) => {
                setHeaderTickerRef.current(Math.floor(value / HEADER_SECONDS));
            });
            return () => {
                Console.log(`${NAME} useEffect remove animate header`);
                animatedHeader.removeAllListeners();
            };
        },
        [
            animatedHeader,
            setHeaderTickerRef,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect header`, { headerTicker });
            setHeaderIndexRef.current(v => (v + 1) % HEADERS.length);
        },
        [
            headerTicker,
            setHeaderIndexRef,
        ],
    );

    useEffect(
        () => {
            const welcomeIpaString = t(WELCOME_IPA);
            const dialects = welcomeIpaString.split(';');
            const ipas = dialects.map(dialect => {
                const dialectTokens = dialect.split(':');
                const flag = dialectTokens[0];
                const text = dialectTokens[1];
                Console.log(`${NAME} useEffect welcome ipas`, { language, flag, text });
                return { flag, text };
            });

            Console.log(`${NAME} useEffect welcome`, { language, welcomeIpaString, dialects, ipas });
            setWelcomeIpasRef.current(ipas);
        },
        [
            t,
            language,
            setWelcomeIpasRef,
        ],
    );

    const renderVideo = useMemo(
        () => {
            Console.log(`${NAME}.renderVideo`, { asl });
            const positionStyle = {
                left: (width - VIDEO_WIDTH) / 2,
            };
            return Optional(asl, (
                <View
                    value={'SplashVideo'}
                    style={[styles.popupVideo, positionStyle]}
                >
                    <Video
                        source={ASL_VIDEO}
                        youtube={true}
                        muted={true}
                        onEnded={() => setAslRef.current(false)}
                    />
                </View>
            ));
        },
        [
            width,
            asl,
            setAslRef,
        ],
    );

    const renderHelp = useMemo(
        () => {
            Console.log(`${NAME}.renderHelp`);
            return (
                <View
                    value={'SplashHelp'}
                    style={styles.helpView}
                >
                    <View
                        value={'SplashHelpHeader'}
                        style={styles.helpHeader}
                    >
                        <Text
                            value={'Information'}
                        />
                    </View>
                    {Optional(RENDER_OAHU, (
                        <View
                            value={'SplashHelpMain'}
                            style={styles.helpMain}
                        >
                            <Video
                                source={DA_APP_HAWAII_VIDEO}
                                youtube={true}
                            />
                        </View>
                    ), (
                        <View
                            value={'SplashHelpMain'}
                            style={styles.helpMain}
                        >
                            <Video
                                source={asl ? ASL_VIDEO : video ? KRASHEN_LONG_VIDEO : KRASHEN_SHORT_VIDEO}
                                youtube={true}
                                muted={asl}
                                onEnded={() => setAslRef.current(false)}
                            />
                            <Switch
                                leftLabel={INFORMATION}
                                rightLabel={KRASHEN}
                                textVariant={TEXT_VARIANTS.SUBHEADING}
                                value={video}
                                onValueChange={setVideoRef.current}
                            />
                        </View>
                    ))}
                    <View
                        value={'SplashHelpFooter'}
                        style={styles.helpFooter}
                    >
                        <Text
                            value={RENDER_OAHU ? 'www.youtube.com/@braddahhonu' : 'www.youtube.com/@acquilingua'}
                        />
                    </View>
                </View>
            );
        },
        [
            asl,
            video,
            setVideoRef,
            setAslRef,
        ],
    );

    const renderTitleWelcome = useMemo(
        () => {
            const xWelcome = t(WELCOME);
            Console.log(`${NAME}.renderTitleWelcome`, { isIOS, xWelcome, language });
            return (
                <View
                    value={'SplashWelcome'}
                    style={styles.welcome}
                >
                    <View
                        style={styles.buttonIpa}
                    >
                        <Button
                            value={`${ACQUILINGUA_IPA} 🔊`}
                            variant={BUTTON_VARIANTS.TEXT}
                            onPress={() => {
                                //appDispatchRef.current({ type: APP_TYPES.NEXT_HEADER_STYLE_INDEX });
                                Audio.Play(Audios.general[ACQUILINGUA_IPA_AUDIO]);
                            }}
                        />
                    </View>
                    {LEGACY_UI &&
                    <View
                        value={'SplashWelcomeASLButton'}
                        style={styles.welcomeAsl}
                    >
                        <Button
                            value={`${xWelcome} 🖐️📽️`}
                            variant={BUTTON_VARIANTS.TEXT}
                            onPress={() => setAslRef.current(v => !v)}
                        />
                    </View>
                    }
                    {welcomeIpas.map((ipa, ipaIndex) => {
                        return Optional(LEGACY_UI && language.split('_')[1] === ipa.flag, (
                            <View
                                key={`welcome_ipa_${ipaIndex}`}
                                id={`welcome_ipa_${ipaIndex}`}
                                value={`SplashWelcomeIPA_${ipaIndex}`}
                                style={styles.welcomeIpa}
                            >
                                {LEGACY_UI &&
                                <Flag
                                    country={ipa.flag}
                                    scale={0.5}
                                />
                                }
                                <Button
                                    value={`${ipa.text} 🔊`}
                                    variant={BUTTON_VARIANTS.TEXT}
                                    onPress={() => Speech.Talk(isIOS, xWelcome, language)}
                                />
                            </View>
                        ));
                    })}
                </View>
            );
        },
        [
            t,
            isIOS,
            language,
            welcomeIpas,
            setAslRef,
            //appDispatchRef,
        ],
    );

    const renderLogo = useMemo(
        () => {
            const logoStyle = isWeb ? styles.logo_web : styles.logo_native;
            Console.log(`${NAME}.renderLogo`, {logoStyle});
            return (
                <View
                    value={'SplashLogo'}
                    style={logoStyle}
                >
                    <Image
                        source={Images.logo.acquilingua}
                        width={LOGO_WIDTH * 2.5}
                        height={LOGO_HEIGHT * 2.5}
                    />
                </View>
            );
        },
        [
            isWeb,
        ],
    );

    const renderHeaderScroll = useMemo(
        () => {
            const { colors } = theme;
            const { primary } = colors;
            Console.log(`${NAME}.renderHeaderScroll`, { primary, themeUpdate });
            const fontSize = {
                fontSize: 12 * deviceScale,
            };
            return (
                <View
                    value={'SplashHeaderScroll'}
                    style={styles.headerScroll}
                >
                    <View
                        value={'SplashHeader'}
                        style={styles.headerIcons}
                    >
                        <Icon icon={Icons.HandUp} />
                        <Icon icon={HEADERS[headerIndex][1]} />
                    </View>
                    <Text
                        style={[styles.headerIconsText, fontSize]}
                        value={HEADERS[headerIndex][0]}
                        color={primary}
                    />

                </View>
            );
        },
        [
            deviceScale,
            theme,
            themeUpdate,
            headerIndex,
        ],
    );
/*
    const renderFeatureScroll = useMemo(
        () => {
            Console.log(`${NAME}.renderFeatureScroll`, { language });
            var fontSize = {
                fontSize: 11 * deviceScale,
            };
            return Optional(SHOW_FEATURES, (
                <View
                    value={'SplashFeatureScroll'}
                    style={styles.features}
                >
                    {FEATURES.map((feature, fIndex) => {
                        var color = null;
                        var variant = TEXT_VARIANTS.TEXT;
                        var textStyle = styles.featureList;
                        if (featureIndex === fIndex) {
                            color = featureColor;
                            variant = TEXT_VARIANTS.SUBHEADING;
                            textStyle = styles.featureListSelected;
                            fontSize.fontSize = 13 * deviceScale;
                        }
                        return (
                            <Text
                                key={`feature_${fIndex}`}
                                id={`feature_${fIndex}`}
                                style={[textStyle, fontSize]}
                                value={t(feature)}
                                variant={variant}
                                noTranslate={true}
                                color={color}
                                onPress={() => setFeatureIndexOffsetRef.current(v => {
                                    return v + (fIndex < featureIndex ? -1 : fIndex > featureIndex ? 1 : 0);
                                })}
                            />
                        );
                    })}
                </View>
            ));
        },
        [
            t,
            language,
            deviceScale,
            featureIndex,
            featureColor,
            setFeatureIndexOffsetRef,
        ],
    );

    const renderFeature = useMemo(
        () => {
            Console.log(`${NAME}.renderFeature`, { language });
            const fontSize = {
                fontSize: 12 * deviceScale,
                lineHeight: 15 * deviceScale,
            };
            return Optional(SHOW_FEATURES, (
                <View
                    value={'SplashFeature'}
                    style={styles.feature}
                >
                    {t(`_${FEATURES.filter(v => !FEATURES_BLACKLIST.has(v))[featureIndex]}`).split('|').map((item, itemIndex) => (
                        <Text
                            key={`detail_${itemIndex}`}
                            id={`detail_${itemIndex}`}
                            style={[styles.featureItem, fontSize]}
                            value={item}
                            noTranslate={true}
                            color={featureColor}
                        />
                    ))}
                </View>
            ));
        },
        [
            language,
            t,
            deviceScale,
            featureIndex,
            featureColor,
        ],
    );
*/
    const renderEntry = useMemo(
        () => {
            Console.log(`${NAME}.renderEntry`);
            return (
                <View
                    style={styles.entry}
                >
                    <Button
                        value={LEGACY_UI ? ENTER_APP : START_HERE}
                        style={styles.buttonEnter}
                        textColor={Colors.colors.blue}
                        onPress={onEnter}
                    />
                    {LEGACY_UI && (<>
                    <Icon
                        icon={Icons.HandLeft}
                        color={Colors.colors.red}
                    />
                    <Text
                        style={styles.textEnter}
                        value={START_HERE}
                        variant={TEXT_VARIANTS.SUBHEADING}
                    />
                    </>)}
                </View>
            );
        },
        [
            onEnter,
        ],
    );

    const renderOahu = useMemo(
        () => {
            Console.log(`${NAME}.renderOahu`, { width, adjHeight });
            return (
                <>
                    <Image
                        containerStyle={styles.oahuImage}
                        source={Images.logo.da_app_hawaii}
                        width={width}
                        height={adjHeight}
                        resizeMode={'contain'}
                    />
                    <Button
                        value={ENTER_APP}
                        style={styles.buttonOahu}
                        textColor={'rgb(255, 205, 0)'}
                        onPress={() => Navigate(navigation, t(LOGIN), { title: t(LOGIN) })}
                    />
                </>
            );
        },
        [
            t,
            navigation,
            width,
            adjHeight,
        ],
    );

    Console.stack(NAME, props, { auth, isWeb, isIOS, width, adjHeight, headerHeight/*, featureIndex*/, asl, video, welcomeIpas });

    return useMemo(
        () => {
            Console.log(`${NAME} render`);
            return Optional(RENDER_OAHU, (
                <Screen
                    {...props}
                    value={NAME}
                    renderHelp={renderHelp}
                    helpBackgroundOpacity={1.0}
                >
                    {renderOahu}
                </Screen>
            ), (
                <Screen
                    {...props}
                    value={NAME}
                    renderHelp={renderHelp}
                    helpBackgroundOpacity={1.0}
                    headerHeight={'35%'}
                    headerStyle={styles.header}
                    headerView={(
                        <>
                            <View
                                value={'SplashScreenHeaderLeft'}
                                style={styles.headerLeft}
                            >
                                {renderTitleWelcome}
                            </View>
                            <View
                                value={'SplashScreenRight'}
                                style={styles.headerRight}
                            >
                                {LEGACY_UI && renderHeaderScroll}
                            </View>
                        </>
                    )}
                    mainHeight={-1}
                    mainStyle={styles.main}
                    mainView={renderEntry}
                    footerHeight={LEGACY_UI ? '50%' : '0%'}
                    footerStyle={LEGACY_UI ? styles.footer : {}}
                    footerView={(LEGACY_UI &&
                        <>
                            <View
                                value={'SplashVideo'}
                                style={styles.video}
                            >
                                <Video
                                    source={video ? KRASHEN_LONG_VIDEO : KRASHEN_SHORT_VIDEO}
                                    youtube={true}
                                />
                                <Switch
                                    leftLabel={SHORT}
                                    rightLabel={LONG}
                                    textVariant={TEXT_VARIANTS.SUBHEADING}
                                    value={video}
                                    onValueChange={setVideoRef.current}
                                />
                            </View>
                        </>
                    )}
                >
                    <>
                        {renderVideo}
                        {renderLogo}
                    </>
                </Screen >
            ));
        },
        [
            props,
            renderHelp,
            renderTitleWelcome,
            renderHeaderScroll,
            renderLogo,
            renderEntry,
            //renderFeatureScroll,
            //renderFeature,
            renderVideo,
            renderOahu,
            video,
        ],
    );
};

/*
                    footerView={(
                        <>
                            {renderFeatureScroll}
                            {renderFeature}
                            <View
                                value={'SplashVideo'}
                                style={styles.video}
                            >
                                <Video
                                    source={video ? KRASHEN_LONG_VIDEO : KRASHEN_SHORT_VIDEO}
                                    youtube={true}
                                />
                                <Switch
                                    leftLabel={SHORT}
                                    rightLabel={LONG}
                                    textVariant={TEXT_VARIANTS.SUBHEADING}
                                    value={video}
                                    onValueChange={setVideoRef.current}
                                />
                            </View>
                        </>
                    )}

*/

const logoStyle = {
    position: 'absolute',
    top: '15%',
    left: '67%',
    width: LOGO_WIDTH,
    height: LOGO_HEIGHT,
    backgroundColor: DEV_COLOR(Colors.colors.yellow),
};

const styles = StyleSheet.create({
    popupVideo: {
        position: 'absolute',
        top: '38%',
    },
    header: {
        paddingHorizontal: '2%',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        backgroundColor: DEV_COLOR(Colors.colors.orange),
    },
    headerLeft: {
        width: '55%',
        height: '100%',
        backgroundColor: DEV_COLOR(Colors.colors.purple),
    },
    headerRight: {
        width: '45%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        backgroundColor: DEV_COLOR(Colors.colors.magenta),
    },
    main: {
        //flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
    },
    footer: {
        paddingHorizontal: '1.5%',
        //flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        backgroundColor: DEV_COLOR(Colors.colors.lightblue),
    },
    welcome: {
        backgroundColor: DEV_COLOR(Colors.colors.blue),
    },
    welcomeAsl: {
        alignItems: 'flex-end',
        backgroundColor: DEV_COLOR(Colors.colors.lightgreen),
    },
    welcomeIpa: {
        alignSelf: 'flex-start',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: DEV_COLOR(Colors.colors.green),
    },
    headerIcons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '3%',
        backgroundColor: DEV_COLOR(Colors.colors.purple),
    },
    headerIconsText: {
        fontStyle: 'italic',
        fontWeight: 'bold',
        textAlign: 'center',
        backgroundColor: DEV_COLOR(Colors.colors.red),
    },
    headerScroll: {
        alignSelf: 'flex-end',
        backgroundColor: DEV_COLOR(Colors.colors.green),
    },
    entry: {
        flexDirection: 'row',
        //height: 30,
        //position: 'absolute',
        top: '25%',
        //left: '3%',
        alignContent: 'center',
        justifyContent: 'center',
    },
    buttonEnter: {
        marginRight: 5,
        elevation: 5,
        zIndex: 5,
        backgroundColor: Colors.colors.lightgreen,
    },
    textEnter: {
        marginLeft: 5,
        color: Colors.colors.red,
    },
    buttonIpa: {
        alignItems: 'flex-start',
    },
    buttonOahu: {
        position: 'absolute',
        top: '75%',
        left: '15%',
        elevation: 5,
        zIndex: 5,
        backgroundColor: 'rgb(23, 95, 186)',
    },
    features: {
        width: '53%',
        paddingLeft: '0.5%',
        backgroundColor: DEV_COLOR(Colors.colors.lime),
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    feature: {
        width: '47%',
        justifyContent: 'center',
        paddingHorizontal: '0.5%',
        backgroundColor: DEV_COLOR(Colors.colors.pink),
    },
    featureList: {
        fontStyle: 'normal',
        backgroundColor: DEV_COLOR(Colors.colors.cyan),
    },
    featureListSelected: {
        fontStyle: 'italic',
        fontWeight: 'bold',
        backgroundColor: DEV_COLOR(Colors.colors.lightgreen),
    },
    featureItem: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        marginVertical: '3%',
        width: '100%',
        backgroundColor: DEV_COLOR(Colors.colors.brown),
    },
    helpView: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    helpHeader: {
        width: '100%',
        height: '25%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    helpMain: {
        width: '100%',
        height: '50%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    video: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    helpFooter: {
        width: '100%',
        height: '25%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo_native: {
        ...logoStyle,
        top: '25%',
        left: '30%',
    },
    logo_web: {
        ...logoStyle,
        top: '5%',
        left: '50%',
    },
    oahuImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        //alignItems: 'center',
        //justifyContent: 'center',
    },
});
