import Matter from 'matter-js';
import { SvgCircle, SvgRect, SvgText } from './renderers';
import { Physics, Time, TimeUtils, Touch, TouchUtils } from './systems';
import { Numbers, Sensors, Validate } from '../../utils';
import { Colors } from '../../styles';

const accelerometerScale = 1;

// https://gamedev.stackexchange.com/questions/141224/physics-engine-and-squishing-of-stacked-objects

Sensors.setUpdateIntervalForType(Sensors.SensorTypes.accelerometer, 15);

//-- Overriding this function because the original references HTMLElement
//-- which will throw an error when running in a React Native context
Matter.Common.isElement = () => false;
Matter.Mouse._getRelativeMousePosition = event => event.position;

const MIN_DIM = 25;
const MAX_DIM = 50;

const CONFIG = {
    gravity: {
        x: 0,
        y: 1,
        scale: 0.0001,
        magnitude: 0.1,
    },
    timing: {
        timeScale: 1.0,
    },
};

const OPTIONS = {
    angle: 0,
    angularVelocity: 0,
    collisionFilter: {
        category: 1,
        group: 0,
        mask: -1,
    },
    density: 0.001,
    friction: 0.1,
    frictionAir: 0.01,
    frictionStatic: 0.5,
    isSensor: false,
    isStatic: false,
    restitution: 0,
    slop: 0.05,
};

const ColorWheel = Object.keys(Colors.colors)
    .filter(c => c !== Colors.colors.transparent && c !== Colors.colors.black && c !== Colors.colors.white);


export class PhysicsEngine {

    constructor(
        top, left, width, height,
        callbacks = null,
        events = null,
        tScale = CONFIG.timing.timeScale,
        gScale = CONFIG.gravity.scale,
        gMagnitude = CONFIG.gravity.magnitude,
        gx = CONFIG.gravity.x,
        gy = CONFIG.gravity.y,
    ) {
        var useTouch = false;
        this.offset = 1;
        this.enableSleeping = false;

        this.operations = [Physics];
        if (Validate.isValid(callbacks) && Object.keys(callbacks).length) {
            TouchUtils.Callbacks(callbacks);
            TouchUtils.Offset(top, left);
            this.operations.push(Touch);
            useTouch = true;
        }
        if (Validate.isValid(events) && events.length) {
            TimeUtils.Events(events);
            TimeUtils.Elapse(0);
            this.operations.push(Time);
        }

        this.bodies = null;
        this.engine = null;
        this.mouse = null;

        this.beforeUpdate = { caller: null, callback: null };
        this.afterUpdate = { caller: null, callback: null };

        this.gMagnitude = gMagnitude;
        this.engine = Matter.Engine.create({ enableSleeping: this.enableSleeping });
        this.bodies = new Map();

        this.timeScale(tScale);
        this.gravityScale(gScale * accelerometerScale);
        this.gravity(gx, gy, gMagnitude);

        if (useTouch) {
            const constraint = Matter.MouseConstraint.create(this.engine, {
                mouse: Matter.Mouse.create(TouchUtils.Element()),
            });
            this.add(constraint);
            this.mouse = { constraint: constraint.mouse };
        }

        this.setDimensions(width, height);
        this.#createWalls();
    }

    subBeforeUpdate(callback) {
        Matter.Events.on(this.engine, 'beforeUpdate', callback);
    }

    subAfterUpdate(callback) {
        Matter.Events.on(this.engine, 'afterUpdate', callback);
    }

    physics() {
        return {
            engine: this.engine,
            world: this.engine.world,
            system: this,
        };
    }

    entities() {
        var result = { physics: this.physics() };
        this.bodies.forEach((value, key) => { result[key] = value; });
        return result;
    }

    setDimensions(width, height) {
        if (width === this.width && height === this.height) {
            return;
        }
        this.width = width;
        this.height = height;
        this.#createWalls();
    }

    dimensions() {
        return {
            width: this.width,
            height: this.height,
        };
    }

    systems() {
        return [...this.operations];
    }

    timeScale(scale) {
        this.engine.timing.timeScale = scale;
    }

    gravityScale(scale) {
        this.engine.gravity.scale = scale;
    }

    gravity(x, y, magnitude = null) {
        const length = Math.sqrt(x * x + y * y);
        if (Validate.isValid(magnitude)) {
            this.gMagnitude = magnitude;
        }
        const scale = this.gMagnitude / length;
        this.engine.gravity.x = x * scale;
        this.engine.gravity.y = y * scale;
    }

    add(obj, composite = null) {
        return Matter.Composite.add(composite ? composite : this.engine.world, obj);
    }

    remove(obj, composite = null) {
        return Matter.Composite.remove(composite ? composite : this.engine.world, this.#getBody(obj));
    }

    addCircle(id = null, x = -1, y = -1, r = -1, color = null, opts = null) {
        const { label, pos } = this.#getParms(id, x, y, MAX_DIM, 'circle');
        const radius = PhysicsEngine.#GetDimension(r, MIN_DIM, MAX_DIM);
        const size = 2 * radius;
        const options = PhysicsEngine.#GetOptions(label, opts);
        const body = Matter.Bodies.circle(pos.x, pos.y, radius, options);
        return this.#addBody(label, body, SvgCircle, size, size, color);
    }

    addRectangle(id = null, x = -1, y = -1, w = -1, h = -1, color = null, opts = null) {
        const { label, pos } = this.#getParms(id, x, y, MAX_DIM, 'rectangle');
        const width = PhysicsEngine.#GetDimension(w, MIN_DIM, MAX_DIM);
        const height = PhysicsEngine.#GetDimension(h, MIN_DIM, MAX_DIM);
        const options = PhysicsEngine.#GetOptions(label, opts);
        const body = Matter.Bodies.rectangle(pos.x, pos.y, width, height, options);
        return this.#addBody(label, body, SvgRect, width, height, color);
    }

    addText(id = null, text, x = -1, y = -1, w = -1, h = -1, color = null, opts = null) {
        const { label, pos } = this.#getParms(id, x, y, MAX_DIM, 'rectangle');
        const width = PhysicsEngine.#GetDimension(w, MIN_DIM, MAX_DIM);
        const height = PhysicsEngine.#GetDimension(h, MIN_DIM, MAX_DIM);
        const options = PhysicsEngine.#GetOptions(label, opts);
        const body = Matter.Bodies.rectangle(pos.x, pos.y, width, height, options);
        return this.#addBody(label, body, SvgText(text), width, height, color);
    }

    addPolygon(id = null, x = -1, y = -1, r = -1, s = -1, color = null, opts = null) {
        const { label, pos } = this.#getParms(id, x, y, MAX_DIM, 'polygon');
        const radius = PhysicsEngine.#GetDimension(r, MIN_DIM, MAX_DIM);
        const sides = PhysicsEngine.#GetDimension(s, 3, 12); // MARKMARK min/max sides ???
        const options = PhysicsEngine.#GetOptions(label, opts);
        const body = Matter.Bodies.polygon(pos.x, pos.y, sides, radius, options);
        return this.#addBody(label, body, SvgRect, radius, radius, color);
    }

    addTrapezoid(id = null, x = -1, y = -1, w = -1, h = -1, a = -1, color = null, opts = null) {
        const { label, pos } = this.#getParms(id, x, y, MAX_DIM, 'trapezoid');
        const width = PhysicsEngine.#GetDimension(w, MIN_DIM, MAX_DIM);
        const height = PhysicsEngine.#GetDimension(h, MIN_DIM, MAX_DIM);
        const slope = PhysicsEngine.#GetDimension(a, 0, 10); // MARKMARK min/max slope ???
        const options = PhysicsEngine.#GetOptions(label, opts);
        const body = Matter.Bodies.polygon(pos.x, pos.y, width, height, slope, options);
        return this.#addBody(label, body, SvgRect, width, height, color);
    }

    addVertices(id = null, vertices, renderer, x = -1, y = -1, scale = 1, color = null, opts = null) {
        const { label, pos } = this.#getParms(id, x, y, MAX_DIM, 'vertices');
        const options = PhysicsEngine.#GetOptions(label, opts);
        const coordinates = vertices.map(v => { return { x: scale * v[0], y: scale * v[1]}; });
        const { w, h } = Numbers.boundingBox(coordinates);
        var body = Matter.Bodies.fromVertices(pos.x, pos.y, [coordinates], options);
        body.svg = { scale, width: w, height: h, center: Matter.Vertices.centre(coordinates) };
        return this.#addBody(label, body, renderer, w, h, color);
    }

    addChain(xPos, yPos, radius = 20, links = 8, gap = 20, length = 2, offset = 0.5, stiff1 = 0.8, stiff2 = 0.5) {
        var group = Matter.Body.nextGroup(true);
        var chain = Matter.Composites.stack(xPos, yPos, links, 1, gap, gap, (x, y) => {
            return this.addCircle(null, x, y, radius, null, { collisionFilter: { group } }).body.body;
        });
        Matter.Composites.chain(chain, offset, 0, -offset, 0, { stiffness: stiff1, length, render: { type: 'line' } });
        Matter.Composite.add(chain, Matter.Constraint.create({
            bodyB: chain.bodies[0],
            pointB: { x: -radius, y: 0 },
            pointA: { x: chain.bodies[0].position.x, y: chain.bodies[0].position.y },
            stiffness: stiff2,
        }));
        this.add(chain);
    }

    addConstraint(body, point, stiffness, damping) {
        var constraint = Matter.Constraint.create({
            pointA: point,
            bodyB: body,
            stiffness,
            damping,
            length: 0,
        });
        this.add(constraint);
        return constraint;
    }

    applyForce(body, position, force) {
        Matter.Body.applyForce(this.#getBody(body), position, force);
    }

    setAngle(body, angle) {
        Matter.Body.setAngle(this.#getBody(body), angle);
    }

    setAngularVelocity(body, velocity) {
        Matter.Body.setAngularVelocity(this.#getBody(body), velocity);
    }

    setCenter(body, center, relative = true) {
        Matter.Body.setCentre(this.#getBody(body), center, relative);
    }

    // hmmm
    setCentre(body, center, relative = true) {
        this.setCenter(body, center, relative);
    }

    setDensity(body, density) {
        Matter.Body.setDensity(this.#getBody(body), density);
    }

    setInertia(body, inertia) {
        Matter.Body.setInertia(this.#getBody(body), inertia);
    }

    setMass(body, mass) {
        Matter.Body.setMass(this.#getBody(body), mass);
    }

    setPosition(body, position) {
        Matter.Body.setPosition(this.#getBody(body), position);
    }

    setStatic(body, isStatic) {
        Matter.Body.setStatic(this.#getBody(body), isStatic);
    }

    setVelocity(body, velocity) {
        Matter.Body.setVelocity(this.#getBody(body), velocity);
    }

    rotate(body, rotation, point) {
        Matter.Body.rotate(this.#getBody(body), rotation, point);
    }

    scale(body, scalex, scaley, point) {
        Matter.Body.scale(this.#getBody(body), scalex, scaley, point);
    }

    translate(body, translation) {
        Matter.Body.translate(this.#getBody(body), translation);
    }

    mouseEvent(event) {
        this.mouse.constraint[event.type](event);
    }

    #addBody(id, body, renderer, width, height, color) {
        this.add(body);
        this.bodies.set(id, {
            body,
            size: [width, height],
            color: PhysicsEngine.#GetColor(color),
            renderer,
        });
        return { id, body: this.bodies.get(id) };
    }

    #getBody(bodyOrId) {
        var result = bodyOrId;
        if (Validate.isValidNonEmptyString(bodyOrId)) {
            result = this.engine.world.bodies.find(v => v.label === bodyOrId);
        }
        return result;
    }

    #getLabel(label = null, tag = 'entity') {
        return label ? label : `${tag}-${this.bodies.size}`;
    }

    #getParms(label, xPos, yPos, offset, tag) {
        const pos = {
            x: PhysicsEngine.#GetDimension(xPos, offset, this.width - offset),
            y: PhysicsEngine.#GetDimension(yPos, offset, this.height - offset),
        };
        return { label: this.#getLabel(label, tag), pos };
    }

    #createWalls() {
        if (!Validate.isValid(this.engine?.world)) {
            return;
        }
        const thick = 2 * Math.max(this.width, this.height); // really big
        const halfThick = (thick / 2) - this.offset;
        const halfWidth = this.width / 2;
        const halfHeight = this.height / 2;
        const walls = [
            { label: 'BOTTOM_WALL', x: halfWidth, y: this.height + halfThick, t: thick },
            { label: 'TOP_WALL', x: halfWidth, y: -halfThick, t: thick },
            { label: 'LEFT_WALL', x: -halfThick, y: halfHeight, t: thick },
            { label: 'RIGHT_WALL', x: this.width + halfThick, y: halfHeight, t: thick },
        ];
        const color = Colors.colors.transparent;
        const options = {
            isStatic: true,
            friction: 0,
            frictionAir: 0,
            frictionStatic: 0,
            restitution: 1,
        };
        walls.forEach(wall => {
            const body = this.#getBody(wall.label);
            if (Validate.isValid(body)) {
                this.remove(body);
            }
            this.addRectangle(wall.label, wall.x, wall.y, wall.t, wall.t, color, options);
        });
    }

    static #GetColor(color) {
        return color ? color : Colors.colors[Numbers.randomItem(ColorWheel)];
    }

    static #GetDimension(dimension, min, max) {
        return dimension !== -1
            ? dimension
            : min + Numbers.random(max - min);
    }

    static #GetOptions(label, overrideOptions) {
        const newOptions = overrideOptions
            ? overrideOptions
            : { friction: -1, frictionStatic: -1, restitution: -1 };
        var options = { ...OPTIONS, ...newOptions, label };
        if (options.friction === -1) {
            options.friction = Numbers.randomFloat();
        }
        if (options.frictionStatic === -1) {
            options.frictionStatic = Numbers.randomFloat();
        }
        if (options.restitution === -1) {
            options.restitution = Numbers.randomFloat();
        }
        return options;
    }

    static Car(xx, yy, width, height, wheelSize, wheelBase = 20) {

        const density = 0.0002;
        const friction = 0.8;
        const length = 0;
        const stiffness = 1;

        var group = Matter.Body.nextGroup(true),
            wheelBase,
            wheelAOffset = -width * 0.5 + wheelBase,
            wheelBOffset = width * 0.5 - wheelBase,
            wheelYOffset = 0;

        var car = Matter.Composite.create({ label: 'Car' });

        var body = Matter.Bodies.rectangle(
            xx,
            yy,
            width,
            height, {
            collisionFilter: { group },
            chamfer: { radius: height * 0.5 },
            density,
        });

        var wheelA = Matter.Bodies.circle(
            xx + wheelAOffset,
            yy + wheelYOffset,
            wheelSize, {
            collisionFilter: { group },
            friction,
        });

        var wheelB = Matter.Bodies.circle(
            xx + wheelBOffset,
            yy + wheelYOffset,
            wheelSize, {
            collisionFilter: { group },
            friction,
        });

        var axelA = Matter.Constraint.create({
            bodyA: wheelA,
            bodyB: body,
            pointB: { x: wheelAOffset, y: wheelYOffset },
            stiffness,
            length,
        });

        var axelB = Matter.Constraint.create({
            bodyA: wheelB,
            bodyB: body,
            pointB: { x: wheelBOffset, y: wheelYOffset },
            stiffness,
            length,
        });

        Matter.Composite.addBody(car, body);
        Matter.Composite.addBody(car, wheelA);
        Matter.Composite.addBody(car, wheelB);
        Matter.Composite.addConstraint(car, axelA);
        Matter.Composite.addConstraint(car, axelB);

        return car;
    }

    static Cloth(xx, yy, columns, rows, columnGap, rowGap, crossBrace, particleRadius, particleOptions, constraintOptions) {
        var group = Matter.Body.nextGroup(true);
        particleOptions = Matter.Common.extend({ inertia: Infinity, friction: 0.00001, collisionFilter: { group: group }, render: { visible: false } }, particleOptions);
        constraintOptions = Matter.Common.extend({ stiffness: 0.06, render: { type: 'line', anchors: false } }, constraintOptions);
        var cloth = Matter.Composites.stack(xx, yy, columns, rows, columnGap, rowGap, (x, y) => {
            return Matter.Bodies.circle(x, y, particleRadius, particleOptions);
        });
        Matter.Composites.mesh(cloth, columns, rows, crossBrace, constraintOptions);
        cloth.label = 'Cloth Body';
        return cloth;
    }

    static NewtonsCradle(xx, yy, number, size, length) {
        var newtonsCradle = Matter.Composite.create({ label: 'Newtons Cradle' });
        const options = { inertia: Infinity, restitution: 1, friction: 0, frictionAir: 0.0001, slop: 1 };
        for (var i = 0; i < number; i++) {
            var separation = 1.9;
            var circle = Matter.Bodies.circle(xx + i * (size * separation), yy + length, size, options);
            var constraint = Matter.Constraint.create({ pointA: { x: xx + i * (size * separation), y: yy }, bodyB: circle });
            Matter.Composite.addBody(newtonsCradle, circle);
            Matter.Composite.addConstraint(newtonsCradle, constraint);
        }
        return newtonsCradle;
    }

    static Person(x, y, scale = 1, options = {}) {
        const s8 = scale * 8;
        const s10 = scale * 10;
        const s15 = scale * 15;
        const s20 = scale * 20;
        const s23 = scale * 23;
        const s24 = scale * 24;
        const s25 = scale * 25;
        const s26 = scale * 26;
        const s30 = scale * 30;
        const s34 = scale * 34;
        const s35 = scale * 35;
        const s39 = scale * 39;
        const s40 = scale * 40;
        const s55 = scale * 50;
        const s57 = scale * 57;
        const s60 = scale * 60;
        const s80 = scale * 80;
        const s97 = scale * 97;

        const bodyOptions = (label, chamfer = { radius: s10 }) => {
            return { label, chamfer, group: Matter.Body.nextGroup(true) };
        };

        var headOptions = Matter.Common.extend({ ...bodyOptions('head'), chamfer: { radius: [s15, s15, s15, s15] } }, options);
        var chestOptions = Matter.Common.extend({ ...bodyOptions('chest'), chamfer: { radius: [s20, s20, s26, s26] } }, options);
        var leftArmOptions = Matter.Common.extend({ ...bodyOptions('left-arm') }, options);
        var rightArmOptions = Matter.Common.extend({ ...bodyOptions('right-arm') }, options);
        var leftLegOptions = Matter.Common.extend({ ...bodyOptions('left-leg') }, options);
        var rightLegOptions = Matter.Common.extend({ ...bodyOptions('right-leg') }, options);

        var head = Matter.Bodies.rectangle(x, y - s60, s34, s40, headOptions);
        var chest = Matter.Bodies.rectangle(x, y, s55, s80, chestOptions);
        var rightUpperArm = Matter.Bodies.rectangle(x + s39, y - s15, s20, s40, rightArmOptions);
        var rightLowerArm = Matter.Bodies.rectangle(x + s39, y + s25, s20, s60, rightArmOptions);
        var leftUpperArm = Matter.Bodies.rectangle(x - s39, y - s15, s20, s40, leftArmOptions);
        var leftLowerArm = Matter.Bodies.rectangle(x - s39, y + s25, s20, s60, leftArmOptions);
        var leftUpperLeg = Matter.Bodies.rectangle(x - s20, y + s57, s20, s40, leftLegOptions);
        var leftLowerLeg = Matter.Bodies.rectangle(x - s20, y + s97, s20, s60, leftLegOptions);
        var rightUpperLeg = Matter.Bodies.rectangle(x + s20, y + s57, s20, s40, rightLegOptions);
        var rightLowerLeg = Matter.Bodies.rectangle(x + s20, y + s97, s20, s60, rightLegOptions);

        const constraintOptions = (bodyA, bodyB, stiffness = 0.6) => {
            return {
                bodyA,
                bodyB,
                stiffness,
                render: { visible: false },
            };
        };

        var chestToRightUpperArm = Matter.Constraint.create({
            ...constraintOptions(chest, rightUpperArm),
            pointA: { x: s24, y: -s23 },
            pointB: { x: 0, y: -s8 },
        });

        var chestToLeftUpperArm = Matter.Constraint.create({
            ...constraintOptions(chest, leftUpperArm),
            pointA: { x: -s24, y: -s23 },
            pointB: { x: 0, y: -s8 },
        });

        var chestToLeftUpperLeg = Matter.Constraint.create({
            ...constraintOptions(chest, leftUpperLeg),
            pointA: { x: -s10, y: s30 },
            pointB: { x: 0, y: -s10 },
        });

        var chestToRightUpperLeg = Matter.Constraint.create({
            ...constraintOptions(chest, rightUpperLeg),
            pointA: { x: s10, y: s30 },
            pointB: { x: 0, y: -s10 },
        });

        var upperToLowerRightArm = Matter.Constraint.create({
            ...constraintOptions(rightUpperArm, rightLowerArm),
            pointA: { x: 0, y: s15 },
            pointB: { x: 0, y: -s25 },
        });

        var upperToLowerLeftArm = Matter.Constraint.create({
            ...constraintOptions(leftUpperArm, leftLowerArm),
            pointA: { x: 0, y: s15 },
            pointB: { x: 0, y: -s25 },
        });

        var upperToLowerLeftLeg = Matter.Constraint.create({
            ...constraintOptions(leftUpperLeg, leftLowerLeg),
            pointA: { x: 0, y: s20 },
            pointB: { x: 0, y: -s20 },
        });

        var upperToLowerRightLeg = Matter.Constraint.create({
            ...constraintOptions(rightUpperLeg, rightLowerLeg),
            pointA: { x: 0, y: s20 },
            pointB: { x: 0, y: -s20 },
        });

        var headContraint = Matter.Constraint.create({
            ...constraintOptions(head, chest),
            pointA: { x: 0, y: s25 },
            pointB: { x: 0, y: -s35 },
        });

        var legToLeg = Matter.Constraint.create({
            ...constraintOptions(leftLowerLeg, rightLowerLeg, 0.01),
        });

        var person = Matter.Composite.create({
            bodies: [
                chest, head,
                leftUpperArm, rightUpperArm,
                leftUpperLeg, rightUpperLeg,
                leftLowerArm, rightLowerArm,
                leftLowerLeg, rightLowerLeg,
            ],
            constraints: [
                headContraint, legToLeg,
                upperToLowerLeftArm, upperToLowerRightArm,
                upperToLowerLeftLeg, upperToLowerRightLeg,
                chestToLeftUpperArm, chestToRightUpperArm,
                chestToLeftUpperLeg, chestToRightUpperLeg,
            ],
        });

        return person;
    }

    static SoftBody(xx, yy, columns, rows, columnGap, rowGap, crossBrace, particleRadius, particleOptions, constraintOptions) {
        const stiffness = 0.2;
        particleOptions = Matter.Common.extend({ inertia: Infinity }, particleOptions);
        constraintOptions = Matter.Common.extend({ stiffness, render: { type: 'line', anchors: false } }, constraintOptions);
        var softBody = Matter.Composites.stack(xx, yy, columns, rows, columnGap, rowGap, (x, y) => {
            return Matter.Bodies.circle(x, y, particleRadius, particleOptions);
        });
        Matter.Composites.mesh(softBody, columns, rows, crossBrace, constraintOptions);
        softBody.label = 'Soft Body';
        return softBody;
    }
}
