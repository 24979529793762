import { Coupons, Calendar, MapDetail, MapView, Scrapbook } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Oahu,

    TabArray: ['MapView', 'Coupons', 'Calendar'],

    Tabs: {
        MapView: {
            Component: MapView,
            Icon: Icons.MapView,
            children: new Set(['MapDetail']),
            menu: true,
        },
        Coupons: {
            Component: Coupons,
            Icon: Icons.Coupons,
        },
        Calendar: {
            Component: Calendar,
            Icon: Icons.Calendar,
            children: new Set(['Scrapbook']),
        },
    },

    Stacks: {
        MapDetail: {
            Component: MapDetail,
        },
        Scrapbook: {
            Component: Scrapbook,
        },
    },

};
