export const Array2Object = (array, cb = (object, key) => { object[key] = key; }) => {
    var object = {};
    array.forEach(key => { cb(object, key); });
    return object;
};

/*
https://bretcameron.medium.com/how-javascript-maps-can-make-your-code-faster-90f56bf61d9d

Object.fromEntries(map); // convert Map to object
new Map(Object.entries(obj)); // convert object to Map
*/
