import React from 'react';
import { StyleSheet } from 'react-native';
import { TextInput } from 'react-native-paper';
import { Text, TEXT_VARIANTS } from './Text';
import { View } from './View';
import { useAppState, useSystemState } from '../context';
import { useKeyboard } from '../hooks';
import { Console, Optional, Validate } from '../utils';

const NAME = 'Input';

const softKeyboard = true;
const OUT_OF_SCALE = { override: true, value: true };
const DEFAULT_FONT_SIZE = 16;
const SCALE_THRESHOLD = [0.66, 1.25];

export const INPUT_VARIANTS = {
    STD: 'std',
    FLAT: 'flat',
    OUTLINED: 'outlined',
};

export const INPUT_TYPE = {
    NUMBER: /[^0-9]/g,
};


export const Input = React.forwardRef((props, ref) => {

    const {
        value,
        title,
        placeholder,
        editable,
        keyboardType,
        password,
        noTranslate,
        errorMessage,
        variant,
        style,
        containerStyle,
        labelContainerStyle,
        onChangeText,
        type,
        activeColor,
        inactiveColor,
        textColor,
        multiline,
    } = props;

    const { deviceScale, isWeb } = useSystemState();
    const { t } = useAppState();

    const { setKbVisible } = useKeyboard();

    const T = s => (
        (
            (Validate.isValid(noTranslate) && noTranslate) ||
            (!Validate.isValidNonEmptyString(s) || s.includes(':'))
        )
            ? s
            : t(s)
    );

    // MARKMARK: Try this alternative to outOfScale
    // upgrade react-native-paper
    // https://github.com/callstack/react-native-paper/pull/3131/commits/7a55dcf97d989b34e014ffbbdb71fff0d7b28261
    const outOfScale = OUT_OF_SCALE.override ? OUT_OF_SCALE.value : deviceScale < SCALE_THRESHOLD[0] || deviceScale > SCALE_THRESHOLD[1];

    const mode = variant ? variant : INPUT_VARIANTS.FLAT;

    const fontSize = deviceScale * (props?.fontSize ? props.fontSize : DEFAULT_FONT_SIZE);
    const height = multiline ? null : fontSize * (mode === INPUT_VARIANTS.OUTLINED ? 3 : 2);

    // MARKMARK: does 'color' do anything?
    const inputStyle = {
        ...style,
        fontSize,
        lineHeight: fontSize * 1.75,
        height,
        //color: style?.color ? style.color : theme.colors.onSurface,
        //backgroundColor: style?.backgroundColor ? style.backgroundColor : theme.colors.surface,
    };

    const labelStyle = {
        paddingTop: fontSize,
        fontSize: outOfScale ? fontSize : fontSize * deviceScale * 0.75,
    };

    const MARKMARK_DOES_THIS_COLOR_WORK = 'lime';

    const _onChangeText = _value => {
        var text = _value?.target?.value ? _value.target.value : _value;
        if (Validate.isValid(type)) {
            text.replaceAll(type, '');
        }
        Console.devLog(`${NAME}._onChangeText`, { type, _value, text });
        if (Validate.isValid(onChangeText)) {
            onChangeText(text);
        }
    };

    let inputType = password ? 'password'
        : keyboardType === 'email-pad' ? 'email'
        : keyboardType === 'phone-pad' ? 'tel'
        : 'text';

    const inputView = () => {
        Console.devLog(`${NAME}.inputView`, { title, editable, inputType, placeholder });
        return (
        <View
            value={'TextInput'}
            style={styles.inputView}
        >
            {Optional(isWeb, (
                <input
                    ref={ref}
                    name={title}
                    placeholder={T(placeholder)}
                    onChange={_onChangeText}
                    readOnly={editable !== undefined ? !editable : false}
                    type={inputType}
                />
            ), (
                <TextInput
                    ref={ref}
                    value={value}
                    style={inputStyle}
                    editable={editable}
                    keyboardType={keyboardType}
                    mode={mode}
                    label={Optional(!outOfScale, T(title))}
                    placeholder={T(placeholder)}
                    error={errorMessage}
                    secureTextEntry={password}
                    multiline={multiline}
                    onChangeText={_onChangeText}
                    selectionColor={MARKMARK_DOES_THIS_COLOR_WORK}
                    underlineColor={inactiveColor}
                    activeUnderlineColor={activeColor}
                    outlineColor={inactiveColor}
                    activeOutlineColor={activeColor}
                    dense={false}
                    showSoftInputOnFocus={softKeyboard}
                    onFocus={() => {
                        Console.log(`${NAME}[${title}].onFocus`);
                        softKeyboard && setKbVisible(true);
                    }}
                    onBlur={() => {
                        Console.log(`${NAME}[${title}].onBlur`);
                        softKeyboard && setKbVisible(false);
                    }}
                />
            ))}
            <Text
                value={errorMessage}
                variant={TEXT_VARIANTS.ERROR}
            />
        </View>
        );
    };

    Console.devStack(NAME, props, { deviceScale, mode, outOfScale });

    return Optional(outOfScale,
        (
            <View
                value={'InputView'}
                style={[styles.container, containerStyle]}
            >
                <View
                    value={'InputLabel'}
                    style={[styles.labelView, labelContainerStyle]}
                >
                    <Text
                        style={[styles.label, labelStyle]}
                        value={T(title)}
                        color={textColor}
                    />
                </View>
                {inputView()}
            </View>
        ),
        inputView(),
    );
});

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    labelView: {
        marginRight: '2%',
        width: '26%',
    },
    label: {
    },
    inputView: {
        flexGrow: 1,
    },
    input: {
    },
});
