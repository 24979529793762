import { languageColors } from '../../constants';
import { Colors } from '../../styles';


export const chatStyles = (isWeb, isDark, deviceScale, botLanguage, userLanguage, styles) => {
    const dark = isDark ? 'dark' : 'light';
    const nonDark = dark === 'dark' ? 'light' : 'dark';
    const botColors = languageColors[botLanguage][dark];
    const userColors = languageColors[userLanguage][nonDark];

    const getScale = scale => scale * deviceScale;

    const wrapperStyle = {
        ...styles.bubbleWrapper,
        borderRadius: getScale(15),
        minHeight: getScale(20),
    };

    const textStyle = {
        ...styles.bubbleText,
        fontSize: getScale(16),
        lineHeight: getScale(20),
        marginTop: getScale(5),
        marginBottom: getScale(5),
        marginLeft: getScale(10),
        marginRight: getScale(10),
    };

    return {
        avatarContainer: {
            left: {
                ...styles.avatarContainer,
                marginRight: getScale(8),
            },
            right: {
                ...styles.avatarContainer,
                marginLeft: getScale(8),
            },
        },
        avatarImage: {
            ...styles.avatarImage,
            height: getScale(36),
            width: getScale(36),
            borderRadius: getScale(36) * 0.5,
        },
        bubbleContent: {
            left: {
                ...styles.bubbleContent,
            },
            right: {
                ...styles.bubbleContent,
            },
        },
        bubbleWrapper: {
            left: {
                ...wrapperStyle,
                marginRight: getScale(60),
                backgroundColor: botColors.backgroundColor,
            },
            right: {
                ...wrapperStyle,
                marginLeft: getScale(60),
                backgroundColor: userColors.backgroundColor,
            },
        },
        bubbleText: {
            left: {
                ...textStyle,
            },
            right: {
                ...textStyle,
            },
        },
        containerToNext: {
            left: {
                ...styles.containerToNext,
                marginLeft: getScale(18),
                borderBottomLeftRadius: getScale(3),
            },
            right: {
                ...styles.containerToNext,
                borderBottomRightRadius: getScale(3),
            },
        },
        containerToPrevious: {
            left: {
                ...styles.containerToPrevious,
                borderTopLeftRadius: getScale(3),
            },
            right: {
                ...styles.containerToPrevious,
                borderTopRightRadius: getScale(3),
            },
        },
        messageContainer: {
            left: {
                ...styles.messageContainer,
                marginLeft: getScale(8),
            },
            right: {
                ...styles.messageContainer,
                marginRight: getScale(8),
            },
        },
        messageTextContainer: {
            left: {
                ...styles.messageTextContainer,
            },
            right: {
                ...styles.messageTextContainer,
            },
        },
        messageText: {
            left: {
                ...styles.messageText,
                marginLeft: getScale(8),
                color: botColors.color,
            },
            right: {
                ...styles.messageText,
                marginRight: getScale(8),
                color: userColors.color,
            },
        },
        tick: {
            ...styles.tick,
            fontSize: getScale(10),
        },
        /*
        tickView: { // MARKMARK: not accessible in lib
            marginRight: 10,
        },
        */
        dayContainer: {
            ...styles.dayContainer,
            marginTop: getScale(5),
            marginBottom: getScale(10),
        },
        dayText: {
            ...styles.dayText,
            fontSize: getScale(12),
        },
        actionsContainer: {
            ...styles.actionsContainer,
            //...devStyle,
            //borderColor: DEV_COLOR(Colors.colors.blue),
            //alignItems: 'center',
            //justifyContent: 'center',
            width: isWeb ? getScale(6) : getScale(30),
            //height: '90%',
            height: getScale(44),
            //alignItems: 'center',
            //justifyContent: 'space-around',
            //paddingHorizontal: getScale(5),
            //backgroundColor: 'gray',
        },
        actionsText: {
            ...styles.actionsText,
            //...devStyle,
            //borderColor: DEV_COLOR(Colors.colors.lightblue),
            // backgroundColor: 'yellow',
        },
        textInput: {
            ///...styles.textInput,
            color: Colors.colors.black,
            marginHorizontal: getScale(5),
            fontSize: getScale(16),
            lineHeight: getScale(16),
            //paddingTop: isWeb ? getScale(6) : 0,
            //paddingLeft: isWeb ? getScale(6) : getScale(40),
            //marginTop: isAndroid ? getScale(10) : getScale(6),
            //marginBottom: isWeb ? getScale(4) : isIOS ? getScale(5) : getScale(3),
            //backgroundColor: 'lime',
        },
        inputToolbarContainer: {
            ...styles.inputToolbarContainer,
            bottom: 0,
            left: 0,
            right: 0,
            //height: '7.5%', // new
            //justifyContent: 'center', // new
            //backgroundColor: 'orange',
        },
        inputToolbarPrimary: {
            ...styles.inputToolbarPrimary,
            //backgroundColor: 'pink',
        },
        inputToolbarAccessory: {
            ...styles.inputToolbarAccessory,
            height: getScale(44),
            //backgroundColor: 'cyan',
        },
        sendContainer: {
            ...styles.sendContainer,
            height: getScale(44),
            //backgroundColor: 'maroon',
            //width: isWeb ? getScale(6) : getScale(60),
            //width: 80,
        },
        sendText: {
            ...styles.sendText,
            fontSize: getScale(17),
            marginBottom: getScale(12),
            marginLeft: getScale(5),
            marginRight: getScale(5),
            // backgroundColor: 'blue',
        },
        minComposerHeight: getScale(85), //isWeb ? getScale(34) : isIOS ? getScale(33) : getScale(41),
        maxComposerHeight: getScale(200),
        minInputToolbarHeight: getScale(85),
    };
};
