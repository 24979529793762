import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
//import { Rect, Text } from 'react-native-svg';
import { Pressable, Text, View } from '../components';
import Svg, { G } from '../svg';
import { IpaAudio, IPAColors, Vocal, Phonics, ReversePhonics } from '../media';
import { useAppState, useSystemState } from '../context';
import { useKeyboard } from '../hooks';
import { Console } from '../utils';

const NAME = 'Speech2D';

// http://smu-facweb.smu.ca/~s0949176/sammy/

const SCALE = 0.15;

const LIPS = 'Lips';
const TONGUE = 'Tongue';
const VELUM = 'Velum';
const VOCAL_FOLDS = 'Vocal Folds';

const NASAL = 'nasal';
const ORAL = 'oral';
const VOICED = 'voiced';
const VOICELESS = 'voiceless';
const REST = 'rest';
const SPREAD = 'spread';
const ROUNDED = 'rounded';
const BILABIAL_FRICATIVE = 'bilabial-fricative';
const BILABIAL_STOP = 'bilabial-stop';
const LABIODENTAL = 'labiodental';
const DENTAL = 'dental';
const ALVEOLAR = 'alveolar';
const RETROFLEX = 'retroflex';
const POSTALVEOLAR = 'postalveolar';
const PALATAL = 'palatal';
const VELAR = 'velar';
const UVULAR = 'uvular';
const FRICATIVE = 'fricative';
const STOP = 'stop';

const DFRIC = 'DFric';
const TFRIC = 'Tongue11';

const Top = [
    'TopOral',
    'TopNasal',
];

const Back = [
    'BackOral',
    'BackNasal',
];

const Bottom = [
    'BottomVoiced',
    'BottomVoiceless',
];

const Lips = [
    ['LipsSpread', SPREAD],
    ['LipsRounded', ROUNDED],
    ['LipsBLFric', BILABIAL_FRICATIVE],
    ['LipsBLStop', BILABIAL_STOP],
    ['LipsLD', LABIODENTAL],
];

const Tongue = [
    ['Tongue00', REST, ''], // rest
    ['Tongue11', DENTAL, FRICATIVE], // dental fric
    ['Tongue21', DENTAL, STOP],
    ['Tongue22', ALVEOLAR, STOP],
    ['Tongue23', RETROFLEX, STOP],
    ['Tongue25', PALATAL, STOP],
    ['Tongue26', VELAR, STOP],
    ['Tongue27', UVULAR, STOP],
    ['Tongue12', ALVEOLAR, FRICATIVE],
    ['Tongue13', RETROFLEX, FRICATIVE],
    ['Tongue14', POSTALVEOLAR, FRICATIVE],
    ['Tongue15', PALATAL, FRICATIVE],
    ['Tongue16', VELAR, FRICATIVE],
    ['Tongue17', UVULAR, FRICATIVE],
];

export const Speech2D = props => {

    const {
    } = props;

    const { deviceScale } = useSystemState();
    const { t, dark, theme, gesture } = useAppState();

    const { kbInput } = useKeyboard();

    const [top, setTop] = useState(0);
    const [back, setBack] = useState(0);
    const [bottom, setBottom] = useState(0);
    const [lips, setLips] = useState(3);
    const [tongue, setTongue] = useState(0);

    const [ipa, setIpa] = useState('');
    const [audioIndex, setAudioIndex] = useState(0);

    const setTopRef = useRef(setTop);
    const setBackRef = useRef(setBack);
    const setBottomRef = useRef(setBottom);
    const setLipsRef = useRef(setLips);
    const setTongueRef = useRef(setTongue);

    const setIpaRef = useRef(setIpa);
    const setAudioIndexRef = useRef(setAudioIndex);

    useEffect(
        () => {
            const indices = ReversePhonics.get(kbInput);
            const tokens = indices ? indices.split('-') : [];
            if (tokens.length === 4) {
                const vv = +tokens[0];
                const nn = +tokens[1];
                const ll = +tokens[2];
                const tt = +tokens[3];
                Console.log(`${NAME} useEffect keyboard`, { kbInput, indices, tokens, vv, nn, ll, tt });
                setBottomRef.current(vv);
                setTopRef.current(nn);
                setBackRef.current(nn);
                setLipsRef.current(ll);
                setTongueRef.current(tt);
            }
        },
        [
            kbInput,
            setTopRef,
            setBackRef,
            setBottomRef,
            setLipsRef,
            setTongueRef,
        ],
    );

    useEffect(
        () => {
            const ipaValue = Phonics[bottom][top][lips][tongue].ipa;
            Console.log(`${NAME} useEffect audio`, { bottom, top, lips, tongue, ipa: ipaValue });
            setIpaRef.current(ipaValue);
            setAudioIndexRef.current(0);
        },
        [
            bottom,
            top,
            lips,
            tongue,
            setIpaRef,
            setAudioIndexRef,
        ],
    );

    const renderPressable = useCallback(
        (style, onPress) => {
            // MARKMARK: This doesn't work on android
            return (
                <Pressable
                    style={style}
                    onPress={onPress}
                />
            );
        },
        [
        ],
    );

    const renderButton = useCallback(
        (label, value, backgroundColor, onPress) => {
            const buttonStyle = {
                backgroundColor: backgroundColor + (dark ? '9' : '3'),
                width: '49%',
                borderColor: dark ? 'white' : 'black',
                borderWidth: 1,
                borderRadius: 50,
            };
            return (
                <Pressable
                    style={[ styles.button, buttonStyle ]}
                    onPress={onPress}
                >
                    <Text
                        style={styles.textStyle}
                        noTranslate={true}
                        value={label}
                    />
                    <Text
                        style={styles.textStyle}
                        noTranslate={true}
                        value={value}
                    />
                </Pressable>
            );
        },
        [
            dark,
        ],
    );
    /*
                        <Button
                            variant={BUTTON_VARIANTS.TEXT}
                            noTranslate={true}
                            value={value}
                            labelStyle={labelStyle}
                            onPress={onPress}
                        />
    */
    Console.stack(NAME, props, { dark, gesture, kbInput, top, back, bottom, lips, tongue, ipa, audioIndex });

    return useMemo(
        () => {

            const foreColor = dark ? '#fff' : '#000';
            const backColor = theme.colors.background;

            const svgSize = {
                width: 250 * deviceScale,
                height: 400 * deviceScale,
            };

            const ipaStyle = {
                paddingBottom: '25%',
            };

            const onVelum = () => {
                setTopRef.current(v => (v + 1) % Top.length);
                setBackRef.current(v => (v + 1) % Back.length);
            };

            const onLips = () => {
                setLipsRef.current(v => (v + 1) % Lips.length);
            };

            const onVocalFolds = () => {
                setBottomRef.current(v => (v + 1) % Bottom.length);
            };

            const onTongue = () => {
                setTongueRef.current(v => (v + 1) % Tongue.length);
            };

            const backgroundStyle = {
                backgroundColor: dark ? 'rgba(100, 100, 100, 0.5)' : 'rgba(200, 200, 200, 0.5)',
            };
            Console.devLog(`${NAME} render`, { dark, top, back, bottom, lips, tongue, ipa, audioIndex, theme });

            return (
                <View
                    value={'Speech2DView'}
                    style={[styles.view, backgroundStyle]}
                >
                    <View
                        value={'Speech2DTopButtons'}
                        style={styles.buttons}
                    >
                        {renderButton(
                            t(VELUM),
                            t(top ? NASAL : ORAL),
                            IPAColors.NasalColor(dark),
                            onVelum,
                        )}
                        {renderButton(
                            t(LIPS),
                            t(Lips[lips][1]),
                            IPAColors.LipsColor(dark),
                            onLips,
                        )}
                    </View>
                    <View
                        value={'Speech2DMain'}
                        style={styles.main}
                    >
                        <View
                            value={'Speech2DSvg'}
                            style={[styles.svg, svgSize]}
                        >
                            <Svg
                                viewBox={'0 0 100 110'}
                            >
                                <G
                                    x={100}
                                    y={0}
                                    scalex={-1}
                                    scaley={1}
                                >
                                    <G
                                        x={0}
                                        y={0}
                                        scale={SCALE}
                                    >
                                        {Vocal(foreColor, backColor, dark)[Top[top]].svg}
                                    </G>
                                    <G
                                        x={0}
                                        y={30}
                                        scale={SCALE}
                                    >
                                        {Vocal(foreColor, backColor, dark)[`${Lips[lips][0]}${Tongue[tongue][0] === TFRIC ? DFRIC : ''}`].svg}
                                    </G>
                                    <G
                                        x={20}
                                        y={30}
                                        scale={SCALE}
                                    >
                                        {Vocal(foreColor, backColor, dark)[Tongue[tongue][0]].svg}
                                    </G>
                                    <G
                                        x={64.35}
                                        y={30}
                                        scale={SCALE}
                                    >
                                        {Vocal(foreColor, backColor, dark)[Back[back]].svg}
                                    </G>
                                    <G
                                        x={0}
                                        y={56.5}
                                        scale={SCALE}
                                    >
                                        {Vocal(foreColor, backColor, dark)[Bottom[bottom]].svg}
                                    </G>
                                </G>
                            </Svg>
                            {renderPressable({
                                position: 'absolute',
                                top: '30%',
                                left: '30%',
                                width: '10%',
                                height: '15%',
                            }, onVelum)}
                            {renderPressable({
                                position: 'absolute',
                                top: '35%',
                                left: '80%',
                                width: '15%',
                                height: '15%',
                            }, onLips)}
                            {renderPressable({
                                position: 'absolute',
                                top: '35%',
                                left: '40%',
                                width: '40%',
                                height: '15%',
                            }, onTongue)}
                            {renderPressable({
                                position: 'absolute',
                                top: '65%',
                                left: '30%',
                                width: '15%',
                                height: '15%',
                            }, onVocalFolds)}
                        </View>
                        <View
                            value={'Speech2DIpa'}
                            style={[styles.ipa, ipaStyle]}
                        >
                            <IpaAudio
                                style={styles.text}
                                ipa={ipa}
                                index={audioIndex}
                                onPress={() => setAudioIndexRef.current(audioIndex + 1)}
                            />
                        </View>
                    </View>
                    <View
                        value={'Speech2DBottomButtons'}
                        style={styles.buttons}
                    >
                        {renderButton(
                            t(VOCAL_FOLDS),
                            t(bottom ? VOICELESS : VOICED),
                            IPAColors.VoiceColor(dark),
                            onVocalFolds,
                        )}
                        {renderButton(
                            t(TONGUE),
                            tongue ? `${t(Tongue[tongue][1])}-${t(Tongue[tongue][2])}` : t(Tongue[tongue][1]),
                            IPAColors.TongueColor(dark),
                            onTongue,
                        )}
                    </View>
                </View>
            );
        },
        [
            t,
            deviceScale,
            dark,
            theme,
            bottom,
            top,
            back,
            lips,
            tongue,
            ipa,
            audioIndex,
            setBottomRef,
            setTopRef,
            setBackRef,
            setLipsRef,
            setTongueRef,
            setAudioIndexRef,
            renderButton,
            renderPressable,
        ],
    );
};

/*

*** see dynamic setting in Speech.js ***

                                <G
                                    scale={0.125}
                                >
                                    <Text
                                        x={0}
                                        y={60}
                                        fontSize={60}
                                        fontWeight={'normal'}
                                        fill={Colors.colors.red}
                                        stroke={Colors.colors.red}
                                    >
                                        something
                                    </Text>
                                </G>
                                <Rect
                                    x={30}
                                    y={25}
                                    width={10}
                                    height={20}
                                    fill={Colors.colors.blue}
                                    opacity={0.5}
                                    onPress={() => console.log('voila')}
                                />
*/

const styles = StyleSheet.create({
    view: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    main: {
        flexDirection: 'row',
        width: '90%',
        height: '70%',
        justifyContent: 'center',
    },
    button: {
        justifyContent: 'center',
        width: '50%',
    },
    textStyle: {
        textAlign: 'center',
    },
    buttons: {
        flexDirection: 'row',
        width: '95%',
        height: '10%',
        paddingVertical: '2%',
        marginHorizontal: '5%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    text: {
    },
    ipa: {
        flexDirection: 'row',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderColor: 'yellow',
        borderWidth: 0,

    },
    svg: {
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'cyan',
        borderWidth: 0,
    },
});
