import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert } from 'react-native';
import { GameUtils } from './GameUtils';
import { Button, Dictation, Microphone, Pressable/*, Text*/, View } from '../../components';
import { useAppState, useSystemState } from '../../context';
import { Colors } from '../../styles';
import { Audio, Console, Optional, Validate } from '../../utils';
import { languageColors } from '../../constants';
import {GENDER, TTS_VARIANT, useTTS} from '../../voice';

const NAME = 'CrossWordPuzzle';

const DARK = 'dark';
const LIGHT = 'light';

const DUMMY_COORD = -1;
const EMPTY_STRING = '';
const EMPTY_ARRAY = [];


export const CrossWordPuzzle = props => {

    const { deviceScale, os, isWeb } = useSystemState();
    const { DEFAULT, dark, language, t } = useAppState();
    const {speak} = useTTS(Audio.Play);
    const { KB_BUTTON_HEIGHT, KB_BUTTON_WIDTH, KB_BUTTON_RADIUS, KB_MARGIN_HZ, KB_MARGIN_VT, KB_ROW_HEIGHT } = DEFAULT;

    const [coordinate, setCoordinate] = useState({ i: DUMMY_COORD, j: DUMMY_COORD });
    const [puzzle, setPuzzle] = useState({ matrix: EMPTY_ARRAY, words: EMPTY_ARRAY });
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(EMPTY_STRING);
    const [category, setCategory] = useState(EMPTY_STRING);
    const [transcription, setTranscription] = useState(EMPTY_STRING);
    const [unlocked, setUnlocked] = useState(EMPTY_ARRAY);
    const [test, setTest] = useState(true);

    const setCoordinateRef = useRef(setCoordinate);
    const setTargetRef = useRef(setTarget);
    const setTranscriptionRef = useRef(setTranscription);
    const setUnlockedRef = useRef(setUnlocked);
    const setPuzzleRef = useRef(setPuzzle);
    const setShowRef = useRef(setShow);
    const setCategoryRef = useRef(setCategory);
    const setTestRef = useRef(setTest);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect createNewPuzzle`, { language });
            createNewPuzzle(false, null);
        },
        [
            language, // force a new puzzle if the language changes
            createNewPuzzle,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect match`, { category, target, transcription });
            if (Validate.isValidNonEmptyString(transcription) &&
                Validate.isValidNonEmptyString(category) && // category
                Validate.isValidNonEmptyString(target)) {
                if (GameUtils.isMatch(/*os, language,*/ transcription, /*category,*/ target)) {
                    var coords = [];
                    puzzle.matrix.forEach((row, i) => {
                        row.forEach((cell, j) => {
                            if (cell.isUsed && !GameUtils.isUnlocked(i, j, unlocked) && cell.words.includes(target)) {
                                coords.push({ i, j });
                            }
                        });
                    });
                    setUnlockedRef.current([...unlocked, ...coords]);
                } else {
                    Console.log(`${NAME} useEffect NO MATCH`, { transcription, target });
                    // MARKMARK: remove this alert.  transcription is nice, but if disabled, puzzle doesn't
                    Alert.alert(null, transcription, [{ text: 'OK' }]);
                }
                setTranscriptionRef.current(EMPTY_STRING);
                onSelect();
            }
        },
        [
            language,
            puzzle.matrix,
            os,
            transcription,
            target,
            unlocked,
            setTranscriptionRef,
            setUnlockedRef,
            onSelect,
            category,
        ],
    );

    const createNewPuzzle = useCallback(
        (audio, notCategory) => {
            Console.devLog(`${NAME}.createNewPuzzle`, { audio, notCategory });
            const newPuzzle = GameUtils.newPuzzle(t, notCategory);
            setPuzzleRef.current(newPuzzle?.game);
            setCategoryRef.current(newPuzzle?.category);
            setShowRef.current(false);
            setUnlockedRef.current(EMPTY_ARRAY);
            onSelect();
            audio && speak(TTS_VARIANT.PLATFORM, t(newPuzzle?.category), language, GENDER.FEMALE);
        },
        [
            language,
            setPuzzleRef,
            setShowRef,
            setCategoryRef,
            setUnlockedRef,
            onSelect,
            t,
            speak,
        ],
    );

    const onSelect = useCallback(
        (i = DUMMY_COORD, j = DUMMY_COORD) => {
            const val = i !== DUMMY_COORD && j !== DUMMY_COORD
                ? puzzle.matrix[i][j].words[0]
                : EMPTY_STRING;
            Console.devLog(`${NAME}.onSelect`, { i, j, coordinate, word: val });
            setCoordinateRef.current({ i, j });
            setTargetRef.current(val);
        },
        [
            coordinate,
            puzzle.matrix,
            setCoordinateRef,
            setTargetRef,
        ],
    );

    const onPress = useCallback(
        (i, j, cell) => {
            Console.log(`*** ${cell.value} ***`, { i, j, cell });
            if (!GameUtils.isUnlocked(i, j, unlocked) && /[a-z]/.test(cell.value)) {
                //      setShow(v => !v);
                if (cell.icon === '?') {
                    setUnlockedRef.current([...unlocked, { i, j }]);
                    onSelect();
                } else {
                    if (test) {
                        console.log('CELL', cell);
                        speak(TTS_VARIANT.PLATFORM, cell.words[0], language, GENDER.MALE);
                    } else {
                        onSelect(i, j);
                    }
                }
            } else {
                onSelect();
            }
        },
        [
            language,
            speak,
            test,
            setUnlockedRef,
            onSelect,
            unlocked,
        ],
    );

    const onTranscript = useCallback(text => {
        Console.devLog(`${NAME}.onTranscript`, {text, target});
        if (GameUtils.isMatch(text, target)) {
            return 'match';
        }
        return false;
    }, [target]);

    const Cell = useCallback(
        (i, j, cell) => {
            const { icon, isUsed, words } = cell;
            const title = cell.value;
            const showValue = GameUtils.isUnlocked(i, j, unlocked);

            const fontSize = KB_BUTTON_HEIGHT * 0.5 * deviceScale;
            const keyboardButtonText = {
                fontSize,
                lineHeight: fontSize * 1.15,
                fontWeight: show ? 'bold' : 'normal',
            };
            const keyboardButton = {
                width: KB_BUTTON_WIDTH * deviceScale,
                height: KB_BUTTON_HEIGHT * deviceScale,
                borderRadius: KB_BUTTON_RADIUS * deviceScale,
                marginVertical: KB_MARGIN_VT * deviceScale,
                marginHorizontal: KB_MARGIN_HZ * deviceScale,
                alignItems: 'center',
                justifyContent: 'center',
            };

            if (isUsed) {
                if (coordinate.i === i && coordinate.j === j) {
                    Console.log(`${NAME}.Cell`, { i, j, c: title, icon, words, show: showValue });
                }
            }
            //                    onEnd={onSelect}
            return Optional(coordinate.i === i && coordinate.j === j,
                // MARKMARK: when selecting mic to turn off, the emoji should be restored
                // MARKMARK: maybe color the text button to make it obvious to select
                // MARKMARK: don't keep alive for game
                Optional(isWeb, (
                    <Microphone
                        key={'microphone'}
                        style={keyboardButton}
                        setTranscriptionRef={setTranscriptionRef}
                    />
                ), (<Dictation
                    key={`mic_${i}_${j}`}
                    auto={target?.length}
                    keepAlive={false}
                    onTranscript={onTranscript}
                    onUtterance={setTranscriptionRef.current}
                    onStop={onSelect}
                />)),
                (
                    <Pressable
                        key={`game_${i}_${j}`}
                        style={keyboardButton}
                        textStyle={keyboardButtonText}
                        backgroundColor={
                            title === EMPTY_STRING
                                ? languageColors[language][dark ? DARK : LIGHT].backgroundColor
                                : show
                                    ? languageColors[language][dark ? LIGHT : DARK].backgroundColor
                                    : Colors.colors.white
                        }
                        textColor={
                            title === EMPTY_STRING
                                ? languageColors[language][dark ? DARK : LIGHT].color
                                : show
                                    ? languageColors[language][dark ? LIGHT : DARK].color
                                    : Colors.colors.black
                        }
                        value={show || showValue ? title : icon}
                        disabled={isUsed ? false : true}
                        onPress={() => onPress(i, j, cell)}
                    />
                ));
        },
        [
            isWeb,
            KB_BUTTON_HEIGHT,
            KB_BUTTON_WIDTH,
            KB_BUTTON_RADIUS,
            KB_MARGIN_HZ,
            KB_MARGIN_VT,
            deviceScale,
            dark,
            coordinate,
            language,
            onPress,
            onSelect,
            onTranscript,
            show,
            target,
            unlocked,
            setTranscriptionRef,
        ],
    );

    const Row = useCallback(
        (row, i) => {
            const keyboardRow = {
                height: KB_ROW_HEIGHT * deviceScale,
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            };
            Console.log(`${NAME}.Row`, { i });
            return (
                <View
                key={`game_row_${i}`}
                value={`CrosswordRow_${i}`}
                    style={keyboardRow}
                >
                    {row.map((cell, j) => Cell(i, j, cell))}
                </View>
            );
        },
        [
            KB_ROW_HEIGHT,
            deviceScale,
            Cell,
        ],
    );

    Console.stack(NAME, props, { KB_BUTTON_HEIGHT, KB_BUTTON_WIDTH, KB_BUTTON_RADIUS, KB_MARGIN_HZ, KB_MARGIN_VT, KB_ROW_HEIGHT, deviceScale, category, words: puzzle.words, target });

    return useMemo(
        () => {
            const smallValue = 5 * deviceScale;
            const gameView = {
                padding: smallValue,
                alignItems: 'center',
                justifyContent: 'space-around',
                height: '100%',
            };
            const gameButtonRow = {
                padding: smallValue,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
            };
            const gameButton = {
                marginHorizontal: smallValue,
                backgroundColor: dark ? 'black' : 'white',
                borderRadius: 20,
            };
            const gameBackground = {backgroundColor: dark ? 'darkgray' : 'lightgray'};
            const controlBackground = {backgroundColor: test ? 'lime' : 'red' };
            Console.devLog(`${NAME} render`, { puzzle, category, show });
            return (
                <View
                    value={'Crossword'}
                    style={[gameView, gameBackground]}
                >
                    {puzzle.matrix.map((row, i) => Row(row, i))}
                    <View
                        value={'CrosswordButtonRow'}
                        style={gameButtonRow}
                    >
                        <Button
                            style={gameButton}
                            value={category}
                            disabled={show}
                            onPress={() => createNewPuzzle(true, category)}
                        />
                        <Button
                            style={gameButton}
                            value={show ? 'Hide' : 'Show'}
                            onPress={() => { setShowRef.current(v => !v); }}
                        />
                        <Button
                            style={[gameButton, controlBackground]}
                            value={test ? '🔊' : '🎤'}
                            disabled={show}
                            onPress={() => { setTestRef.current(v => !v); }}
                        />
                    </View>
                </View>
            );
        },
        [
            dark,
            test,
            deviceScale,
            puzzle,
            category,
            show,
            createNewPuzzle,
            Row,
            setShowRef,
            setTestRef,
        ],
    );
};
