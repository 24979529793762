import axios from 'axios'; // MARKMARK should use utils/server
import {Console} from '../../utils';
import {
  DEEPGRAM_API_KEY,
  DEEPGRAM_TTS,
  SPEECHIFY_API_KEY,
  SPEECHIFY_TTS,
  UNREAL_API_KEY,
  UNREAL_TTS,
} from './constants';

const NAME = 'TTS';

const ACCEPT_ALL = '*/*';
const APP_JSON = 'application/json';

const DEFAULT_LANGUAGE = 'en-US';
const SPEECHIFY_MODEL = 'simba-base'; // simba-(base|turbo)

const MAX_TEXT_DEEPGRAM = 2000;
const MAX_TEXT_SPEECHIFY = 2000;
const MAX_TEXT_UNREAL = 1000;

// pricing
// - deepgram:
//   $0.0150/1k characters: pay-as-you-go
//   $0.0135/1k characters: growth
// - speechify:
// - unreal:

export class TTS {
  constructor(
    speak,
    deepgram_key = DEEPGRAM_API_KEY,
    speechify_key = SPEECHIFY_API_KEY,
    unreal_key = UNREAL_API_KEY,
  ) {
    this.speak = speak;
    this.deepgram_key = deepgram_key;
    this.speechify_key = speechify_key;
    this.unreal_key = unreal_key;
    this.deepgram_audio = null;
    this.speechify_audio = null;
    this.unreal_audio = null;
  }

  async #fetch(endpoint, data, config) {
    Console.log(`${NAME}.#fetch`, {endpoint, data});
    try {
      const response = await axios.post(endpoint, data, config);
      if (response?.status !== 200) {
        Console.warn(`${NAME}.#fetch: bad response`, {response});
        return null;
      }
      Console.log(`${NAME}.#fetch response`, {response});
      return response?.data;
    } catch (error) {
      Console.warn(`${NAME}.#fetch: exception`, {error});
      return null;
    }
  }

  async #tts_deepgram(text, female) {
    Console.log(`${NAME}.#tts_deepgram`, {text, female});

    const endpoint = `${DEEPGRAM_TTS}${
      female ? 'aura-asteria-en' : 'aura-arcas-en'
    }`;
    const headers = {
      Authorization: `Token ${this.deepgram_key}`,
      'Content-Type': APP_JSON,
    };
    const config = {
      headers,
      responseType: 'blob',
    };
    const data = {
      text: text.substring(0, MAX_TEXT_DEEPGRAM),
    };

    const audio = await this.#fetch(endpoint, data, config);
    this.deepgram_audio = 'deepgram.wav';
    Console.log(`${NAME}.#tts_deepgram: audio`, {audio: this.deepgram_audio});

    const reader = new FileReader();
    reader.onload = async () => {
      const base64Data = reader.result.split(',')[1]; // Strip off the data URL prefix
      this.speak(base64Data, 1, this.deepgram_audio);
    };
    reader.readAsDataURL(audio);
  }

  async #tts_speechify(text, female, language) {
    // MARKMARK: This needs some tlc...test speechify's langs
    // also, only es-MX voices
    // https://docs.sws.speechify.com/docs/language-support
    const LangMap = new Map([
      ['en_us', 'en-US'],
      ['es_es', 'es-ES'],
      ['fr_fr', 'fr-FR'],
    ]);
    let lang = LangMap.get(language);
    if (!lang?.length) {
      lang = DEFAULT_LANGUAGE;
    }

    Console.log(`${NAME}.#tts_speechify`, {text, female, language, lang});

    const endpoint = SPEECHIFY_TTS;
    const headers = {
      Authorization: `Bearer ${this.speechify_key}`,
      'Content-Type': APP_JSON,
      accept: ACCEPT_ALL,
    };
    const config = {
      headers,
    };
    const data = {
      audio_format: 'wav',
      input: text.substring(0, MAX_TEXT_SPEECHIFY),
      language: lang, // https://docs.sws.speechify.com/docs/language-support (en-US, es-ES, fr-FR)
      model: SPEECHIFY_MODEL,
      voice_id: female ? 'carly' : 'henry', // MARKMARK: spanish voices
    };

    const audio = await this.#fetch(endpoint, data, config);
    this.speechify_audio = 'speechify.wav';
    Console.log(`${NAME}.#tts_speechify: audio`, {
      audio: this.speechify_audio,
      billable_characters_count: audio?.billable_characters_count,
    });

    //const base64Data = Buffer.from(audio.audio_data, 'base64');
    this.speak(audio.audio_data, 1, this.speechify_audio);
  }

  async #tts_unreal(text, female) {
    Console.log(`${NAME}.#tts_unreal`, {text, female});

    const endpoint = UNREAL_TTS;
    const headers = {
      Authorization: `Bearer ${this.unreal_key}`,
      'Content-Type': APP_JSON,
      accept: APP_JSON,
    };
    const config = {
      headers,
    };
    const data = {
      Text: text.substring(0, MAX_TEXT_UNREAL),
      VoiceId: female ? 'Liv' : 'Dan', // Dan, Will, Scarlett, Liv, Amy
      Bitrate: '192k', // 320k, 256k, 192k, ...
      Speed: '0', // -1.0 to 1.0
      Pitch: '1', // -0.5 to 1.5
      TimestampType: 'sentence',
      Codec: 'libmp3lame',
    };

    const audio = await this.#fetch(endpoint, data, config);
    this.unreal_audio = audio.OutputUri;
    Console.log(`${NAME}.#tts_unreal: audio`, {audio: this.unreal_audio});
    this.speak(this.unreal_audio);
  }

  async deepgram(text = null, female = true) {
    if (text?.length) {
      this.#tts_deepgram(text, female);
    } else {
      this.speak(this.deepgram_audio);
    }
  }

  async speechify(text = null, female = true, language = DEFAULT_LANGUAGE) {
    if (text?.length) {
      this.#tts_speechify(text, female, language);
    } else {
      this.speak(this.speechify_audio);
    }
  }

  async unreal(text = null, female = true) {
    if (text?.length) {
      this.#tts_unreal(text, female);
    } else {
      this.speak(this.unreal_audio);
    }
  }
}
