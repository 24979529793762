import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, Card, Flag, FlatList, Screen, View } from '../components';
import { useAppState, useMessagesDispatch, useSystemState, useUsersDispatch, MESSAGES_TYPES, USERS_TYPES } from '../context';
import { Console, Numbers, Optional } from '../utils';
import { Navigate } from './utils';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';

import DEFAULT_USERS from '../constants/users.json';

const NAME = 'Users';

const CHAT = 'Chat';


export const Users = props => {

    const {
        navigation,
    } = props;

    const { width, adjHeight } = useSystemState();
    const { t, dark, showAds } = useAppState();

    const usersDispatch = useUsersDispatch();
    const usersDispatchRef = useRef(usersDispatch);
    const messagesDispatch = useMessagesDispatch();
    const messagesDispatchRef = useRef(messagesDispatch);

    const [users, setUsers] = useState(DEFAULT_USERS);
    const setUsersRef = useRef(setUsers);

    const [loading] = useState(false); // MARKMARK: get rid of this

    useEffect(
        () => {
            const BASE_COLOR = dark ? 63 : 192;
            const OFFSET_COLOR = 63;
            const RANDOM_COLOR = () => `${BASE_COLOR + Numbers.random(OFFSET_COLOR)}`;
            setUsersRef.current(arr => arr.map(v => ({ ...v, backgroundColor: `rgb(${RANDOM_COLOR()},${RANDOM_COLOR()},${RANDOM_COLOR()})` })));
        },
        [
            setUsersRef,
            dark,
        ],
    );

    const onPress = useCallback(
        payload => {
            Console.log(`${NAME}.onPress`, { payload });
            messagesDispatchRef.current({ type: MESSAGES_TYPES.UNSET_MESSAGES });
            usersDispatchRef.current({ type: USERS_TYPES.SET_BOT, payload });
            Navigate(navigation, t(CHAT), { title: payload?.name, language: payload?.language });
        },
        [
            navigation,
            t,
            messagesDispatchRef,
            usersDispatchRef,
        ],
    );

    const renderItem = useCallback(
        ({ item }) => {
            Console.log(`${NAME}.renderItem`, { item });
            return (
                <Card
                    id={item._id}
                    title={item.name}
                    subtitle={item.lastMessage}
                    cardStyle={styles.card}
                    onPress={() => onPress(item)}
                    left={<Flag country={item.language.split('_')[1]} />}
                    backgroundColor={item.backgroundColor}
                />
            );
        },
        [
            onPress,
        ],
    );

    Console.stack(NAME, props, { width, adjHeight, loading, users, dark, showAds });

    return useMemo(
        () => {
            const adsHeight = { height: showAds ? adjHeight - BANNER_AD_HEIGHT : adjHeight };
            const heightStyle = { width, height: adjHeight };
            Console.devLog(`${NAME} render`, { props, heightStyle, adjHeight, showAds });
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    {Optional(loading, (
                        <View
                            style={[styles.view, adsHeight]}
                        >
                            <ActivityIndicator />
                        </View>
                    ), (
                        <View
                            style={[heightStyle, adsHeight]}
                        >
                            <FlatList
                                data={users}
                                renderItem={renderItem}
                            />
                        </View>
                    ))}
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            props,
            width,
            adjHeight,
            loading,
            users,
            renderItem,
            showAds,
        ],
    );
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
        //backgroundColor: Colors.colors.red,
    },
    view: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        paddingVertical: '33%',
    },
    card: {
        paddingVertical: '2%',
    },
});
