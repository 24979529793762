import React from 'react';
import { Text as RNText } from 'react-native';
import { Text, TEXT_VARIANTS, Pressable, View } from '../components';
import { useAppState, useSystemState } from '../context';
import { Audio, Console, Numbers, Optional } from '../utils';
import { TextStyles } from '../styles';
import { S3_SERVER } from '../constants';

// android files must be mp3
// https://online-audio-converter.com/
// note: can keep launching in incognito to bypass daily limit

import { ipaWavLookup } from './ipaWavLookup';

import { ipaExamples } from './ipaExamples';
import {GENDER, TTS_VARIANT, useTTS} from '../voice';

const NAME = 'IpaAudio';


export const IpaAudio = props => {

    const {
        ipa,
        index,
        style,
        onPress,
    } = props;

    const {speak} = useTTS(Audio.Play);
    const { dark, language } = useAppState();

    const { deviceScale } = useSystemState();

    var ipaWavs = ipaWavLookup[ipa === 'ɡ' ? 'g' : ipa];
    var ipaExample = ipaExamples[language] && ipaExamples[language][ipa === 'ɡ' ? 'g' : ipa];

    var textStyle = {...TextStyles.title};
    textStyle.lineHeight = textStyle.fontSize * 2;
    const _textStyle = Numbers.ReScaleStyle(textStyle, deviceScale);

    Console.devStack(NAME, props, { deviceScale, ipaWavs, ipaExample, style, _textStyle });

    const mainWidthStyle = {width: 150};
    return (
        <View
            style={mainWidthStyle}
        >
            <Pressable
                onPress={() => {
                    if (ipaWavs) {
                        const types = Object.keys(ipaWavs);
                        const type = types[index % types.length];
                        Audio.Play(`${S3_SERVER}/ipa_2d/${ipaWavs[type]}.mp3`);
                        if (onPress) {
                            onPress();
                        }
                    }
                }}
            >
                <Text
                    style={[_textStyle, style]}
                    value={`/${ipa}/${ipaWavs ? ' 🔊' : ''}`}
                    variant={TEXT_VARIANTS.HEADLINE}
                />
            </Pressable>
            {Optional(ipaExample, ['start', 'middle', 'end'].map(position => {
                const IPA_ALTERNATES = new Map([['b', 'β'], ['d', 'ð']]);
                const example = ipaExample && ipaExample[position];
                if (!example || !example?.emoji || !example?.word || !example?.ipa) {
                    return <></>;
                }
                const ipaToControl = (str, _ipa) => {
                    let t = str.replace(_ipa, `+${ipa}-`);

                    if (t.indexOf('+') < 0) {
                        const alt = IPA_ALTERNATES.get(_ipa);
                        if (alt) {
                            t = str.replace(alt, `+${alt}-`);
                        }
                    }

                    const s1 = t.split('+');
                    if (s1?.length !== 2) {
                      Console.error(`${NAME} unexpected ipa string tokens (s1.len != 2)`, {str, s1, t});
                      return null;
                    }
                    const s2 = s1[1].split('-');
                    if (s2?.length !== 2) {
                        Console.error(`${NAME} unexpected ipa string tokens (s2.len != 2)`, {str, s1, s2, t});
                        return null;
                    }
                    const nonIpaStyle = {color: dark ? 'rgb(200, 200, 200)' : 'rgb(50, 50, 50)'};
                    const ipaStyle = {fontWeight: 'bold', color: dark ? 'rgb(255, 0, 0)' : 'rgb(0, 0, 255)'};
                    return (
                        <RNText style={nonIpaStyle}>
                            {s1[0]}
                            <RNText style={ipaStyle}>
                                {s2[0]}
                            </RNText>
                            {s2[1]}
                        </RNText>
                    );
                };
                const buttonStyle = { borderColor: 'gray', borderWidth: 0, flexDirection: 'row', justifyContent: 'flex-start', paddingVertical: 10 };
                const emojiStyle = {fontSize: 30, lineHeight: 50, backgroundColor: 'white', borderRadius: 25, paddingHorizontal: 8};
                const wordStyle = {borderColor: 'lime', borderWidth: 0, marginLeft: 5};
                return (
                    <Pressable
                        key={position}
                        style={buttonStyle}
                        onPress={() => speak(TTS_VARIANT.PLATFORM, example.word, language, GENDER.FEMALE)}
                    >
                        <Text
                            key={`emoji_${position}`}
                            style={[_textStyle, style, emojiStyle]}
                            value={example.emoji}
                            variant={TEXT_VARIANTS.SUBHEADING}
                        />
                        <View
                            style={wordStyle}
                        >
                            <Text
                                key={`word_${position}`}
                                style={[_textStyle, style]}
                                value={example.word}
                                variant={TEXT_VARIANTS.SUBHEADING}
                            />
                            {ipaToControl(example.ipa, ipa)}
                        </View>
                    </Pressable>
                );
            }))}
        </View>
    );
};
