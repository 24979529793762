const EMOJI_REGEX_BRACKETS = /\[\]/g;
const EMOJI_REGEX_BOTH = /\s([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])+\s/g;
const EMOJI_REGEX_PRE = /\s([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
const EMOJI_REGEX_POST = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])\s/g;
const EMOJI_REGEX = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;

export class Strings {

    static #Diacritics = {
        a: /[áàâãă]/g, A: /[ÁÀÂÃĂ]/g,
        c: /[ç]/g, C: /[Ç]/g,
        e: /[éèêë]/g, E: /[ÉÈÊË]/g,
        i: /[íìîï]/g, I: /[ÍÌÍÎ]/g,
        n: /[ñ]/g, N: /[Ñ]/g,
        o: /[óòôõ]/g, O: /[ÓÒÔÕ]/g,
        s: /[şș]/g, S: /[ŞȘ]/g,
        t: /[ţț]/g, T: /[ŢȚ]/g,
        u: /[úùûü]/g, U: /[ÚÙÛÜ]/g,
        y: /[ÿ]/g, Y: /[Ÿ]/g,
    };

    static Capitalize = s => {
        return (!s) ? s :
            s.toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.substring(1))
                .join(' ');
    };

    static ReplaceDiacritics = word => {
        var result = word;
        if (!result) {
            return result;
        }
        Object.keys(Strings.#Diacritics).forEach(letter => {
            result = result.replace(Strings.#Diacritics[letter], letter);
        });
        return result;
    };

    static #ReplaceInnerQuotes = (sentence, open, close) => {
        // replace first quote with open, second one with close
        // assumes all inner quotes are not nested
        const doReplace = (s, o, c) => {
            const i = s.indexOf('"');
            const j = i < 0 || i === s.length - 1 ? -1 : i + 1 + s.substring(i + 1).indexOf('"');
            return (i >= 0 && j >= 0)
                ? `${s.slice(0, i)}${o}${s.slice(i + 1, j)}${c}${s.slice(j + 1)}`
                : s;
        };
        // repeat replacement until no more quote pairs
        var result = sentence;
        do {
            var temp = result;
            result = doReplace(temp, open, close);
        } while (result !== temp);
        return result;
    };

    static LanguageQuotes = (language, sentence, primary = true) => {
        // assume sentence is unquoted, but may have inner double quotations: blah blah said, "blah blah"
        // https://asynonymforrambling.wordpress.com/2018/08/07/american-english-french-and-romanian-punctuation-differences/#:~:text=In%20Romanian%2C%20there%20are%20no,and%20Popa%202015%2C%20450).
        // https://en.wikipedia.org/wiki/Quotation_mark
        switch (language.split('_')[1]) {
            case 'haw':
            case 'us':
            case 'gb':
            case 'br': // go with PT on this one (MARKMARK: mark these types of issues with a dialect initiative tag)
                return `${primary ? '“' : ''}${Strings.#ReplaceInnerQuotes(sentence, '‘', '’')}${primary ? '”' : ''}`;
            case 'es':
            case 'mx':
            case 'fr':
            case 'it':
            case 'pt':
                return `${primary ? '« ' : ''}${Strings.#ReplaceInnerQuotes(sentence, '“', '”')}${primary ? ' »' : ''}`;
            case 'ro':
                return `${primary ? '„' : ''}${Strings.#ReplaceInnerQuotes(sentence, '«', '»')}${primary ? '”' : ''}`;
            default:
                return null;
        }
    };

    static RemoveEmojis = (s, isIPA = false) => {
        if (!s) {
            return null;
        }
        var t = s;
        t = t.replace(EMOJI_REGEX_BOTH, ' ');
        t = t.replace(EMOJI_REGEX_PRE, '');
        t = t.replace(EMOJI_REGEX_POST, '');
        t = t.replace(EMOJI_REGEX, '');
        if (isIPA) {
            t = t.replace(EMOJI_REGEX_BRACKETS, '');
        }
        return t;
    };
}
