import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, StyleSheet } from 'react-native';
import { Button, Card, FlatList, Image, Text, View, Screen, BUTTON_VARIANTS, TEXT_VARIANTS, VIEW_VARIANTS } from '../components';
import { Console, Optional } from '../utils';
import { Colors } from '../styles';
import { useAppState, useSystemState } from '../context';
import { S3_SERVER } from '../constants';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';
import { NavParams } from './utils';

const NAME = 'MapDetail';

const IMAGE_WIDTH = 360;
const IMAGE_HEIGHT = 240;

const DUKES = [
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_omelet.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_eggs.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_eggs_ben.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_pancakes.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_bacon.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_meat.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_potatoes.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_toast.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_muffins.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_rice.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_frice.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_fruit1.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_fruit2.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_fruit3.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_granola.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_oatmeal.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_cereal.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_dairy.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_salad.jpg` } },
    { source: { uri: `${S3_SERVER}/da_app_hawaii/dukes_toppings.jpg` } },
];

const MALEKOS = [
    { title: 'Bagel', price: '$3.25', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_bagel.jpg` } },
    { title: 'Bear Claw', price: '$4.00', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_bearclaw.jpg` } },
    { title: 'Croissant', price: '$4.00', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_croissant.jpg` } },
    { title: 'Brownie', price: '$2.60', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_brownies.jpg` } },
    { title: 'Scone', price: '$3.00', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_blueberryscone.jpg` } },
    { title: 'Danish', price: '$3.60', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_danish.jpg` } },
    { title: 'Macaroon', price: '$2.60', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_macaroons.jpg` } },
    { title: 'Malasada', price: '$2.85', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_malasadas.jpg` } },
    { title: 'Muffin', price: '$3.25', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_muffin.jpg` } },
    { title: 'Palm', price: '$2.00', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_palm.jpg` } },
    { title: 'Croissant Sandwich', price: '$4.75', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_croissantsandwiches.jpg` } },
    { title: 'Hotdog Roll', price: '$2.00', source: { uri: `${S3_SERVER}/da_app_hawaii/pastries_hotdogrolls.jpg` } },
];

export const MapDetail = props => {

    const {
        navigation,
        route,
    } = props;

    const { adjHeight } = useSystemState();
    const { language, dark, showAds } = useAppState();

    const [item, setItem] = useState({});
    const setItemRef = useRef(setItem);

    const [data, setData] = useState([]);
    const setDataRef = useRef(setData);

    const [description, setDescription] = useState(null);
    const setDescriptionRef = useRef(setDescription);

    const [showPics, setShowPics] = useState(false);
    const setShowPicsRef = useRef(setShowPics);

    const [showHP, setShowHP] = useState(true);
    const setShowHPRef = useRef(setShowHP);

    const [loc] = useState(NavParams(route)?.loc);
    const [title] = useState(NavParams(route)?.title);
    /*
        const [ads, setAds] = useState(false);
        const setAdsRef = useRef(setAds);

        useEffect(
            () => {
                Console.log(`${NAME} useEffect GoogleAds`);
                return GoogleAds.Get().subscribe(AD_TYPES.BANNER, setAdsRef);
            },
            [
                setAdsRef,
            ],
        );
    */
    useEffect(
        () => {

            var tempData = [];
            if (loc?.phone) {
                tempData.push(`☎️ ${loc.phone}`);
            }
            if (loc?.address) {
                tempData.push(`✉️ ${loc.address.split('|')[0].split(',')[0]}`);
            }
            if (loc?.price) {
                tempData.push(`🪙 ${loc.price}`);
            }

            var tempDescription = null;
            if (showHP && language.startsWith('en') && loc?.hp) {
                tempDescription = `🤙🏽 ${loc.hp}`;
            } else {
                const languageTokens = language.toLowerCase().split('_');
                if (loc[languageTokens[0]]) {
                    tempDescription = `ℹ️ ${loc[languageTokens[0]]}`;
                } else if (languageTokens.length > 1 && loc[languageTokens[1]]) {
                    tempDescription = `ℹ️ ${loc[languageTokens[1]]}`;
                }
            }

            Console.devLog(`${NAME} useEffect entry`, { title, loc, tempData, tempDescription, language, showHP });

            setItemRef.current({ ...loc });
            setDataRef.current(tempData);
            setDescriptionRef.current(tempDescription);

            navigation.setOptions({
                headerTitle: ({ tintColor }) => (
                    <Text
                        value={title}
                        color={tintColor}
                        variant={title?.length > 20 ? TEXT_VARIANTS.SUBHEADING : TEXT_VARIANTS.TITLE}
                    />
                ),
            });
        },
        [
            loc,
            showHP,
            language,
            title,
            navigation,
            setItemRef,
            setDataRef,
            setDescriptionRef,
        ],
    );

    const renderItem = useCallback(
        _item => {
            Console.log(`${NAME}.renderItem`, { _item, showHP, dark });
            const cardColor = { backgroundColor: dark ? Colors.colors.black : Colors.colors.lightgray };
            const blackColorStyle = { color: dark ? Colors.colors.white : Colors.colors.black };
            return (
                <Card
                    cardStyle={[styles.card, cardColor]}
                    titleStyle={[blackColorStyle, cardColor]}
                    title={_item.item}
                    onPress={() => setShowHPRef.current(v => !v)}
                />
            );
        },
        [
            dark,
            showHP,
            setShowHPRef,
        ],
    );

    const renderMalekoMenu = useMemo(
        () => {
            return (
                <View
                    style={styles.scrollView}
                    variant={VIEW_VARIANTS.SCROLL}
                >
                    {MALEKOS.map((v, idx) => (
                        <View
                            style={styles.menu}
                        >
                            <View
                                style={styles.menuItem}
                            >
                                <Text
                                    value={v.title}
                                    color={Colors.colors.black}
                                    variant={TEXT_VARIANTS.TITLE}
                                />
                                <Text
                                    value={v.price}
                                    color={Colors.colors.black}
                                    variant={TEXT_VARIANTS.TITLE}
                                />
                            </View>
                            <Image
                                source={v.source}
                                containerStyle={styles.logo}
                                width={IMAGE_WIDTH}
                                height={IMAGE_HEIGHT}
                                resizeMode={'contain'}
                            />
                        </View>
                    ))}
                </View>
            );
        },
        [
        ],
    );

    const renderDukesMenu = useMemo(
        () => {
            return (
                <View
                    style={styles.scrollView}
                    variant={VIEW_VARIANTS.SCROLL}
                >
                    {DUKES.map((v, idx) => (
                        <Image
                            source={v.source}
                            containerStyle={styles.logo}
                            width={IMAGE_WIDTH}
                            height={IMAGE_HEIGHT}
                            resizeMode={'contain'}
                        />
                    ))}
                </View>
            );
        },
        [
        ],
    );

    Console.stack(NAME, props, { adjHeight, item, showAds, data, description });

    return useMemo(
        () => {
            const viewColor = { backgroundColor: dark ? Colors.colors.black : Colors.colors.lightgray };
            const adsHeight = { height: showAds ? adjHeight - BANNER_AD_HEIGHT : '100%' };
            Console.devLog(`${NAME} render`, { data, adjHeight, adsHeight, showAds, showPics });
            const lightGreenColorStyle = { backgroundColor: Colors.colors.lightgreen };
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <View
                        style={[styles.view, adsHeight, viewColor]}
                    >
                        {Optional(!showPics, (
                            <>
                                {Optional(item?.image_url, (
                                    <Image
                                        source={{ uri: item.image_url }}
                                        containerStyle={styles.logo}
                                        width={IMAGE_WIDTH}
                                        height={IMAGE_HEIGHT}
                                        resizeMode={'contain'}
                                    />
                                ), (
                                    <Image
                                        source={{ uri: `${S3_SERVER}/da_app_hawaii/biz_maleko.png` }}
                                        containerStyle={styles.logo}
                                        width={IMAGE_WIDTH}
                                        height={IMAGE_HEIGHT}
                                        resizeMode={'contain'}
                                    />
                                ))}
                                {Optional(data.length, (
                                    <View
                                        style={styles.iconGroup}
                                    >
                                        {data.map(v => (
                                            <Button
                                                value={v.charAt(0)}
                                                labelStyle={styles.iconGroupLabel}
                                                variant={BUTTON_VARIANTS.TEXT}
                                                onPress={() => Alert.alert(null, v.substring(2), [{ text: 'OK' }])}
                                            />
                                        ))}
                                    </View>
                                ))}
                                {Optional(description?.length, (
                                    <View
                                        style={styles.list}
                                    >
                                        <FlatList
                                            data={[description]}
                                            renderItem={renderItem}
                                        />
                                    </View>
                                ))}
                            </>
                        ), Optional(item?.title === 'Maleko\'s',
                            renderMalekoMenu,
                            renderDukesMenu,
                        ))}
                        {Optional(item?.price, (
                            <View
                                style={styles.buttonGroup}
                            >
                                {Optional(item.title === 'Maleko\'s', (
                                    <Button
                                        value={'Menu'}
                                        variant={BUTTON_VARIANTS.CONTAINED}
                                        style={[styles.button, showPics ? lightGreenColorStyle : {}]}
                                        labelStyle={styles.buttonLabel}
                                        onPress={() => setShowPicsRef.current(v => !v)}
                                    />
                                ), (
                                    <>
                                        <Button
                                            value={'Breakfast'}
                                            variant={BUTTON_VARIANTS.CONTAINED}
                                            style={[styles.button, showPics ? lightGreenColorStyle : {}]}
                                            labelStyle={styles.buttonLabel}
                                            onPress={() => setShowPicsRef.current(v => !v)}
                                        />
                                        <Button
                                            value={'Lunch'}
                                            variant={BUTTON_VARIANTS.CONTAINED}
                                            style={styles.button}
                                            labelStyle={styles.buttonLabel}
                                        />
                                        <Button
                                            value={'Dinner'}
                                            variant={BUTTON_VARIANTS.CONTAINED}
                                            style={styles.button}
                                            labelStyle={styles.buttonLabel}
                                        />
                                    </>
                                ))}
                            </View>
                        ))}
                        <View
                            style={styles.buttonGroup}
                        >
                            <Button
                                value={'Coupons'}
                                variant={BUTTON_VARIANTS.CONTAINED}
                                style={[styles.button, lightGreenColorStyle]}
                                backgroundColor={Colors.colors.green}
                                labelStyle={styles.buttonLabel}
                                onPress={() => Console.devLog('Coupon')}
                            />
                            <Button
                                value={item.title === 'Maleko\'s' ? 'Order' : 'Reservations'}
                                variant={BUTTON_VARIANTS.CONTAINED}
                                style={[styles.button, lightGreenColorStyle]}
                                labelStyle={styles.buttonLabel}
                                onPress={() => Console.devLog('Booking')}
                            />
                        </View>
                    </View>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            props,
            dark,
            adjHeight,
            showAds,
            item,
            data,
            description,
            showPics,
            setShowPicsRef,
            renderItem,
            renderMalekoMenu,
            renderDukesMenu,
        ],
    );
    /*
        const renderItem = item => {
            const { id, name, image, price } = item.item;
            Console.log(`${NAME}.renderItem`, { item });
            return (
                <Card
                    title={name}
                    left={<Image source={image} />}
                    right={<Text value={price} />}
                />
            );
        }
    */
    /*
        return (
            <View
                style={styles.view}
            >
                <View
                    style={styles.logo}
                >
                    <Image
                        source={Images.oahu.biz_maleko}
                        width={320}
                        height={214}
                    />
                </View>
                <View
                    style={styles.list}
                >
                    <FlatList
                        data={DATA}
                        renderItem={renderItem}
                    />
                </View>
            </View>
        );
    */
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
    },
    view: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    logo: {
        height: IMAGE_HEIGHT,
        width: IMAGE_WIDTH,
    },
    list: {
        flex: -1,
        width: '98%',
        marginVertical: '1%',
    },
    iconGroup: {
        position: 'absolute',
        width: '10%',
        height: IMAGE_HEIGHT,
        left: '85%',
        top: 0,
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: Colors.colors.transparent,
    },
    iconGroupLabel: {
        fontSize: 30,
        //fontWeight: 'bold',
        //color: Colors.colors.black,
    },
    scrollView: {
        width: '100%',
        height: '79%',
        alignItems: 'center',
    },
    card: {
        //paddingVertical: 2,
        paddingHorizontal: 2,
    },
    button: {
        marginVertical: 5,
    },
    buttonLabel: {
        fontSize: 20,
        fontWeight: 'bold',
        fontStyle: 'italic',
        color: Colors.colors.black,
    },
    buttonGroup: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    menu: {
        width: '100%',
        marginTop: '15%',
    },
    menuItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});
