export const ipaExamples = {
    // MARKMARK: no 'g'
    en_us: {
    'p': {
    start: { word: 'pen', emoji: '🖊', ipa: '/pɛn/', en: 'pen' },
    end: { word: 'tap', emoji: '🚰', ipa: '/tæp/', en: 'tap' },
    middle: { word: 'apple', emoji: '🍎', ipa: '/ˈæpəl/', en: 'apple' },
    },
    't': {
    start: { word: 'ten', emoji: '🔟', ipa: '/tɛn/', en: 'ten' },
    end: { word: 'cat', emoji: '🐱', ipa: '/kæt/', en: 'cat' },
    middle: { word: 'butter', emoji: '🧈', ipa: '/ˈbʌtəɹ/', en: 'butter' },
    },
    'b': {
    start: { word: 'bat', emoji: '🦇', ipa: '/bæt/', en: 'bat' },
    end: { word: 'cube', emoji: '🎲', ipa: '/kjuːb/', en: 'cube' },
    middle: { word: 'rabbit', emoji: '🐇', ipa: '/ˈɹæbɪt/', en: 'rabbit' },
    },
    'd': {
    start: { word: 'dog', emoji: '🐶', ipa: '/dɔɡ/', en: 'dog' },
    end: { word: 'bed', emoji: '🛏', ipa: '/bɛd/', en: 'bed' },
    middle: { word: 'ladder', emoji: '🪜', ipa: '/ˈlædəɹ/', en: 'ladder' },
    },
    'k': {
    start: { word: 'cat', emoji: '🐱', ipa: '/kæt/', en: 'cat' },
    end: { word: 'book', emoji: '📚', ipa: '/bʊk/', en: 'book' },
    middle: { word: 'bucket', emoji: '🪣', ipa: '/ˈbʌkɪt/', en: 'bucket' },
    },
    'f': {
    start: { word: 'fish', emoji: '🐟', ipa: '/fɪʃ/', en: 'fish' },
    end: { word: 'safe', emoji: '🔒', ipa: '/seɪf/', en: 'safe' },
    middle: { word: 'coffee', emoji: '☕', ipa: '/ˈkɔːfi/', en: 'coffee' },
    },
    'v': {
    start: { word: 'van', emoji: '🚐', ipa: '/væn/', en: 'van' },
    end: { word: 'dove', emoji: '🕊', ipa: '/dʌv/', en: 'dove' },
    middle: { word: 'river', emoji: '🏞', ipa: '/ˈɹɪvəɹ/', en: 'river' },
    },
    'θ': {
    start: { word: 'thumb', emoji: '👍', ipa: '/θʌm/', en: 'thumb' },
    end: { word: 'bath', emoji: '🛁', ipa: '/bæθ/', en: 'bath' },
    middle: { word: 'author', emoji: '✍️', ipa: '/ˈɔːθəɹ/', en: 'author' },
    },
    'ð': {
    start: { word: 'this', emoji: '👇', ipa: '/ðɪs/', en: 'this' },
    end: { word: 'breathe', emoji: '🌬️', ipa: '/bɹið/', en: 'breathe' },
    middle: { word: 'weather', emoji: '🌦️', ipa: '/ˈwɛðɚ/', en: 'weather' }, // MARKMARK: emoji
    },
    's': {
    start: { word: 'sun', emoji: '☀️', ipa: '/sʌn/', en: 'sun' },
    end: { word: 'bus', emoji: '🚌', ipa: '/bʌs/', en: 'bus' },
    middle: { word: 'basket', emoji: '🧺', ipa: '/ˈbæskɪt/', en: 'basket' },
    },
    'z': {
    start: { word: 'zoo', emoji: '🦁', ipa: '/zuː/', en: 'zoo' },
    end: { word: 'buzz', emoji: '🐝', ipa: '/bʌz/', en: 'buzz' },
    middle: { word: 'music', emoji: '🎵', ipa: '/ˈmjuːzɪk/', en: 'music' },
    },
    'ʃ': {
    start: { word: 'shoe', emoji: '👟', ipa: '/ʃuː/', en: 'shoe' },
    end: { word: 'fish', emoji: '🐟', ipa: '/fɪʃ/', en: 'fish' },
    middle: { word: 'mushroom', emoji: '🍄', ipa: '/ˈmʌʃɹuːm/', en: 'mushroom' },
    },
    'ʒ': {
    start: { word: 'genre', emoji: '🎭', ipa: '/ˈʒɑːnɹə/', en: 'genre' },
    end: { word: 'garage', emoji: '🏠', ipa: '/ɡəˈɹɑːʒ/', en: 'garage' },
    middle: { word: 'measure', emoji: '📏', ipa: '/ˈmɛʒəɹ/', en: 'measure' },
    },
    'h': {
    start: { word: 'hat', emoji: '🎩', ipa: '/hæt/', en: 'hat' },
    end: { word: 'tooth', emoji: '🦷', ipa: '/tuːθ/', en: 'tooth' },
    middle: { word: 'ahead', emoji: '➡️', ipa: '/əˈhɛd/', en: 'ahead' },
    },
    'm': {
    start: { word: 'man', emoji: '👨', ipa: '/mæn/', en: 'man' },
    end: { word: 'room', emoji: '🚪', ipa: '/ɹuːm/', en: 'room' },
    middle: { word: 'lemon', emoji: '🍋', ipa: '/ˈlɛmən/', en: 'lemon' },
    },
    'n': {
    start: { word: 'nose', emoji: '👃', ipa: '/noʊz/', en: 'nose' },
    end: { word: 'can', emoji: '🥫', ipa: '/kæn/', en: 'can' },
    middle: { word: 'banana', emoji: '🍌', ipa: '/bəˈnænə/', en: 'banana' },
    },
    'ŋ': {
    // MARKMARK: no common words for 'start' // start: { word: 'ngoma', emoji: '🥁', ipa: '/ŋɡoʊmə/', en: 'drum (Swahili origin)' },
    end: { word: 'ring', emoji: '💍', ipa: '/ɹɪŋ/', en: 'ring' },
    middle: { word: 'singer', emoji: '🎤', ipa: '/ˈsɪŋəɹ/', en: 'singer' },
    },
    'l': {
    start: { word: 'leaf', emoji: '🍃', ipa: '/liːf/', en: 'leaf' },
    end: { word: 'bell', emoji: '🔔', ipa: '/bɛl/', en: 'bell' },
    middle: { word: 'yellow', emoji: '💛', ipa: '/ˈjɛloʊ/', en: 'yellow' },
    },
    'ɹ': {
    start: { word: 'red', emoji: '🔴', ipa: '/ɹɛd/', en: 'red' },
    end: { word: 'car', emoji: '🚗', ipa: '/kɑːɹ/', en: 'car' },
    middle: { word: 'carrot', emoji: '🥕', ipa: '/ˈkæɹət/', en: 'carrot' },
    },
    'j': {
    start: { word: 'yellow', emoji: '💛', ipa: '/ˈjɛloʊ/', en: 'yellow' },
    end: { word: 'boy', emoji: '👦', ipa: '/bɔɪ/', en: 'boy' },
    middle: { word: 'player', emoji: '🏅', ipa: '/ˈpleɪəɹ/', en: 'player' },
    },
    'w': {
    start: { word: 'water', emoji: '💧', ipa: '/ˈwɔːtəɹ/', en: 'water' },
    end: { word: 'cow', emoji: '🐄', ipa: '/kaʊ/', en: 'cow' },
    middle: { word: 'flower', emoji: '🌸', ipa: '/ˈflaʊəɹ/', en: 'flower' },
    },
    },
    ja_jp: {
    'p': {
    start: { word: 'パン', emoji: '🍞', ipa: '/paɴ/', en: 'bread' },
    end: { word: 'キャップ', emoji: '🧢', ipa: '/kja.pːu/', en: 'cap' },
    middle: { word: 'スパゲティ', emoji: '🍝', ipa: '/sɯ.pa.ɡe.tiː/', en: 'spaghetti' },
    },
    't': {
    start: { word: 'テレビ', emoji: '📺', ipa: '/te.re.bi/', en: 'television' },
    end: { word: 'キャット', emoji: '🐱', ipa: '/kjat.to/', en: 'cat' },
    middle: { word: 'サラダ', emoji: '🥗', ipa: '/sa.ra.da/', en: 'salad' },
    },
    'b': {
    start: { word: 'バナナ', emoji: '🍌', ipa: '/ba.na.na/', en: 'banana' },
    end: { word: 'クラブ', emoji: '🏆', ipa: '/kɯ.ra.bɯ/', en: 'club' },
    middle: { word: 'リボン', emoji: '🎀', ipa: '/ɾi.boɴ/', en: 'ribbon' },
    },
    'd': {
    start: { word: 'ドア', emoji: '🚪', ipa: '/do.a/', en: 'door' },
    end: { word: 'クリード', emoji: '🛐', ipa: '/kɯɾiːdo/', en: 'creed' },
    middle: { word: 'カード', emoji: '🃏', ipa: '/kaː.do/', en: 'card' },
    },
    'k': {
    start: { word: 'カメラ', emoji: '📷', ipa: '/ka.me.ɾa/', en: 'camera' },
    end: { word: 'ロック', emoji: '🔒', ipa: '/ɾo.kɯ/', en: 'lock' },
    middle: { word: 'ベーコン', emoji: '🥓', ipa: '/beː.koɴ/', en: 'bacon' },
    },
    'f': {
    start: { word: 'フィルム', emoji: '🎞', ipa: '/fi.ru.mu/', en: 'film' },
    end: { word: 'オフ', emoji: '🚫', ipa: '/o.fɯ/', en: 'off' },
    middle: { word: 'ソファ', emoji: '🛋', ipa: '/so.fa/', en: 'sofa' },
    },
    'v': {
    start: { word: 'バイオリン', emoji: '🎻', ipa: '/ba.i.o.ɾin/', en: 'violin' },
    end: { word: 'ネガティブ', emoji: '⚠️', ipa: '/ne.ɡa.ti.bɯ/', en: 'negative' },
    middle: { word: 'アドバイス', emoji: '💡', ipa: '/a.do.ba.i.sɯ/', en: 'advice' },
    },
    's': {
    start: { word: 'サル', emoji: '🐒', ipa: '/sa.ru/', en: 'monkey' },
    end: { word: 'バス', emoji: '🚌', ipa: '/ba.sɯ/', en: 'bus' },
    middle: { word: 'カサ', emoji: '☂️', ipa: '/ka.sa/', en: 'umbrella' },
    },
    'z': {
    start: { word: 'ゾウ', emoji: '🐘', ipa: '/zo.u/', en: 'elephant' },
    end: { word: 'レンズ', emoji: '📷', ipa: '/re.n.zu/', en: 'lens' },
    middle: { word: 'カゼ', emoji: '🤒', ipa: '/ka.ze/', en: 'cold (illness)' },
    },
    'ʃ': {
    start: { word: 'シャツ', emoji: '👕', ipa: '/ʃa.tɯ/', en: 'shirt' },
    end: { word: 'ラッシュ', emoji: '🏃', ipa: '/raʃ.ʃɯ/', en: 'rush' },
    middle: { word: 'フラッシュ', emoji: '⚡', ipa: '/fɯ.raʃ.ʃɯ/', en: 'flash' },
    },
    'h': {
    start: { word: 'ホテル', emoji: '🏨', ipa: '/ho.te.ru/', en: 'hotel' },
    end: { word: 'シェフ', emoji: '󰞽', ipa: '/ʃe.fɯ/', en: 'chef' },
    middle: { word: 'マッシュルーム', emoji: '🍄', ipa: '/maʃ.ʃɯ.ɾuː.mu/', en: 'mushroom' },
    },
    'm': {
    start: { word: 'マスク', emoji: '😷', ipa: '/ma.sɯ.ku/', en: 'mask' },
    end: { word: 'チーム', emoji: '👥', ipa: '/͡tʃiː.mɯ/', en: 'team' },
    middle: { word: 'カメ', emoji: '🐢', ipa: '/ka.me/', en: 'turtle' },
    },
    'n': {
    start: { word: 'ナイフ', emoji: '🔪', ipa: '/na.i.fɯ/', en: 'knife' },
    end: { word: 'トーン', emoji: '🎶', ipa: '/toːn/', en: 'tone' },
    middle: { word: 'タヌキ', emoji: '🦝', ipa: '/ta.nɯ.ki/', en: 'raccoon dog' },
    },
    'ŋ': {
    start: { word: 'ンゴマ', emoji: '🥁', ipa: '/ŋɡo.ma/', en: 'ngoma (drum)' },
    end: { word: 'リング', emoji: '💍', ipa: '/ɾiŋ.ɡɯ/', en: 'ring' },
    middle: { word: 'コング', emoji: '🎵', ipa: '/koŋ.ɡɯ/', en: 'gong' },
    },
    'l': {
    start: { word: 'ラム', emoji: '🐑', ipa: '/ra.mu/', en: 'lamb' },
    end: { word: 'ボール', emoji: '⚽', ipa: '/boː.ɾɯ/', en: 'ball' },
    middle: { word: 'ホリデー', emoji: '🎉', ipa: '/ho.ɾi.deː/', en: 'holiday' },
    },
    'ɾ': {
    start: { word: 'リス', emoji: '🐿', ipa: '/ɾi.sɯ/', en: 'squirrel' },
    end: { word: 'カート', emoji: '🛒', ipa: '/kaː.to/', en: 'cart' },
    middle: { word: 'サル', emoji: '🐒', ipa: '/sa.ɾɯ/', en: 'monkey' },
    },
    'w': {
    start: { word: 'ワイン', emoji: '🍷', ipa: '/wa.in/', en: 'wine' },
    end: { word: 'クロー', emoji: '🦅', ipa: '/kɯ.roː/', en: 'crow' },
    middle: { word: 'パワー', emoji: '💪', ipa: '/pa.waː/', en: 'power' },
    },
    },
    es_mx: {
    'p': {
    start: { word: 'pan', emoji: '🍞', ipa: '/pan/', translation: 'bread' },
    end: { word: 'sirop', emoji: '🍯', ipa: '/siˈɾop/', translation: 'syrup' },
    middle: { word: 'sopa', emoji: '🍲', ipa: '/ˈsopa/', translation: 'soup' },
    // MARKMARK: add 'soup' and 'syrup' to CWPuzzle emojiList.js, then localize
    // DO THIS IN MENAPPGERIE
    },
    't': {
    start: { word: 'tasa', emoji: '☕', ipa: '/ˈtasa/', translation: 'cup' },
    middle: { word: 'cantar', emoji: '🎤', ipa: '/kanˈtaɾ/', translation: 'sing' },
    },
    'b': {
    start: { word: 'baño', emoji: '🛁', ipa: '/ˈbaɲo/', translation: 'bathroom' },
    middle: { word: 'caballo', emoji: '🐴', ipa: '/kaˈβaʎo/', translation: 'horse' },
    },
    'd': {
    start: { word: 'dar', emoji: '🤲', ipa: '/dar/', translation: 'to give' },
    end: { word: 'salud', emoji: '🍻', ipa: '/saˈluð/', translation: 'health' },
    middle: { word: 'boda', emoji: '💍', ipa: '/ˈboða/', translation: 'wedding' },
    },
    'k': {
    start: { word: 'casa', emoji: '🏠', ipa: '/ˈkasa/', translation: 'house' },
    end: { word: 'tocar', emoji: '🎼', ipa: '/toˈkaɾ/', translation: 'to play (an instrument)' },
    middle: { word: 'roca', emoji: '🪨', ipa: '/ˈroka/', translation: 'rock' },
    },
    'f': {
    start: { word: 'foca', emoji: '🦭', ipa: '/ˈfoka/', translation: 'seal' },
    end: { word: 'chef', emoji: '󰞽', ipa: '/ʃef/', translation: 'chef' },
    middle: { word: 'sofá', emoji: '🛋', ipa: '/soˈfa/', translation: 'sofa' },
    },
    'v': {
    start: { word: 'violín', emoji: '🎻', ipa: '/bjoˈlin/', translation: 'violin' },
    end: { word: 'nieve', emoji: '❄️', ipa: '/ˈnjeβe/', translation: 'snow' },
    middle: { word: 'nuevo', emoji: '🆕', ipa: '/ˈnweβo/', translation: 'new' },
    },
    's': {
    start: { word: 'sol', emoji: '☀️', ipa: '/sol/', translation: 'sun' },
    end: { word: 'tres', emoji: '3️⃣', ipa: '/tɾes/', translation: 'three' },
    middle: { word: 'rosa', emoji: '🌹', ipa: '/ˈrosa/', translation: 'rose' },
    },
    'z': {
    start: { word: 'zanahoria', emoji: '🥕', ipa: '/θanaˈoɾja/', translation: 'carrot' },
    end: { word: 'luz', emoji: '💡', ipa: '/luθ/', translation: 'light' },
    middle: { word: 'cruzar', emoji: '➡️', ipa: '/kruˈθaɾ/', translation: 'cross' },
    },
    'ʃ': {
    start: { word: 'shampoo', emoji: '🧴', ipa: '/ʃamˈpu/', translation: 'shampoo' },
    end: { word: 'cash', emoji: '💵', ipa: '/kaʃ/', translation: 'cash' },
    middle: { word: 'mash', emoji: '🍲', ipa: '/maʃ/', translation: 'mash' },
    },
    'ʒ': {
    start: { word: 'género', emoji: '📂', ipa: '/ˈxe.neɾo/', translation: 'gender' },
    end: { word: 'collage', emoji: '🖼', ipa: '/koˈlaʒe/', translation: 'collage' },
    middle: { word: 'viaje', emoji: '✈️', ipa: '/ˈbja.xe/', translation: 'trip' },
    },
    'm': {
    start: { word: 'mesa', emoji: '🪑', ipa: '/ˈmesa/', translation: 'table' },
    end: { word: 'tomar', emoji: '🍹', ipa: '/toˈmaɾ/', translation: 'to drink' },
    middle: { word: 'camino', emoji: '🛤', ipa: '/kaˈmino/', translation: 'path' },
    },
    'n': {
    start: { word: 'nube', emoji: '☁️', ipa: '/ˈnuβe/', translation: 'cloud' },
    end: { word: 'pan', emoji: '🍞', ipa: '/pan/', translation: 'bread' },
    middle: { word: 'banana', emoji: '🍌', ipa: '/baˈnana/', translation: 'banana' },
    },
    'ɲ': {
    start: { word: 'ñu', emoji: '🐃', ipa: '/ɲu/', translation: 'gnu' },
    end: { word: 'cañón', emoji: '🌀', ipa: '/kaˈɲon/', translation: 'canyon' },
    middle: { word: 'señal', emoji: '🚦', ipa: '/seˈɲal/', translation: 'signal' },
    },
    'l': {
    start: { word: 'luna', emoji: '🌙', ipa: '/ˈluna/', translation: 'moon' },
    end: { word: 'papel', emoji: '📄', ipa: '/paˈpel/', translation: 'paper' },
    middle: { word: 'bolígrafo', emoji: '🖊', ipa: '/boˈliɣɾafo/', translation: 'pen' },
    },
    'r': {
    start: { word: 'rojo', emoji: '🔴', ipa: '/ˈroxo/', translation: 'red' },
    end: { word: 'arroz', emoji: '🍚', ipa: '/aˈros/', translation: 'rice' },
    middle: { word: 'carro', emoji: '🚗', ipa: '/ˈkaro/', translation: 'car' },
    },
    'ɾ': {
    start: { word: 'rata', emoji: '🐀', ipa: '/ˈrata/', translation: 'rat' },
    end: { word: 'mar', emoji: '🌊', ipa: '/mar/', translation: 'sea' },
    middle: { word: 'caro', emoji: '💰', ipa: '/ˈkaɾo/', translation: 'expensive' },
    },
    'ʝ': {
    start: { word: 'llama', emoji: '🦙', ipa: '/ˈʝama/', translation: 'flame' },
    end: { word: 'rey', emoji: '👑', ipa: '/reʝ/', translation: 'king' },
    middle: { word: 'mayo', emoji: '🌼', ipa: '/ˈmaʝo/', translation: 'May' },
    },
    'ʎ': {
    start: { word: 'llama', emoji: '🦙', ipa: '/ˈʎama/', translation: 'llama' },
    end: { word: 'calle', emoji: '🛣', ipa: '/ˈkaʎe/', translation: 'street' },
    middle: { word: 'pollo', emoji: '🐔', ipa: '/ˈpoʎo/', translation: 'chicken' },
    },
    'ɣ': {
    start: { word: 'gato', emoji: '🐱', ipa: '/ˈɣato/', translation: 'cat' },
    end: { word: 'hogar', emoji: '🏡', ipa: '/oˈɣar/', translation: 'home' },
    middle: { word: 'agua', emoji: '💧', ipa: '/ˈaɣwa/', translation: 'water' },
    },
    'x': {
    start: { word: 'juego', emoji: '🎮', ipa: '/ˈxweɣo/', translation: 'game' },
    end: { word: 'bajo', emoji: '🔽', ipa: '/ˈbaxo/', translation: 'low' },
    middle: { word: 'reloj', emoji: '⌚', ipa: '/reˈlox/', translation: 'clock' },
    },
    },
};
