import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
    GoogleMap,
    DirectionsRenderer,
    DirectionsService,
    GroundOverlay, // Overlay
    Marker,
    MarkerClusterer,
    OverlayView, // Callout
    Polyline,
    useJsApiLoader,
} from '@react-google-maps/api';
import { Console, Optional } from '../../utils';
import { CustomMapStyle, TransitModeMap } from './maps';

const NAME = 'GoogleMapView.web';

const MAP_CONFIG = {
    id: 'google-map-script',
    // MARKMARK: SECURITY !!! googleMapsApiKey
    googleMapsApiKey: 'AIzaSyCdxCTyCHWbQgHVTWfjIYL2rYA2OtGMR3c',
};


export const GoogleMapView = props => {

    const {
        mapType,
        lat,
        lng,
        heading,
        pitch,
        zoom,
        minZoom,
        maxZoom,
        dark,
        weatherMarkers,
        weatherUpdate,
        callouts,
        calloutsUpdate,
        markers,
        markersUpdate,
        overlays,
        overlaysUpdate,
        polylines,
        polylinesUpdate,
        clusteringEnabled,
        origin,
        destination,
        travelMode,
        onDirections,
        onPress,
    } = props;

    const { isLoaded } = useJsApiLoader(MAP_CONFIG);

    const [map, setMap] = useState(null);
    const setMapRef = useRef(setMap);

    const [directions, setDirections] = useState(null);
    const setDirectionsRef = useRef(setDirections);

    const onLoad = useCallback(
        _map => {
            Console.devLog(`${NAME}.onLoad`, { _map });
            // This is just an example of getting and using the map instance!!! don't just blindly copy!
            //const bounds = new window.google.maps.LatLngBounds(Center);
            //_map.fitBounds(bounds);
            setMapRef.current(_map);
        },
        [
            setMapRef,
        ],
    );

    const onUnmount = useCallback(
        _map => {
            Console.devLog(`${NAME}.onUnmount`, { _map });
            setMapRef.current(null);
        },
        [
            setMapRef,
        ],
    );

    const _onDirections = useCallback(
        (_directions, _travelMode) => {
            Console.devLog(`${NAME}._onDirections`, { _directions, _travelMode });
            if (_directions?.status !== 200) {
                setDirectionsRef.current(null);
            } else {
                setDirectionsRef.current(_directions);
                if (onDirections) {
                    onDirections(_directions, _travelMode);
                }
            }
        },
        [
            onDirections,
            setDirectionsRef,
        ],
    );

    Console.stack(NAME, props, { isLoaded, map, directions });

    return useMemo(
        () => {
            const MARKMARK_MARKERSCALE = 1;
            const showDirections = (origin && origin !== '' && destination && destination !== '') ? true : false;
            Console.devLog(`${NAME} render`, { isLoaded, mapType, lat, lng, zoom, pitch, heading, weatherMarkers: weatherMarkers?.length, weatherUpdate, callouts: callouts?.length, calloutsUpdate, markers: markers?.length, markersUpdate, overlays: overlays?.length, overlaysUpdate, polylines: polylines?.length, polylinesUpdate, clusteringEnabled, origin, destination, travelMode, directions, minZoom, maxZoom, showDirections });
            return Optional(isLoaded, (
                <GoogleMap
                    mapTypeId={mapType}
                    mapContainerStyle={styles.map}
                    options={{
                        styles: CustomMapStyle(dark, overlays?.length, MARKMARK_MARKERSCALE),
                        fullscreenControl: false,
                        keyboardShortcuts: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                        zoomControl: false,
                    }}
                    center={{ lat, lng }}
                    heading={heading}
                    tilt={pitch}
                    zoom={zoom}
                    onClick={onPress}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    {
                        [
                            { id: 'callout', data: callouts, Comp: OverlayView },
                            { id: 'overlay', data: overlays, Comp: GroundOverlay },
                            { id: 'polyline', data: polylines, Comp: Polyline },
                        ].map(({ id, data, Comp }, index) => data.map(_props => <Comp key={`${id}${index}`} {..._props} />))
                    }
                    {Optional(clusteringEnabled, (
                        <MarkerClusterer
                            options={{
                                averageCenter: true,
                                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
                                minZoom,
                                maxZoom,
                            }}
                        >
                            {clusterer => markers.map(marker => <Marker {...marker} clusterer={clusterer} />)}
                        </MarkerClusterer>
                    ), (
                        <>
                            {markers.map(marker => <Marker {...marker} />)}
                        </>
                    ))}
                    {Optional(showDirections, (
                        <DirectionsService
                            options={{
                                origin,
                                destination,
                                travelMode,
                            }}
                            callback={_directions => _onDirections(_directions, travelMode)}
                        />
                    ))}
                    {Optional(directions, (
                        <DirectionsRenderer
                            options={{
                                directions,
                                strokeColor: TransitModeMap.get(travelMode).color,
                                strokeWeight: TransitModeMap.get(travelMode).width,
                            }}
                        />
                    ))}
                </GoogleMap>
            ));
        },
        [
            isLoaded,
            mapType,
            lat,
            lng,
            heading,
            pitch,
            zoom,
            minZoom,
            maxZoom,
            dark,
            weatherMarkers,
            weatherUpdate,
            callouts,
            calloutsUpdate,
            markers,
            markersUpdate,
            overlays,
            overlaysUpdate,
            polylines,
            polylinesUpdate,
            clusteringEnabled,
            origin,
            destination,
            travelMode,
            directions,
            _onDirections,
            onPress,
            onLoad,
            onUnmount,
        ],
    );
};

const styles = {
    map: {
        width: '360px',
        height: '413px',
    },
};
