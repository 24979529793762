import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImageBackground, Keyboard, StyleSheet } from 'react-native'; // MARKMARK: make ImageBackground a component?
import { GiftedChat, Actions, Avatar, Bubble, Composer, Day, InputToolbar, Message, MessageText, Send } from 'react-native-gifted-chat';
import { Flag, Icon, Microphone, Screen, Text, TEXT_VARIANTS, View } from '../components';
import { Icons, Images } from '../media';
import { useAppState, useMessagesDispatch, useMessagesState, useSystemState, useUserState, useUsersState, MESSAGES_TYPES } from '../context';
import { artist, chatbot } from '../ai';
import { ChatStyles } from './styles';
import { NavParams } from './utils';
import { MAX_CONTENT_LEN, USE_DEV_COLOR, DEV_COLOR, LANGUAGE_LOOKUP, LOCALE_LOOKUP } from '../constants';
import { Colors } from '../styles';
import { Console, Numbers, Optional, /*Speech,*/ Strings } from '../utils';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';

import {Dictation} from '../components';
import {GENDER, TTS_VARIANT, useTTS} from '../voice';
import {Audio} from '../utils';

const NAME = 'Chat';

const HUMAN_PROMPT = ' Human:';
const AI_PROMPT = ' AI:';

const EMPTY_ARRAY = [];
const EMPTY_STRING = '';
const SEPERATOR = '|';
const SEND_MESSAGE = 'Send a message';
const HELLO = 'Hello';
// const NO_MESSAGES = 'No messages'; // MARKMARK: localization files already have this

const TALK_NORMAL = 0.5;
const TALK_SLOW = 0.33;

const TRANSLATE_COLOR = [
    Colors.colors.green,
    Colors.colors.orange,
    Colors.colors.red,
];

class ChatUtils {
    static updateMessages(messages, user, bot, translate, language) {

        Console.log('MARKMARK ChatUtils.updateMessages: input', { messages, user, bot, translate, language });

        const result = messages.map((message, arrIndex) => {

            // create our updated message
            var updatedMessage = {
                ...message,
                _id: arrIndex,
                user: message.senderId === user.username ? { ...user, name: user.username } : bot,
            };

            // set the text based on the translate switch

            switch (translate) {
                case 1: // mode
                    if (updatedMessage?.message_mode?.length) {
                        updatedMessage.text = updatedMessage.message_mode;
                        updatedMessage.talk = `${language}|${TALK_NORMAL}|${updatedMessage.message_mode}`;
                    }
                    break;
                case 2: // ipa
                    if (updatedMessage?.message_ipa?.length) {
                        updatedMessage.text = updatedMessage.message_ipa;
                        updatedMessage.talk = `${bot.language}|${TALK_SLOW}|${updatedMessage.message}`;
                    }
                    break;
                default: // normal
                    if (updatedMessage?.message?.length) {
                        updatedMessage.text = updatedMessage.message;
                        updatedMessage.talk = `${bot.language}|${TALK_NORMAL}|${updatedMessage.message}`;
                    }
                    break;
            }

            Console.log(`ChatUtils.updateMessages: Mapping Message (${arrIndex}) [translate=${translate}]`, { message, updatedMessage });
            return updatedMessage;
        });

        Console.log(`ChatUtils.updateMessages: result [translate=${translate}]`, { messages, result });
        return result;
    }

    static FixIPA(s) {
        if (!s) {
            return null;
        }
        const t = Strings.RemoveEmojis(s.trim());
        const u = (!/^(\/|\[)/.test(t) && !/(\/|\])$/.test(t)) ? `/${t}/` : t;
        return u.replace(/(\[|\])/g, '/');
    }
}

export const Chat = props => {

    const {
        navigation,
        route,
    } = props;

    const {speak} = useTTS(Audio.Play);

    const messagesDispatch = useMessagesDispatch();
    const messagesDispatchRef = useRef(messagesDispatch);

    const { isWeb, isIOS, deviceScale, adjHeight } = useSystemState();
    const { dark, audio, language, t, aiPrompt, aiEMOJIS, aiIPA, phone, image, showAds } = useAppState();
    const { messages, messagesUpdated } = useMessagesState();
    const { user } = useUserState();
    const { bot/*, socketUrl*/ } = useUsersState();

    const [numMessages, setNumMessages] = useState(0);
    const setNumMessagesRef = useRef(setNumMessages);

    const [botMessage, setBotMessage] = useState(EMPTY_STRING);
    const setBotMessageRef = useRef(setBotMessage);

    const [nextMessage, setNextMessage] = useState(EMPTY_STRING);
    const setNextMessageRef = useRef(setNextMessage);

    const [adSearch, setAdSearch] = useState(null);
    const setAdSearchRef = useRef(setAdSearch);

    const [chatMessages, setChatMessages] = useState(EMPTY_ARRAY);
    const setChatMessagesRef = useRef(setChatMessages);

    const [typing, setTyping] = useState(false);
    const setTypingRef = useRef(setTyping);

    const [transcription, setTranscription] = useState(EMPTY_STRING);
    const setTranscriptionRef = useRef(setTranscription);

    const [transcribing, setTranscribing] = useState(false);
    const setTranscribingRef = useRef(setTranscribing);

    const [utterance, setUtterance] = useState(EMPTY_STRING);
    const setUtteranceRef = useRef(setUtterance);

    const [customText, setCustomText] = useState(EMPTY_STRING);
    const setCustomTextRef = useRef(setCustomText);

    const [chatStyles, setChatStyles] = useState({});
    const setChatStylesRef = useRef(setChatStyles);

    const [styleChanged, setStyleChanged] = useState(0);
    const setStyleChangedRef = useRef(setStyleChanged);

    const [translate, setTranslate] = useState(0);
    const setTranslateRef = useRef(setTranslate);

    const [botVoice, setBotVoice] = useState(null);
    const setBotVoiceRef = useRef(setBotVoice);

    const [modeVoice, setModeVoice] = useState(null);
    const setModeVoiceRef = useRef(setModeVoice);

    useEffect(
        () => {
            const parmsLang = NavParams(route)?.language;
            const parmsTitle = NavParams(route)?.title;
            const title = parmsTitle ? parmsTitle : NAME;
            Console.log(`${NAME} useEffect entry`, { route, parmsTitle, parmsLang, title });
            navigation.setOptions({
                headerTitle: ({ tintColor }) => (
                    <Text
                        value={title}
                        color={tintColor}
                        variant={TEXT_VARIANTS.TITLE}
                    />
                ),
            });
            /*
            const parmsLang = NavParams(route)?.language;
            if (parmsLang) {
                setLangRef.current(parmsLang);
            }
            */
        },
        [
            route,
            navigation,
            // language,
            // setLangRef,
        ],
    );

    useEffect(
        () => {
            setChatStylesRef.current(ChatStyles(isWeb, dark, deviceScale, bot.language, user.language, styles));
            setStyleChangedRef.current(v => v + 1);
        },
        [
            bot.language,
            user.language,
            isWeb,
            dark,
            deviceScale,
            setChatStylesRef,
            setStyleChangedRef,
        ],
    );

    useEffect(
        () => {
            if (audio && botMessage?.length) {
                const parms = botMessage.split(SEPERATOR);
                Console.devLog(`${NAME} useEffect talk`, { isIOS, audio, botMessage, parms, language, translateShouldBeFalse: translate });
                if (parms?.length === 3) {
                    const _lang = translate === 1 ? language : parms[1];
                    (async () => speak(TTS_VARIANT.SPEECHIFY, Strings.RemoveEmojis(parms[0]), _lang, GENDER.FEMALE))();
                    /*
                    const voice = Speech.Talk(isIOS, Strings.RemoveEmojis(parms[0]), _lang, botVoice, parms[2]);
                    setBotVoiceRef.current(v => v ? v : voice);
                    */
                }
                setBotMessageRef.current(EMPTY_STRING);
            }
        },
        [
            isIOS,
            translate,
            language,
            audio,
            botMessage,
            setBotMessageRef,
            botVoice,
            setBotVoiceRef,
            setAdSearchRef,
            speak,
        ],
    );

    const addMessage = useCallback(
        payload => {
            Console.devLog(`${NAME}.addMessage`, { payload });
            messagesDispatchRef.current({ type: MESSAGES_TYPES.ADD_MESSAGE, payload });
        },
        [
            messagesDispatchRef,
        ],
    );

    const onSend = useCallback(
        text => {
            const variables = {
                senderId: user.username,
                recipientId: bot.name,
                text,
            };
            Console.log(`${NAME}.onSend user_utterred`, { variables });
            addMessage(variables);

            // once a message is sent, the ui should generally reset as well
            setCustomTextRef.current(EMPTY_STRING);
            setNextMessageRef.current(EMPTY_STRING);
            setTranscriptionRef.current(EMPTY_STRING);
            setUtteranceRef.current(EMPTY_STRING);
            setTranslateRef.current(0);
            Keyboard.dismiss();
        },
        [
            user.username,
            bot.name,
            setCustomTextRef,
            setNextMessageRef,
            setTranscriptionRef,
            setTranslateRef,
            setUtteranceRef,
            addMessage,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect transcription`, { transcription, phone });
            if (phone) {
                if (transcription?.length) {
                    onSend(transcription);
                }
            } else {
                setCustomTextRef.current(transcription);
            }
        },
        [
            phone,
            transcription,
            setCustomTextRef,
            onSend,
        ],
    );
/*
    useEffect(
        () => {
            if (adSearch?.length) {
                const variables = {
                    text: adSearch,
                    system: true,
                };
                Console.log(`${NAME} useEffect ad search`);
                addMessage(variables);
                setAdSearchRef.current(null);
            }
        },
        [
            adSearch,
            setAdSearchRef,
            addMessage,
        ],
    );
*/
    useEffect(
        () => {
            var msgs = ChatUtils.updateMessages(messages, user, bot, translate, language);
            Console.log(`${NAME} useEffect messagesUpdated [${messagesUpdated}], msgs.length=${msgs?.length}`, { user, bot, translate, image });

            if (msgs?.length) {
                const userSentLastMessage = msgs[0].senderId === user.username;
                setTypingRef.current(userSentLastMessage);
                if (userSentLastMessage && numMessages !== messages.length) {
                    //if (SOCKETS[bot.id]) {
                    //    SOCKETS[bot.id].emit(msgs[0].text);
                    //}
                    if (image) {
                        (async () => {
                            const request = { prompt: msgs[0].text };
                            const response = await artist(user.username, request.prompt);
                            const variables = {
                                senderId: bot.name,
                                recipientId: user.username,
                                image: response?.message,
                                //system: true, // DOESNT DISPLAY IMAGE IF TRUE
                            };
                            Console.log(`${NAME} artist`, { request, response, variables });
                            addMessage(variables);
                        })();
                    } else {
                        (async () => {

                            var now = new Date();
                            var nowString = `${now.toDateString()}, ${now.toLocaleTimeString()}`;

                            var request1 = {
                                language,
                                lang: bot.language,
                                user: user.username,
                                message: msgs[0].text,
                                message_en: null,
                                message_mode: null,
                                chitchat: '',
                                options: {
                                    prompt: 'dummy',
                                },
                            };

                            /*
                            // if the user message matches the next message, pass the translation along
                            const nextMessageTokens = nextMessage?.length ? nextMessage.split(SEPERATOR) : EMPTY_ARRAY;
                            if (nextMessageTokens.length === 2 && request.message === nextMessageTokens[0]) {
                                request.message_en = nextMessageTokens[1];
                            }
                            Console.devLog('\n\n\n\n REQUEST NEXT TRANSLATION', request);
                            */

                            var response1 = await chatbot(
                                request1.language, // language
                                request1.lang, // bot language
                                request1.user, // user id
                                request1.message, // message in bot language
                                request1.message_en, // message translated to english
                                request1.message_mode, // message translated to mode language
                                request1.chitchat, // chat history in english
                                request1.options, // our prompt, aiPrompt with substitutions
                                aiEMOJIS,
                                aiIPA,
                            );

                            //=============================

                            var result = {};

                            if (!response1?.language?.length) {
                                Console.LOG('\n\nBAD CHATBOT 1 RESPONSE', { msgs, request1, response1 });
                                return;
                            }

                            // retrieved detected language

                            // the previous message was the request.message
                            result.previous = request1.message;

                            // the previous user message language
                            result.previous_language = response1.language.toLowerCase();

                            // the previous user message ipa in the bot language
                            result.previous_ipa = ChatUtils.FixIPA(response1?.previous_ipa);

                            // the previous user message in english
                            result.previous_en = response1?.previous_en;

                            // the previous user message in the mode language
                            result.previous_mode = response1?.previous_mode;

                            var variables = {
                                previous_language: result.previous_language,
                                previous: result.previous,
                                previous_ipa: result.previous_ipa,
                                previous_en: result.previous_en,
                                previous_mode: result.previous_mode,
                            };

                            Console.log(`\n\nCHATBOT 1 (${response1?.elapse})\n`, { /*request1,*/ response1, result/*, variables*/ });
                            Console.log('\n\n');

                            var request2 = { ...request1 };
                            request2.message = result.previous;
                            request2.message_en = result.previous_en;
                            request2.message_mode = result.previous_mode;
                            request2.chitchat = msgs
                                .slice(0)
                                .reverse()
                                .filter( v => v?.type !== 'system')
                                .map(v => {
                                    return `${v.senderId === bot.name ? AI_PROMPT : HUMAN_PROMPT} ${v?.message_en ? v.message_en : result?.previous_en}`;
                                })
                                .join(SEPERATOR);
                            request2.options.prompt = aiPrompt
                                .replace(/<DATE>/g, nowString)
                                .replace(/<NAME>/g, bot.name)
                                .replace(/<GENDER>/g, bot.gender)
                                .replace(/<DOB>/g, bot.dob)
                                .replace(/<LOCATION>/g, bot.location)
                                .replace(/<LANGUAGE>/g, LANGUAGE_LOOKUP.get(bot.language))
                                .replace(/<FORMAL>/g, bot.formal ? 'formal' : 'friendly');

                            //Console.LOG('\n\n\nCHITCHAT\n\n\n', { msgs, chitchat: request2.chitchat });
                            var response2 = await chatbot(
                                request2.language,
                                request2.lang,
                                request2.user,
                                request2.message,
                                request2.message_en,
                                request2.message_mode,
                                request2.chitchat,
                                request2.options,
                                aiEMOJIS,
                                aiIPA,
                            );

                            if (!response2?.message?.length) {
                                Console.LOG('\n\nBAD CHATBOT 2 RESPONSE A', { request2, response2 });
                                response2 = await chatbot(
                                    request2.language,
                                    request2.lang,
                                    request2.user,
                                    request2.message,
                                    request2.message_en,
                                    request2.message_mode,
                                    request2.chitchat,
                                    request2.options,
                                    aiEMOJIS,
                                    aiIPA,
                                );
                                if (!response2?.message?.length) {
                                    Console.LOG('\n\nBAD CHATBOT 2 RESPONSE B', { request2, response2 });
                                    return;
                                }
                            }

                            // the bot response in the bot language
                            result.message = response2?.message;

                            // the bot response ipa in the bot language
                            result.message_ipa = ChatUtils.FixIPA(response2?.message_ipa);

                            // the bot response in english
                            result.message_en = response2?.message_en;

                            // the bot response in the mode language
                            result.message_mode = response2?.message_mode;

                            const msg = `${result.message}|${bot.language}|${bot.gender}`;

                            variables = {
                                ...variables,
                                senderId: bot.name,
                                recipientId: user.username,
                                language: bot.language,
                                message: result.message,
                                message_ipa: result.message_ipa,
                                message_en: result.message_en,
                                message_mode: result.message_mode,
                            };

                            Console.log(`${NAME} useEffect bot_utterred`, { msg, variables });
                            addMessage(variables);
                            setBotMessageRef.current(msg);

                            // set the next message
                            if (response2?.next && response2?.next_en) {
                                setNextMessageRef.current(`${response2.next}|${response2.next_en}`);
                            }

                            // set the ad search
                            if (response2?.search_ad) {
                                setAdSearchRef.current(response2?.search_ad);
                            }

                            Console.log(`\n\nCHATBOT 2 (${response2?.elapse})\n`, { /*msgs, request2,*/ response2, result });
                            Console.log('\n\n');
                        })();
                    }
                }
            }
            setChatMessagesRef.current(msgs);
            setNumMessagesRef.current(msgs.length);
        },
        [
            numMessages,
            setNumMessagesRef,
            image,
            language,
            messages,
            messagesUpdated,
            user,
            bot,
            setTypingRef,
            setChatMessagesRef,
            setBotMessageRef,
            translate,
            aiPrompt,
            aiEMOJIS,
            aiIPA,
            nextMessage,
            setNextMessageRef,
            setAdSearchRef,
            addMessage,
        ],
    );

    const onInputTextChanged = useCallback(
        text => {
            Console.trace(`${NAME}.onInputTextChanged`, { text, transcribing });
            transcribing || setCustomTextRef.current(text);
        },
        [
            setCustomTextRef,
            transcribing,
        ],
    );

    const renderAvatar = useCallback(
        _props => {
            Console.log(`${NAME}.renderAvatar`, { _props });
            return (
                <Avatar
                    {..._props}
                    containerStyle={chatStyles.avatarContainer}
                    imageStyle={chatStyles.avatarImage}
                    renderAvatar={() => (
                        <Flag
                            country={_props.currentMessage.user.language.split('_')[1]}
                        />
                    )}
                />
            );
        },
        [
            chatStyles,
        ],
    );

    const renderBubble = useCallback(
        _props => {

            const onPress = (context, message) => {
                Console.devLog(`${NAME}.renderBubble.onPress`, { context, message });
            };

            const onLongPress = (context, message) => {

                if (!message?.talk?.length) {
                    Console.devLog(`${NAME}.renderBubble.onLongPress no message.talk`, { message });
                    return;
                }

                const talkParms = message.talk.split(SEPERATOR);

                const _lang = talkParms[0];
                const _rate = talkParms[1];
                const _text = talkParms[2];
                const _inVoice = _lang === bot.language ? botVoice : modeVoice;

                (async () => speak(TTS_VARIANT.SPEECHIFY, Strings.RemoveEmojis(_text), _lang, GENDER.FEMALE))();
                const _outVoice = null;

                /*
                const _outVoice = Speech.Talk(isIOS, Strings.RemoveEmojis(_text), _lang, _inVoice, bot.gender, _rate);
                */

                Console.devLog(`${NAME}.renderBubble.onLongPress`, { isIOS, context, message, language, botVoice, modeVoice, botLanguage: bot.language, botGender: bot.gender, _lang, _rate, _inVoice, _outVoice });

                if (!_inVoice) {
                    if (translate) {
                        setModeVoiceRef.current(v => v ? v : _outVoice);
                    } else {
                        setBotVoiceRef.current(v => v ? v : _outVoice);
                    }
                }
            };

            Console.log(`${NAME}.renderBubble`, { _props, botLanguage: bot.language, botGender: bot.gender, translate, chatStyles, styleChanged, leftColor: chatStyles.bubbleContent.left.backgroundColor, rightColor: chatStyles.bubbleContent.right.backgroundColor });

            return (
                <Bubble
                    {..._props}
                    contentStyle={chatStyles.bubbleContent}
                    wrapperStyle={chatStyles.bubbleWrapper}
                    textStyle={chatStyles.bubbleText}
                    containerToNextStyle={chatStyles.containerToNext}
                    containerToPreviousStyle={chatStyles.containerToPrevious}
                    tickStyle={chatStyles.tick}
                    onPress={(_context, _message) => onPress(_context, _message)}
                    onLongPress={(_context, _message) => onLongPress(_context, _message)}
                />
            );
        },
        [
            isIOS,
            bot.gender,
            bot.language,
            botVoice,
            modeVoice,
            setBotVoiceRef,
            setModeVoiceRef,
            language,
            translate,
            chatStyles,
            styleChanged,
            speak,
        ],
    );

    const renderMessage = useCallback(
        _props => {
            Console.log(`${NAME}.renderMessage`, {
                _props,
                messageContainer: chatStyles.messageContainer,
                styleChanged,
            });
            return (
                <Message
                    {..._props}
                    containerStyle={chatStyles.messageContainer}
                />
            );
        },
        [
            chatStyles,
            styleChanged,
        ],
    );

    const renderMessageText = useCallback(
        _props => {
            Console.log(`${NAME}.renderMessageText`, {
                styleChanged,
                currentMessage: _props?.currentMessage,
                messageTextContainer: chatStyles.messageText,
            });
            return (
                <MessageText
                    {..._props}
                    containerStyle={chatStyles.messageTextContainer}
                    textStyle={chatStyles.messageText}
                    textProps={{ selectable: false }}
                />
            );
        },
        [
            chatStyles,
            styleChanged,
        ],
    );

    const renderDay = useCallback(
        _props => {
            Console.log(`${NAME}.renderDay`, { _props, chatStyles });
            return (
                <Day
                    {..._props}
                    containerStyle={chatStyles.dayContainer}
                    textStyle={chatStyles.dayText}
                    textProps={{ selectable: false }}
                />
            );
        },
        [
            chatStyles,
        ],
    );

    const renderInputToolbar = useCallback(
        _props => {
            Console.log(`${NAME}.renderInputToolbar`, {
                _props,
                inputToolbarContainer: chatStyles.inputToolbarContainer,
                inputToolbarPrimary: chatStyles.inputToolbarPrimary,
                inputToolbarAccessory: chatStyles.inputToolbarAccessory,
            });
            return (
                <InputToolbar
                    {..._props}
                    containerStyle={chatStyles.inputToolbarContainer}
                    primaryStyle={chatStyles.inputToolbarPrimary}
                    accessoryStyle={chatStyles.inputToolbarAccessory}
                />
            );
        },
        [
            chatStyles,
        ],
    );

    const renderComposer = useCallback(
        _props => {
            Console.log(`${NAME}.renderComposer`, {
                _props,
                isWeb,
                textInput: chatStyles.textInput,
            });

            // composerHeight={chatStyles.minComposerHeight}
            return (
                <Composer
                    {..._props}
                    multiline={true}
                    textInputStyle={chatStyles.textInput}
                    textInputProps={{
                        ..._props.textInputProps,
                        blurOnSubmit: isWeb,
                        onSubmitEditing: !isWeb ? undefined : () => {
                            if (_props.text && _props.onSend) {
                                Console.log(`${NAME}.renderComposer onSubmitEditing`, { _props });
                                _props.onSend(_props.text.trim());
                            }
                        },
                    }}
                />
            );
        },
        [
            isWeb,
            chatStyles,
        ],
    );

    const renderActions = useCallback(
        _props => {
            Console.log(`${NAME}.renderActions`, {
                //_props,
                translate,
                //actionsContainer: chatStyles.actionsContainer,
                //actionsText: chatStyles.actionsText,
            });

            return (
                <Actions
                    {..._props}
                    containerStyle={chatStyles.actionsContainer}
                    textStyle={chatStyles.actionsText}
                    icon={() => (
                        <View
                            style={styles.row}
                        >
                            <Icon
                                icon={Icons.Translate}
                                color={TRANSLATE_COLOR[translate]}
                                onPress={() => setTranslateRef.current(v => (v + 1) % TRANSLATE_COLOR.length)}
                            />
                        </View>
                    )}
                />
            );
        },
        [
            chatStyles,
            translate,
            setTranslateRef,
        ],
    );

    const renderSend = useCallback(
        _props => {
            Console.log(`${NAME}.renderSend`, {
                //_props,
                customText,
                nextMessage,
                sendContainer: chatStyles.sendContainer,
                sendText: chatStyles.sendText,
            });
            const rowStyle = {
                flexDirection: 'row',
                width: 70,
                borderColor: 'black',
                borderWidth: 0,
                alignItems: 'center',
                justifyContent: 'space-between',
            };
            const flexEndStyle = {justifyContent: 'flex-end'};
            return (
                <Send
                    {..._props}
                    containerStyle={chatStyles.sendContainer}
                    textStyle={chatStyles.sendText}
                >

                    {Optional(customText?.length, (
                        <View
                            value={'ChatSend'}
                            style={rowStyle}
                        >
                            <Icon
                                icon={Icons.Delete}
                                onPress={() => setCustomTextRef.current(EMPTY_STRING)}
                            />
                            <Icon
                                icon={Icons.Send}
                            />
                        </View>

                    ), Optional(nextMessage?.length, (
                        <View
                            value={'ChatSend'}
                            style={rowStyle}
                        >
                            <Icon
                                icon={Icons.SkipNext}
                                onPress={() => setCustomTextRef.current(nextMessage.split(SEPERATOR)[0])}
                            />
                            {Optional(isWeb, (
                                <Microphone
                                    languageOverride={bot.language}
                                    setTranscriptionRef={setTranscriptionRef}
                                />
                            ), (
                                <Dictation
                                    keepAlive={true}
                                    languageOverride={bot.language}
                                    onTranscribing={setTranscribingRef.current}
                                    onTranscript={setUtteranceRef.current}
                                    onUtterance={setTranscriptionRef.current}
                                />
                            ))}
                        </View>
                    ), (
                        <View
                            value={'ChatSend'}
                            style={[rowStyle, flexEndStyle]}
                        >
                            {Optional(isWeb, (
                                <Microphone
                                    languageOverride={bot.language}
                                    setTranscriptionRef={setTranscriptionRef}
                                />
                            ), (
                                <Dictation
                                    keepAlive={true}
                                    languageOverride={bot.language}
                                    onTranscribing={setTranscribingRef.current}
                                    onTranscript={setUtteranceRef.current}
                                    onUtterance={setTranscriptionRef.current}
                                />
                            ))}
                        </View>
                    )))}
                </Send>
            );
        },
        [
            isWeb,
            bot.language,
            customText,
            nextMessage,
            setCustomTextRef,
            setTranscribingRef,
            setTranscriptionRef,
            setUtteranceRef,
            chatStyles,
        ],
    );

    Console.stack(NAME, props, { audio, isWeb, isIOS, phone, image, deviceScale, user, bot, typing, messages/*: messages?.length*/, dark, chatMessages/*: chatMessages?.length, chatStyles,*/, translate, adjHeight, showAds });

    return useMemo(
        () => {
            const adsHeight = { height: showAds ? adjHeight - BANNER_AD_HEIGHT : adjHeight };
            const currencyImages = Images.money[bot.language.split('_')[1]];
            const currencyImageKeys = Object.keys(currencyImages);
            var moneyImages = currencyImageKeys
                .filter(key => key.indexOf(dark ? 'r_' : 'o_') === 0)
                .map(key => currencyImages[key]);
            if (!moneyImages?.length && dark) {
                moneyImages = currencyImageKeys
                    .filter(key => key.indexOf('o_') === 0)
                    .map(key => currencyImages[key]);
            }

            Console.log(`${NAME} render`, { chatMessages, dark, styleChanged, bot, typing, adjHeight, showAds });

            /*
                        <ImageBackground
                            style={styles.full}
                            imageStyle={styles.image}
                            source={Numbers.randomItem(moneyImages)}
                        >
            */
            return (
                <Screen
                    {...props}
                    style={styles.full}
                    value={NAME}
                >
                    <View
                        style={[styles.full, adsHeight]}
                    >
                            <GiftedChat
                                messageIdGenerator={() => `${messages ? messages.length : 0}`}
                                locale={LOCALE_LOOKUP.get(bot.language)}
                                text={customText?.length ? customText : utterance}
                                placeholder={messages?.length ? t(SEND_MESSAGE) : t(HELLO)}
                                user={{
                                    _id: user._id,
                                    name: user.username,
                                    avatar: null,
                                    language,
                                }}
                                isTyping={typing}
                                messages={chatMessages}
                                renderAccessory={null}
                                renderActions={renderActions}
                                renderAvatar={renderAvatar}
                                renderBubble={renderBubble}
                                renderChatFooter={null}
                                renderComposer={renderComposer}
                                renderCustomView={null}
                                renderDay={renderDay}
                                renderFooter={null}
                                renderInputToolbar={renderInputToolbar}
                                renderMessage={renderMessage}
                                renderMessageAudio={null}
                                renderMessageImage={null}
                                renderMessageText={renderMessageText}
                                renderMessageVideo={null}
                                renderSend={renderSend}
                                renderTime={() => null}
                                scrollToBottom
                                scrollToBottomComponent={() => (<Icon icon={Icons.ScrollToBottom} />)}
                                onInputTextChanged={onInputTextChanged}
                                onSend={_msgs => _msgs?.length ? onSend(_msgs[0].text) : {}}
                                alwaysShowSend={true}
                                showUserAvatar={true}
                                shouldUpdateMessage={(_props, nextProps) => _props.extraData !== nextProps.extraData}
                                minInputToolbarHeight={chatStyles.minInputToolbarHeight}
                                minComposerHeight={chatStyles.minComposerHeight}
                                maxComposerHeight={chatStyles.maxComposerHeight}
                                maxInputLength={MAX_CONTENT_LEN}
                                onPressAvator={u => Console.LOG('Press Avatar', { u })}
                                onLongPressAvator={u => Console.LOG('Long Press Avatar', { u })}
                            />
                    </View>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true, adSearch)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            t,
            language,
            props,
            chatStyles,
            styleChanged,
            dark,
            user,
            bot,
            chatMessages,
            customText,
            utterance,
            typing,
            messages,
            onInputTextChanged,
            renderActions,
            renderAvatar,
            renderBubble,
            renderComposer,
            renderInputToolbar,
            renderMessage,
            renderMessageText,
            renderSend,
            renderDay,
            onSend,
            showAds,
            adjHeight,
            adSearch,
        ],
    );
};

const devStyle = {
    borderWidth: USE_DEV_COLOR ? 1 : 0,
    borderColor: Colors.colors.black,
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
        //backgroundColor: Colors.colors.red,
    },
    full: {
        width: '100%',
        height: '100%',
    },
    image: {
        marginBottom: '70%',
        height: '30%',
        resizeMode: 'contain',
        opacity: 0.3,
        //      transform: [{ rotate: '90deg' }],
    },
    avatarContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lime),
    },
    avatarImage: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.magenta),
    },
    dayContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lime),
    },
    dayText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.magenta),
    },
    bubbleContent: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.purple),
    },
    bubbleWrapper: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lightblue),
    },
    bubbleText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.yellow),
    },
    containerToNext: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.green),
    },
    containerToPrevious: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.red),
    },
    bubbleTick: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.black),
    },
    messageContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.green),
        alignItems: 'center',
        justifyContent: 'center',
    },
    messageTextContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.red),
    },
    messageText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lightgreen),
    },
    inputToolbarContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.cyan),
    },
    inputToolbarAccessory: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.magenta),
    },
    inputToolbarPrimary: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.orange),
        alignItems: 'center',
        justifyContent: 'center',
    },
    actionsContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.blue),
        alignItems: 'center',
        justifyContent: 'center',
        //width: 30,
    },
    actionsText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lightblue),
    },
    sendContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.blue),
        alignItems: 'center',
        justifyContent: 'center',
    },
    sendText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lightblue),
    },
    row: {
        flexDirection: 'row',
    },
});
