import { S3_SERVER } from '../constants';
import { Console } from '../utils';

const NAME = 'Images';

// https://www.peko-step.com/en/tool/alphachannel_en.html MARKMARK: TRANSPARENCY PNG


var images = {
    flag: {
        WIDTH: 39,
        HEIGHT: 30,
    },
    money: {},
    sign: {},
    mrph: {},
    miscl: {},
    logo: {},
};

[
    'br',
    'cn',
    'de',
    'es',
    'fr',
    'gb',
    'hi',
    'in',
    'it',
    'jp',
    'kr',
    'mx',
    'pt',
    'ro',
    'ru',
    'tw',
    'us',
    'sa',
].forEach(region => {
    images.flag[region] = { uri: `${S3_SERVER}/flags/${region}.png` };
});

[
    'de_5_o',
    'de_5_r',
    'de_10_o',
    'de_10_r',
    'de_20_o',
    'de_20_r',
    'de_50_o',
    'de_50_r',
    'de_100_o',
    'de_100_r',
    'de_200_o',
    'de_200_r',
    'de_500_o',
    'de_500_r',
    'de_1000_o',
    'de_1000_r',
    'us_1_o',
    'us_1_r',
    'us_2_o',
    'us_2_r',
    'us_5_o',
    'us_5_r',
    'us_10_o',
    'us_10_r',
    'us_20_o',
    'us_20_r',
    'us_50_o',
    'us_50_r',
    'us_100_o',
    'us_100_r',
    'gb_5_o',
    'gb_5_r',
    'gb_10_o',
    'gb_10_r',
    'gb_20_o',
    'gb_20_r',
    'gb_50_o',
    'gb_50_r',
    'hi_10_o',
    'hi_20_o',
    'hi_50_o',
    'hi_100_o',
    'hi_500_o',
    'es_1000_o',
    'es_5000_o',
    'es_10000_o',
    'mx_20_o',
    'mx_50_o',
    'mx_100_o',
    'mx_200_o',
    'mx_500_o',
    'mx_1000_o',
    'fr_50_o',
    'fr_50_r',
    'fr_100_o',
    'fr_100_r',
    'fr_200_o',
    'fr_200_r',
    'fr_500_o',
    'fr_500_r',
    'in_1_o',
    'in_1_r',
    'in_5_o',
    'in_5_r',
    'in_10_o',
    'in_10_r',
    'in_20_o',
    'in_20_r',
    'in_50_o',
    'in_50_r',
    'in_100_o',
    'in_100_r',
    'in_200_o',
    'in_200_r',
    'in_500_o',
    'in_500_r',
    'in_2000_o',
    'in_2000_r',
    'it_1000_o',
    'it_1000_r',
    'it_2000_o',
    'it_2000_r',
    'it_5000_o',
    'it_5000_r',
    'it_10000_o',
    'it_10000_r',
    'it_50000_o',
    'it_50000_r',
    'it_100000_o',
    'it_100000_r',
    'it_500000_o',
    'it_500000_r',
    'jp_1000_o',
    'jp_1000_r',
    'jp_2000_o',
    'jp_2000_r',
    'jp_5000_o',
    'jp_5000_r',
    'jp_10000_o',
    'jp_10000_r',
    'kr_1000_o',
    'kr_5000_o',
    'kr_10000_o',
    'kr_50000_o',
    'br_2_o',
    'br_2_r',
    'br_5_o',
    'br_5_r',
    'br_10_o',
    'br_10_r',
    'br_20_o',
    'br_20_r',
    'br_50_o',
    'br_50_r',
    'br_100_o',
    'br_100_r',
    'br_200_o',
    'br_200_r',
    'pt_500_o',
    'pt_500_r',
    'pt_1000_o',
    'pt_1000_r',
    'pt_2000_o',
    'pt_2000_r',
    'pt_5000_o',
    'pt_5000_r',
    'pt_10000_o',
    'pt_10000_r',
    'ro_1_o',
    'ro_1_r',
    'ro_5_o',
    'ro_5_r',
    'ro_10_o',
    'ro_10_r',
    'ro_50_o',
    'ro_50_r',
    'ro_100_o',
    'ro_100_r',
    'ro_200_o',
    'ro_200_r',
    'ro_500_o',
    'ro_500_r',
    'ru_5_o',
    'ru_5_r',
    'ru_10_o',
    'ru_10_r',
    'ru_50_o',
    'ru_50_r',
    'ru_100_o',
    'ru_100_r',
    'ru_500_o',
    'ru_500_r',
    'ru_1000_o',
    'ru_1000_r',
    'ru_5000_o',
    'ru_5000_r',
    'cn_1_o',
    'cn_5_o',
    'cn_10_o',
    'cn_20_o',
    'cn_50_o',
    'cn_100_o',
    'tw_100_o',
    'tw_200_o',
    'tw_500_o',
    'tw_1000_o',
    'tw_2000_o',
    'sa_5_o',
    'sa_5_r',
    'sa_10_o',
    'sa_10_r',
    'sa_50_o',
    'sa_50_r',
    'sa_100_o',
    'sa_100_r',
    'sa_200_o',
    'sa_200_r',
    'sa_500_o',
    'sa_500_r',
].forEach(note => {
    const t = note.split('_');
    const region = t[0];
    const denomination = t[1];
    const side = t[2];
    if (!images.money[region]) {
        images.money[region] = {};
    }
    images.money[region][`${side}_${denomination}`] = { uri: `${S3_SERVER}/money/${note}.jpg` };
});

Object.keys(images.money).forEach(region => {
    const notes = Object.keys(images.money[region]);
    var index = -1;
    var max = -1;
    notes.forEach((note, i) => {
        const t = note.split('_');
        const side = t[0];
        const denomination = +t[1];
        if (side === 'o' && denomination > max) {
            max = denomination;
            index = i;
        }
    });
    images.money[region].note = images.money[region][notes[index]];
});

// override gb's note to get the reverse, Alan Turning!
images.money.gb.note = images.money.gb.r_50;

[
    '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
    'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
    'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
].forEach(sign => {
    images.sign[`sign_${sign}`] = { uri: `${S3_SERVER}/asl/${sign}.svg` };
});

[
    'hat',
    'head',
    'lear',
    'leye',
    'mouth',
    'mustache',
    'nose',
    'rear',
    'reye',
].forEach(part => {
    images.mrph[part] = { uri: `${S3_SERVER}/mrph/mrph${part}.png` };
});

[
    'acquilingua_title_header',
    'da_app_hawaii_title_header',
].forEach(title => {
    images.miscl[title] = { uri: `${S3_SERVER}/private/${title}.png` };
});

[
    'acquilingua',
    'da_app_hawaii',
].forEach(title => {
    // MARKMARK: S3 Images 'https://buw4bn01ac.execute-api.us-west-1.amazonaws.com/prod/acquilingua/data/images/logo_acquilingua.png'
    images.logo[title] = { uri: `${S3_SERVER}/private/logo_${title}.png` };
});


export const Images = images;
Console.log(`${NAME} Images`, Images);
