export const DEEPGRAM_API_KEY = '66749ad805df6f929f38a5453a4ebd468dbabdee';
export const DEEPGRAM_TTS =
  'https://api.deepgram.com/v1/speak?encoding=linear16&container=wav&model=';
export const DEEPGRAM_STT = 'wss://api.deepgram.com/v1/listen';

export const SPEECHIFY_API_KEY = 'rBjaolLEiumWQsQaoRidkMC6LQbH7LytxLN2u7cZn_I=';
export const SPEECHIFY_TTS = 'https://api.sws.speechify.com/v1/audio/speech';

export const UNREAL_API_KEY =
  '4cB3hJKGpjTVnGr0AkJPV7BNFoafq2VWCWH9na8lKrby0nsGU01vIo';
export const UNREAL_TTS = 'https://api.v6.unrealspeech.com/speech';

export const CLOSE_STREAM = JSON.stringify({type: 'CloseStream'});
export const KEEP_ALIVE = JSON.stringify({type: 'KeepAlive'});

export const TEN_SECONDS = 10000;

export const LiveConnectionState = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export const LiveTranscriptionEvents = {
  Open: 'open',
  Close: 'close',
  Transcript: 'Results',
  Metadata: 'Metadata',
  Error: 'error',
  Warning: 'warning',
  UtteranceEnd: 'UtteranceEnd',
  SpeechStarted: 'SpeechStarted',
};
