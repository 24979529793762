import {Console} from '../../utils';
import {
  DEEPGRAM_API_KEY,
  DEEPGRAM_STT,
  CLOSE_STREAM,
  KEEP_ALIVE,
  LiveConnectionState,
  LiveTranscriptionEvents,
  TEN_SECONDS,
} from './constants';

const NAME = 'STT';

const MODEL = 'nova-2';
// https://deepgram.com/pricing
// $0.0059/min: pay-as-you-go
// $0.0049/min: growth
const MODEL_OPTION = lang =>
  lang === 'en' || lang === 'en-US' ? '-conversationalai' : '-general';

const DEFAULT_LANGUAGE = 'en-US';
const CHANNELS = 1;
const SAMPLE_RATE = 16000;
const ENCODING = 'linear16';
const SMART_FORMAT = true;

// https://developers.deepgram.com/reference/listen-live
const OPTIONS = {
  // model, language
  model: MODEL,
  language: DEFAULT_LANGUAGE,
  // audio
  channels: CHANNELS,
  sample_rate: SAMPLE_RATE,
  encoding: ENCODING,
  // streaming
  interim_results: true,
  vad_events: true, // speech detection
  utterance_end_ms: 1250, // utterance end requires interim_results, sb >= 1000
  endpointing: false, //250, // default 50
  // formatting
  diarize: false,
  filler_words: false,
  // keywords: 'Acquilingua', MARKMARK
  multichannel: CHANNELS > 1 ? true : false,
  numerals: false,
  profanity_filter: true,
  punctuate: true,
  smart_format: SMART_FORMAT,
  /* unused
  callback,
  callback_method: false,
  diarize_version,
  extra,
  redact,
  replace,
  search,
  tag,
  version,
  */
};

// https://developers.deepgram.com/docs/lower-level-websockets
// streaming audio should be between 20-250ms

export class STT {
  constructor(
    language,
    options,
    onConnect,
    onDisconnect,
    onSpeechEnded,
    onTranscript,
    key = DEEPGRAM_API_KEY,
  ) {
    let searchOptions = {...OPTIONS, ...options};
    searchOptions.language = STT.getNovaLanguage(language);
    searchOptions.model =
      searchOptions.model + MODEL_OPTION(searchOptions.language);

    const searchParams = new URLSearchParams(searchOptions);

    this.key = key;
    this.socket = null;
    this.text = '';
    this.timerId = null;
    this.transcript = '';

    this.url = `${DEEPGRAM_STT}?${searchParams.toString()}`;

    this.onConnect = onConnect;
    this.onDisconnect = onDisconnect;
    this.onSpeechEnded = onSpeechEnded;
    this.onTranscript = onTranscript;

    Console.devLog(`${NAME}.ctor`, {url: this.url});
  }

  // MARKMARK TODO: use whisper as alternative model, especially for unsupported nova languages (ar, haw)
  // https://developers.deepgram.com/docs/deepgram-whisper-cloud#supported-languages
  static getNovaLanguage(langCode) {
    // https://developers.deepgram.com/docs/models-languages-overview
    const LangMap = new Map([
      ['en_us', 'en-US'],
      ['en_gb', 'en-GB'],
      ['es_es', 'es'],
      ['es_mx', 'es-419'],
      ['pt_pt', 'pt'],
      ['pt_br', 'pt-BR'],
      ['fr_fr', 'fr'],
      ['it_it', 'it'],
      ['ro_ro', 'ro'],
      ['de_de', 'de'],
      ['ru_ru', 'ru'],
      ['ja_jp', 'ja'],
      ['ko_kr', 'ko-KR'],
      ['zh_cn', 'zn-CN'],
      ['zh_tw', 'zn-TW'],
      ['hi_in', 'hi'],
      ['ar_sa', DEFAULT_LANGUAGE],
      ['haw_hi', DEFAULT_LANGUAGE],
    ]);
    return LangMap.get(langCode);
  }

  start(keepAlive = true) {
    const keepAliveMs = keepAlive ? TEN_SECONDS : 0;
    Console.log(`${NAME}.start`, {keepAlive, keepAliveMs});

    // initialize web socket
    this.socket = new WebSocket(this.url, null, {
      headers: {
        Authorization: `Token ${this.key}`,
      },
    });

    // set up event listener threads
    this.socket.onopen = () => {
      Console.log(`${NAME} socket.onopen`);
      this.onConnect && this.onConnect();

      this.socket.onclose = event => {
        const reason =
          event?.code === 1008
            ? ' [audio decoding error]'
            : event?.code === 1011
            ? ' [timeout]'
            : '';
        Console.log(`${NAME} socket.onclose${reason}`, {event});
        if (this.timerId) {
          Console.log(`${NAME} socket.onclose stop keep alive`, {
            timerId: this.timerId,
          });
          clearInterval(this.timerId);
          this.timerId = null;
        }
        this.onDisconnect && this.onDisconnect();
      };

      this.socket.onerror = event => {
        Console.warn(`${NAME} socket.onerror`, {event});
      };

      this.socket.onmessage = event => {
        if (!this.onTranscript) {
          Console.warn(`${NAME} socket.onmessage invalid onTranscript`, {
            event,
          });
          return;
        }
        if (!event?.data) {
          Console.warn(`${NAME} socket.onmessage invalid event`, {event});
          return;
        }

        let data = null;
        try {
          data = JSON.parse(event.data.toString());
        } catch (error) {
          Console.warn(`${NAME} socket.onmessage invalid data`, {event, error});
          return;
        }

        Console.log(
          `${NAME} socket.onmessage ${data.type} socket.onmessage event`,
        );

        switch (data?.type) {
          case LiveTranscriptionEvents.Transcript:
            const {
              is_final,
              channel: {alternatives},
            } = data;
            const utterance = alternatives?.length
              ? alternatives[0].transcript
              : '';
            this.transcript = this.text + ' ' + utterance;
            this.onTranscript(this.transcript);
            if (is_final) {
              this.text = this.transcript;
            }
            Console.log(`${NAME} socket.onmessage ${data.type} data`, {
              is_final,
              text: this.text,
              transcript: this.transcript,
              utterance,
            });
            break;
          case LiveTranscriptionEvents.UtteranceEnd:
            Console.log(`${NAME} socket.onmessage ${data.type} data`, {
              transcript: this.transcript,
            });
            this.onSpeechEnded && this.onSpeechEnded(this.transcript);
            break;
          case LiveTranscriptionEvents.Open:
          case LiveTranscriptionEvents.Close:
          case LiveTranscriptionEvents.SpeechStarted:
          case LiveTranscriptionEvents.Metadata:
          case LiveTranscriptionEvents.Error:
          case LiveTranscriptionEvents.Warning:
            // handled with above logging
            break;
          default:
            Console.warn(
              `${NAME} socket.onmessage unexpected event [${data?.type}]`,
              {data},
            );
            break;
        }
      };
    };

    if (keepAliveMs > 0 && !this.timerId) {
      Console.log(`${NAME}.start keep alive`, {keepAliveMs});
      this.timerId = setInterval(() => {
        this.send(KEEP_ALIVE);
      }, keepAliveMs);
    }
  }

  stop() {
    Console.log(`${NAME}.stop`);
    if (this.timerId) {
      Console.log(`${NAME}.stop stop keep alive`, {
        timerId: this.timerId,
      });
      clearInterval(this.timerId);
      this.timerId = null;
    }
    this.send(CLOSE_STREAM);
  }

  send(data) {
    if (this?.socket?.readyState !== LiveConnectionState.OPEN) {
      Console.warn(
        `${NAME}.send: not connected, readyState=[${this?.socket?.readyState}]`,
      );
      return;
    }

    Console.trace(
      `${NAME}.send [${typeof data}][${
        data?.length < 100 && typeof data === 'string' ? data : data?.length
      }]`,
    );

    this.socket.send(data);
  }
}
