import React, { createContext, useContext, useReducer } from 'react';
import { Array2Object, Console, Token } from '../utils';

const types = [
  'SET_USER',
  'UNSET_USER',
  'SET_USERNAME',
];
//console.log('MARKMARK before', { Array2Object, types });
export const TYPES = Array2Object(types);
//console.log('MARKMARK after', { Array2Object, types, TYPES });

export const updateUser = (user, _id = 0) => {
  return {
    ...user,
    _id,
    lastMessage: null,
  };
};

const UserStateContext = createContext();
const UserDispatchContext = createContext();

const userState = {
  user: null,
  username: null,
};

const userReducer = (state, action) => {
  const { type, payload } = action;
  var result;

  switch (type) {

    case TYPES.SET_USER:
      Token.set(payload.token);
      result = {
        ...state,
        user: updateUser(payload),
      };
      break;

    case TYPES.UNSET_USER:
      Token.del();
      result = {
        ...state,
        user: null,
        username: null,
      };
      break;

    case TYPES.SET_USERNAME:
      result = {
        ...state,
        username: payload,
      };
      break;

    default:
      throw new Error(`userReducer: Unhandled action "${type}".`);
  }

  Console.LOG(`userReducer[${type}] (${JSON.stringify(payload)})`);
  Console.trace('userReducer', { state, result });

  return result;
};

const NAME = 'UserProvider';


export const UserProvider = props => {

  const {
    children,
  } = props;

  const [state, dispatch] = useReducer(userReducer, userState);

  Console.stack(NAME, props, { state });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

export const useUserState = () => useContext(UserStateContext);
export const useUserDispatch = () => useContext(UserDispatchContext);
