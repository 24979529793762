import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Screen, View } from '../components';
import { /*FingersGame,*/ MrPotatoHeadGame, CrossWordPuzzle } from '../applications';
import { useAppState, useSystemState } from '../context';
import { Console, Optional } from '../utils';
import { GoogleAds, /*BANNER_AD_WIDTH,*/ BANNER_AD_HEIGHT } from '../ads';

const NAME = 'Games';

const GAMES = [
  CrossWordPuzzle,
  MrPotatoHeadGame,
  //  FingersGame,
];


export const Games = props => {

  const { width, /*headerHeight,*/ adjHeight } = useSystemState();
  const { game, pause, tScale, gScale, gMagnitude, showAds } = useAppState();

  Console.stack(NAME, props, { width, /*headerHeight,*/ adjHeight, game, pause, tScale, gScale, gMagnitude, showAds });

  return useMemo(
    () => {
      const adsHeight = { height: showAds ? adjHeight - BANNER_AD_HEIGHT : adjHeight };
      const Game = GAMES[game % GAMES.length];
      Console.devLog(`${NAME} render`, { width, adjHeight, adsHeight, game, pause, tScale, gScale, gMagnitude, showAds });
      return (
        <Screen
          {...props}
          value={NAME}
        >
          <View
            style={[styles.view, adsHeight]}
          >
            <Game
              top={0}
              left={0}
              width={width}
              height={adsHeight.height}
              running={!pause}
              tScale={tScale}
              gScale={gScale}
              gMagnitude={gMagnitude}
              gx={0}
              gy={1}
            />
          </View>
          {Optional(showAds, (
              <View
                  style={styles.ads}
              >
                  {GoogleAds.Get().show(true)}
              </View>
          ))}
        </Screen>
      );
    },
    [
      props,
      width,
      adjHeight,
      game,
      pause,
      tScale,
      gScale,
      gMagnitude,
      showAds,
    ],
  );
};

const styles = StyleSheet.create({

  view: {
    width: '100%',
    height: '100%',
  },
});
