import { Chat, Games, /*LookCamera,*/ Speech, Users } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Home,

    TabArray: ['Users', 'Games', 'Speech'/*, 'Camera'*/],

    Tabs: {
        Users: {
            title: 'Chat',
            Component: Users,
            Icon: Icons.Users,
            children: new Set(['Chat']),
        },
        Games: {
            Component: Games,
            Icon: Icons.Games,
        },
        Speech: {
            Component: Speech,
            Icon: Icons.Speech,
        },
        //Camera: {
        //    Component: LookCamera,
        //    Icon: Icons.Camera,
        //},
    },

    Stacks: {
        Chat: {
            Component: Chat,
        },
    },
};
