import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useAppState} from '../context';
import {Icons} from '../media';
import {Console} from '../utils';
import {useSTT} from '../voice';
import {Pressable} from './Pressable';

const NAME = 'Dictation';

export const Dictation = props => {
  const {auto, disabled, keepAlive, languageOverride, onStop, onTranscribing, onTranscript, onUtterance, style} = props;

  const {language} = useAppState();

  const [once, setOnce] = useState(false);
  const setOnceRef = useRef(setOnce);

  const {
    startTranscription,
    stopTranscription,
    transcribing,
    transcript,
    utterance,
  } = useSTT(keepAlive);

  useEffect(() => {
    if (once) {
      return;
    }
    if (!auto) {
      return;
    }
    const lang = languageOverride?.length ? languageOverride : language;
    Console.devLog(`${NAME} useEffect: auto`, {lang, auto, once});
    startTranscription(lang);
    setOnceRef.current(true);
  }, [auto, language, languageOverride, once, setOnceRef, startTranscription]);

  // transcribing
  useEffect(() => {
    Console.devLog(`${NAME} useEffect: transcribing`, {transcribing});
    onTranscribing && onTranscribing(transcribing);
  }, [onTranscribing, transcribing]);

  // transcript
  useEffect(() => {
    Console.devLog(`${NAME} useEffect: transcript`, {transcript});
    if (onTranscript) {
      if (onTranscript(transcript) === 'match') {
        stopTranscription(transcript);
      }
    }
  }, [onTranscript, stopTranscription, transcript]);

  // utterance
  useEffect(() => {
    Console.devLog(`${NAME} useEffect: utterance`, {utterance});
    if (onUtterance) {
      if (onUtterance(utterance) === 'match') {
        stopTranscription(utterance);
      }
    }
  }, [onUtterance, stopTranscription, utterance]);

  return useMemo(() => {
    const lang = languageOverride?.length ? languageOverride : language;
    Console.devLog(`${NAME} render`, {lang, transcribing});
    return (
      <Pressable
          style={style}
          icon={Icons.Microphone}
          onPress={() => {
            Console.devLog(transcribing ? 'MIC OFF' : 'MIC ON');
            if (transcribing) {
              stopTranscription();
              onStop && onStop();
            } else {
              startTranscription(lang);
            }
          }}
          iconColor={transcribing ? 'red' : 'green'}
          disabled={disabled}
      />
    );
  }, [
    disabled,
    language,
    languageOverride,
    onStop,
    startTranscription,
    stopTranscription,
    style,
    transcribing,
  ]);
};
