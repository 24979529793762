import React from 'react';
import { AppProvider } from './AppProvider';
import { MessagesProvider } from './MessagesProvider';
import { OahuProvider } from './OahuProvider';
import { SystemProvider } from './SystemProvider';
import { UserProvider } from './UserProvider';
import { UsersProvider } from './UsersProvider';
import { Console } from '../utils';

const NAME = 'Providers';


export const Providers = props => {

  const {
    children,
  } = props;

  Console.stack(NAME, props);

  return (
    <SystemProvider>
      <AppProvider>
        <UserProvider>
            <OahuProvider>
              <UsersProvider>
                <MessagesProvider>
                  {children}
                </MessagesProvider>
              </UsersProvider>
            </OahuProvider>
        </UserProvider>
      </AppProvider>
    </SystemProvider>
  );
};
