import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Chart, Button, Screen, Text, View } from '../components';
import { useSystemState } from '../context';
import { Console } from '../utils';

const NAME = 'Charts';

const SHORT_CIRCUIT = true;

const data = [10, 7, 5, 6, 9, 15, 21, 23, 23, 22, 17, 13, 11, 10];


export const Charts = props => {

    const { isWeb, width, adjHeight } = useSystemState();
    const [type, setType] = useState('line');

    const sizeStyle = { width: 0.9 * width, height: 0.9 * 0.75 * adjHeight };

    var component = null;
    switch (type) {
        case 'bar': component = <Chart style={sizeStyle} data={data} duration={500} />; break;
        case 'line': component = <Chart style={sizeStyle} data={data} duration={500} />; break;
        case 'pie': component = <Chart style={sizeStyle} data={data} duration={500} />; break;
        case 'hbar': component = <Chart style={sizeStyle} data={data} duration={500} />; break;
        case 'area': component = <Chart style={sizeStyle} data={data} duration={500} />; break;
        case 'donut': component = <Chart style={sizeStyle} data={data} duration={500} />; break;
    }

    Console.stack(NAME, props, { component, isWeb, width, adjHeight, type });

    return SHORT_CIRCUIT ? <Screen {...props} /> :
    (
        <Screen
            {...props}
            value={NAME}
            headerHeight={'5%'}
            headerView={(
                <Text
                    value={'header'}
                />
            )}
            mainHeight={-1}
            mainView={component}
            footerHeight={'20%'}
            footerView={(
                <>
                    <View
                        value={'ChartsButtons1'}
                        style={styles.buttons}
                    >
                        <Button value={'Bar'} onPress={() => setType('bar')} />
                        <Button value={'Line'} onPress={() => setType('line')} />
                        <Button value={'Pie'} onPress={() => setType('pie')} />
                    </View>
                    <View
                        value={'ChartsButtons2'}
                        style={styles.buttons}
                    >
                        <Button value={'HBar'} onPress={() => setType('hbar')} />
                        <Button value={'Area'} onPress={() => setType('area')} />
                        <Button value={'Donut'} onPress={() => setType('donut')} />
                    </View>
                </>
            )}
        />
    );
};

const styles = StyleSheet.create({
    buttons: {
        width: '100%',
        flexDirection: 'row',
        alignSelf: 'auto',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
});
