import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Svg, { G, Circle, Line, Rect, Text, TSpan } from 'react-native-svg';
import * as d3 from 'd3-force';
import { IsSenseType, GetNodeEmoji } from './utils';
import { useAppState } from '../context';
import { Console, Optional, Validate } from '../utils';
import { Colors, Words } from '../styles';

const NAME = 'Network';

// MARKMARK: TODO: Try react-native-paper TextLayoutEvent to determine text box size ???

const ALPHA = 0.25;

const GRAVITY = 0;

const LINK_STRENGTH = 1.0;
const LINK_ITERATIONS = 10;
const LINK_DISTANCE = 2;
const LINK_ROOT_SCALE = 4;
const LINK_RELATION_SCALE = 0.5;

const CHARGE_STRENGTH = -100;
const CHARGE_MIN_DIST = 1;
const CHARGE_MAX_DIST = 10;

const COLLIDE_STRENGTH = 0.75;
const COLLIDE_RADIUS = 1.5;

let Simulation = null;

class PreviousValue {
    constructor() {
        this.current = null;
        this.previous = null;
    }

    set(value) {
        this.previous = this.current;
        this.current = value;
    }

    get() {
        return this.current;
    }

    prev() {
        return this.previous;
    }
}

var Nodes = new PreviousValue();


const RenderColor = (dark, color = Colors.colors.transparent) => {
    return color !== Colors.colors.transparent
        ? color
        : dark
            ? Colors.colors.white
            : Colors.colors.black;
};

export class NetworkUtils {

    static Node(props, nodeTextHeight) {

        const posProps = Words.Pos.get(props.pos);

        const labelProps = {
            ...posProps,
            fontSize: posProps._scale * nodeTextHeight,
            fill: posProps.color,
            stroke: posProps.color,
        };

        const emoji = GetNodeEmoji(props.value);

        return ({
            ...props,
            emoji,
            labelProps,
            vx: 0,
            vy: 0,
        });
    }

    static Edge(props) {

        const {
            type,
            sourceId,
            targetId,
        } = props;

        var lineProps = Words.Relation.get(type);
        if (!Validate.isValid(lineProps)) {
            Console.LOG(`${NAME}.NetworkUtils.Edge unknown relation`, { type }); // MARKMARK: remove this sentinel eventually
            lineProps = Words.BaseRelation;
        }

        return ({
            type,
            info: lineProps.info,
            source: sourceId,
            target: targetId,
            lineProps,
        });
    }

    static RenderNode(node, dark, background, nodeTextHeight) {

        var text = IsSenseType(node.value) ? null : node.value;
        const fill = RenderColor(dark, node.labelProps.fill);
        const stroke = RenderColor(dark, node.labelProps.stroke);

        const height2width = 2.45;
        const textHeightScale = 1.1;

        const textHeight = node.labelProps.fontSize * textHeightScale;
        const textLength = Validate.isValidNonEmptyString(text) ? text.length : 1;
        const textWidth = textLength * textHeight / height2width;

        const rectMargin = textHeight / 2;
        const verticalOffset = -(4 * textHeight);

        var rdims = { width: 0, height: 0 };
        var infoText = node && node?.selected && node.info ? node.info.split('|') : [];

        if (infoText.length) {
            if (infoText.length > 2) {
                if (infoText[1] === 'all') {
                    infoText.splice(1, 1);
                } else {
                    infoText.splice(0, 2, `${infoText[0]} [${infoText[1]}]`);
                }
            }
            const maxLength = infoText.reduce((max, str) => Math.max(max, str.length), 0);
            rdims.height = infoText.length * textHeightScale * textHeight;
            rdims.width = maxLength * textHeightScale * textHeight / height2width;
            Console.trace(`${NAME} selected`, { infoText, maxLength, rdims });
        }
        rdims.dx = -(rdims.width / 2);
        rdims.dy = -rdims.height;

        return (
            <G
                key={`node_${node.index}`}
            >
                {
                    Optional(infoText.length, (
                        <>
                            <Rect
                                key={`node_info_rect_${node.index}`}
                                x={node.x + rdims.dx - rectMargin}
                                y={node.y + rdims.dy + verticalOffset - rectMargin}
                                width={rdims.width + 2 * rectMargin}
                                height={rdims.height + 2 * rectMargin}
                                stroke={stroke}
                                strokeWidth={1}
                                fill={background}
                                opacity={1}
                            />
                            <Line
                                key={`node_info_line_${node.index}`}
                                color={stroke}
                                stroke={stroke}
                                x1={node.x}
                                y1={node.y + verticalOffset + rectMargin}
                                x2={node.x}
                                y2={node.y}
                            />
                            <Text
                                key={`node_info_rect_text_${node.index}`}
                                fontSize={nodeTextHeight}
                                textAnchor={'middle'}
                                stroke={stroke}
                                x={node.x}
                                y={node.y + rdims.dy + verticalOffset - nodeTextHeight / 2}
                            >
                                {
                                    infoText.map((txt, idx) => (
                                        <TSpan
                                            key={`node_info_rect_text_span_${idx}`}
                                            x={node.x}
                                            dy={textHeightScale * nodeTextHeight}
                                        >
                                            {txt}
                                        </TSpan>
                                    ))
                                }
                            </Text>
                        </>
                    ))
                }
                <Circle
                    key={`node_text_circle_${node.index}`}
                    cx={node.x}
                    cy={node.y + (Validate.isValidNonEmptyString(text) ? (textHeight / 4) : 0)}
                    r={textHeight / (Validate.isValidNonEmptyString(text) ? 2 : 1.5)}
                    fill={background}
                    opacity={0.67}
                />
                {
                    Optional(Validate.isValidNonEmptyString(text), (
                        <>
                            <Rect
                                key={`node_text_rect_${node.index}`}
                                x={node.x - (node.emoji ? 0 : textWidth / 2)}
                                y={node.y - (textHeight / 4)}
                                width={textWidth}
                                height={textHeight}
                                fill={background}
                                opacity={0.67}
                            />
                            <Text
                                {...node.labelProps}
                                fill={fill}
                                stroke={stroke}
                                key={`node_text_${node.index}`}
                                textAnchor={node.emoji ? 'start' : 'middle'}
                                x={node.x}
                                y={node.y + textHeight / 2}
                            >
                                {text}
                            </Text>
                        </>
                    ))
                }
                {
                    Optional(Validate.isValid(node.emoji), (
                        <>
                            <Text
                                key={`node_emoji_${node.index}`}
                                textAnchor={Validate.isValidNonEmptyString(text) ? 'end' : 'middle'}
                                x={node.x}
                                y={node.y + textHeight / 2}
                            >
                                {node.emoji}
                            </Text>
                        </>
                    ))
                }
            </G>
        );
    }

    static RenderLink(link, dark) {
        const color = RenderColor(dark, link.lineProps.color);
        const stroke = RenderColor(dark, link.lineProps.stroke);
        return (
            <G
                key={`link_${link.index}`}
            >
                <Line
                    {...link.lineProps}
                    color={color}
                    stroke={stroke}
                    key={`link_line_${link.index}`}
                    x1={link.source.x}
                    y1={link.source.y}
                    x2={link.target.x}
                    y2={link.target.y}
                />
            </G>
        );
    }
}


export const Network = props => {

    const {
        data,
        selectDistance,
        height,
        width,
        onSelect,
        onRelease,
        dragging,
    } = props;

    const { DEFAULT, dark, gesture, theme, themeUpdate } = useAppState();

    const [xPosition, setXPosition] = useState(width / 2);
    const [yPosition, setYPosition] = useState(height / 2);
    const [currentNode, setCurrentNode] = useState(null);

    const [d3Nodes, setD3Nodes] = useState([]);
    const [d3Links, setD3Links] = useState([]);
    const [svgNodes, setSvgNodes] = useState({});
    const [svgLinks, setSvgLinks] = useState({});

    const setXPositionRef = useRef(setXPosition);
    const setYPositionRef = useRef(setYPosition);
    const setCurrentNodeRef = useRef(setCurrentNode);

    const setD3NodesRef = useRef(setD3Nodes);
    const setD3LinksRef = useRef(setD3Links);
    const setSvgNodesRef = useRef(setSvgNodes);
    const setSvgLinksRef = useRef(setSvgLinks);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect entry`);
            const { TEXT_HEIGHT } = DEFAULT;
            Simulation = d3
                .forceSimulation()
                .alpha(ALPHA)
                .force('charge', d3.forceManyBody().strength(CHARGE_STRENGTH).distanceMax(CHARGE_MAX_DIST * TEXT_HEIGHT).distanceMin(CHARGE_MIN_DIST * TEXT_HEIGHT))
                .force('collide', d3.forceCollide().strength(COLLIDE_STRENGTH).radius(COLLIDE_RADIUS * TEXT_HEIGHT))
                .force('link', d3.forceLink().id(d => d.id).strength(LINK_STRENGTH).iterations(LINK_ITERATIONS)
                    .distance(link => {
                        var result = LINK_DISTANCE * TEXT_HEIGHT;
                        return link.source?.pos === 'root'
                            ? result * LINK_ROOT_SCALE
                            : link.target?.words
                                ? result * LINK_RELATION_SCALE
                                : result;
                    }));
            //    .linkStrength(0.1)
            //    .friction(0.9)
            //    .linkDistance(20)
            //    .gravity(0.1)
            //    .theta(0.8)
        },
        [
            DEFAULT,
        ],
    );

    useEffect(
        () => {
            const { colors } = theme;
            const { background } = colors;
            Console.log(`${NAME} useEffect d3 simulation onTick/onEnd`, { background, themeUpdate });
            Simulation.on('tick', () => {
                Console.trace(`${NAME}.onTick`);
                updateData(dark, background);
            });
            Simulation.on('end', () => {
                Console.log(`${NAME}.onEnd`);
                updateData(dark, background);
            });
        },
        [
            dark,
            theme,
            themeUpdate,
            updateData,
        ],
    );

    useEffect(
        () => {
            if (!Validate.isValid(data) ||
                !Validate.isValid(data.nodes) ||
                !Validate.isValid(data.links) ||
                !data.nodes.length) {
                return;
            }

            Console.log(`${NAME} useEffect d3 simulation data nodes/links`, { data });

            Simulation.nodes(data.nodes);
            Simulation.force('link').links(data.links);

            if (data.nodes.length) {
                data.nodes[0].lock = true;
                data.nodes[0].fx = data.nodes[0].x;
                data.nodes[0].fy = data.nodes[0].y;
            }

            setD3NodesRef.current(Simulation.nodes());
            setD3LinksRef.current(Simulation.force('link').links());
        },
        [
            data,
            data.nodes,
            data.links,
            setD3NodesRef,
            setD3LinksRef,
        ],
    );

    const updateData = useCallback(
        (_dark, background) => {
            const { TEXT_HEIGHT } = DEFAULT;
            var updatedSvgNodes = {};
            Simulation.nodes().forEach(d3Node => {
                updatedSvgNodes[d3Node.index] = NetworkUtils.RenderNode(d3Node, _dark, background, TEXT_HEIGHT);
            });
            setSvgNodesRef.current(updatedSvgNodes);

            var updatedSvgLinks = {};
            Simulation.force('link').links().forEach(d3Link => {
                updatedSvgLinks[d3Link.index] = NetworkUtils.RenderLink(d3Link, _dark);
            });
            setSvgLinksRef.current(updatedSvgLinks);

            Console.trace(`${NAME}.updateData`, { _dark, background, updatedSvgNodes, updatedSvgLinks });
        },
        [
            DEFAULT,
            setSvgNodesRef,
            setSvgLinksRef,
        ],
    );

    useEffect(
        () => {
            if (!dragging) {
                Console.log(`${NAME} useEffect d3 simulation size`, { width, height, GRAVITY });
                Simulation.force('center', d3.forceCenter(width / 2, height / 2).strength(GRAVITY));
            }
        },
        [
            dragging,
            height,
            width,
        ],
    );

    useEffect(
        () => {
            if (!Validate.isValid(d3Nodes) ||
                !Validate.isValid(d3Links)) {
                return;
            }
            const { TEXT_HEIGHT } = DEFAULT;
            var renderNodeObj = {};
            var renderLinkObj = {};
            const { colors } = theme;
            const { background } = colors;
            d3Nodes.forEach(node => { renderNodeObj[node.index] = NetworkUtils.RenderNode(node, dark, background, TEXT_HEIGHT); });
            d3Links.forEach(link => { renderLinkObj[link.index] = NetworkUtils.RenderLink(link, dark); });
            setSvgNodesRef.current(renderNodeObj);
            setSvgLinksRef.current(renderLinkObj);
            Console.log(`${NAME} useEffect render data`, { dark, background, themeUpdate, d3Nodes, d3Links, renderNodeObj, renderLinkObj });
            Simulation.alpha(ALPHA).restart();
        },
        [
            DEFAULT,
            dark,
            theme,
            themeUpdate,
            d3Nodes,
            d3Links,
            setSvgNodesRef,
            setSvgLinksRef,
        ],
    );

    useEffect(
        () => {

            if (currentNode === Nodes.get()) {
                return;
            }

            if (Validate.isValid(currentNode)) {

                Nodes.set(currentNode);
                onSelect(currentNode, currentNode.x, currentNode.y, height, width);
                currentNode.fx = currentNode.x;
                currentNode.fy = currentNode.y;
                currentNode.selected = true;
                Console.log(`${NAME} useEffect simulation start { ${currentNode.x}, ${currentNode.y} } [${currentNode.id}`);
                Simulation.alphaTarget(ALPHA).restart();

            } else {

                Nodes.set(null);
                var previousNode = Nodes.prev();
                if (Validate.isValid(previousNode?.selected)) {
                    delete previousNode.selected;
                }
                if (Validate.isValid(previousNode)) {
                    onRelease(previousNode, previousNode.x, previousNode.y, height, width);
                    if (!previousNode.lock) {
                        delete previousNode.fx;
                        delete previousNode.fy;
                    }
                    Console.log(`${NAME} useEffect simulation stop`);
                    Simulation.alphaTarget(0);
                }
            }
        },
        [
            height,
            width,
            currentNode,
            onSelect,
            onRelease,
        ],
    );

    useEffect(
        () => {
            if (Validate.isValid(currentNode)) {
                Console.trace(`${NAME} useEffect move { ${xPosition}, ${yPosition} } [ ${currentNode.id} ]`, currentNode);
                currentNode.fx = xPosition;
                currentNode.fy = yPosition;
                currentNode.x = xPosition;
                currentNode.y = yPosition;
            }
        },
        [
            currentNode,
            xPosition,
            yPosition,
        ],
    );

    useEffect(
        () => {
            const { x, y, state } = gesture;

            if (state === 'select') {

                const node = Simulation.find(x, y);
                if (Validate.isValid(node)) {
                    const dx = x - node.x;
                    const dy = y - node.y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    Console.log(`${NAME} useEffect onGesture select  { ${x}, ${y} } distance=${distance}`, node);
                    if (distance < selectDistance) {
                        setXPositionRef.current(x);
                        setYPositionRef.current(y);
                        setCurrentNodeRef.current(node);
                    }
                }

            } else if (state === 'release') {

                Console.log(`${NAME} useEffect onGesture release { ${x}, ${y} }`);
                setCurrentNodeRef.current(null);

            } else { // move

                Console.trace(`${NAME} useEffect onGesture move    { ${x}, ${y} } `);
                setXPositionRef.current(x);
                setYPositionRef.current(y);

            }
        },
        [
            gesture,
            selectDistance,
            setXPositionRef,
            setYPositionRef,
            setCurrentNodeRef,
        ],
    );

    const render = useMemo(
        () => {
            const nodeKeys = Object.keys(svgNodes);
            const linkKeys = Object.keys(svgLinks);
            Console.log(`${NAME}.render`, { data, svgNodes, nodes: nodeKeys.length, links: linkKeys.length });
            var rootKey = null;
            var selectedKey = null;
            var retval = [
                ...linkKeys.map(linkKey => svgLinks[linkKey]),
                ...nodeKeys
                    .filter(nodeKey => {
                        const idx = +(svgNodes[nodeKey].key.split('_')[1]);
                        const isRoot = data.nodes[idx]?.pos === 'root';
                        const isSelected = data.nodes[idx]?.selected ? true : false;
                        if (isRoot) {
                            rootKey = nodeKey;
                        }
                        if (isSelected) {
                            selectedKey = nodeKey;
                        }
                        return !isRoot && !isSelected;
                    })
                    .map(nodeKey => svgNodes[nodeKey]),
            ];
            if (Validate.isValid(rootKey)) {
                retval.push(svgNodes[rootKey]);
            }
            if (Validate.isValid(selectedKey) && selectedKey !== rootKey) {
                retval.push(svgNodes[selectedKey]);
            }
            return retval;
        },
        [
            svgNodes,
            svgLinks,
            data,
        ],
    );

    Console.stack(NAME, props, { DEFAULT, themeUpdate, width, height, xPosition, yPosition, currentNode, dragging });

    return useMemo(
        () => {
            Console.trace(`${NAME}.render`, { width, height, dragging });
            return Optional(height > 0 && !dragging, (
                <Svg
                    width={width}
                    height={height}
                >
                    {render}
                </Svg>
            ));
        },
        [
            dragging,
            width,
            height,
            render,
        ],
    );
};
