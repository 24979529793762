import React from 'react';
import { Screen, View } from '../components';
import { Speech2D as Component2D } from './Speech2D';
import { Speech3D as Component3D } from './Speech3D';
import { useAppState, useSystemState } from '../context';
import { Console, Optional } from '../utils';
import { GoogleAds, /*BANNER_AD_WIDTH,*/ BANNER_AD_HEIGHT } from '../ads';

const NAME = 'Speech';


export const Speech = props => {

  const { adjHeight } = useSystemState();
  const { threeD, showAds } = useAppState();

  Console.devStack(NAME, props, { adjHeight, threeD, showAds });

  return (
    <Screen
      {...props}
      value={NAME}
    >
      <View
            style={{ height: showAds ? adjHeight - BANNER_AD_HEIGHT : adjHeight }}
      >
      {Optional(threeD, <Component3D />, <Component2D />)}
      </View>
      {Optional(showAds, GoogleAds.Get().show(true))}
    </Screen>
  );
};

export const Speech2D = props => {

  Console.stack(`${NAME}2D`, props);

  return (
    <Screen
      {...props}
      value={NAME}
    >
      <Component2D />
    </Screen>
  );
};

export const Speech3D = props => {

  Console.stack(`${NAME}3D`, props);

  return (
    <Screen
      {...props}
      value={NAME}
    >
      <Component3D />
    </Screen>
  );
};
