// MARKMARK: Citation?
// https://emojipedia.org/people/
/*
const bugs = {
  'zero': ['0️⃣', { en_us: '0' }],
  'one': ['1️⃣|🕐', { en_us: '1|won' }],
  'two': ['2️⃣|🕑', { en_us: '2|to|too' }],
  'three': ['3️⃣|🕒', { en_us: '3' }],
  'four': ['4️⃣|🕓', { en_us: '4|for' }],
  'five': ['5️⃣|🕔', { en_us: '5' }],
  'six': ['6️⃣|🕕', { en_us: '6' }],
  'seven': ['7️⃣|🕖', { en_us: '7' }],
  'eight': ['8️⃣|🕗', { en_us: '8|ate' }],
  'nine': ['9️⃣|🕘', { en_us: '9' }],
  'ten': ['🔟|🕙', { en_us: '10' }],
  'eleven': ['🕚', { en_us: '11' }],
  'twelve': ['🕛', { en_us: '12' }],
  'beard': ['🧔'], // MARKMARK: BUG: This shows up as 'bear' on puzzle
  'donut': ['🍩'],// MARKMARK: BUG: no emoji, shows --adonut ??
  'boat': ['🛥️'], // MARKMARK: BUG: no emoji, shows ---aboat
  'vase': ['🏺'], // MARKMARK: BUG: no emoji, shows --avase
  'skis': ['🎿'], // MARKMARK: BUG: no emoji, shows --skis
};
*/
const zoo = {
  'monkey': ['🐒|🐵'],
  'gorilla': ['🦍'],
  'orangutan': ['🦧'],
  'lion': ['🦁'],
  'tiger': ['🐅|🐯'],
  'leopard': ['🐆'],
  'unicorn': ['🦄'],
  'zebra': ['🦓'],
  'camel': ['🐪'],
  'llama': ['🦙'],
  'giraffe': ['🦒'],
  'elephant': ['🐘'],
  'rhinoceros': ['🦏'],
  'hippopotamus': ['🦛'], // MARKMARK: too long
  'bear': ['🐻', { en_us: 'bare' }], // MARKMARK: isolated
  'panda': ['🐼'],
  'koala': ['🐨'],
  'sloth': ['🦥'],
  'kangaroo': ['🦘'],
  'wolf': ['🐺', { en_us: 'woof' }],
  'fox': ['🦊'],
};
const animals = {
  'dog': ['🐕|🐶'],
  'poodle': ['🐩'],
  'raccoon': ['🦝'],
  'cat': ['🐈'],
  'horse': ['🐎|🐴'],
  'deer': ['🦌', { en_us: 'dear' }],
  'ox': ['🐂'],
  'cow': ['🐄|🐮'],
  'pig': ['🐖|🐷'],
  'boar': ['🐗', { en_us: 'bore' }],
  'ram': ['🐏'],
  'ewe': ['🐑', { en_us: 'u|you' }],
  'goat': ['🐐'],
  'mouse': ['🐁|🐭'],
  'rat': ['🐀'],
  'hamster': ['🐹'],
  'rabbit': ['🐇|🐰'],
  'chipmunk': ['🐿️'],
  'hedgehog': ['🦔'],
  'bat': ['🦇'],
  'otter': ['🦦'],
  'skunk': ['🦨'],
  'badger': ['🦡'],
};
const birds = {
  'turkey': ['🦃'],
  'chicken': ['🐔'],
  'rooster': ['🐓'],
  'chick': ['🐤'],
  'bird': ['🐦'],
  'penguin': ['🐧'],
  'dove': ['🕊️'],
  'eagle': ['🦅'],
  'duck': ['🦆'],
  'swan': ['🦢'],
  'owl': ['🦉'],
  'flamingo': ['🦩'],
  'peacock': ['🦚'],
  'parrot': ['🦜'],
};
const reptiles = {
  'frog': ['🐸'], // MARKMARK: isolated
  'crocodile': ['🐊'],
  'turtle': ['🐢'], // MARKMARK: isolated
  'lizard': ['🦎'],
  'snake': ['🐍'],
  'dragon': ['🐉|🐲'],
  'dinosour': ['🦖|🦕'],
};
const marine = {
  'whale': ['🐳', { en_us: 'wail' }],
  'dolphin': ['🐬'],
  'fish': ['🐟'], // MARKMARK: isolated
  'shark': ['🦈'],
  'octopus': ['🐙'],
  'crab': ['🦀'], // MARKMARK: isolated
  'lobster': ['🦞'],
  'shrimp': ['🦐'],
  'squid': ['🦑'],
  'oyster': ['🦪'],
};
const insects = {
  'snail': ['🐌'],
  'butterfly': ['🦋'],
  'bug': ['🐛'],
  'ant': ['🐜', { en_us: 'aunt' }],
  'bee': ['🐝', { en_us: 'b|be' }],
  'ladybug': ['🐞'],
  'cricket': ['🦗'],
  'spider': ['🕷️'],
  'mosquito': ['🦟'],
};
const plants = {
  'flowers': ['💐'],
  'rose': ['🌹'],
  'hibiscus': ['🌺'],
  'sunflower': ['🌻'],
  'blossom': ['🌼'],
  'tulip': ['🌷'], // MARKMARK: isolated
  'evergreen': ['🌲'],
  'palmtree': ['🌴', { en_us: 'palm tree' }],
  'tree': ['🌳'],
  'cactus': ['🌵'], // MARKMARK: isolated
  'herb': ['🌿'],
  'clover': ['☘️'],
  'leaf': ['🍂'],
  'plant': ['🌱'],
};
const body = {
  'hand': ['🖐️'],
  'hands': ['👐'],
  'arm': ['💪'],
  'leg': ['🦵'],
  'foot': ['🦶'],
  'ear': ['👂'],
  'nose': ['👃'],
  'brain': ['🧠'],
  'tooth': ['🦷'],
  'bone': ['🦴'],
  'eyes': ['👀'],
  'eye': ['👁️', { en_us: 'i|aye' }],
  'tongue': ['👅'],
  'mouth': ['👄'],
  'heart': ['❤️'],
  'thumb': ['👍'],
  'skull': ['💀', { en_us: 'skol' }],
  'bald': ['👨‍🦲'],
  'blood': ['🩸'],
  //'beard': ['🧔'], // MARKMARK: BUG: This shows up as 'bear' on puzzle
};
const people = {
  'baby': ['👶'],
  'child': ['🧒'],
  'boy': ['👦'],
  'girl': ['👧'],
  'person': ['🧑'],
  'man': ['👨'],
  'woman': ['👩'],
  'pregnant': ['🤰'],
  'male': ['♂️', { en_us: 'mail' }],
  'female': ['♀️'],
  'family': ['👪'],
  'ghost': ['👻'],
  'alien': ['👽'],
  'robot': ['🤖'],
  'clown': ['🤡'],
};
const occupations = {
  'doctor': ['👨‍⚕️|👩‍⚕️'],
  'student': ['👨‍🎓|👩‍🎓'],
  'teacher': ['👨‍🏫|👩‍🏫'],
  'judge': ['👨‍⚖️|👩‍⚖️'],
  'farmer': ['👨‍🌾|👩‍🌾'],
  'cook': ['👨‍🍳|👩‍🍳'],
  'mechanic': ['👨‍🔧|👩‍🔧'],
  'scientist': ['👨‍🔬|👩‍🔬'],
  'engineer': ['👨‍💻|👩‍💻'],
  'astronaut': ['👨‍🚀|👩‍🚀'],
  'detective': ['🕵️‍♂️|🕵️‍♀️'],
  'guard': ['💂‍♂️|💂‍♀️'],
  'pilot': ['👨‍✈️|👩‍✈️'],
  'singer': ['👨‍🎤|👩‍🎤'],
  'artist': ['👨‍🎨|👩‍🎨'],
  'firefighter': ['👨‍🚒|👩‍🚒'], // MARKMARK: Too long
  'police': ['👮‍♂️|👮‍♀️'],
//  'pirate': ['🏴‍☠️'],
};
const fruit = {
  'grapes': ['🍇'],
  'melon': ['🍈'],
  'watermelon': ['🍉'],
  'tangerine': ['🍊'],
  'lemon': ['🍋'],
  'banana': ['🍌'],
  'pineapple': ['🍍'],
  'mango': ['🥭'],
  'apple': ['🍎|🍏'],
  'pear': ['🍐', { en_us: 'pair|pare' }],
  'peach': ['🍑'],
  'cherries': ['🍒'],
  'strawberry': ['🍓'],
  'kiwi': ['🥝'], // MARKMARK: isolated
  'tomato': ['🍅'],
  'avocado': ['🥑'],
};
const vegetables = { // MARKMARK: correctly classify
  'coconut': ['🥥'],
  'eggplant': ['🍆'],
  'potato': ['🥔'],
  'carrot': ['🥕'],
  'corn': ['🌽'],
  'pepper': ['🌶️'],
  'cucumber': ['🥒'],
  'lettuce': ['🥬', { en_us: 'let us' }],
  'broccoli': ['🥦'],
  'garlic': ['🧄'],
  'onion': ['🧅'],
  'mushroom': ['🍄'],
  'peanuts': ['🥜'],
  'chestnut': ['🌰'],
};
const food = {
  'bread': ['🍞'],
  'croissant': ['🥐'],
  'baguette': ['🥖'],
  'pretzel': ['🥨'],
  'bagel': ['🥯'],
  'pancake': ['🥞'],
  'waffle': ['🧇'],
  'cheese': ['🧀'],
  'meat': ['🥩'],
  'poultry': ['🍗'],
  'bacon': ['🥓'],
  'hamburger': ['🍔'],
  'fries': ['🍟'],
  'pizza': ['🍕'],
  'hotdog': ['🌭', { en_us: 'hot dog' }],
  'sandwich': ['🥪'],
  'taco': ['🌮'],
  'burrito': ['🌯'],
  'falafel': ['🧆'],
  'sushi': ['🍣'],
  'ramen': ['🍜'],
  'pasta': ['🍝'],
  'egg': ['🥚'],
  'salad': ['🥗'],
  'popcorn': ['🍿'],
  'butter': ['🧈'],
  'salt': ['🧂'],
  //'donut': ['🍩'],// MARKMARK: BUG: no emoji, shows ---adonut ??
  'honey': ['🍯'],
};
const drink = {
  'milk': ['🥛'],
  'coffee': ['☕'],
  'tea': ['🍵', { en_us: 't|tee' }],
  'wine': ['🍷', { en_us: 'whine' }],
  'beer': ['🍺'],
  'alcohol': ['🍹'],
  'beverage': ['🥤'],
  'ice': ['🧊'],
  'water': ['💧'],
};
const dessert = {
  'icecream': ['🍦', { en_us: 'ice cream' }],
  'cookie': ['🍪'],
  'cake': ['🎂'],
  'cupcake': ['🧁'],
  'pie': ['🥧'],
  'chocolate': ['🍫'],
  'candy': ['🍬'],
};
const eating = {
  'chopsticks': ['🥢'],
  'utensils': ['🍴'],
  'spoon': ['🥄'],
  'knife': ['🔪'],
  'bottle': ['🍾|🍼'],
  'bowl': ['🥣'],
};
const nature = {
  'mountain': ['⛰️'],
  'volcano': ['🌋'],
  'beach': ['🏖️', { en_us: 'peach' }],
  'desert': ['🏜️'],
  'island': ['🏝️'],
  'fire': ['🔥'],
  'water': ['💧'],
  'wave': ['🌊'],
  'planet': ['🪐'], // MARKMARK: isolated
  'star': ['⭐'],
  'galaxy': ['🌌'], // MARKMARK: isolated
  'moon': ['🌜'],
  'comet': ['☄️', { en_us: 'comment' }],
  'sun': ['🌞'],
};
const colors = {
  'red': ['🔴'],
  'orange': ['🟠'],
  'yellow': ['🟡'],
  'green': ['🟢'],
  'blue': ['🔵'],
  'purple': ['🟣'],
  'brown': ['🟤'],
  'black': ['⚫'],
  'white': ['⚪'],
};
const clothes = {
  'glasses': ['👓'],
  'sunglasses': ['🕶️'],
  'goggles': ['🥽'],
  'underwear': ['🩲'],
  'shirt': ['👕'],
  'pants': ['👖'],
  'scarf': ['🧣'],
  'gloves': ['🧤'],
  'coat': ['🧥'],
  'socks': ['🧦', { en_us: 'sucks' }],
  'swimsuit': ['🩱|👙'],
  'dress': ['👗'],
  'purse': ['👜'],
  'backpack': ['🎒'],
  'shoe': ['👞|👟|👠', { en_us: 'shoo' }],
  'boot': ['🥾|👢'],
  'crown': ['👑'],
  'hat': ['🎩|👒'], // MARKMARK: isolated
  'necktie': ['👔'],
  'ring': ['💍'],
  'lipstick': ['💄'],
};
const music = {
  'bell': ['🔔'],
  'horn': ['📯'], // MARKMARK: isolated
  'saxophone': ['🎷'], // MARKMARK: isolated
  'guitar': ['🎸'],
  'piano': ['🎹'],
  'trumpet': ['🎺'],
  'violin': ['🎻'],
  'banjo': ['🪕'],
  'drum': ['🥁'], // MARKMARK: isolated
};
const places = {
  'post': ['🏤'],
  'hospital': ['🏥'],
  'bank': ['🏦'],
  'hotel': ['🏨'],
  'store': ['🏪'],
  'school': ['🏫'],
  'factory': ['🏭'],
  'castle': ['🏰'],
  'church': ['⛪|🕌|🛕|🕍'], // MARKMARK, break this up
  'fountain': ['⛲'],
  'barber': ['💈'],
  'circus': ['🎪'],
  'bridge': ['🌉'],
};
const vehicles = {
  'train': ['🚂|🚆'],
  'bus': ['🚌|🚍'],
  'ambulance': ['🚑'],
  'fireengine': ['🚒', { en_us: 'fire engine' }],
  'policecar': ['🚓|🚔', { en_us: 'police car' }],
  'taxi': ['🚕|🚖'],
  'car': ['🚗'],
  'truck': ['🚚'],
  'tractor': ['🚜'],
  'racecar': ['🏎️', { en_us: 'race car' }],
  'motorcycle': ['🏍️'],
  'wheelchair': ['🦽', { en_us: 'wheel chair' }],
  'bicycle': ['🚲'],
  'skateboard': ['🛹', { en_us: 'skate board' }], // MARKMARK: isolated
  'scooter': ['🛴'],
  'sailboat': ['⛵', { en_us: 'sail boat' }],
  'canoe': ['🛶'],
  'speedboat': ['🚤', { en_us: 'speed boat' }],
  //'boat': ['🛥️'], // MARKMARK: BUG: no emoji, shows ---aboat
  'ship': ['🚢'],
  'airplane': ['✈️'],
  'helicopter': ['🚁'],
  'satellite': ['🛰️'],
  'rocket': ['🚀'],
  'spaceship': ['🛸'],
};
const weather = {
  'cloud': ['☁️'], // MARKMARK: isolated
  'rain': ['🌧️'],
  'snow': ['🌨️'],
  'lightning': ['🌩️'],
  'tornado': ['🌪️'],
  'fog': ['🌫️'], // MARKMARK: isolated
  'wind': ['🌬️'],
  'cyclone': ['🌀'],
  'rainbow': ['🌈'],
  'sunny': ['🌞'],
};
const home = {
  'door': ['🚪'],
  'bed': ['🛏️'],
  'chair': ['🪑'],
  'toilet': ['🚽'],
  'shower': ['🚿'],
  'bathtub': ['🛁'],
  'razor': ['🪒'],
  'broom': ['🧹'],
  'basket': ['🧺'],
  'soap': ['🧼'],
  'sponge': ['🧽'],
  'painting': ['🖼️'],
  'umbrella': ['☂️'],
  'thread': ['🧵'],
  'yarn': ['🧶'],
  'toiletpaper': ['🧻'], // MARKMARK: too long
  'candle': ['🕯️'],
  'lightbulb': ['💡', { en_us: 'light bulb' }],
  'flashlight': ['🔦'],
  'book': ['📖'],
  'books': ['📚'],
  'clock': ['🕰️'],
  'alarmclock': ['⏰', { en_us: 'alarm clock' }],
  'teddybear': ['🧸', { en_us: 'teddy bear' }],
  //'vase': ['🏺'], // MARKMARK: BUG: no emoji, shows --avase
  'paintbrush': ['🖌️'],
  'crayon': ['🖍️'],
  'lock': ['🔒'],
  'key': ['🔑'],
  'bandage': ['🩹'],
  'money': ['💵'],
};
const office = {
  'paperclip': ['📎'],
  'ruler': ['📏'],
  'scissors': ['✂️'],
  'wastebasket': ['🗑️'], // MARKMARK: too long
  'folder': ['📁'],
  'calendar': ['📅'],
  'clipboard': ['📋'],
  'pin': ['📌|📍'],
  'chart': ['📊'],
  'pencil': ['✏️'],
  'pen': ['🖋️'],
  'paper': ['📃'],
  'envelope': ['✉️'],
};
const activities = {
  'walk': ['🚶‍♂️|🚶‍♀️'],
  'stand': ['🧍‍♂️|🧍‍♀️'],
  'kneel': ['🧎‍♂️|🧎‍♀️', { en_us: 'neil' }],
  'run': ['🏃‍♂️|🏃‍♀️'],
  'dance': ['🕺|💃'],
  'golf': ['🏌️‍♂️|🏌️‍♀️'],
  'surf': ['🏄‍♂️|🏄‍♀️'],
  'row': ['🚣‍♂️|🚣‍♀️'],
  'swim': ['🏊‍♂️|🏊‍♀️'],
  'lift': ['🏋️‍♂️|🏋️‍♀️'],
  'climb': ['🧗‍♂️|🧗‍♀️'],
  'bike': ['🚴‍♂️|🚴‍♀️'],
  'juggle': ['🤹‍♂️|🤹‍♀️'],
  'meditate': ['🧘‍♂️|🧘‍♀️'],
  'ski': ['⛷️'],
  'snowboard': ['🏂'], // MARKMARK: isolated
};
const electronics = {//computers = {
  'laptop': ['💻'], // MARKMARK: isolated
  'computer': ['🖥️'],
  'printer': ['🖨️'],
  'keyboard': ['⌨️'],
  'mouse': ['🖱️'], // MARKMARK: too long
  //'disk': ['💿|💾', { en_us: 'disc' }], // MARKMARK: can't stt match ? ('computer disk' works, but is too long)
//};
//const electronics = {
  'battery': ['🔋'],
  'plug': ['🔌'],
  'television': ['📺'],
  'camera': ['📷'],
  'microphone': ['🎤'],
  'speaker': ['🔊'],
  'headphone': ['🎧'], // MARKMARK: isolated
  'radio': ['📻'],
  'telephone': ['☎️'],
  'cellphone': ['📱', { en_us: 'cell phone' }],
  'videocamera': ['📹'], // MARKMARK: too long
  'faxmachine': ['📠', { en_us: 'fax machine' }],
};
const games = {
  'soccer': ['⚽'],
  'baseball': ['⚾'],
  'basketball': ['🏀'], // MARKMARK: isolated
  'volleyball': ['🏐'],
  'football': ['🏈'],
  'tennis': ['🎾'],
  'frisbee': ['🥏'],
  'bowling': ['🎳'],
  'hockey': ['🏒'],
  'pingpong': ['🏓', { en_us: 'ping pong' }],
  'badminton': ['🏸'], // MARKMARK: isolated
  'boxing': ['🥊'],
  'golf': ['⛳'],
  'skate': ['⛸️'],
  'fishing': ['🎣'],
  'diving': ['🤿'],
  //'skis': ['🎿'], // MARKMARK: BUG: no emoji, shows --skis
  'sled': ['🛷'],
  'darts': ['🎯'],
  'kite': ['🪁'],
  'billiards': ['🎱'],
  'game': ['🎮'],
  'dice': ['🎲'],
  'puzzle': ['🧩'],
  'chess': ['♟️'],
  'cards': ['🃏'],
  'archery': ['🏹'],
};
const signs = {
  'stop': ['🛑'],
  'warning': ['⚠️'],
  'currency': ['💱'],
  'crossing': ['🚸'],
  'wrongway': ['⛔', { en_us: 'wrong way' }],
  'trash': ['🚮'],
  'men': ['🚹'],
  'women': ['🚺'],
  'baby': ['🚼'],
  'bathroom': ['🚻'],
  'passport': ['🛂'],
  'customs': ['🛃'],
  'baggage': ['🛄'],
  'locker': ['🛅'],
  'money': ['🏧'],
  'recycle': ['♻️'],
  'radioactive': ['☢️'],
  'biohazard': ['☣️'],
};
const symbols = {
  'warning': ['⚠️'],
  'love': ['🤟'],
  'medical': ['⚕️'],
  'recycle': ['♻️'],
  'radioactive': ['☢️'], // MARKMARK: isolated
  'biohazard': ['☣️'],
  'peace': ['☮️'],
  'atom': ['⚛️'],
  'letters': ['🔡|🔠|🔤'],
  'numbers': ['🔢'],
  'symbols': ['🔣'],
};
const mail = {
  'post': ['🏤'],
  'email': ['📧'],
  'package': ['📦'],
  'mailbox': ['📬'], // MARKMARK: isolated
  'postbox': ['📮', { en_us: 'post box' }],
  'paper': ['📃'],
  'envelope': ['✉️'],
};
const tools = {
  'hammer': ['🔨'],
  'axe': ['🪓', { en_us: 'x' }],
  'pick': ['⛏️'],
  'stopwatch': ['⏱️'],
  'map': ['🗺️'],
  'compass': ['🧭'],
  'thermometer': ['🌡️'], // MARKMARK: too long
  'wrench': ['🔧'],
  'gear': ['⚙️'],
  'scale': ['⚖️'],
  'chain': ['⛓️'],
  'toolbox': ['🧰'],
  'magnet': ['🧲'],
  'microscope': ['🔬'],
  'telescope': ['🔭'],
};
const controls = {
  'up': ['⬆️'],
  'down': ['⬇️'],
  'right': ['➡️'],
  'left': ['⬅️'],
  'play': ['▶️'],
  'reverse': ['◀️'],
  'pause': ['⏸️'],
  'record': ['⏺️'],
  'eject': ['⏏️'],
  'shuffle': ['🔀'],
  'repeat': ['🔁'],
  'backward': ['⏪'], // MARKMARK: isolated
  'forward': ['⏩'],
  'next': ['⏭️'],
  'last': ['⏮️'], // MARKMARK: isolated
};
const zodiac = {
  'aries': ['♈'],
  'taurus': ['♉'],
  'gemini': ['♊'], // MARKMARK: isolated
  'cancer': ['♋'],
  'leo': ['♌'],
  'virgo': ['♍'], // MARKMARK: isolated
  'libra': ['♎'],
  'scorpio': ['♏'],
  'sagittarius': ['♐'], // MARKMARK: too long
  'capricorn': ['♑'],
  'aquarius': ['♒'],
  'pisces': ['♓'],
  //    'ophiuchus': ['⛎'],
};
const mood = {
  'grin': ['😀'],
  'wink': ['😉'],
  'smile': ['😊'],
  'think': ['🤔'],
  'drool': ['🤤'],
  'frown': ['☹️'],
  'cry': ['😢'],
  'yawn': ['🥱'],
  'angry': ['😠'],
  'plead': ['🥺'],
  'party': ['🥳'],
  'drunk': ['🥴'],
  'quiet': ['🤫'],
  'vomit': ['🤮'],
  'nausea': ['🤢'],
  'lying': ['🤥'],
  'sneeze': ['🤧'],
  'kiss': ['😗'],
  'smirk': ['😏'],
  'grimace': ['😬'],
  'relief': ['😌'],
  'pensive': ['😔'],
  'tired': ['😪'],
  'sleep': ['😴'],
  'sick': ['🤒'],
  'injury': ['🤕'],
  'dizzy': ['😵'],
  'confusion': ['😕'],
  'worry': ['😟'],
  'astonish': ['😲'],
  'anguish': ['😧'],
  'anxious': ['😰'],
  'fear': ['😨'],
  'perservere': ['😣'], // MARKMARK: isolated
  'disappoint': ['😞'],
  'weary': ['😫'],
  'pout': ['😡'],
};
const math = {
//const shapes = {
  //'circle': ['🔴|🟠|🟡|🟢|🔵|🟣|🟤|⚫|⚪'],
  //'square': ['🟥|🟧|🟨|🟩|🟦|🟪|🟫|⬛|⬜'],
  //'diamond': ['🔶|🔷'],
  //'triangle': ['🔺|🔻'],
//};
/*
const time = {
  'one': ['🕐'],
  'two': ['🕑'],
  'three': ['🕒'],
  'four': ['🕓'],
  'five': ['🕔'],
  'six': ['🕕'],
  'seven': ['🕖'],
  'eight': ['🕗'],
  'nine': ['🕘'],
  'ten': ['🕙'],
  'eleven': ['🕚'],
  'twelve': ['🕛'],
};
*/
//const numbers = {
  'zero': ['0️⃣', { en_us: '0' }],
  'one': ['1️⃣|🕐', { en_us: '1|won' }],
  'two': ['2️⃣|🕑', { en_us: '2|to|too' }],
  'three': ['3️⃣|🕒', { en_us: '3' }],
  'four': ['4️⃣|🕓', { en_us: '4|for' }],
  'five': ['5️⃣|🕔', { en_us: '5' }],
  'six': ['6️⃣|🕕', { en_us: '6' }],
  'seven': ['7️⃣|🕖', { en_us: '7' }],
  'eight': ['8️⃣|🕗', { en_us: '8|ate' }],
  'nine': ['9️⃣|🕘', { en_us: '9' }],
  'ten': ['🔟|🕙', { en_us: '10' }],
  'eleven': ['🕚', { en_us: '11' }],
  'twelve': ['🕛', { en_us: '12' }],
  'hundred': ['💯', { en_us: '100' }],
  'infinity': ['♾️'],
  'add': ['➕'],
  'subtract': ['➖'],
  'multiply': ['✖️'],
  'divide': ['➗'],
};
/*
const random = {
  'scorpion': ['🦂'],
  'microbe': ['🦠'],
  'syringe': ['💉'],
  'stethoscope': ['🩺'],
  'bricks': ['🧱'],
  'crane': ['🏗️'],
  'house': ['🏠'],
  'houses': ['🏘️'],
  'coaster': ['🎢'],
  'wheel': ['🎡'],
  'music': ['🎼'],
  'movie': ['📽️'],
  'newspaper': ['📰'],
  'gas': ['⛽'],
  'anchor': ['⚓'],
  'parachute': ['🪂'],
  'trafficlight': ['🚦|🚥'],
  'siren': ['🚨'],
  'hourglass': ['⏳'],
  'watch': ['⌚'],
  'snowman': ['⛄'],
  'fireworks': ['🎆|🧨'],
  'balloon': ['🎈'],
  'gift': ['🎁'],
  'ticket': ['🎟️|🎫'],
  'ribbon': ['🎗️'],
  'trophy': ['🏆'],
  'palette': ['🎨'],
  'gem': ['💎'],
  'vote': ['🗳️'],
  'abacus': ['🧮'],
  'shield': ['🛡️'],
  'pill': ['💊'],
  'extinguisher': ['🧯'],
  'cart': ['🛒'],
  'cigarette': ['🚬'],
  'coffin': ['⚰️'],
  'luggage': ['🧳'],
  'shell': ['🐚'],
  'web': ['🕸️'],
  'question': ['❔'],
  'exclamation': ['❕'],
  'camping': ['🏕️'],
  'deaf': ['🧏‍♂️|🧏'],
  'blind': ['🦯|👨‍🦯|👩‍🦯'],
  'prince': ['🤴'],
  //    'princess': [''],
  'cold': ['🥶'],
  'hot': ['🥵'],
  'film': ['🎞️'],
  'credit': ['💳'],
  'bomb': ['💣'],
  'hole': ['🕳️'],
  'poop': ['💩'],
  'speaking': ['🗣️'],
  'search': ['🔎'],
  'chat': ['🗨️'],
  'flag': ['🏁'],
};
*/

export const emojiList = {
  //bugs,
  zoo,
  animals,
  birds,
  reptiles,
  marine,
  insects,
  plants,
  body,
  people,
  occupations,
  fruit,
  vegetables,
  food,
  drink,
  dessert,
  eating,
  nature,
  colors,
  clothes,
  music,
  places,
  vehicles,
  weather,
  home,
  office,
  activities,
  //computers,
  electronics,
  games,
  signs,
  symbols,
  mail,
  tools,
  controls,
  zodiac,
  mood,
  math,
  //  shapes,
  //  time,
  //  numbers,
  //random, // MARKMARK: Sean needs to get ipa's for these
};

/*
export const emojiList = {
  zoo,
  animals,
  fruit,
  vegetables,
  colors,
  weather,
  games,
  clothes,
  body,
  marine,
  insects,
};
*/
