import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Menu as RNPMenu } from 'react-native-paper';
import { useAppState, useSystemState } from '../context';
import { View } from './View';
import { Console } from '../utils';
import { Colors, TextStyles } from '../styles';

const NAME = 'Menu';

const FONT_HEIGHT_SCALE = 4;


export const Menu = props => {

    const {
        value,
        current,
        Anchor,
        backgroundColor,
        iconColor,
        icon,
        items,
        onPress,
        disabled,
    } = props;

    const { deviceScale, width, headerHeight } = useSystemState();
    const { t } = useAppState();

    const [menuItems, setMenuItems] = useState([]);
    const setMenuItemsRef = useRef(setMenuItems);

    const [visible, setVisible] = useState(false);
    const setVisibleRef = useRef(setVisible);

    const [fontSize, setFontSize] = useState(TextStyles.menuItem.fontSize * deviceScale);
    const setFontSizeRef = useRef(setFontSize);

    const [yPosition, setYPosition] = useState(headerHeight * deviceScale);
    const setYPositionRef = useRef(setYPosition);

    useEffect(
        () => {
            setMenuItemsRef.current(items.map(item => ({
                ...item,
                title: t(item.title),
                icon: () => item.image,
            })));
        },
        [
            t,
            items,
            setMenuItemsRef,
        ],
    );

    useEffect(
        () => {
            setFontSizeRef.current(TextStyles.menuItem.fontSize * deviceScale);
            setYPositionRef.current(headerHeight * deviceScale);
        },
        [
            headerHeight,
            deviceScale,
        ],
    );

    const toggleAnchor = useCallback(
        () => {
            setVisibleRef.current(v => !v);
        },
        [
            setVisibleRef,
        ],
    );

    const onItem = useCallback(
        item => {
            setVisibleRef.current(false);
            onPress(item);
        },
        [
            onPress,
            setVisibleRef,
        ],
    );

    Console.stack(`${NAME}[${value}]`, props, { deviceScale, width, headerHeight, menuItems, visible, fontSize, yPosition });

    return useMemo(
        () => {
            const menuItemTitle = {
                fontSize,
                marginLeft: '15%',
            };
            const menuItemContent = {
            };
            const menuItem = {
                height: fontSize * FONT_HEIGHT_SCALE,
            };
            const menuStyle = {
                width: '60%',
            };
            const menuContent = {
            };

            const currentItem = menuItems.find(v => v.id === current);
            const _icon = currentItem ? null : icon;
            const _image = currentItem?.image ? currentItem.image : null;

            Console.log(`${NAME}[${value}] render`, { width, menuItems, current, visible, yPosition, menuItemTitle, menuItemContent, menuItem, menuStyle, menuContent });

            return (
                <View
                    value={'Menu'}
                >
                    <Anchor
                        icon={_icon}
                        iconColor={iconColor}
                        backgroundColor={backgroundColor}
                        onPress={() => toggleAnchor()}
                        selected={visible}
                        disabled={disabled}
                    >
                        {_image}
                    </Anchor>
                    <RNPMenu
                        visible={visible}
                        onDismiss={() => setVisibleRef.current(false)}
                        style={[styles.menuStyle, menuStyle]}
                        contentStyle={[styles.menuContent, menuContent]}
                        anchor={{
                            x: width,
                            y: yPosition,
                        }}
                    >
                        {
                            menuItems.map(item => (
                                <RNPMenu.Item
                                    key={item.id}
                                    title={item.title}
                                    style={[item.id === current ? styles.menuItemActive : styles.menuItem, menuItem]}
                                    titleStyle={[styles.menuItemTitle, menuItemTitle]}
                                    contentStyle={[styles.menuItemContent, menuItemContent]}
                                    leadingIcon={() => item.image}
                                    onPress={() => onItem(item.id)}
                                />
                            ))
                        }
                    </RNPMenu>
                </View>
            );
        },
        [
            width,
            fontSize,
            value,
            current,
            backgroundColor,
            disabled,
            icon,
            iconColor,
            visible,
            menuItems,
            yPosition,
            toggleAnchor,
            onItem,
            setVisibleRef,
        ],
    );
};

const styles = StyleSheet.create({
    menuItemTitle: {
        //backgroundColor: Colors.colors.green,
    },
    menuItemContent: {
        //backgroundColor: Colors.colors.cyan,
        //width: '50%',
    },
    menuItem: {
        //backgroundColor: Colors.colors.gray,
        //borderColor: Colors.colors.green,
        //borderWidth: 1,
    },
    menuItemActive: {
        backgroundColor: Colors.colors.gray,
    },
    menuStyle: {
        //borderWidth: 1,
        //borderColor: Colors.colors.lime,
    },
    menuContent: {
        //borderWidth: 1,
        //borderColor: Colors.colors.yellow,
        //margin: 5,
    },
});
