import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Button, Input, INPUT_VARIANTS, Text, TEXT_VARIANTS, Screen, View } from '../components';
import { Auth } from '../aws';
import { useAppState } from '../context';
import { Console, Optional, Validate } from '../utils';
import { Colors } from '../styles';
import { REGEX } from '../constants';
import { Navigate, NavParams } from './utils';

const WHITE_LIST = new Set([
    'mark',
    'sean',
    'mike',
    'dana',
    'bob',
    'kian',
    'molly',
    'erin',
    'trish',
    'lynn',
    'pat',
    'terry',
    'lydia',
    'bernie',
    'dianes',
    'maddie',
    'gracie',
    'jasonj',
    'jasonw',
    'todd',
    'michael',
    'stella',
    'angie',
    'stephen',
    'rick',
    'jeremy',
    'jeanette',
    'drake',
    'kobie',
    'kroft',
    'matt',
    'amy',
    'davy',
    'blanca',
    'bobg',
    'misti',
]);

const NAME = 'Register';

const LOGIN = 'Login';
const USERNAME = 'Name';
const EMAIL = 'Email';
const PHONE = 'Phone';
const PASSWORD = 'Password';
const REENTER_PASSWORD = 'Confirm Password';
const CODE = 'Code';
const REGISTER_MESSAGE = 'Sign up for your new account';
const CONFIRM_MESSAGE = 'Confirm your registration code';
const REGISTER = 'Register';
const RESEND_CODE = 'Resend Code';
const CONFIRM_CODE = 'Confirm Code';
const CANCEL = 'Cancel';
//const CONFIRM_REGISTER = 'ConfirmRegister';

const PLACEHOLDER_NAME = 'Enter your name';
const PLACEHOLDER_PHONE = '800-555-1212';
const PLACEHOLDER_EMAIL = 'name@email.com';
const PLACEHOLDER_PASSWORD = 'Enter your password';
const PLACEHOLDER_REENTER_PASSWORD = 'Re-enter your password';
const PLACEHOLDER_CODE = 'Enter your code';
const CODE_SENT = 'Check your email for Verification Code';

const INVALID_CONFIRM_PASSWORD = 'Passwords do not match';
const INVALID_FORMAT = 'Invalid format';

const INVALID_CODE = 'Username/client id combination not found.';
const INVALID_PASSWORD_SHORT = 'Password did not conform with policy: Password not long enough';
const USER_ALREADY_EXISTS = 'User already exists';
const __INVALID_CODE__ = 'Invalid code.';
const __INVALID_PASSWORD_SHORT__ = 'Password not long enough';
const __USER_ALREADY_EXISTS__ = 'Name already exists';


export const Register = props => {

    const {
        route,
        navigation,
    } = props;

    const { t, language } = useAppState();

    const [username, setUsername] = useState('');
    const setUsernameRef = useRef(setUsername);

    const [password, setPassword] = useState('');
    const setPasswordRef = useRef(setPassword);

    const [confirmPassword, setConfirmPassword] = useState('');
    const setConfirmPasswordRef = useRef(setConfirmPassword);

    const [email, setEmail] = useState('');
    const setEmailRef = useRef(setEmail);

    const [phone, setPhone] = useState('');
    const setPhoneRef = useRef(setPhone);

    const [code, setCode] = useState('');
    const setCodeRef = useRef(setCode);

    const [codeSent, setCodeSent] = useState(false);
    const setCodeSentRef = useRef(setCodeSent);

    const [error, setError] = useState({});
    const setErrorRef = useRef(setError);

    /*
        useEffect(
            () => {
                Console.log(`${NAME} useEffect`, { kbVisible });
                if (!kbVisible) {
                    //appDispatchRef.current({ type: APP_TYPES.SET_AUTH, payload: NAME });
                }
            },
            [
                //appDispatchRef,
                kbVisible,
            ],
        );
    */
    useEffect(
        () => {
            const parmsTitle = NavParams(route)?.title;
            const title = parmsTitle ? parmsTitle : NAME;
            Console.log(`${NAME} useEffect entry`, { title });
            navigation.setOptions({ headerTitle: ({ tintColor }) => <Text value={title} color={tintColor} variant={TEXT_VARIANTS.TITLE} /> });
        },
        [
            route,
            navigation,
        ],
    );

    const onRegister = useCallback(
        async (_username, _password, _confirmPassword, _phone, _email, _language) => {
            var _error = {};
            if (_password !== _confirmPassword) {
                _error = { ..._error, confirmPassword: INVALID_CONFIRM_PASSWORD };
            }
            if (!REGEX.PHONE.test(_phone)) {
                _error = { ..._error, phone: `${INVALID_FORMAT}: ${PLACEHOLDER_PHONE}` };
            }
            if (!REGEX.EMAIL.test(_email)) {
                _error = { ..._error, email: `${INVALID_FORMAT}: ${PLACEHOLDER_EMAIL}` };
            }
            if (!WHITE_LIST.has(_username)) {
                _error = { ..._error, network: 'Contact al@acquilingua.com to ask about alpha testing.' };
            }
            if (Object.keys(_error)?.length) {
                setErrorRef.current(_error);
                return;
            }
            setErrorRef.current({});

            const result = await Auth.register(_username, _password, _email, _language);
            if (Validate.isValid(result)) {
                if (Validate.isValid(result?.error) && Validate.isValidNonEmptyString(result.error?.message)) {
                    Console.LOG(`${NAME}.onRegister error`, { _username, _password: _password?.length, _phone, _email, _language, result });
                    var _error = {};
                    switch (result.error.message) {
                        case USER_ALREADY_EXISTS:
                            _error = { ..._error, username: __USER_ALREADY_EXISTS__ };
                            break;
                        case INVALID_PASSWORD_SHORT:
                            _error = { ..._error, password: __INVALID_PASSWORD_SHORT__ };
                            break;
                        default:
                            break;
                    }
                    if (Object.keys(_error).length) {
                        setErrorRef.current(_error);
                    }
                } else {
                    Console.log(`${NAME}.onRegister`, { _username, _password: _password?.length, _email, _phone, _language, result });
                    setCodeSentRef.current(true);
                }
            }
        },
        [
            setCodeSentRef,
            setErrorRef,
        ],
    );

    const onResend = useCallback(
        async (_username) => {
            var result = await Auth.resendRegister(_username);
            if (Validate.isValid(result)) {
                if (Validate.isValid(result?.error) && Validate.isValidNonEmptyString(result.error?.message)) {
                    Console.LOG(`${NAME}.onResend error`, { _username, result });
                } else {
                    Console.log(`${NAME}.onResend`, { _username, result });
                    setCodeSentRef.current(true);
                }
            }
        },
        [
            setCodeSentRef,
        ],
    );

    const onConfirm = useCallback(
        async (_username, _code) => {
            const result = await Auth.confirmRegister(_username, _code);
            Console.log(`${NAME}.onConfirm`, { _username, _code, result });
            if (Validate.isValid(result)) {
                if (!Validate.isValid(result?.error) && Validate.isValidNonEmptyString(result.error?.message)) {
                    var _error = {};
                    switch (result.error.message) {
                        case INVALID_CODE:
                            _error = { ..._error, code: __INVALID_CODE__ };
                            break;
                        default:
                            break;
                    }
                    if (Object.keys(_error).length) {
                        setErrorRef.current(_error);
                    }
                } else {
                    Navigate(navigation, t(LOGIN), { title: t(LOGIN), username: _username });
                }
            }
        },
        [
            t,
            navigation,
            setErrorRef,
        ],
    );

    const onCancel = useCallback(
        () => {
            setCodeSentRef.current(false);
            setPasswordRef.current('');
            setConfirmPasswordRef.current('');
            setCodeRef.current('');
        },
        [
            setCodeSentRef,
            setPasswordRef,
            setConfirmPasswordRef,
            setCodeRef,
        ],
    );

    Console.stack(NAME, props, { /*kbVisible,*/ username, email, phone, language, password: password?.length, match: confirmPassword?.length });

    return useMemo(
        () => {
            const allowPassword = username?.length && email?.length && phone?.length ? true : false;
            const allowConfirmPassword = allowPassword && password?.length ? true : false;
            const allowRegister = allowConfirmPassword && password.length === confirmPassword?.length ? true : false;
            Console.log(`${NAME} render`, { allowPassword, allowConfirmPassword, allowRegister, username, email, phone, language, password: password?.length, confirmPassword: confirmPassword?.length, code });
            return (
                <Screen
                    {...props}
                    value={NAME}
                    headerHeight={'20%'}
                    headerStyle={styles.header}
                    headerView={(
                        <>
                            <Text
                                value={codeSent ? CONFIRM_MESSAGE : REGISTER_MESSAGE}
                            />
                            <Text
                                value={error?.network}
                                variant={TEXT_VARIANTS.ERROR}
                            />
                        </>
                    )}
                    mainHeight={'50%'}
                    mainStyle={styles.main}
                    mainView={(
                        <>
                            <Input
                                editable={!codeSent}
                                labelContainerStyle={styles.labelContainer}
                                variant={INPUT_VARIANTS.OUTLINED}
                                title={USERNAME}
                                placeholder={PLACEHOLDER_NAME}
                                value={username}
                                onChangeText={setUsernameRef.current}
                                errorMessage={error?.username}
                            />
                            <Input
                                editable={!codeSent}
                                labelContainerStyle={styles.labelContainer}
                                variant={INPUT_VARIANTS.OUTLINED}
                                title={PHONE}
                                placeholder={PLACEHOLDER_PHONE}
                                value={phone}
                                onChangeText={setPhoneRef.current}
                                errorMessage={error?.phone}
                                keyboardType={'phone-pad'}
                            />
                            <Input
                                editable={!codeSent}
                                labelContainerStyle={styles.labelContainer}
                                variant={INPUT_VARIANTS.OUTLINED}
                                title={EMAIL}
                                placeholder={PLACEHOLDER_EMAIL}
                                value={email}
                                onChangeText={setEmailRef.current}
                                errorMessage={error?.email}
                                keyboardType={'email-pad'}
                            />
                            {Optional(codeSent, (
                                <>
                                    <Text
                                        value={CODE_SENT}
                                        variant={TEXT_VARIANTS.INFO}
                                        color={Colors.colors.red}
                                    />
                                    <Input
                                        labelContainerStyle={styles.labelContainer}
                                        variant={INPUT_VARIANTS.OUTLINED}
                                        title={CODE}
                                        placeholder={PLACEHOLDER_CODE}
                                        value={code}
                                        onChangeText={setCodeRef.current}
                                        errorMessage={error?.code}
                                        keyboardType={'phone-pad'}
                                    />
                                </>
                            ), (
                                <>
                                    <Input
                                        editable={allowPassword}
                                        labelContainerStyle={styles.labelContainer}
                                        variant={INPUT_VARIANTS.OUTLINED}
                                        title={PASSWORD}
                                        placeholder={PLACEHOLDER_PASSWORD}
                                        value={password}
                                        onChangeText={v => {
                                            setPasswordRef.current(v);
                                            setConfirmPasswordRef.current('');
                                        }}
                                        errorMessage={error?.password}
                                        password={true}
                                    />
                                    <Input
                                        editable={allowConfirmPassword}
                                        labelContainerStyle={styles.labelContainer}
                                        variant={INPUT_VARIANTS.OUTLINED}
                                        title={REENTER_PASSWORD}
                                        placeholder={PLACEHOLDER_REENTER_PASSWORD}
                                        value={confirmPassword}
                                        onChangeText={setConfirmPasswordRef.current}
                                        errorMessage={error?.confirmPassword}
                                        password={true}
                                    />
                                </>
                            ))}
                        </>
                    )}
                    footerHeight={'30%'}
                    footerStyle={styles.footer}
                    footerView={Optional(codeSent, (
                        <>
                            <Button
                                disabled={!code?.length}
                                style={[code?.length ? styles.button : null]}
                                value={CONFIRM_CODE}
                                onPress={() => onConfirm(username, code)}
                            />
                            <View
                                style={styles.buttonRow}
                            >
                                <Button
                                    style={styles.button}
                                    value={RESEND_CODE}
                                    onPress={() => onResend(username)}
                                />
                                <Button
                                    style={styles.button}
                                    value={CANCEL}
                                    onPress={() => onCancel()}
                                />
                            </View>
                        </>
                    ), (
                        <Button
                            disabled={!allowRegister}
                            style={[allowRegister ? styles.button : null]}
                            value={REGISTER}
                            onPress={() => onRegister(username, password, confirmPassword, phone, email, language)}
                        />
                    ))}
                />
            );
        },
        [
            //t,
            //navigation,
            error,
            props,
            onRegister,
            onResend,
            onConfirm,
            onCancel,
            username,
            password,
            confirmPassword,
            email,
            phone,
            language,
            code,
            setUsernameRef,
            setPasswordRef,
            setConfirmPasswordRef,
            setEmailRef,
            setPhoneRef,
            setCodeRef,
            codeSent,
        ],
    );
};

/*
                            <Button
                                diabled={true}
                                style={styles.button}
                                value={CONFIRM_REGISTER}
                                onPress={() => Navigate(navigation, t(CONFIRM_REGISTER), { title: t(CONFIRM_REGISTER) })}
                            />
*/

const styles = StyleSheet.create({
    header: {
        width: '80%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    main: {
        width: '80%',
        //alignItems: 'center',
        //justifyContent: 'space-evenly',
        height: '100%',
        justifyContent: 'center',
    },
    footer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    labelContainer: {
    },
    buttonRow: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-evenly',
    },
    button: {
        backgroundColor: Colors.colors.lightgreen,
    },
});
