import React from 'react';
import { G, Path } from '../svg';

export const NasalColor = dark => dark ? '#0ff' : '#00f';
export const VoiceColor = dark => dark ? '#ff0' : '#fa0';
export const TongueColor = dark => dark ? '#0f0' : '#0a0';
export const LipsColor = dark => '#f00';

const BACKGROUND_STROKE_WIDTH = 2;
const STROKE_WIDTH = 5;

// http://smu-facweb.smu.ca/~s0949176/sammy/
// http://slideplayer.com/slide/13882519/
// http://web.uvic.ca/ling/resources/ipa/charts/IPAlab/IPAlab.htm
// https://www.nature.com/articles/s41598-020-58103-6

const Bottom =
  // front neck, chin, up to lips
    'M 384.3,356.8 '
  + 'C 383.2,355.6,376.5,346.2,369.5,336.1 '
  + 'C 346.3,302.6,343.1,298.5,336.6,294.4 '
  + 'C 330.8,290.7,323.2,283.2,320.5,278.7 '
  + 'C 318.6,275.4,317.8,271.3,316.1,257.2 '
  + 'C 309.3,199.1,305.3,177.7,298.9,165.1 '
  + 'C 296.4,160.2,291,158.3,276,156.7 '
  + 'C 272.4,156.3,260.3,155,249,153.6 '
  + 'C 237.7,152.3,221.3,150.5,212.5,149.6 '
  + 'C 159.9,144.5,127.9,140.3,120.4,137.5 '
  + 'C 113.8,135.1,112.8,133.5,105,114 '
  + 'C 104,111.5,101.7,105.9,99.8,101.5 '
  + 'C 96.6,94,94.9,92.6,95.6,81.8 '
  + 'L 96,74.8 '
  + 'L 96.5,70.5 '
  + 'L 103.4,60.7 '
  + 'L 110.3,50.9 '
  + 'L 109.3,32.7 '
  + 'C 108.4,19.4,107.6,12.8,106.1,8 '
  + 'C 104.6,3.3,104,0.2,104.3,-0.6 '
  // back of neck (bottom)
  + 'M 468.4,-0.6 '
  + 'C 471.1,-1.1,470.3,-0.1,473,22 '
  + 'C 474.1,31.1,475.7,41.7,476.5,45.6 '
  + 'C 478.5,55.7,497.5,99.4,527,162 '
  + 'C 540.5,190.6,542.4,195.1,542.8,200.1 '
  // front throat
  + 'M 427,8.3 '
  + 'C 427,25,422.6,48.2,415,71.6 '
  + 'C 410.7,84.7,410.6,85.4,412,84.5 '
  + 'C 412.5,84.2,413.7,81.6,414.6,78.7 '
  + 'C 415.5,75.8,416.6,73,417,72.5 '
  + 'C 417.4,72,418.7,68.8,420,65.5 '
  + 'C 423.4,56.7,430.8,42.5,433.5,39.6 '
  + 'C 435.4,37.6,436.9,37,440.3,37 '
  + 'C 445.3,37,446,37.9,446,43.6 '
  + 'C 446,48.2,431.2,86.7,423.5,102.5 '
  + 'C 420.7,108.1,409,137.8,409,139.2 '
  + 'C 409,139.7,407.3,144.7,405.1,150.3 '
  + 'C 400.1,163.6,395.3,178.2,388.6,201 '
  + 'C 379.5,232,370,267.2,370,270.2 '
  + 'L 375.1,281.6 '
  + 'C 375.1,284.5,376.3,285.8,406,317.9 '
  + 'C 426.5,340,437,353.3,437,357.2 '
  // back throat
  + 'M 525.6,359 '
  + 'C 522.4,359,515.3,344.2,486.6,277.7 '
  + 'C 475.9,252.6,461.1,221.5,460.1,221.8 '
  + 'L 457.4,209.2 '
  + 'C 457.7,209.9,457.7,209.3,457.5,207.9 '
  + 'C 456.3,201.6,455.1,186.7,455.1,179.4 '
  + 'C 455.1,166,460,161.2,468.7,165.9 '
  + 'C 481.6,172.7,528,229.6,539.7,252.8 '
;

const Back =
    'M 14.5,-0.2 '
  + 'C 15.9,-0.2,17,0.4,17,0.9 '
  + 'C 17,2.6,27.1,81.7,28,87.5 '
  + 'C 28.5,90.8,30.8,108.9,33,127.7 '
  + 'C 35.2,146.5,37.7,165.1,38.5,169 '
  + 'C 39.3,172.9,40,176.3,40,176.6 '
  + 'C 40,176.8,38.9,177,37.7,177 '
;

const Top =
  // nose
    'M 47.5,203.9 '
  + 'L 37,203.9 '
  + 'C 26.7,203.9,26.4,203.9,19.5,200.1 '
  + 'C 15.1,197.8,11.3,194.8,9.3,192.1 '
  + 'C 3.8,185,4.3,182.8,16.9,157.6 '
  + 'C 28,135.6,41.8,113.4,51.9,101.5 '
  + 'C 54.7,98.2,58.9,93.2,61.2,90.3 '
  + 'C 68.3,81.8,90.8,45.5,98.8,30 '
  + 'C 106.2,15.6,108.5,12,110.2,12 '
  // nasal cavity top
  + 'M 23.6,157.2 '
  + 'C 24.7,158.9,28.6,158.2,41.3,154.2 '
  + 'C 61.3,147.6,64.3,144.6,81.7,114 '
  + 'C 87.1,104.4,101.1,83.2,106.3,76.5 '
  + 'C 121.4,57.3,161.1,38,204.5,28.8 '
  + 'C 215.2,26.5,216.9,26.5,266.5,26.5 '
  + 'C 310.6,26.5,318.7,26.7,326.5,28.2 '
  + 'C 338.8,30.5,342.2,31.6,346,34.2 '
  + 'C 350.5,37.3,356.6,48.9,360.2,61.2 '
  + 'C 364.3,75.3,369,84.8,374.8,90.4 '
  + 'C 377.4,92.9,385.6,99.1,393,104.1 '
  + 'C 407.8,114.1,415.4,120.9,420.2,128.3 '
  + 'C 426.3,137.7,436.7,165.5,442.4,187.1 '
  + 'C 446.7,203.3,446.7,206.2,443.8,205.8 '
  // nasal cavity bottom
  + 'M 62,203.5 '
  + 'C 62,202.3,78.3,182.9,81.8,179.9 '
  + 'C 91.7,171.5,104.8,165.2,120.5,161.5 '
  + 'C 130.1,159.2,178.6,153,195.2,151.9 '
  + 'C 232.1,149.5,307.7,151,326.5,154.5 '
  + 'C 330.9,155.3,345.1,157.6,358,159.5 '
  + 'L 413.1,168.3 '
;

const TopNasal =
    'M 413.1,168.3 '
  + 'L 418.9,173.9 '
  + 'C 425.5,180.5,433,197.4,433,203.3 '
  + 'C 433,206.5,432.7,204,430.5,204 '
;

const BackNasal =
    'M 1.4 0 '
  + 'C 4.9,-0.4,4,0,6.6,15 '
  + 'C 8.1,24.3,8.4,33.1,8.4,49.5 '
  + 'C 8.5,72.8,7.6,82.3,5.2,88 '
  + 'C 4.3,89.9,3.7,91.8,3.8,92.2 '
  + 'C 3.9,92.6,0.4,93.4,1.9,94.1 '
;

const TopOral =
    'M 413.1,168.3 '
  + 'L 423.7,171 '
  + 'L 440,180 '
  + 'L 442,182 '
  + 'L 443,190 '
  + 'L 454,260 '
;

const BackOral =
    'M 25.4,60.1 '
  + 'L 20.4,75.1 '
  + 'C 19.7,76.4,19.1,78.5,19,79.7 '
  + 'C 18.9,83.2,14.6,87.5,7,91.4 '
  + 'L 0,95.1 '
;

const VibrationOn =
    'M 457.4,209.2 '
  + 'C 459,218.7,456.6,215.5,453.6,212.4 '
  + 'C 449.5,208.3,447.4,207,445.3,207 '
  + 'C 439.6,207,438.7,210.4,442.5,217.6 '
  + 'C 446.1,224.3,445.9,230.7,442.2,234.3 '
  + 'C 437.4,238.9,431.8,237.7,423.8,230.5 '
  + 'C 418.7,225.9,414.6,224.8,412.2,227.2 '
  + 'C 410.3,229.1,410.8,232,414.1,239 '
  + 'C 416.5,244.1,417.1,246.4,416.7,249.5 '
  + 'C 416.1,254.4,412.1,258,407.2,258 '
  + 'C 403,258,400.7,256.8,394.7,251.4 '
  + 'C 389.6,246.8,385.4,245.8,383.1,248.5 '
  + 'C 381.5,250.5,382.5,255.5,385.7,261.7 '
  + 'C 390.3,270.4,386.7,279,378.3,279.1 '
  + 'L 375.1,281.6 '
;

const VibrationOff =
    'M 457.4,209.2 '
  + 'L 375.1,281.6 '
;

const TopTooth =
    'M 133.4, 51.5, '
  + 'L 133.8, 56.7, '
  + 'C 134.2, 63.5, 131.7, 69.3, 125.4, 76, '
  + 'C 120.6, 81.1, 118.7, 82, 117.3, 79.8, '
  + 'C 116.9, 79.1, 116.6, 70.5, 116.8, 60.8, '
  + 'C 117, 51, 116.9, 43, 116.7, 43, '
;

const BottomTooth =
    'M 4.6, 150.1, '
  + 'C 1.1, 145.6, 0, 143.3, 0, 140.7, '
  + 'C 0, 138.7, 0.5, 137, 1.2, 136.8, '
  + 'C 2, 136.5, 2.6, 132.4, 2.9, 124.7, '
  + 'C 3.4, 115.4, 3.9, 112.7, 5.3, 111.3, '
  + 'C 8, 108.6, 9.8, 110.4, 16.8, 123.1, '
  + 'C 20.3, 129.4, 23.8, 135.2, 24.6, 136, '
;

const Pallet =
    'M 296, 91.9,  '
  + 'C 296, 96.4, 290.3, 97.6, 285.8, 94, '
  + 'C 282.6, 91.6, 276.3, 79.3, 272, 67.5, '
  + 'C 267.2, 54.3, 262.7, 45.8, 258.3, 41.9, '
  + 'C 246.6, 31.4, 212.8, 16.9, 188.5, 12.1, '
  + 'C 166.2, 7.7, 156.4, 6.8, 136.5, 7.3, '
  + 'C 111, 7.9, 93.4, 11.5, 74.8, 19.9, '
  + 'C 64, 24.7, 57, 30.1, 45.4, 42.4, '
  + 'C 32, 56.8, 30.5, 57.5, 15, 56.8, '
  + 'C 4, 56.2, 3.9, 56.3, 1.9, 58.8, '
;

const DFTongueTip =
    'M 134, 80.5, '
  + 'C 134, 83, 133.9, 83, 126.2, 83, '
  + 'C 117.2, 83, 114, 84.5, 114, 88.8, '
  + 'C 114, 93.2, 115, 95.4, 117.3, 96.5, '
  + 'C 118.5, 97.1, 121.3, 98.5, 123.5, 99.7, '
  + 'C 125.7, 100.9, 129, 102.4, 130.8, 103, '
  + 'C 133.2, 103.9, 134, 104.9, 134, 106.6, '
;

const DFTongueTipFric =
    'M 134, 80.4, '
  + 'C 134, 82.9, 134, 83, 127, 82.5, '
  + 'L 125, 82.1, '
  + 'L 126.5, 85.7, '
  + 'C 127.3, 87.6, 128, 91.2, 128, 93.6, '
  + 'C 128, 97.4, 128.3, 98, 130, 98, '
  + 'C 132.7, 98, 134.5, 102.9, 134.5, 105.5, '
  + 'C 134.5, 107.2, 134, 107.3, 134.4, 107.9, '
;

const LipsSpread =
  // lower
    'M 105.8, 177, '
  + 'C 104.1, 176.9, 102.5, 175.3, 99.5, 170.4, '
  + 'C 97.3, 166.8, 94, 162.4, 92.2, 160.7, '
  + 'C 90.4, 158.9, 87.3, 155.5, 85.4, 153.1, '
  + 'C 82.2, 149, 82, 148.3, 82, 140.9, '
  + 'C 82, 133.6, 82.2, 132.8, 85, 129.7, '
  + 'C 88.6, 125.6, 98.1, 121.1, 105, 120.3, '
  + 'C 109.3, 119.8, 110.9, 120.2, 116.2, 122.8, '
  + 'C 119.7, 124.5, 125.1, 128.2, 128.3, 131, '
  + 'C 133.3, 135.5, 134, 136.6, 134, 139.9, '
  // upper
  + 'M 116.7, 43, '
  + 'C 116.4, 43, 111.8, 47.1, 106.3, 52.1, '
  + 'C 88.9, 68.3, 77.1, 71.2, 68.1, 61.6, '
  + 'L 65.7, 59, '
  + 'L 66.6, 44.3, '
  + 'C 67.9, 21.5, 67.7, 16, 65.6, 12.7, '
  + 'C 62.9, 8.6, 59.6, 7.3, 43.2, 3.5, '
;

const LipsRounded =
    'M 103, 176.1, '
  + 'C 103, 175.6, 100.9, 172.4, 98.3, 168.9, '
  + 'C 95.7, 165.4, 92.9, 161.2, 92, 159.5, '
  + 'C 91.2, 157.9, 88.4, 155, 85.8, 153, '
  + 'C 80.7, 149.2, 66, 143, 62.1, 143, '
  + 'C 60.8, 143, 58.2, 141.9, 56.3, 140.4, '
  + 'C 53.3, 138.1, 53, 137.4, 53, 132.6, '
  + 'C 53, 125.6, 57.1, 117.4, 62, 114.6, '
  + 'C 67, 111.7, 82.7, 111.1, 90.2, 113.5, '
  + 'C 102.8, 117.7, 115.8, 123.5, 120.8, 127.4, '
  + 'C 123.7, 129.6, 127.9, 132.8, 130.1, 134.3, '
  + 'C 133.3, 136.6, 134, 137.7, 134, 140.5, '

  + 'M 117.1, 42.8, '
  + 'L 114.3, 44.8, '
  + 'C 112.8, 45.9, 109.7, 48.7, 107.6, 50.9, '
  + 'C 105.4, 53.1, 103.3, 54.7, 102.8, 54.4, '
  + 'C 102.3, 54.1, 99.7, 55.3, 96.8, 57, '
  + 'C 92, 60, 91, 60.2, 79.1, 60.7, '
  + 'C 72.2, 61, 63, 61, 58.8, 60.6, '
  + 'C 51.7, 59.9, 50.7, 59.5, 46.5, 55.8, '
  + 'C 42.2, 52, 42, 51.6, 42, 46.5, '
  + 'C 42, 41.8, 42.3, 40.9, 44.8, 39.3, '
  + 'C 48.6, 36.8, 53.5, 34.6, 60.1, 32.5, '
  + 'C 67.1, 30.3, 68.5, 28.2, 67.7, 20.6, '
  + 'C 67.1, 14.3, 64.9, 10.5, 60.8, 8.4, '
  + 'C 59.2, 7.6, 51.3, 5.3, 43.2, 3.5, '
;

const LipsBLStop =
// bottom outside end
    'M 103, 176.1, '
  + 'C 103, 175.6, 100.9, 172.4, 98.3, 168.9, '
  + 'C 95.7, 165.4, 92.8, 161.2, 91.9, 159.5, '
  + 'C 87.8, 152.1, 79, 133.8, 78.4, 131.5, '
  + 'C 78.1, 130.1, 77.4, 128.7, 76.9, 128.4, '
  + 'C 76.4, 128.1, 76, 126.8, 76, 125.5, '
  + 'C 76, 124.2, 75.7, 123, 75.3, 122.8, '
  + 'C 74.3, 122.4, 70, 113.9, 70, 112.5, '
  + 'C 70, 112, 68.6, 108.8, 67, 105.6, '
  + 'C 63.8, 99.3, 62.9, 91.5, 65.2, 89.2, '
  + 'C 66.1, 88.3, 65.9, 87.5, 64.2, 85.7, '
  + 'C 61.9, 83.2, 61.4, 80, 62.9, 77.2, '
  + 'C 63.4, 76.2, 64.1, 72.7, 64.3, 69.5, '
  + 'C 64.6, 66.2, 65.5, 57.9, 66.5, 51, '
  + 'C 67.6, 43.1, 68, 34.3, 67.8, 27, '
  + 'C 67.3, 9.6, 66.5, 8.9, 43.2, 3.5, '
  // top outside

  // top inside
  + 'M 117.3, 42.6, '
  + 'L 114.2, 44.9, '
  + 'C 110.2, 47.7, 105, 54, 105, 56, '
  + 'C 105, 56.8, 104.4, 59.5, 103.6, 62, '
  + 'C 102.8, 64.5, 101.2, 69.9, 100, 74, '
  + 'C 98.5, 79.6, 97.1, 82.4, 94.4, 85.1, '
  + 'L 90.8, 88.8, '
  + 'L 92.8, 91.8, '
  + 'C 94, 93.4, 95.6, 95.3, 96.5, 96, '
  + 'C 98.5, 97.7, 104, 105.8, 104, 107.1, '
  + 'C 104, 109.9, 123.2, 129.9, 130.3, 134.6, '
  + 'C 133.5, 136.7, 134, 137.5, 134, 140.8, '
  + 'C 133.9, 143.9, 133.8, 144.2, 132.9, 142.7, '
  // bottom inside

  // touching
  + 'M 92.8, 91.8, '
  + 'L 65.2, 89.2, '
;

const LipsBLFric =
    'M 103, 176.1, '
  + 'C 103, 175.6, 100.9, 172.4, 98.3, 168.9, '
  + 'C 95.7, 165.4, 92.8, 161.2, 91.9, 159.5, '
  + 'C 87.8, 152.1, 79, 133.8, 78.4, 131.5, '
  + 'C 78.1, 130.1, 77.4, 128.7, 76.9, 128.4, '
  + 'C 76.4, 128.1, 76, 126.8, 76, 125.4, '
  + 'C 76, 124.1, 75.6, 123, 75.2, 123, '
  + 'C 73.7, 123, 71.8, 113.9, 72.3, 109.3, '
  + 'C 72.7, 105.7, 73.6, 103.9, 76.9, 100.6, '
  + 'L 81, 96.5, '
  + 'L 89.8, 96.2, '
  + 'C 99.9, 95.8, 101.5, 96.7, 103.1, 104, '
  + 'C 103.7, 106.4, 105.8, 110.4, 108.3, 113.3, '
  + 'C 114, 120.3, 125.7, 131.6, 130.3, 134.6, '
  + 'C 133.5, 136.7, 134, 137.5, 134, 140.7, '

  + 'M 70.5, 81.1, '
  + 'C 68.9, 80.1, 66.7, 78.4, 65.7, 77.2, '
  + 'C 63.6, 74.9, 63.7, 71.5, 66.5, 51, '
  + 'C 67.6, 43.1, 68, 34.3, 67.8, 27, '
  + 'C 67.3, 9.6, 66.5, 8.9, 43.2, 3.5, '

  + 'M 117.3, 42.6, '
  + 'L 114.2, 44.9, '
  + 'C 110.2, 47.7, 105, 54, 105, 56, '
  + 'C 105, 56.8, 104.4, 59.5, 103.6, 62, '
  + 'C 102.8, 64.5, 101.5, 68.7, 100.9, 71.4, '
  + 'C 100.2, 74.1, 98.3, 77.8, 96.7, 79.7, '
  + 'L 93.8, 83, '
  + 'L 83.6, 83, '
  + 'C 75.5, 83, 72.9, 82.6, 70.5, 81.1, '
;

const LipsLD =
    'M 102, 173.3, '
  + 'C 98.3, 159.6, 91.7, 124.8, 90.4, 111.4, '
  + 'C 89.9, 105.2, 90.1, 103.1, 92.4, 96.3, '
  + 'C 96.2, 84.9, 103.1, 79, 112.7, 79, '
  + 'L 116.5, 79, '

  + 'M 116.7, 43, '
  + 'C 116.4, 43, 111.8, 47.1, 106.3, 52.1, '
  + 'C 88.9, 68.3, 77.1, 71.2, 68.1, 61.6, '
  + 'L 65.7, 59, '
  + 'L 66.6, 44.3, '
  + 'C 67.9, 21.5, 67.7, 16, 65.6, 12.7, '
  + 'C 62.9, 8.6, 59.6, 7.3, 43.2, 3.5, '

  + 'M 121.1, 80.3, '
  + 'L 124.2, 84, '
  + 'C 125.9, 86, 127.9, 89.7, 128.7, 92.1, '
  + 'C 130.4, 97.7, 132.3, 121.3, 131.4, 126.3, '
  + 'C 131.1, 128.6, 131.3, 130.6, 132, 131.5, '
  + 'C 132.6, 132.2, 133.4, 135.5, 133.7, 138.7, '
  + 'L 134.3, 144.5, '

  + 'M 105.7, 177, '
  + 'C 104.5, 177, 103.7, 177.7, 103.2, 176.9, '
  + 'C 102.7, 176.3, 102.4, 174.6, 102, 173.3, '
;

const Tongue00 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 288.9, 171.2, 287.9, 164.5, 286.6, 158.3, '
  + 'C 284.5, 148.1, 283.8, 146.5, 278.5, 138.4, '
  + 'C 264.5, 117.5, 256.8, 113, 217.6, 103.5, '
  + 'C 191, 97.1, 176.6, 94.3, 153.5, 91.5, '
  + 'C 142.7, 90.1, 134.6, 89.9, 106.5, 90.4, '
  + 'C 49.4, 91.5, 44, 91.9, 39.1, 96, '
  + 'C 36.3, 98.4, 35.9, 99.2, 36.5, 101.8, '
  + 'C 36.8, 103.4, 38.1, 105.6, 39.3, 106.7, '
  + 'C 41.9, 109.1, 50.8, 114, 52.4, 114, '
  + 'C 53, 114, 54.7, 114.6, 56.2, 115.4, '
  + 'C 58.9, 116.8, 63.6, 118.4, 68.2, 119.4, '
  + 'C 71.7, 120.2, 77, 124.7, 77, 127, '
  + 'C 77, 129.4, 67.9, 137.4, 61.6, 140.5, '
  + 'C 57.3, 142.6, 55.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue11 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 287.8, 161.1, 285.3, 149.5, 283.3, 145.7, '
  + 'C 281.6, 142.3, 281.2, 141.9, 270.7, 130.9, '
  + 'C 259.9, 119.7, 243.2, 109.5, 227.2, 104.6, '
  + 'C 221.3, 102.7, 214.2, 100.3, 211.4, 99.2, '
  + 'C 206.4, 97.2, 186.3, 93.2, 170, 91, '
  + 'C 165.3, 90.3, 157.5, 88.7, 152.7, 87.4, '
  + 'C 147.2, 85.9, 138.7, 84.6, 130.2, 83.9, '
  + 'C 122.7, 83.4, 112.5, 82.5, 107.5, 81.9, '
  + 'C 91.4, 80.2, 52.8, 79.6, 34.7, 80.8, '
  + 'C -1.2, 83.2, 0, 83.2, 0, 80.5, '

  + 'M 0, 107.1, '
  + 'C 0, 104.3, 0.1, 104.2, 3.3, 105, '
  + 'C 6, 105.7, 21.3, 106.7, 40.5, 107.5, '
  + 'C 46.2, 107.8, 49.3, 109.3, 52.7, 113.7, '
  + 'C 56.4, 118.3, 57.4, 121.4, 57.1, 126, '
  + 'C 56.7, 130.9, 54.7, 134.9, 50.6, 139.3, '
  + 'C 47.4, 142.6, 46.4, 143, 42.2, 143, '
  + 'C 39.6, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue12 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 288.9, 171.2, 287.9, 164.5, 286.6, 158.3, '
  + 'C 284.5, 148.1, 283.8, 146.5, 278.5, 138.4, '
  + 'C 264.5, 117.5, 256.8, 113, 217.6, 103.5, '
  + 'C 187.6, 96.2, 169.6, 93, 155.8, 92.5, '
  + 'C 144.8, 92.1, 116.1, 88.6, 105, 86.4, '
  + 'C 95.4, 84.4, 73.3, 77.3, 55.7, 70.4, '
  + 'C 39.4, 64, 35.1, 63.6, 27.5, 67.8, '
  + 'C 21.4, 71.1, 20.9, 73.3, 24, 84.8, '
  + 'C 25.6, 90.9, 27.1, 93.8, 30, 97.1, '
  + 'C 32.2, 99.5, 34, 101.8, 34, 102.2, '
  + 'C 34, 102.5, 36.4, 104.3, 39.3, 106, '
  + 'C 51.6, 113.6, 57.6, 120.6, 58, 128, '
  + 'C 58.4, 134.2, 58.1, 135.1, 54.2, 139.1, '
  + 'C 50.5, 142.8, 50.1, 143, 43.9, 143, '
  + 'C 40.4, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue13 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 288.9, 171.2, 287.9, 164.5, 286.6, 158.3, '
  + 'C 284.5, 148.1, 283.8, 146.5, 278.5, 138.4, '
  + 'C 264.5, 117.5, 256.8, 113, 217.6, 103.5, '
  + 'C 183.5, 95.2, 156.8, 91, 129, 89.5, '
  + 'C 123.2, 89.2, 111.3, 88.3, 102.5, 87.4, '
  + 'C 87, 85.9, 86.2, 85.7, 78.6, 81.7, '
  + 'C 73.3, 78.9, 69.8, 76.2, 67.9, 73.7, '
  + 'C 64.9, 69.5, 60, 56.1, 60, 51.8, '
  + 'C 60, 48.4, 58.3, 47.3, 56.2, 49.4, '
  + 'C 53.3, 52.2, 53.2, 78.6, 56, 93, '
  + 'C 59.1, 109.4, 61.5, 113.3, 73.9, 122.8, '
  + 'C 75.6, 124.1, 77, 126, 77, 127, '
  + 'C 77, 129.4, 67.9, 137.3, 61.6, 140.5, '
  + 'C 57.3, 142.6, 55.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue14 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 288.9, 171.2, 287.9, 164.5, 286.6, 158.3, '
  + 'C 284.5, 148.1, 283.8, 146.4, 278.5, 138.5, '
  + 'C 271.1, 127.4, 264.3, 120.5, 255, 114.9, '
  + 'C 250.9, 112.4, 245, 108.9, 242, 107, '
  + 'C 236.4, 103.6, 232.6, 101.5, 223, 96.2, '
  + 'C 216.4, 92.6, 195.5, 84.8, 177, 79, '
  + 'C 169.6, 76.7, 162.8, 74.1, 161.9, 73.4, '
  + 'C 161, 72.6, 155.3, 69.8, 149.2, 67.1, '
  + 'C 143.1, 64.4, 129.4, 57.7, 118.8, 52.2, '
  + 'C 108.2, 46.8, 95.4, 40.7, 90.4, 38.6, '
  + 'C 76.5, 33.1, 71.9, 33.7, 62.9, 42.1, '
  + 'C 60.9, 44, 57.8, 46.7, 55.9, 48.1, '
  + 'C 53.6, 49.9, 51.4, 53.3, 49, 58.6, '
  + 'C 47.1, 63, 43.9, 69.4, 41.8, 73, '
  + 'C 37, 81.2, 36.7, 87.3, 40.6, 96, '
  + 'C 43, 101.4, 43.7, 102.1, 49.8, 105.1, '
  + 'C 53.4, 106.8, 59.6, 111.2, 63.6, 114.6, '
  + 'C 67.6, 118.1, 71.3, 121, 71.8, 121, '
  + 'C 73.2, 121, 77, 125.5, 77, 127.2, '
  + 'C 77, 129.4, 67.6, 137.5, 61.6, 140.5, '
  + 'C 57.3, 142.6, 55.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue15 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 285.6, 142.3, 277.1, 121, 262.3, 105, '
  + 'C 257.9, 100.3, 252.5, 93.8, 250.3, 90.5, '
  + 'C 247.9, 87, 238.1, 76.8, 226.6, 66, '
  + 'C 215.8, 55.8, 205.1, 45.4, 203, 42.8, '
  + 'C 200.7, 40.2, 197.2, 37.4, 194.8, 36.3, '
  + 'C 192.4, 35.3, 186.9, 32.7, 182.4, 30.4, '
  + 'C 178, 28.1, 171.9, 25.5, 168.9, 24.6, '
  + 'C 154.9, 20.3, 130.6, 18.8, 118.4, 21.3, '
  + 'C 107.8, 23.5, 104.8, 25.9, 69.1, 60.3, '
  + 'C 59, 70, 54, 77.4, 54, 82.4, '
  + 'C 54.1, 88.7, 57.1, 94.3, 63.2, 99, '
  + 'C 72.5, 106.3, 78.5, 115.8, 77, 121, '
  + 'C 76.6, 122.5, 76.4, 124.8, 76.7, 126.2, '
  + 'C 77.1, 128.2, 76.2, 129.6, 71.8, 133.4, '
  + 'C 62.9, 141.2, 58.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue16 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 289, 170.8, 283.6, 156.3, 276.6, 141.5, '
  + 'C 272.1, 131.9, 268.8, 122.9, 266.1, 113, '
  + 'C 262.6, 100.1, 252.7, 69.5, 247.3, 55.2, '
  + 'C 245.8, 51.3, 243.8, 48.6, 239.9, 45.2, '
  + 'C 229, 35.7, 206.1, 27, 195.1, 28.3, '
  + 'C 187.7, 29.2, 162.2, 35, 152.5, 38, '
  + 'C 139, 42.3, 121.3, 46.7, 104.4, 49.9, '
  + 'C 94, 51.9, 86.5, 54, 80.5, 56.6, '
  + 'C 53.5, 68.2, 46.3, 85.8, 63.2, 99, '
  + 'C 72.5, 106.3, 78.5, 115.8, 77, 121, '
  + 'C 76.6, 122.5, 76.4, 124.8, 76.7, 126.2, '
  + 'C 77.1, 128.2, 76.2, 129.6, 71.8, 133.4, '
  + 'C 62.9, 141.2, 58.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue17 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 288.7, 149.5, 288.5, 139.5, 288.4, 137, '
  + 'C 288.1, 130.4, 284.1, 114.2, 281.5, 109, '
  + 'C 280.3, 106.5, 278.7, 102.8, 278.1, 100.8, '
  + 'C 276.7, 96.5, 269.5, 89.5, 264.2, 87.3, '
  + 'C 260.2, 85.6, 259.2, 85.6, 187.5, 88.1, '
  + 'C 161.2, 89, 133.1, 89.9, 68, 91.8, '
  + 'C 47.9, 92.4, 45.1, 92.7, 42, 94.5, '
  + 'C 39.5, 96, 38.2, 97.6, 37.6, 100.2, '
  + 'C 36.4, 105, 38.4, 108.2, 44.8, 111.4, '
  + 'C 49.3, 113.7, 62.1, 118.1, 68.2, 119.4, '
  + 'C 71.7, 120.2, 77, 124.7, 77, 127, '
  + 'C 77, 129.4, 67.9, 137.4, 61.6, 140.5, '
  + 'C 57.3, 142.6, 55.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue21 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 288.9, 171.2, 287.9, 164.5, 286.6, 158.3, '
  + 'C 284.5, 148.1, 283.8, 146.5, 278.5, 138.4, '
  + 'C 264.5, 117.5, 256.8, 113, 217.6, 103.5, '
  + 'C 175.2, 93.2, 147, 89.3, 122, 90.4, '
  + 'C 100, 91.4, 93.5, 91.2, 80.5, 89.5, '
  + 'C 59.5, 86.6, 45.3, 82.6, 35.6, 76.8, '
  + 'C 31.7, 74.5, 27, 71.8, 25.1, 70.6, '
  + 'C 23.2, 69.5, 19, 66.5, 15.9, 63.8, '
  + 'C 11.3, 60, 9.3, 59, 6.5, 59, '
  + 'L 3, 59, '
  + 'L 3.6, 63.3, '
  + 'C 5.2, 74.7, 9.6, 84.4, 15.7, 90.1, '
  + 'C 22.8, 96.8, 49.2, 114, 52.4, 114, '
  + 'C 53, 114, 54.7, 114.6, 56.2, 115.4, '
  + 'C 58.9, 116.8, 63.6, 118.4, 68.2, 119.4, '
  + 'C 71.7, 120.2, 77, 124.7, 77, 127, '
  + 'C 77, 129.4, 67.9, 137.4, 61.6, 140.5, '
  + 'C 57.3, 142.6, 55.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue22 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 288.9, 171.2, 287.9, 164.5, 286.6, 158.3, '
  + 'C 284.5, 148.1, 283.8, 146.5, 278.5, 138.4, '
  + 'C 264.5, 117.5, 256.8, 113, 217.6, 103.5, '
  + 'C 187.6, 96.2, 169.6, 93, 155.8, 92.5, '
  + 'C 144.8, 92.1, 116.1, 88.6, 105, 86.4, '
  + 'C 92.5, 83.8, 69.9, 76.1, 64.5, 72.5, '
  + 'C 61.8, 70.7, 56.9, 68, 53.8, 66.6, '
  + 'C 50.7, 65.1, 45.9, 61.9, 43.2, 59.5, '
  + 'C 40.4, 57, 37.6, 55, 36.9, 55, '
  + 'C 36.1, 55, 32.4, 56.3, 28.5, 57.9, '
  + 'L 21.5, 60.7, '
  + 'L 21.7, 68.7, '
  + 'C 21.9, 80.5, 25.2, 91.7, 30, 97.1, '
  + 'C 32.2, 99.5, 34, 101.8, 34, 102.2, '
  + 'C 34, 102.5, 36.4, 104.3, 39.3, 106, '
  + 'C 51.6, 113.6, 57.6, 120.6, 58, 128, '
  + 'C 58.4, 134.2, 58.1, 135.1, 54.2, 139.1, '
  + 'C 50.5, 142.8, 50.1, 143, 43.9, 143, '
  + 'C 40.4, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue23 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 288.9, 171.2, 287.9, 164.5, 286.6, 158.3, '
  + 'C 284.5, 148.1, 283.8, 146.5, 278.5, 138.4, '
  + 'C 264.5, 117.5, 256.8, 113, 217.6, 103.5, '
  + 'C 183.5, 95.2, 156.8, 91, 129, 89.5, '
  + 'C 123.2, 89.2, 111.3, 88.3, 102.5, 87.4, '
  + 'C 87, 85.9, 86.3, 85.7, 78.6, 81.7, '
  + 'C 72.5, 78.4, 69.8, 76.3, 66.8, 72.3, '
  + 'C 61.8, 65.5, 56, 55.8, 56, 54.3, '
  + 'C 56, 53.6, 55.1, 50.6, 53.9, 47.5, '
  + 'C 51.5, 40.9, 49, 40.2, 46, 45.3, '
  + 'C 44.3, 47.9, 44, 50.3, 44, 59, '
  + 'C 44, 73, 46.2, 80.8, 55.1, 98.7, '
  + 'C 62.2, 113, 64, 115.3, 73.9, 122.8, '
  + 'C 75.6, 124.1, 77, 126, 77, 127, '
  + 'C 77, 129.4, 67.9, 137.3, 61.6, 140.5, '
  + 'C 57.3, 142.6, 55.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue25 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 286.5, 151.1, 281, 132.2, 274.4, 122.5, '
  + 'C 273.1, 120.5, 271.7, 117.7, 271.4, 116.2, '
  + 'C 270.8, 113.8, 268.6, 109.6, 259.9, 94.4, '
  + 'C 256.3, 88.1, 232.9, 60.5, 227.3, 56.1, '
  + 'C 224.7, 54, 218.9, 48.5, 214.5, 44, '
  + 'C 202.5, 31.7, 181.2, 16, 172.5, 13.1, '
  + 'C 163.6, 10.1, 144.8, 8.6, 127.7, 9.4, '
  + 'C 112.6, 10.1, 106.7, 12.1, 102.3, 17.9, '
  + 'C 100.8, 19.9, 96.2, 25.3, 92, 30, '
  + 'C 87.8, 34.7, 81.2, 42.8, 77.3, 48.1, '
  + 'C 73.4, 53.4, 67.8, 60.6, 64.8, 64.1, '
  + 'C 56.1, 74.4, 54, 78, 54, 82.5, '
  + 'C 54.1, 88.7, 57.2, 94.3, 63.2, 99, '
  + 'C 72.5, 106.3, 78.5, 115.8, 77, 121, '
  + 'C 76.6, 122.5, 76.4, 124.8, 76.7, 126.2, '
  + 'C 77.1, 128.2, 76.2, 129.6, 71.8, 133.4, '
  + 'C 62.9, 141.2, 58.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue26 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 287.6, 163.9, 284.9, 153.3, 282.1, 144.8, '
  + 'C 275.8, 125.6, 265.5, 84.4, 264, 72.5, '
  + 'C 263.3, 67.6, 262.1, 59.5, 261.1, 54.5, '
  + 'L 259.5, 45.5, '
  + 'L 254.5, 42.3, '
  + 'C 251.7, 40.5, 246.3, 36.8, 242.5, 34.1, '
  + 'C 238.1, 31, 231.8, 27.8, 225.8, 25.7, '
  + 'C 215, 21.8, 214, 21.8, 199, 26.5, '
  + 'C 194.9, 27.8, 184.8, 30.2, 176.5, 32, '
  + 'C 168.3, 33.7, 157.5, 36.5, 152.5, 38, '
  + 'C 139, 42.3, 121.3, 46.7, 104.4, 49.9, '
  + 'C 94, 51.9, 86.5, 54, 80.5, 56.6, '
  + 'C 53.5, 68.2, 46.3, 85.8, 63.2, 99, '
  + 'C 72.5, 106.3, 78.5, 115.8, 77, 121, '
  + 'C 76.6, 122.5, 76.4, 124.8, 76.7, 126.2, '
  + 'C 77.1, 128.2, 76.2, 129.6, 71.8, 133.4, '
  + 'C 62.9, 141.2, 58.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;

const Tongue27 =
    'M 290.9, 177, '
  + 'C 289.4, 177, 289, 176.3, 289, 173.3, '
  + 'C 289.5, 143, 289.1, 125.3, 288.4, 114.8, '
  + 'L 287.2, 96.1, '
  + 'L 283.4, 91.6, '
  + 'L 279.5, 87.2, '
  + 'L 271, 86.4, '
  + 'C 262.7, 85.7, 247.1, 86, 188, 88.1, '
  + 'C 161.3, 89, 133.9, 89.8, 68, 91.8, '
  + 'C 47.9, 92.4, 45.1, 92.7, 42, 94.5, '
  + 'C 39.5, 96, 38.2, 97.6, 37.6, 100.2, '
  + 'C 36.4, 105, 38.4, 108.2, 44.8, 111.4, '
  + 'C 49.3, 113.7, 62.1, 118.1, 68.2, 119.4, '
  + 'C 71.7, 120.2, 77, 124.7, 77, 127, '
  + 'C 77, 129.4, 67.9, 137.4, 61.6, 140.5, '
  + 'C 57.3, 142.6, 55.2, 143, 47.1, 143, '
  + 'C 41.8, 143, 35.7, 142.5, 33.5, 142, '
  + 'C 28.7, 140.9, 26.9, 142.1, 28.8, 145, '
;


export const ipa = (fore, back, dark) => {

  const gProps = {
    fill: back,
    fillOpacity: 0,
    strokeWidth: BACKGROUND_STROKE_WIDTH,
    stroke: fore,
  };

  const lipsColor = LipsColor(dark);
  const nasalColor = NasalColor(dark);
  const tongueColor = TongueColor(dark);
  const voiceColor = VoiceColor(dark);

  return {
    TopOral: {
      svg: (
        <G {...gProps} >
          <Path d={Top} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={nasalColor}
            d={TopOral}
          />
        </G>
      ),
    },
    TopNasal: {
      svg: (
        <G {...gProps} >
          <Path d={Top} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={nasalColor}
            d={TopNasal}
          />
        </G>
      ),
    },
    BackOral: {
      svg: (
        <G {...gProps} >
          <Path d={Back} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={nasalColor}
            d={BackOral}
          />
        </G>
      ),
    },
    BackNasal: {
      svg: (
        <G {...gProps} >
          <Path d={Back} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={nasalColor}
            d={BackNasal}
          />
        </G>
      ),
    },
    BottomVoiced: {
      svg: (
        <G {...gProps} >
          <Path d={Bottom} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={voiceColor}
            d={VibrationOn}
          />
        </G>
      ),
    },
    BottomVoiceless: {
      svg: (
        <G {...gProps} >
          <Path d={Bottom} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={voiceColor}
            d={VibrationOff}
          />
        </G>
      ),
    },
    LipsSpread: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsSpread}
          />
        </G>
      ),
    },
    LipsRounded: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsRounded}
          />
        </G>
      ),
    },
    LipsBLStop: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsBLStop}
          />
        </G>
      ),
    },
    LipsBLFric: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsBLFric}
          />
        </G>
      ),
    },
    LipsLD: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsLD}
          />
        </G>
      ),
    },
    LipsSpreadDFric: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsSpread}
          />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={DFTongueTip}
          />
        </G>
      ),
    },
    LipsRoundedDFric: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsRounded}
          />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={DFTongueTip}
          />
        </G>
      ),
    },
    LipsBLStopDFric: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsBLStop}
          />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={DFTongueTip}
          />
        </G>
      ),
    },
    LipsBLFricDFric: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsBLFric}
          />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={DFTongueTip}
          />
        </G>
      ),
    },
    LipsLDDFric: {
      svg: (
        <G {...gProps} >
          <Path d={TopTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={lipsColor}
            d={LipsLD}
          />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={DFTongueTipFric}
          />
        </G>
      ),
    },
    Tongue00: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue00}
          />
        </G>
      ),
    },
    Tongue11: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue11}
          />
        </G>
      ),
    },
    Tongue12: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue12}
          />
        </G>
      ),
    },
    Tongue13: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue13}
          />
        </G>
      ),
    },
    Tongue14: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue14}
          />
        </G>
      ),
    },
    Tongue15: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue15}
          />
        </G>
      ),
    },
    Tongue16: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue16}
          />
        </G>
      ),
    },
    Tongue17: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue17}
          />
        </G>
      ),
    },
    Tongue21: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue21}
          />
        </G>
      ),
    },
    Tongue22: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue22}
          />
        </G>
      ),
    },
    Tongue23: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue23}
          />
        </G>
      ),
    },
    Tongue25: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue25}
          />
        </G>
      ),
    },
    Tongue26: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue26}
          />
        </G>
      ),
    },
    Tongue27: {
      svg: (
        <G {...gProps} >
          <Path d={Pallet + BottomTooth} />
          <Path
            strokeWidth={STROKE_WIDTH}
            stroke={tongueColor}
            d={Tongue27}
          />
        </G>
      ),
    },
  };
};


var Phonics = [];
for (let v = 0; v < 2; v++) {
  Phonics.push([]);
  for (let n = 0; n < 2; n++) {
    Phonics[v].push([]);
    for (let l = 0; l < 5; l++) {
      Phonics[v][n].push([]);
      for (let t = 0; t < 14; t++) {
        Phonics[v][n][l].push([]);
      }
    }
  }
}

Phonics[0][0][0][0] = { ipa: 'ɦ' };
Phonics[0][0][0][1] = { ipa: 'ð' };
Phonics[0][0][0][2] = { ipa: 'd̪' };
Phonics[0][0][0][3] = { ipa: 'd' };
Phonics[0][0][0][4] = { ipa: 'ɖ' };
Phonics[0][0][0][5] = { ipa: 'ɟ' };
Phonics[0][0][0][6] = { ipa: 'ɡ' };
Phonics[0][0][0][7] = { ipa: 'ɢ' };
Phonics[0][0][0][8] = { ipa: 'z' };
Phonics[0][0][0][9] = { ipa: 'ʐ' };
Phonics[0][0][0][10] = { ipa: 'ʒ' };
Phonics[0][0][0][11] = { ipa: 'ʝ' };
Phonics[0][0][0][12] = { ipa: 'ɣ' };
Phonics[0][0][0][13] = { ipa: 'ʁ' };

Phonics[0][0][1][0] = { ipa: 'ɦʷ' };
Phonics[0][0][1][1] = { ipa: 'ðʷ' };
Phonics[0][0][1][2] = { ipa: 'd̪ʷ' };
Phonics[0][0][1][3] = { ipa: 'dʷ' };
Phonics[0][0][1][4] = { ipa: 'ɖʷ' };
Phonics[0][0][1][5] = { ipa: 'ɟʷ' };
Phonics[0][0][1][6] = { ipa: 'ɡʷ' };
Phonics[0][0][1][7] = { ipa: 'ɢʷ' };
Phonics[0][0][1][8] = { ipa: 'zʷ' };
Phonics[0][0][1][9] = { ipa: 'ʐʷ' };
Phonics[0][0][1][10] = { ipa: 'ʒʷ' };
Phonics[0][0][1][11] = { ipa: 'ʝʷ' };
Phonics[0][0][1][12] = { ipa: 'ɣʷ' };
Phonics[0][0][1][13] = { ipa: 'ʁʷ' };

Phonics[0][0][2][0] = { ipa: 'β' };
Phonics[0][0][2][1] = { ipa: 'ð͡β' };
Phonics[0][0][2][2] = { ipa: 'd̪' };
Phonics[0][0][2][3] = { ipa: 'd' };
Phonics[0][0][2][4] = { ipa: 'ɖ' };
Phonics[0][0][2][5] = { ipa: 'ɟ' };
Phonics[0][0][2][6] = { ipa: 'ɡ' };
Phonics[0][0][2][7] = { ipa: 'ɢ' };
Phonics[0][0][2][8] = { ipa: 'z͡β' };
Phonics[0][0][2][9] = { ipa: 'ʐ͡β' };
Phonics[0][0][2][10] = { ipa: 'ʒ͡β' };
Phonics[0][0][2][11] = { ipa: 'ʝ͡β' };
Phonics[0][0][2][12] = { ipa: 'ɣ͡β' };
Phonics[0][0][2][13] = { ipa: 'ʁ͡β' };

Phonics[0][0][3][0] = { ipa: 'b' };
Phonics[0][0][3][1] = { ipa: 'b' };
Phonics[0][0][3][2] = { ipa: 'd̪͡b' };
Phonics[0][0][3][3] = { ipa: 'd͡b' };
Phonics[0][0][3][4] = { ipa: 'ɖ͡b' };
Phonics[0][0][3][5] = { ipa: 'ɟ͡b' };
Phonics[0][0][3][6] = { ipa: 'ɡ͡b' };
Phonics[0][0][3][7] = { ipa: 'ɢ͡b' };
Phonics[0][0][3][8] = { ipa: 'b' };
Phonics[0][0][3][9] = { ipa: 'b' };
Phonics[0][0][3][10] = { ipa: 'b' };
Phonics[0][0][3][11] = { ipa: 'b' };
Phonics[0][0][3][12] = { ipa: 'b' };
Phonics[0][0][3][13] = { ipa: 'b' };

Phonics[0][0][4][0] = { ipa: 'v' };
Phonics[0][0][4][1] = { ipa: 'ð͡v' };
Phonics[0][0][4][2] = { ipa: 'd̪' };
Phonics[0][0][4][3] = { ipa: 'd' };
Phonics[0][0][4][4] = { ipa: 'ɖ' };
Phonics[0][0][4][5] = { ipa: 'ɟ' };
Phonics[0][0][4][6] = { ipa: 'ɡ' };
Phonics[0][0][4][7] = { ipa: 'ɢ' };
Phonics[0][0][4][8] = { ipa: 'z͡v' };
Phonics[0][0][4][9] = { ipa: 'ʐ͡v' };
Phonics[0][0][4][10] = { ipa: 'ʒ͡v' };
Phonics[0][0][4][11] = { ipa: 'ʝ͡v' };
Phonics[0][0][4][12] = { ipa: 'ɣ͡v' };
Phonics[0][0][4][13] = { ipa: 'ʁ͡v' };

Phonics[0][1][0][0] = { ipa: 'ɦ̃' };
Phonics[0][1][0][1] = { ipa: 'ð̃' };
Phonics[0][1][0][2] = { ipa: 'n̪' };
Phonics[0][1][0][3] = { ipa: 'n' };
Phonics[0][1][0][4] = { ipa: 'ɳ' };
Phonics[0][1][0][5] = { ipa: 'ɲ' };
Phonics[0][1][0][6] = { ipa: 'ŋ' };
Phonics[0][1][0][7] = { ipa: 'ɴ' };
Phonics[0][1][0][8] = { ipa: 'z̃' };
Phonics[0][1][0][9] = { ipa: 'ʐ̃' };
Phonics[0][1][0][10] = { ipa: 'ʒ̃' };
Phonics[0][1][0][11] = { ipa: 'ʝ̃' };
Phonics[0][1][0][12] = { ipa: 'ɣ̃' };
Phonics[0][1][0][13] = { ipa: 'ʁ̃' };

Phonics[0][1][1][0] = { ipa: 'ɦ̃ʷ' };
Phonics[0][1][1][1] = { ipa: 'ð̃ʷ' };
Phonics[0][1][1][2] = { ipa: 'n̪ʷ' };
Phonics[0][1][1][3] = { ipa: 'nʷ' };
Phonics[0][1][1][4] = { ipa: 'ɳʷ' };
Phonics[0][1][1][5] = { ipa: 'ɲʷ' };
Phonics[0][1][1][6] = { ipa: 'ŋʷ' };
Phonics[0][1][1][7] = { ipa: 'ɴʷ' };
Phonics[0][1][1][8] = { ipa: 'z̃ʷ' };
Phonics[0][1][1][9] = { ipa: 'ʐ̃ʷ' };
Phonics[0][1][1][10] = { ipa: 'ʒ̃ʷ' };
Phonics[0][1][1][11] = { ipa: 'ʝ̃ʷ' };
Phonics[0][1][1][12] = { ipa: 'ɣ̃ʷ' };
Phonics[0][1][1][13] = { ipa: 'ʁ̃ʷ' };

Phonics[0][1][2][0] = { ipa: 'β̃' };
Phonics[0][1][2][1] = { ipa: 'ð̃͡β̃' };
Phonics[0][1][2][2] = { ipa: 'n̪' };
Phonics[0][1][2][3] = { ipa: 'n' };
Phonics[0][1][2][4] = { ipa: 'ɳ' };
Phonics[0][1][2][5] = { ipa: 'ɲ' };
Phonics[0][1][2][6] = { ipa: 'ŋ' };
Phonics[0][1][2][7] = { ipa: 'ɴ' };
Phonics[0][1][2][8] = { ipa: 'z̃͡β̃' };
Phonics[0][1][2][9] = { ipa: 'ʐ̃͡β̃' };
Phonics[0][1][2][10] = { ipa: 'ʒ̃͡β̃' };
Phonics[0][1][2][11] = { ipa: 'ʝ̃͡β̃' };
Phonics[0][1][2][12] = { ipa: 'ɣ̃͡β̃' };
Phonics[0][1][2][13] = { ipa: 'ʁ̃͡β̃' };

Phonics[0][1][3][0] = { ipa: 'm' };
Phonics[0][1][3][1] = { ipa: 'm' };
Phonics[0][1][3][2] = { ipa: 'n̪͡m' };
Phonics[0][1][3][3] = { ipa: 'n͡m' };
Phonics[0][1][3][4] = { ipa: 'ɳ͡m' };
Phonics[0][1][3][5] = { ipa: 'ɲ͡m' };
Phonics[0][1][3][6] = { ipa: 'ŋ͡m' };
Phonics[0][1][3][7] = { ipa: 'ɴ͡m' };
Phonics[0][1][3][8] = { ipa: 'm' };
Phonics[0][1][3][9] = { ipa: 'm' };
Phonics[0][1][3][10] = { ipa: 'm' };
Phonics[0][1][3][11] = { ipa: 'm' };
Phonics[0][1][3][12] = { ipa: 'm' };
Phonics[0][1][3][13] = { ipa: 'm' };

Phonics[0][1][4][0] = { ipa: 'ɱ' };
Phonics[0][1][4][1] = { ipa: 'ɱ' };
Phonics[0][1][4][2] = { ipa: 'n̪͡ɱ' };
Phonics[0][1][4][3] = { ipa: 'n͡ɱ' };
Phonics[0][1][4][4] = { ipa: 'ɳ͡ɱ' };
Phonics[0][1][4][5] = { ipa: 'ɲ͡ɱ' };
Phonics[0][1][4][6] = { ipa: 'ŋ͡ɱ' };
Phonics[0][1][4][7] = { ipa: 'ɴ͡ɱ' };
Phonics[0][1][4][8] = { ipa: 'ɱ' };
Phonics[0][1][4][9] = { ipa: 'ɱ' };
Phonics[0][1][4][10] = { ipa: 'ɱ' };
Phonics[0][1][4][11] = { ipa: 'ɱ ' };
Phonics[0][1][4][12] = { ipa: 'ɱ ' };
Phonics[0][1][4][13] = { ipa: 'ɱ' };

Phonics[1][0][0][0] = { ipa: 'h' };
Phonics[1][0][0][1] = { ipa: 'θ' };
Phonics[1][0][0][2] = { ipa: 't̪' };
Phonics[1][0][0][3] = { ipa: 't' };
Phonics[1][0][0][4] = { ipa: 'ʈ' };
Phonics[1][0][0][5] = { ipa: 'c' };
Phonics[1][0][0][6] = { ipa: 'k' };
Phonics[1][0][0][7] = { ipa: 'q' };
Phonics[1][0][0][8] = { ipa: 's' };
Phonics[1][0][0][9] = { ipa: 'ʂ' };
Phonics[1][0][0][10] = { ipa: 'ʃ' };
Phonics[1][0][0][11] = { ipa: 'ç' };
Phonics[1][0][0][12] = { ipa: 'x' };
Phonics[1][0][0][13] = { ipa: 'χ' };

Phonics[1][0][1][0] = { ipa: 'hʷ' };
Phonics[1][0][1][1] = { ipa: 'θʷ' };
Phonics[1][0][1][2] = { ipa: 't̪ʷ' };
Phonics[1][0][1][3] = { ipa: 'tʷ' };
Phonics[1][0][1][4] = { ipa: 'ʈʷ' };
Phonics[1][0][1][5] = { ipa: 'cʷ' };
Phonics[1][0][1][6] = { ipa: 'kʷ' };
Phonics[1][0][1][7] = { ipa: 'qʷ' };
Phonics[1][0][1][8] = { ipa: 'sʷ' };
Phonics[1][0][1][9] = { ipa: 'ʂʷ' };
Phonics[1][0][1][10] = { ipa: 'ʃʷ' };
Phonics[1][0][1][11] = { ipa: 'çʷ' };
Phonics[1][0][1][12] = { ipa: 'xʷ' };
Phonics[1][0][1][13] = { ipa: 'χʷ' };

Phonics[1][0][2][0] = { ipa: 'ɸ' };
Phonics[1][0][2][1] = { ipa: 'θ͡ɸ' };
Phonics[1][0][2][2] = { ipa: 't̪' };
Phonics[1][0][2][3] = { ipa: 't' };
Phonics[1][0][2][4] = { ipa: 'ʈ' };
Phonics[1][0][2][5] = { ipa: 'c' };
Phonics[1][0][2][6] = { ipa: 'k' };
Phonics[1][0][2][7] = { ipa: 'q' };
Phonics[1][0][2][8] = { ipa: 's͡ɸ' };
Phonics[1][0][2][9] = { ipa: 'ʂ͡ɸ' };
Phonics[1][0][2][10] = { ipa: 'ʃ͡ɸ' };
Phonics[1][0][2][11] = { ipa: 'ç͡ɸ' };
Phonics[1][0][2][12] = { ipa: 'x͡ɸ' };
Phonics[1][0][2][13] = { ipa: 'χ͡ɸ' };

Phonics[1][0][3][0] = { ipa: 'p' };
Phonics[1][0][3][1] = { ipa: 'p' };
Phonics[1][0][3][2] = { ipa: 't̪͡p' };
Phonics[1][0][3][3] = { ipa: 't͡p' };
Phonics[1][0][3][4] = { ipa: 'ʈ͡p' };
Phonics[1][0][3][5] = { ipa: 'c͡p' };
Phonics[1][0][3][6] = { ipa: 'k͡p' };
Phonics[1][0][3][7] = { ipa: 'q͡p' };
Phonics[1][0][3][8] = { ipa: 'p' };
Phonics[1][0][3][9] = { ipa: 'p' };
Phonics[1][0][3][10] = { ipa: 'p' };
Phonics[1][0][3][11] = { ipa: 'p' };
Phonics[1][0][3][12] = { ipa: 'p' };
Phonics[1][0][3][13] = { ipa: 'p' };

Phonics[1][0][4][0] = { ipa: 'f' };
Phonics[1][0][4][1] = { ipa: 'θ͡f' };
Phonics[1][0][4][2] = { ipa: 't̪' };
Phonics[1][0][4][3] = { ipa: 't' };
Phonics[1][0][4][4] = { ipa: 'ʈ' };
Phonics[1][0][4][5] = { ipa: 'c' };
Phonics[1][0][4][6] = { ipa: 'k' };
Phonics[1][0][4][7] = { ipa: 'q' };
Phonics[1][0][4][8] = { ipa: 's͡f' };
Phonics[1][0][4][9] = { ipa: 'ʂ͡f' };
Phonics[1][0][4][10] = { ipa: 'ʃ͡f' };
Phonics[1][0][4][11] = { ipa: 'ç͡f' };
Phonics[1][0][4][12] = { ipa: 'x͡f' };
Phonics[1][0][4][13] = { ipa: 'χ͡f' };

Phonics[1][1][0][0] = { ipa: 'h̃' };
Phonics[1][1][0][1] = { ipa: 'θ̃' };
Phonics[1][1][0][2] = { ipa: 'n̪̥' };
Phonics[1][1][0][3] = { ipa: 'n̥' };
Phonics[1][1][0][4] = { ipa: 'ɳ̥' };
Phonics[1][1][0][5] = { ipa: 'ɲ̥' };
Phonics[1][1][0][6] = { ipa: 'ŋ̥' };
Phonics[1][1][0][7] = { ipa: 'ɴ̥' };
Phonics[1][1][0][8] = { ipa: 's̃' };
Phonics[1][1][0][9] = { ipa: 'ʂ̃' };
Phonics[1][1][0][10] = { ipa: 'ʃ̃' };
Phonics[1][1][0][11] = { ipa: 'ç̃' };
Phonics[1][1][0][12] = { ipa: 'x̃' };
Phonics[1][1][0][13] = { ipa: 'χ̃' };

Phonics[1][1][1][0] = { ipa: 'h̃ʷ' };
Phonics[1][1][1][1] = { ipa: 'θ̃ʷ' };
Phonics[1][1][1][2] = { ipa: 'n̪̥ʷ' };
Phonics[1][1][1][3] = { ipa: 'n̥ʷ' };
Phonics[1][1][1][4] = { ipa: 'ɳ̥ʷ' };
Phonics[1][1][1][5] = { ipa: 'ɲ̥ʷ' };
Phonics[1][1][1][6] = { ipa: 'ŋ̥ʷ' };
Phonics[1][1][1][7] = { ipa: 'ɴ̥ʷ' };
Phonics[1][1][1][8] = { ipa: 's̃ʷ' };
Phonics[1][1][1][9] = { ipa: 'ʂ̃ʷ' };
Phonics[1][1][1][10] = { ipa: 'ʃ̃ʷ' };
Phonics[1][1][1][11] = { ipa: 'ç̃ʷ' };
Phonics[1][1][1][12] = { ipa: 'x̃ʷ' };
Phonics[1][1][1][13] = { ipa: 'χ̃ʷ' };

Phonics[1][1][2][0] = { ipa: 'ɸ̃' };
Phonics[1][1][2][1] = { ipa: 'θ̃͡ɸ̃' };
Phonics[1][1][2][2] = { ipa: 'n̪̥' };
Phonics[1][1][2][3] = { ipa: 'n̥' };
Phonics[1][1][2][4] = { ipa: 'ɳ̥' };
Phonics[1][1][2][5] = { ipa: 'ɲ̥' };
Phonics[1][1][2][6] = { ipa: 'ŋ̥' };
Phonics[1][1][2][7] = { ipa: 'ɴ̥' };
Phonics[1][1][2][8] = { ipa: 's̃͡ɸ̃' };
Phonics[1][1][2][9] = { ipa: 'ʂ̃͡ɸ̃' };
Phonics[1][1][2][10] = { ipa: 'ʃ̃͡ɸ̃' };
Phonics[1][1][2][11] = { ipa: 'ç̃͡ɸ̃' };
Phonics[1][1][2][12] = { ipa: 'x̃͡ɸ̃' };
Phonics[1][1][2][13] = { ipa: 'χ̃͡ɸ̃' };

Phonics[1][1][3][0] = { ipa: 'm̥' };
Phonics[1][1][3][1] = { ipa: 'm̥' };
Phonics[1][1][3][2] = { ipa: 'n̪̥͡m̥' };
Phonics[1][1][3][3] = { ipa: 'n̥͡m̥' };
Phonics[1][1][3][4] = { ipa: 'ɳ̥͡m̥' };
Phonics[1][1][3][5] = { ipa: 'ɲ̥͡m̥' };
Phonics[1][1][3][6] = { ipa: 'ŋ̥͡m̥' };
Phonics[1][1][3][7] = { ipa: 'ɴ̥͡m̥' };
Phonics[1][1][3][8] = { ipa: 'm̥' };
Phonics[1][1][3][9] = { ipa: 'm̥' };
Phonics[1][1][3][10] = { ipa: 'm̥' };
Phonics[1][1][3][11] = { ipa: 'm̥' };
Phonics[1][1][3][12] = { ipa: 'm̥' };
Phonics[1][1][3][13] = { ipa: 'm̥' };

Phonics[1][1][4][0] = { ipa: 'ɱ̥' };
Phonics[1][1][4][1] = { ipa: 'ɱ̥' };
Phonics[1][1][4][2] = { ipa: 'n̪̥͡ɱ̥' };
Phonics[1][1][4][3] = { ipa: 'n̥͡ɱ̥' };
Phonics[1][1][4][4] = { ipa: 'ɳ̥͡ɱ̥' };
Phonics[1][1][4][5] = { ipa: 'ɲ̥͡ɱ̥' };
Phonics[1][1][4][6] = { ipa: 'ŋ̥͡ɱ̥' };
Phonics[1][1][4][7] = { ipa: 'ɴ̥͡ɱ̥' };
Phonics[1][1][4][8] = { ipa: 'ɱ̥' };
Phonics[1][1][4][9] = { ipa: 'ɱ̥' };
Phonics[1][1][4][10] = { ipa: 'ɱ̥' };
Phonics[1][1][4][11] = { ipa: 'ɱ̥' };
Phonics[1][1][4][12] = { ipa: 'ɱ̥' };
Phonics[1][1][4][13] = { ipa: 'ɱ̥' };

export const phonics = Phonics;

var rev = new Map();
for (let v = 0; v < 2; v++) {
  for (let n = 0; n < 2; n++) {
    for (let l = 0; l < 5; l++) {
      for (let t = 0; t < 14; t++) {
        const ipaValue = Phonics[v][n][l][t].ipa;
        if (ipaValue && !rev.has(ipaValue)) {
          rev.set(ipaValue, `${v}-${n}-${l}-${t}`);
        }
      }
    }
  }
}

export const rPhonics = rev;
