import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements';
import { Card, Drawer, DynamicMap, FlatList, LaunchCamera, LaunchGallery, Screen, View } from '../components';
import { useAppDispatch, useAppState, useOahuState, useOahuDispatch, useSystemState, OAHU_KEYS, OAHU_TYPES, APP_TYPES } from '../context';
import { Console, Optional } from '../utils';
import { Colors } from '../styles';
import { getOahuSurf } from '../thirdparty';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';
import { API } from '../aws';
import { hawaii } from './views';
import { Navigate } from './utils';

const NAME = 'MapView';

const MAP_DETAIL = 'MapDetail';
const GET_SURF = true;
const TYPES_HEIGHT = 50;

const MATERIAL_COMMUNITY = 'material-community';
const MATERIAL_ICONS = 'material-icons';

const DISABLED_ICON_COLOR = Colors.colors.lightgray;

const DUMMY = 'dummy';


var Toggles = new Map();

class MapViewUtils {

    static GetToggle(type) {
        return Toggles.has(type) ? Toggles.get(type) : false;
    }

    static Toggle(type) {
        Console.log('Toggle', { type, Toggles });
        if (!Toggles.has(type)) {
            Toggles.set(type, true);
        } else {
            Toggles.set(type, !Toggles.get(type));
        }
    }

    static IsNearby(record, lat, lng, zoom = 1.0) {
        if (!record || !record?.coordinate || !record?.type || !lat || !lng) {
            return false;
        }
        const close = new Set([
            OAHU_KEYS.ATMS,
            OAHU_KEYS.BIKI,
            OAHU_KEYS.BUS_STOPS,
            OAHU_KEYS.BUSES,
            OAHU_KEYS.TOILETS,
        ]);
        if (!close.has(record.type)) {
            return true;
        }
        const NEAR_DLAT = record.type === OAHU_KEYS.BUSES ? 0.025 : 0.005;
        const NEAR_DLNG = record.type === OAHU_KEYS.BUSES ? 0.025 : 0.005;
        const dlat = Math.abs(lat - record.coordinate.latitude);
        const dlng = Math.abs(lng - record.coordinate.longitude);
        return (dlat <= NEAR_DLAT && dlng <= NEAR_DLNG) ? true : false;
    }
}

var MapViews = [];

Object.keys(hawaii).forEach(island =>
    Object.keys(hawaii[island]).forEach(view => {
        MapViews.push({ heading: 0, pitch: 0, ...hawaii[island][view] });
    }));

const IconColors = [
    Colors.colors.red,
    Colors.colors.darkorange,
    Colors.colors.yellow,
    Colors.colors.lightgreen,
    Colors.colors.lightblue,
    Colors.colors.violet,
    'rgb(188,0,188)',
];

const IconMap = new Map([

    // ---- general info

    [OAHU_KEYS.WEATHER, { name: 'weather-cloudy', color: IconColors[0] }],
    [OAHU_KEYS.SURF, { name: 'surfing', color: IconColors[1] }],
    [OAHU_KEYS.BUSES, { name: 'bus', color: IconColors[2] }],
    [OAHU_KEYS.ATMS, { name: 'currency-usd', color: IconColors[3] }],
    [OAHU_KEYS.BUS_STOPS, { name: 'bus-stop', color: IconColors[4] }],
    [OAHU_KEYS.BIKI, { name: 'bicycle', color: IconColors[5] }],
    [OAHU_KEYS.TOILETS, { name: 'human-male-female', color: IconColors[6] }], // 'toilet'

    // attractions

    [OAHU_KEYS.AIRPORTS, { name: 'airplane', color: IconColors[0] }],
    [OAHU_KEYS.MARINAS, { name: 'sail-boat', color: IconColors[1] }],
    [OAHU_KEYS.TOP20, { name: 'binoculars', color: IconColors[2] }],
    [OAHU_KEYS.BEACHES, { name: 'swim'/*'umbrella-beach'*/, color: IconColors[3] }],
    [OAHU_KEYS.HIKES, { name: 'hiking', color: IconColors[4] }],
    [OAHU_KEYS.GOLF, { name: 'golf', color: IconColors[5] }],
    [OAHU_KEYS.CHURCHES, { name: 'church', color: IconColors[6] }],

    // commercial

    [OAHU_KEYS.YELP_DEPTSTORES, { name: 'purse', color: IconColors[0] }],
    [OAHU_KEYS.YELP_GROCERY, { name: 'cart', color: IconColors[1] }],
    [OAHU_KEYS.YELP_COFFEE, { name: 'coffee', color: IconColors[2] }],
    [OAHU_KEYS.YELP_RESTAURANTS, { name: 'silverware-fork-knife', color: IconColors[3] }],
    [OAHU_KEYS.YELP_BARS, { name: 'glass-mug-variant', color: IconColors[4] }],
    [OAHU_KEYS.YELP_HOTELS, { name: 'bed', color: IconColors[5] }],
    [OAHU_KEYS.MUSIC, { name: 'music-note', color: IconColors[6] }],

    // ----
    /*
        ['favorites', { name: 'star', color: IconColors[6] }],
        ['events', { name: 'clock', color: IconColors[6] }],

        ['turtle', { name: 'waterfall', color: IconColors[0] }],
        ['horse', { name: 'horse-human', color: IconColors[0] }],
        ['dinosaur', { name: 'google-downasaur', color: IconColors[0] }],
        ['tour', { name: 'bus-double-decker', color: IconColors[0] }],
        ['helicopter', { name: 'helicopter', color: IconColors[0] }],
        ['swim', { name: 'swim', color: IconColors[0] }],

        // https://materialdesignicons.com/
        // bus, bus-stop, umbrella-beach(-outline), telescope, swim, food-fork-drink, qrcode, tower-beach
        // store, pig-variant, diving-scuba-flag, bed, coffee, palm-tree, pine-tree, plane-car, pistol,
        // fish(-off), jellyfish, satellite-variant, weather-sunset-up, weather-sunset-down, phone-classic,
        // penguin, koala, kitesurfing, parachute, paragliding, noodles, pizza, lighthouse, kayaking, ice-cream
        // hook (fishing), hamburger, glass-wine, flower, cigar, cannabis
    */
]);
/* yelp searches
active, breweries, cafes, hotels, hotelstravel, chinese, japanese, breakfast_brunch,
localflavor, nightlife, movietheaters, museums, parks, religiousorgs, publicservicesgovt,
shopping, restaurants, tours, transport, travelservices, wineries, zoos
*/

// https://github.com/swrobel/meta-surf-forecast#new-api-v2


export const MapView = props => {

    const {
        navigation,
    } = props;

    const appDisatch = useAppDispatch();
    const appDispatchRef = useRef(appDisatch);

    const { isWeb, adjHeight, geocodeLatitude, geocodeLongitude } = useSystemState();

    const { t, dark, drawer, language, showAds } = useAppState();

    const {
        buses, busMap, busesUpdate,
        satellites, satellitesUpdate,
        locations, locationsUpdate,
        surf, surfUpdate,
        weather, weatherUpdate,
    } = useOahuState();

    const oahuDispatch = useOahuDispatch();
    const oahuDispatchRef = useRef(oahuDispatch);

    const [filteredBuses, setFilteredBuses] = useState([]);
    const setFilteredBusesRef = useRef(setFilteredBuses);

    const [type, setType] = useState('');
    const setTypeRef = useRef(setType);

    const [filterUpdate, setFilterUpdate] = useState(0);
    const setFilterUpdateRef = useRef(setFilterUpdate);

    const [show, setShow] = useState(false);
    const setShowRef = useRef(setShow);

    const [info, setInfo] = useState('');
    const setInfoRef = useRef(setInfo);

    const [currency, setCurrency] = useState('currency-usd');
    const setCurrencyRef = useRef(setCurrency);

    const [religion, setReligion] = useState('church');
    const setReligionRef = useRef(setReligion);

    const [iconLayer, setIconLayer] = useState(1);
    const setIconLayerRef = useRef(setIconLayer);

    const [cameraView, setCameraView] = useState();
    const setCameraViewRef = useRef(setCameraView);

    const [uri, setUri] = useState(null);
    const setUriRef = useRef(setUri);

    useEffect(
        () => {
            const filtered = buses.filter(v => MapViewUtils.IsNearby(v, geocodeLatitude, geocodeLongitude));
            Console.log(`${NAME} useEffect bus filter`, { buses: buses?.length ? buses[0] : 0, busesUpdate, geocodeLatitude, geocodeLongitude, filtered: filtered?.length ? filtered[0] : 0 });
            setFilteredBusesRef.current(filtered);
        },
        [
            buses,
            busesUpdate,
            geocodeLatitude,
            geocodeLongitude,
            setFilteredBusesRef,
        ],
    );

    useEffect(
        () => {
            // keep setInterval ids
            var intervalIds = [];

            [
                // MARKMARK: fix this so that multiple categories are called at once
                {
                    key: OAHU_KEYS.LOCATIONS, categories: [

                        OAHU_KEYS.ATMS,
                        OAHU_KEYS.BUS_STOPS,
                        OAHU_KEYS.BIKI,
                        OAHU_KEYS.TOILETS,

                        OAHU_KEYS.ATTRACTIONS,
                        OAHU_KEYS.HIKES,

/*
                        OAHU_KEYS.AIRPORTS,
                        OAHU_KEYS.MARINAS,
                        OAHU_KEYS.TOP20,
                        OAHU_KEYS.BEACHES,
                        OAHU_KEYS.HIKES,
                        OAHU_KEYS.GOLF,
                        OAHU_KEYS.CHURCHES,

                        OAHU_KEYS.YELP_DEPTSTORES,
                        OAHU_KEYS.YELP_GROCERY,
                        OAHU_KEYS.YELP_COFFEE,
                        OAHU_KEYS.YELP_RESTAURANTS,
                        OAHU_KEYS.YELP_BARS,
                        OAHU_KEYS.YELP_HOTELS,
                        OAHU_KEYS.MUSIC,
*/
                    ],
                    delay: 3600000,
                },
                { key: OAHU_KEYS.BUSES, categories: [DUMMY], delay: 60000/* / 8*/ * 2 }, // 7.5s
                { key: OAHU_KEYS.WEATHER, categories: [DUMMY], delay: 3600000 / 4 }, // 15m
                //{ key: SATELLITES, categories: [DUMMY], delay: 86400000 / 4 }, // 6h
            ].forEach(search => {
                const { key, categories, delay } = search;
                const _type = OAHU_TYPES[`UPDATE_${key.toUpperCase()}`];
                Console.log(`${NAME} useEffect hidata`, { search, _type });
                categories.forEach(category => {
                    const func = async () => {
                        var body = {
                            command: key,
                        };
                        if (key === OAHU_KEYS.SATELLITES) {
                            body.lat = 21.82;
                            body.lng = -157.84;
                        }
                        if (category !== DUMMY) {
                            body.categories = [category];
                        }
                        try {
                            const payload = await API.hidata(body);
                            Console.log(`${NAME} hidata result`, { key, category, body, payload });
                            if (payload) {
                                oahuDispatchRef.current({ type: _type, payload });
                            }
                        } catch (error) {
                            Console.error(`${NAME} hidata error [${key},${category}]`, error);
                        }
                    };
                    intervalIds.push(setInterval(func, delay));
                    func();
                });
            });

            // cancel all of the setIntervals
            return () => {
                Console.log(`${NAME} useEffect hidata clearing`, { intervalIds });
                intervalIds.forEach(v => clearInterval(v));
            };
        },
        [
            oahuDispatchRef,
        ],
    );

    useEffect(
        () => {
            var surfIntervalId = null;
            if (GET_SURF && !surf?.length && locations[OAHU_KEYS.SURF] && locations[OAHU_KEYS.SURF]?.length) {
                const surfLocations = locations[OAHU_KEYS.SURF];//.filter(v => v?.type === SURF);
                var surfReports = new Map();

                if (surfLocations?.length) {
                    Console.log(`${NAME} useEffect getOahuSurf`, { surfLocations: surfLocations.length });
                    async function getSurf() {
                        const surflineIds = surfLocations.filter(v => v?.sl).map(v => v.sl);
                        Console.log(`${NAME} useEffect getOahuSurf fetching`, { surflineIds });

                        const surfData = await getOahuSurf(surflineIds);
                        for (let i = 0; i < surfData.length; i++) {
                            const report = surfData[i];
                            const id = report?.id;
                            if (surfReports.has(id)) {
                                surfReports.set(id, { ...surfReports.get(id), ...report });
                            } else {
                                surfReports.set(id, report);
                            }
                        }
                        var payload = [];
                        surfLocations.forEach(loc => {
                            var report = surfReports.get(loc?.sl);
                            var temp = { ...loc };
                            if (temp?.sl) {
                                delete temp.sl;
                            }
                            if (report) {
                                delete report.id;
                                payload.push({ ...temp, report });
                            } else {
                                payload.push(temp);
                            }
                        });
                        oahuDispatchRef.current({ type: OAHU_TYPES.UPDATE_SURF, payload });
                    }
                    getSurf();
                    setInterval(getSurf, 3600000); // 1 hr
                }
            }
            return () => {
                if (surfIntervalId) {
                    Console.log(`${NAME} useEffect getOahuSurf clearing`, { surfIntervalId });
                    clearInterval(surfIntervalId);
                }
            };
        },
        [
            language,
            locations,
            locationsUpdate,
            surf,
            oahuDispatchRef,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect toggle drawer`);
            setShowRef.current(v => !v);
        },
        [
            drawer,
            setShowRef,
        ],
    );

    useEffect(
        () => {
            // MARKMARK: Needs some tlc for other languages, etc, use diff icon lib for mosques, etc
            Console.log(`${NAME} useEffect currency/church update`);
            if (language === 'zh' || language === 'cn' || language === 'tw') {
                setCurrencyRef.current('currency-cny');
                setReligionRef.current('church');
            } else if (language === 'ja' || language === 'jp') {
                setCurrencyRef.current('currency-jpy');
                setReligionRef.current('church');
            } else if (language === 'ko' || language === 'kr') {
                setCurrencyRef.current('currency-krw');
                setReligionRef.current('church');
            } else if (language === 'ar') {
                //setCurrencyRef.current('currency-krw');
                setReligionRef.current('church');
            } else {
                setCurrencyRef.current('currency-usd');
                setReligionRef.current('church');
            }
        },
        [
            language,
            setCurrencyRef,
            setReligionRef,
        ],
    );

    const onCardPress = useCallback(
        (_item, _value) => {
            var _cameraView = { pitch: 0, heading: 0, zoom: 15 };
            if (_item?.item?.coordinate) {
                _cameraView.center = _item.item.coordinate;
            } else if (_item?.item?.coordinates?.length) {
                _cameraView.center = _item.item.coordinates[0];
            }
            Console.devLog(`${NAME}.onCardPress`, { _item, _value, _cameraView });
            setCameraViewRef.current(JSON.stringify(_cameraView));
            appDispatchRef.current({ type: APP_TYPES.TOGGLE_DRAWER });
        },
        [
            setCameraViewRef,
            appDispatchRef,
        ],
    );

    const renderItem = useCallback(
        item => {
            Console.log(`${NAME}.renderItem`, { index: item?.index, item: item?.item });
            const cardStyle = { backgroundColor: item?.item?.color ? item.item.color : Colors.colors.lightgray };
            return (
                <Card
                    id={item?.index}
                    titleStyle={styles.black}
                    cardStyle={cardStyle}
                    title={item?.item?.title ? item.item.title : item?.item ? item.item : 'unknown title'}
                    onPress={value => onCardPress(item, value)}
                />
            );
        },
        [
            onCardPress,
        ],
    );

    const renderControls = useCallback(
        () => {
            return Optional(!isWeb, (
                <View
                    style={styles.controls}
                >
                    <Icon
                        type={MATERIAL_ICONS}
                        name={'public'}
                        color={Colors.colors.lightblue}
                        iconStyle={styles.icon}
                        onPress={() => {
                            Console.devLog('setMapType');
                            //setMapTypeRef.current(v => v === 'standard' ? 'satellite' : 'standard');
                        }}
                    />
                    <Icon
                        type={MATERIAL_ICONS}
                        name={'map'}
                        color={Colors.colors.pink/*overlay ? Colors.colors.white : Colors.colors.lightblue*/}
                        iconStyle={styles.icon}
                        onPress={() => {
                            Console.devLog('setOverlay');
                            //setOverlayRef.current(v => !v);
                        }}
                    />
                    <Icon
                        type={MATERIAL_ICONS}
                        name={'zoom-out-map'}
                        color={Colors.colors.lightblue}
                        iconStyle={styles.icon}
                        onPress={() => {
                            Console.devLog('setMapViewIndex');
                            //setMapViewIndexRef.current(v => v + 1);
                        }}
                    />
                    <Icon
                        type={MATERIAL_ICONS}
                        name={'my-location'}
                        color={Colors.colors.pink/*geocodeTimestamp ? Colors.colors.white : Colors.colors.lightblue*/}
                        iconStyle={styles.icon}
                        onPress={() => {
                            Console.devLog('setPosition');
                            //getGeocode(payload => systemDispatchRef.current({ type: SYSTEM_TYPES.SET_GEOLOCATION, payload }));
                            //center();
                        }}
                    />
                    <Icon
                        type={MATERIAL_COMMUNITY}
                        name={'abacus'/*geocodeTimestamp ? TransitMode[modeIndex].icon : TransitMode[0].icon*/}
                        color={Colors.colors.pink/*!showDirections && geocodeTimestamp ? TransitMode[modeIndex].color : TransitMode[0].color*/}
                        iconStyle={styles.icon}
                        onPress={() => {
                            Console.devLog('setModeIndex');
                            //!showDirections && geocodeTimestamp ? setModeIndexRef.current(m => (m + 1) % TransitMode.length) : null;
                        }}
                    />
                    <Icon
                        type={MATERIAL_COMMUNITY}
                        name={'camera'}
                        color={Colors.colors.lightblue}
                        underlayColor={Colors.colors.transparent}
                        iconStyle={styles.icon}
                        onPress={async () => {
                            const _uri = await LaunchCamera();
                            if (_uri) {
                                setUriRef.current(_uri);
                            }
                        }}
                    />
                    <Icon
                        type={MATERIAL_COMMUNITY}
                        name={'view-gallery'}
                        color={Colors.colors.lightblue}
                        underlayColor={Colors.colors.transparent}
                        iconStyle={styles.icon}
                        onPress={async () => {
                            const _uri = await LaunchGallery();
                            if (_uri) {
                                setUriRef.current(_uri);
                            }
                        }}
                    />
                </View>
            ));
        },
        [
            isWeb,
            setUriRef,
        ],
    );

    const renderIcon = useCallback(
        (_type, enabled = true) => {
            const icon = IconMap.get(_type);
            Console.log(`${NAME}.renderIcon`, { _type, icon });
            const colorStyle = MapViewUtils.GetToggle(_type) ? { color: Colors.colors.white } : {};
            return (
                <Icon
                    type={MATERIAL_COMMUNITY}
                    name={_type === OAHU_KEYS.ATMS ? currency : _type === OAHU_KEYS.CHURCHES ? religion : icon.name}
                    color={enabled ? icon.color : DISABLED_ICON_COLOR}
                    underlayColor={enabled ? icon.color : DISABLED_ICON_COLOR}
                    iconStyle={[styles.icon, colorStyle]}
                    onPress={enabled
                        ? () => {
                            setTypeRef.current(_type);
                            MapViewUtils.Toggle(_type);
                            setFilterUpdateRef.current(v => v + 1);
                        }
                        : () => { }
                    }
                />
            );
        },
        [
            currency,
            religion,
            setTypeRef,
            setFilterUpdateRef,
        ],
    );

    const renderList = useCallback(
        data => {
            const blackList = new Set([
                OAHU_KEYS.ATMS,
                OAHU_KEYS.BIKI,
                OAHU_KEYS.BUS_STOPS,
                OAHU_KEYS.BUSES,
                OAHU_KEYS.SURF,
                OAHU_KEYS.TOILETS,
                OAHU_KEYS.WEATHER,
            ]);
            var locationData = {};
            Object.keys(locations)
                .filter(key => !blackList.has(key) && MapViewUtils.GetToggle(key))
                .forEach(key => {
                    locationData[key] = locations[key];
                });

            const hasData = data?.length ? true : false;
            const hasSurf = MapViewUtils.GetToggle(OAHU_KEYS.SURF) && surf?.length ? true : false;
            const hasLocations = Object.keys(locationData).length ? true : false;
            //            const isLocation = !blackList.has(type);
            var _locations = [];
            if (!hasData && hasSurf) {
                _locations = [..._locations, ...surf];
            }
            if (!hasData && hasLocations) {
                Object.keys(locationData).forEach(key => {
                    _locations = [..._locations, ...locationData[key]];
                });
            }

            //if ((isLocation && !hasLocationData) || (!isLocation && (!hasData && !hasSurf))) {
            if (!hasData && !hasSurf && !hasLocations) {
                return null;
            }

            Console.log(`${NAME}.renderList`, { _locations, hasData, hasSurf, hasLocations, filterUpdate/*, isLocation*/ });

            return (
                <View
                    style={styles.info}
                >
                    {Optional(hasData, (
                        <FlatList
                            data={data}
                            renderItem={renderItem}
                        />
                    ), (
                        <FlatList
                            data={_locations.sort()}
                            renderItem={renderItem}
                        />
                    ))}
                </View>
            );

            /*
            return (
                <View
                    style={styles.info}
                >
                    {Optional(type === OAHU_KEYS.WEATHER && hasData, (
                        <FlatList
                            data={data}
                            renderItem={renderItem}
                        />
                    ))}
                    {Optional(type === OAHU_KEYS.SURF && hasSurf, (
                        <FlatList
                            data={surf}
                            renderItem={renderItem}
                        />
                    ))}
                    {Optional(isLocation && hasLocationData, (
                        <FlatList
                            data={locationData}
                            renderItem={renderItem}
                        />
                    ))}
                </View>
            );
            */
        },
        [
            //type,
            locations,
            surf,
            renderItem,
            filterUpdate,
        ],
    );

    const onCalloutPress = useCallback(
        (loc) => {
            Console.devLog(`${NAME}.onCalloutPress`, { loc });
            return true;
            /*
            if (loc.type === OAHU_KEYS.WEATHER) {
                var text = [];
                if (loc?.minTemperature) {
                    text.push(`min: ${loc.minTemperature.toFixed(2)}`);
                }
                if (loc?.maxTemperature) {
                    text.push(`max: ${loc.maxTemperature.toFixed(2)}`);
                }
                if (loc?.humidity) {
                    text.push(`humidity: ${loc.humidity.toFixed(2)}`);
                }
                if (loc?.dewpoint) {
                    text.push(`dew: ${loc.dewpoint.toFixed(2)}`);
                }
                if (loc?.windDirection) {
                    text.push(`wind-dir: ${loc.windDirection}`);
                }
                if (loc?.windGust) {
                    text.push(`wind-gust: ${loc.windGust.toFixed(2)}`);
                }
                if (loc?.windSpeed) {
                    text.push(`wind-speed: ${loc.windSpeed.toFixed(2)}`);
                }
                const value = text.join('|');
                console.log('\n\n*** onCalloutPress weather ***\n\n', { loc, value });
                setInfoRef.current(value);
            } else if (loc.type === OAHU_KEYS.SURF) {
                var text = [];
                const rt = loc?.sl ? SurfRT[loc.sl] : null;
                if (rt?.description) {
                    text.push(rt.description);
                }
                if (rt?.weather) {
                    text.push(rt.weather)
                }
                if (rt?.wind) {
                    text.push(rt.wind);
                }
                if (rt?.tide) {
                    text.push(rt.tide);
                }
                if (rt?.swell1) {
                    text.push(rt.swell1)
                }
                if (rt?.swell2) {
                    text.push(rt.swell2)
                }
                if (rt?.swell3) {
                    text.push(rt.swell3)
                }
                if (rt?.swell4) {
                    text.push(rt.swell4)
                }
                if (rt?.swell5) {
                    text.push(rt.swell5)
                }
                const value = text.join('|');
                console.log('\n\n*** onCalloutPress surf ***\n\n', { loc, value, rt });
                setInfoRef.current(value);
            } else {
                console.log('\n\n*** onCalloutPress unknown ***\n\n', { loc });
                setInfoRef.current(null);
            }
            */
        },
        [
            //setInfoRef,
        ],
    );

    const onLocationSelect = useCallback(
        loc => {
            Console.devLog(`${NAME}.onLocationSelect`, { loc });
            Navigate(navigation, t(MAP_DETAIL), { title: loc.title, loc });
        },
        [
            navigation,
            t,
        ],
    );

    const renderIcons = useCallback(
        layer => {
            if (layer === 1) {
                return (
                    <>
                        {renderIcon(OAHU_KEYS.WEATHER, weather.length ? true : false)}
                        {renderIcon(OAHU_KEYS.SURF, surf.length ? true : false)}
                        {renderIcon(OAHU_KEYS.BUSES, filteredBuses.length ? true : false)}
                        {renderIcon(OAHU_KEYS.ATMS, geocodeLatitude ? true : false)}
                        {renderIcon(OAHU_KEYS.BUS_STOPS, geocodeLatitude ? true : false)}
                        {renderIcon(OAHU_KEYS.BIKI, geocodeLatitude ? true : false)}
                        {renderIcon(OAHU_KEYS.TOILETS, geocodeLatitude ? true : false)}
                    </>
                );
            } else if (layer === 2) {
                return (
                    <>
                        {renderIcon(OAHU_KEYS.AIRPORTS)}
                        {renderIcon(OAHU_KEYS.MARINAS)}
                        {renderIcon(OAHU_KEYS.TOP20)}
                        {renderIcon(OAHU_KEYS.BEACHES)}
                        {renderIcon(OAHU_KEYS.HIKES)}
                        {renderIcon(OAHU_KEYS.GOLF)}
                        {renderIcon(OAHU_KEYS.CHURCHES)}
                    </>
                );
            } else if (layer === 3) {
                return (
                    <>
                        {renderIcon(OAHU_KEYS.YELP_DEPTSTORES)}
                        {renderIcon(OAHU_KEYS.YELP_GROCERY)}
                        {renderIcon(OAHU_KEYS.YELP_COFFEE)}
                        {renderIcon(OAHU_KEYS.YELP_RESTAURANTS)}
                        {renderIcon(OAHU_KEYS.YELP_BARS)}
                        {renderIcon(OAHU_KEYS.YELP_HOTELS)}
                        {renderIcon(OAHU_KEYS.MUSIC)}
                    </>
                );
            }
            return null;
        },
        [
            renderIcon,
            weather,
            surf,
            filteredBuses,
            geocodeLatitude,
        ],
    );

    const renderContent = useCallback(
        (
            _busesUpdate,
            _satellitesUpdate,
            _locationsUpdate,
            _surfUpdate,
            _weatherUpdate,
            _filterUpdate,
            _cameraView,
        ) => {

            const filterStyle = { backgroundColor: dark ? Colors.colors.black : 'rgb(36,98,195)' };
            var locationData = [];
            Object.keys(locations)
                .filter(key => MapViewUtils.GetToggle(key))
                .forEach(key => {
                    locationData = [...locationData, ...locations[key]];
                });

            const iconEnabled = dark ? Colors.colors.white : Colors.colors.black;
            const iconDisabled = dark ? Colors.colors.gray : Colors.colors.lightgray;
            const leftColor = iconLayer === 1 ? iconDisabled : iconEnabled;
            const rightColor = iconLayer === 3 ? iconDisabled : iconEnabled;

            const adsHeight = { height: showAds ? adjHeight - BANNER_AD_HEIGHT - TYPES_HEIGHT : adjHeight - TYPES_HEIGHT };

            const showBuses = MapViewUtils.GetToggle(OAHU_KEYS.BUSES);
            const showSatellites = MapViewUtils.GetToggle(OAHU_KEYS.SATELLITES);
            const showSurf = MapViewUtils.GetToggle(OAHU_KEYS.SURF);
            const showWeather = MapViewUtils.GetToggle(OAHU_KEYS.WEATHER);

            Console.log(`${NAME}.renderContent`, {
                showBuses,
                showSatellites,
                showSurf,
                showWeather,
                weather: weather?.length ? weather[0] : null,
                buses: filteredBuses?.length ? filteredBuses[0] : null,
                locationData: locationData?.length ? locationData[0] : null,
                adjHeight, _filterUpdate, _busesUpdate, _satellitesUpdate, _locationsUpdate, _surfUpdate, _weatherUpdate, _cameraView,
            });

            // MARKMARK: Remove GetToggle={MapViewUtils.GetToggle} from DynamicMapProps
            return (
                <View
                    style={[styles.container, adsHeight]}
                >
                    <View
                        style={styles.map}
                    >
                        <DynamicMap
                            type={type}
                            locations={locationData}
                            buses={showBuses ? filteredBuses : []}
                            busMap={busMap}
                            busesUpdate={showBuses ? _busesUpdate : -_busesUpdate}
                            weather={showWeather ? weather : []}
                            weatherUpdate={showWeather ? _weatherUpdate : -_weatherUpdate}
                            surf={showSurf ? surf : []}
                            surfUpdate={showSurf ? _surfUpdate : -_surfUpdate}
                            satellites={showSatellites ? satellites : []}
                            satellitesUpdate={showSatellites ? _satellitesUpdate : -_satellitesUpdate}
                            onCalloutPress={onCalloutPress}
                            onLocationSelect={onLocationSelect}
                            iconMap={IconMap}
                            currency={currency}
                            religion={religion}
                            GetToggle={MapViewUtils.GetToggle}
                            mapViews={MapViews}
                            setInfoRef={setInfoRef}
                            initialView={hawaii.oahu.island}
                            cameraView={_cameraView}
                            setCameraViewRef={setCameraViewRef}
                        />
                    </View>
                    <View
                        style={[styles.types, filterStyle]}
                    >
                        <Icon
                            type={MATERIAL_COMMUNITY}
                            name={'menu-left'}
                            color={leftColor}
                            underlayColor={leftColor}
                            iconStyle={styles.icon}
                            onPress={() => setIconLayerRef.current(v => v > 1 ? v - 1 : v)}
                        />
                        {renderIcons(iconLayer)}
                        <Icon
                            type={MATERIAL_COMMUNITY}
                            name={'menu-right'}
                            color={rightColor}
                            underlayColor={rightColor}
                            iconStyle={styles.icon}
                            onPress={() => setIconLayerRef.current(v => v < 3 ? v + 1 : v)}
                        />
                    </View>
                </View>
            );
        },
        [
            showAds,
            adjHeight,
            renderIcons,
            iconLayer,
            setIconLayerRef,
            onCalloutPress,
            onLocationSelect,
            dark,
            type,
            currency,
            religion,
            filteredBuses,
            busMap,
            satellites,
            locations,
            surf,
            weather,
            setCameraViewRef,
        ],
    );

    Console.devStack(NAME, props, { uri, locations, dark, drawer, language, type, show, currency, religion, geocodeLatitude, geocodeLongitude, cameraView, showAds });

    return useMemo(
        () => {
            const data = info ? info.split('|') : [];
            const content = renderList(data);
            Console.log(`${NAME} render`, { busesUpdate, satellitesUpdate, locationsUpdate, surfUpdate, weatherUpdate, cameraView, show, info, data });
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <Drawer
                        show={show}
                        toggleShow={() => show ? appDispatchRef.current({ type: APP_TYPES.TOGGLE_DRAWER }) : null}
                        percentage={85}
                        content={content}
                    >
                        {renderContent(busesUpdate, satellitesUpdate, locationsUpdate, surfUpdate, weatherUpdate, filterUpdate, cameraView)}
                    </Drawer>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                    {renderControls()}
                </Screen>
            );
        },
        [
            props,
            showAds,
            busesUpdate,
            satellitesUpdate,
            locationsUpdate,
            surfUpdate,
            weatherUpdate,
            filterUpdate,
            show,
            //currency,
            //religion,
            info,
            //type,
            renderContent,
            renderList,
            appDispatchRef,
            cameraView,
            renderControls,
        ],
    );
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
        //backgroundColor: Colors.colors.red,
    },
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: Colors.colors.white, //'rgb(36,98,195)',
    },
    map: {
        width: '100%',
    },
    types: {
        width: '100%',
        height: TYPES_HEIGHT,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: Colors.colors.lightgray,
        paddingHorizontal: 10,
    },
    icon: {
        padding: 5,
        fontSize: 30,
    },
    info: {
        height: '85%',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    black: {
        color: Colors.colors.black,
    },
    controls: {
        position: 'absolute',
        top: '3%',
        left: '87%',
        elevation: 5,
        zIndex: 5,
        backgroundColor: Colors.colors.transparent,
    },
});
